/*
Login process:
  1) User submits form on LoginPage.react.js (not done through redux or password would be part of action)
  2) On response - if success, dispatch login event; on fail, dispatch error event
  3) Relocate to dashboard
*/
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGIN_CLEAR = "USER_LOGIN_CLEAR";
export const USER_LOGIN_LOADING = "USER_LOGIN_LOADING";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_NEEDS_LOGIN = "USER_NEEDS_LOGIN";

export const userLoginSuccess = info => ({
  type: USER_LOGIN_SUCCESS,
  payload: info,
});

export const userLoginFailure = error => ({
  type: USER_LOGIN_FAILURE,
  payload: error,
});

export const userLoginLoading = () => ({
  type: USER_LOGIN_LOADING,
});

export const userLoginClear = () => ({
  type: USER_LOGIN_CLEAR,
});

export const userLogout = () => ({
  type: USER_LOGOUT,
});

export const userNeedsToLogin = () => ({
  type: USER_NEEDS_LOGIN,
});
