import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Row, Col, Button, FormGroup } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  HiddenInput,
  TextInput,
  CheckboxInput,
  PasswordStrength,
} from "App/components/Inputs/BasicFormInputs.js";
import axiosAPI, { CancelToken } from "App/services/axios";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import styles from "./UserModal.module.scss";
const qs = require("qs");

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginFileValidateType,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
);

const UserModal = ({
  edit = false,
  visible = false,
  setVisible,
  user = {},
  addUser,
  editUser,
  loading,
}) => {
  const [passwordInfo, setPasswordInfo] = useState({}); //contains lots of bonus ball info about the password eg strength and recommendations
  const [avatarFileId, setAvatarFileId] = useState(user?.AvatarFileId || null); //contains info about avatar
  const [submitDisabled, setSubmitDisabled] = useState(false); //so avatar upload can stop submit

  return (
    <Modal
      className="modal-dialog-centered formModal"
      isOpen={visible}
      data-testid="modal"
      backdrop="static"
    >
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="modal-header">
            <h2 className="modal-title">{edit ? "Edit User" : "Add User"}</h2>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setVisible(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              firstName: user?.firstName || "",
              lastName: user?.lastName || "",
              jobTitle: user?.jobTitle || "",
              email: user?.email || "",
              phone: user?.phone || "",
              disabled: user?.disabled || false,
              password: "",
              passwordRepeat: "",
              changePassword: false,
              requireSetNew: false,
            }}
            validationSchema={Yup.object({
              firstName: Yup.string()
                .required("Required")
                .max(200, "Cannot be longer than 200 characters"),
              lastName: Yup.string()
                .required("Required")
                .max(200, "Cannot be longer than 200 characters"),
              jobTitle: Yup.string()
                .notRequired()
                .max(200, "Cannot be longer than 200 characters"),
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
              changePassword: Yup.bool(),
              requireSetNew: Yup.bool(),
              password: Yup.string().when("changePassword", {
                is: true,
                then: Yup.string()
                  .required("Password is required")
                  .test(
                    "is-strong",
                    "Password is not strong enough",
                    value => passwordInfo.isValid === true,
                  ),
                otherwise: Yup.string(),
              }),
              passwordRepeat: Yup.string().when("changePassword", {
                is: true,
                then: Yup.string()
                  .equals([Yup.ref("password")], "Passwords do not match")
                  .required("Password confirm is required"),
                otherwise: Yup.string(),
              }),
            })}
            onSubmit={(values, { setSubmitting }) => {
              values = {
                ...values,
                AvatarFileId: avatarFileId || null,
              };
              if (edit) {
                let editValues = {
                  id: user.id,
                  ...values,
                };
                if (!editValues.changePassword) {
                  delete editValues.password; //leaves password intact if not asked to change
                }
                editUser(editValues);
              } else addUser(values);
            }}
          >
            {formikProps => {
              if (formikProps.values?.changePassword === true) {
                formikProps.values.requireSetNew = false;
              }
              return (
                <>
                  <div className="modal-body">
                    <Form>
                      <Row>
                        <Col lg={4}>
                          <FormGroup>
                            <label>Avatar</label>
                            <FilePond
                              name="file"
                              //files={
                              /*avatarInfo?.directoryPath &&
                                avatarInfo?.fileName && [
                                  {
                                    source: `${avatarInfo.directoryPath}${avatarInfo.fileName}`,
                                    options: {
                                      type: "local"
                                    }
                                  }
                                ]*/
                              //}
                              className={`${styles.filepond}`}
                              allowMultiple={false}
                              acceptedFileTypes={["image/jpeg", "image/png"]}
                              onprocessfilestart={async () => {
                                setSubmitDisabled(true);
                              }}
                              server={{
                                url: process.env.REACT_APP_API_BASE_URL,
                                revert: (uniqueFileId, load, error) => {
                                  axiosAPI
                                    .delete(`/files/${uniqueFileId}`)
                                    .then(result => {
                                      load();
                                    })
                                    .catch(errorCaught => {
                                      console.log(errorCaught);
                                      error(
                                        "An error occurred while deleting the file",
                                      );
                                    });
                                },
                                restore: null,
                                patch: null,
                                load: (
                                  source,
                                  load,
                                  error,
                                  progress,
                                  abort,
                                  headers,
                                ) => {
                                  const cancelToken = CancelToken.source();
                                  console.log(source, load);
                                  if (source) {
                                    axiosAPI
                                      .get(
                                        `/files/filepath?` +
                                          qs.stringify({
                                            filepath: `${source}`,
                                          }),
                                        {
                                          responseType: "blob",
                                          cancelToken: cancelToken.token,
                                        },
                                      )
                                      .then(result => {
                                        load(result.data);
                                      })
                                      .catch(error => {
                                        abort();
                                        console.error(error);
                                      });
                                  }
                                  // Should expose an abort method so the request can be cancelled
                                  return {
                                    abort: () => {
                                      // User tapped cancel, abort our ongoing actions here
                                      cancelToken.cancel(
                                        "Operation canceled by the user.",
                                      );

                                      // Let FilePond know the request has been cancelled
                                      abort();
                                    },
                                  };
                                },
                                process: (
                                  fieldName,
                                  file,
                                  metadata,
                                  load,
                                  error,
                                  progress,
                                  abort,
                                  transfer,
                                  options,
                                ) => {
                                  // fieldName is the name of the input field
                                  // file is the actual file object to send

                                  const formData = new FormData();
                                  formData.append(fieldName, file, file.name);
                                  formData.append("directoryPath", "avatars/");
                                  formData.append("type", "Avatar");
                                  formData.append("filepond", true);

                                  const source = CancelToken.source();

                                  axiosAPI
                                    .post(`/files`, formData, {
                                      onUploadProgress: function (e) {
                                        progress(
                                          e.lengthComputable,
                                          e.loaded,
                                          e.total,
                                        );
                                      },
                                      cancelToken: source.token,
                                    })
                                    .then(result => {
                                      // Should call the load method when done and pass the returned server file id
                                      // this server file id is then used later on when reverting or restoring a file
                                      // so your server knows which file to return without exposing that info to the client
                                      // the load method accepts either a string (id) or an object
                                      load(result.data);
                                      setAvatarFileId(result.data);
                                    })
                                    .catch(error => {
                                      // Can call the error method if something is wrong, should exit after
                                      //error(error);
                                      setAvatarFileId(null);
                                      console.log("Avatar upload error", error);
                                    })
                                    .finally(() => {
                                      setSubmitDisabled(false);
                                    });

                                  // Should expose an abort method so the request can be cancelled
                                  return {
                                    abort: () => {
                                      // This function is entered if the user has tapped the cancel button
                                      source.cancel(
                                        "Operation canceled by the user.",
                                      );
                                      setAvatarFileId(null);

                                      // Let FilePond know the request has been cancelled
                                      abort();
                                    },
                                  };
                                },
                              }}
                              instantUpload={true}
                              labelIdle="Drag and Drop new avatar or <span class='filepond--label-action'>browse</span>"
                              allowImageExifOrientation={true}
                              checkValidity={true}
                              maxFiles={1}
                              stylePanelLayout="compact circle"
                              imageCropAspectRatio="1:1"
                              imageResizeTargetWidth={300}
                              imageResizeTargetHeight={300}
                              imageResizeMode="cover"
                              imageTransformOutputMimeType="image/jpeg"
                              styleLoadIndicatorPosition="center bottom"
                              styleButtonRemoveItemPosition="left bottom"
                            ></FilePond>
                            <HiddenInput
                              label="Avatar Filename"
                              name="avatarFilename"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={8} className="pr-0">
                          <TextInput
                            label="First Name"
                            name="firstName"
                            col={12}
                          />
                          <TextInput
                            label="Last Name"
                            name="lastName"
                            col={12}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <TextInput label="Email" name="email" col={12} />
                      </Row>
                      <Row>
                        <TextInput label="Job Title" name="jobTitle" col={6} />
                        <TextInput label="Phone" name="phone" col={6} />
                      </Row>
                      <Row>
                        <CheckboxInput col={6} name="changePassword">
                          Change user password
                        </CheckboxInput>
                        {!formikProps.values?.changePassword && (
                          <CheckboxInput col={6} name="requireSetNew">
                            Email change password request
                          </CheckboxInput>
                        )}
                      </Row>
                      {formikProps.values?.changePassword && (
                        <Row>
                          <PasswordStrength
                            label="New Password"
                            name="password"
                            type="password"
                            showRecommendations={true}
                            col={12}
                            passwordInfoChange={setPasswordInfo}
                          />
                          <TextInput
                            label="Confirm New Password"
                            name="passwordRepeat"
                            type="password"
                            col={12}
                          />
                        </Row>
                      )}
                      <Row>
                        <CheckboxInput col={6} name="disabled">
                          Inactive (disables login)
                        </CheckboxInput>
                      </Row>
                    </Form>
                  </div>
                  <div className="modal-footer">
                    <Button
                      onClick={formikProps.submitForm}
                      color="primary"
                      type="submit"
                      data-testid="submitbutton"
                      disabled={submitDisabled}
                    >
                      {edit ? "Edit User" : "Add User"}
                    </Button>
                  </div>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </Modal>
  );
};

UserModal.propTypes = {
  edit: PropTypes.bool,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  user: PropTypes.object,
  addUser: PropTypes.func,
  editUser: PropTypes.func,
  loading: PropTypes.bool,
};

export default UserModal;
