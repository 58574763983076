import React, { useState, useEffect } from "react";
import ProductPicker from "./ProductPicker.js";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
const qs = require("qs");

const ProductPickerContainer = ({
  onSelect,
  containerId = null, //containerId is used to hide results if this changes, if the containers are sensitive to the results - eg different vendors cannot supply other vendor's products
  depotId = null, //so can show whether stock exists in this depot
  specificVendorId = null, //so can show only stock from one vendor (price lists only)
  specificDepotId = null, //so can show only stock from one depot (stock groups only)
}) => {
  const [value, setValue] = useState("");
  const [results, setResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMore, setErrorMore] = useState("");

  const search = async query => {
    let apiEndpoint = "/products";
    if (specificVendorId) apiEndpoint = `/vendors/${specificVendorId}/products`;

    if (query.indexOf("/") > -1) {
      query = query.substring(0, query.indexOf("/"));
    }
    axiosAPI
      .get(
        `${apiEndpoint}?${qs.stringify({
          search: query,
          limit: 5,
          sort: ["name", "ASC"],
        })}`,
      )
      .then(result => {
        setResults(result.data?.rows);
      })
      .catch(error => {
        setErrorMessage("An error occurred while fetching products");
        setErrorMore(error.toString() || "An unknown error occurred");
      });
  };

  const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  useEffect(() => {
    setShowResults(false);
    setResults([]);
    setValue("");
  }, [containerId]); // eslint-disable-line

  useEffect(() => {
    let currentQuery = true;
    const controller = new AbortController();

    const loadResults = async () => {
      if (!value) return setResults([]);

      await sleep(350);
      if (currentQuery) {
        const newResults = await search(value, controller);
        setResults(newResults);
      }
    };
    loadResults();

    return () => {
      currentQuery = false;
      controller.abort();
    };
  }, [value]); // eslint-disable-line

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage("");
          setErrorMore("");
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <ProductPicker
        value={value}
        onFocus={() => {
          search(value); //update results when click back in
          setShowResults(true);
        }}
        onBlur={() => {
          setTimeout(() => setShowResults(false), 200);
        }}
        specificVendorId={specificVendorId}
        specificDepotId={specificDepotId}
        depotId={depotId}
        onChange={value => setValue(value)}
        onSelect={onSelect}
        showResults={showResults}
        results={results}
      />
    </>
  );
};

export default ProductPickerContainer;
