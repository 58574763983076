import { createBrowserHistory } from "history"; //browser history
import { createStore, compose, applyMiddleware } from "redux"; //redux functions
import initialState from "./initialState"; //for pre-hyrdrating the state before redux persist
import createRootReducer from "./rootReducer"; //function exported that needs history passed into it
import createSagaMiddleware from "redux-saga"; //saga middleware to watch and dispatch sagas
import { routerMiddleware } from "connected-react-router"; //router to redux
import rootSaga from "./sagas"; //root saga
import { persistStore } from "redux-persist"; //persisting the store
import { persistReducer } from "redux-persist"; //persisting the reducer
import storage from "redux-persist/lib/storage"; //use default storage for persist

export const history = createBrowserHistory();

//Main config for persist
const mainPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user"],
  timeout: null,
};

const rootReducer = createRootReducer(history);
const rootPersistedReducer = persistReducer(mainPersistConfig, rootReducer);

//Connect devtools as an enhancer
const enhancerList = [];
const devToolsExtension = window && window.__REDUX_DEVTOOLS_EXTENSION__;
if (typeof devToolsExtension === "function") {
  enhancerList.push(devToolsExtension());
}

const sagaMiddleware = createSagaMiddleware(); //sagas
const composedEnhancer = compose(
  applyMiddleware(sagaMiddleware, routerMiddleware(history)),
  ...enhancerList,
);

export const store = createStore(
  rootPersistedReducer,
  initialState,
  composedEnhancer,
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
//persistor.purge();
