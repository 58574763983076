import React from "react";
import styles from "./DepotTransferTableRow.module.scss";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
const classNames = require("classnames");

const PurchaseOrderTableRow = ({
  node,
  path,
  updateNode,
  deleteNode,
  restoreName,
  selectRow,
  moveFocusToNextRow,
  costNominals = [],
  taxRates = [],
  enabled,
}) => {
  return {
    //the qty section of the row
    selectRow,
    dragHandle: (
      <i
        className={classNames({
          fa: true,
          "mx-1": true,
          "fa-box": node.type === 0,
          "opacity-4": node.type === 0,
          "fa-folder": node.type === 1 && !node.expanded,
          "fa-folder-open": node.type === 1 && node.expanded,
        })}
      ></i>
    ),
    qtyTitle: (
      <>
        {node.type === 0 && (
          <>
            <input
              className={`${styles.inputQuantity} ${styles.editableInput}`}
              value={node.quantity}
              name="quantity"
              type="number"
              min="0"
              step="1"
              disabled={!enabled}
              onFocus={e => e.target.select()}
              onKeyDown={e => {
                e.key === "Enter" &&
                  moveFocusToNextRow(node, "quantityInput", path);
              }}
              autoComplete="none"
              onChange={event => {
                updateNode(node, "quantity", event.target.value, path);
              }}
            />
            &times;
          </>
        )}
        <input
          className={`${
            node.type === 1 ? styles.folderName : styles.inputName
          } ${styles.editableInput}`}
          value={node.name}
          name="name"
          disabled={!enabled}
          onFocus={e => e.target.select()}
          onChange={event => {
            updateNode(node, "name", event.target.value, path);
          }}
        />
      </>
    ),
    //the main row
    row: (
      <>
        <div className={`${styles.tableProduct} ${styles.tableCell}`}>
          {node.type === 0 && (
            <>
              {node.type === 0 && (
                <Link
                  to={`/app/product/${node?.ProductId}`}
                  className={styles.productCodeLink}
                  target="_blank"
                  style={{ margin: "8px 0 0 3px", display: "inline-block" }}
                >
                  {node?.Product?.Manufacturer?.name}{" "}
                  {node?.Product?.manufacturerProductCode}
                </Link>
              )}
            </>
          )}
        </div>
      </>
    ),
    //right hand side ... button
    menuOptions: (
      <UncontrolledButtonDropdown size="sm" color="link">
        <DropdownToggle color="link" caret>
          Options
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem
            disabled={!enabled}
            onClick={() => {
              deleteNode(path);
            }}
          >
            Delete
          </DropdownItem>
          {node.type === 0 && (
            <DropdownItem
              disabled={!enabled}
              onClick={() => {
                restoreName(node, path);
              }}
            >
              Restore name
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    ),
  };
};

export default PurchaseOrderTableRow;
