import React from "react";
import { Row, Card, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import PageContainer from "App/components/PageContainer";
import ContactsListContainer from "App/components/Tables/ContactsList/ContactsList.container";
import CompaniesListContainer from "App/components/Tables/CompaniesList/CompaniesList.container";
import { useParams, NavLink } from "react-router-dom";

const Contacts = () => {
  const { tab = "contacts" } = useParams();

  return (
    <PageContainer title="Contacts">
      <Row>
        <div className="col">
          <Card className="shadow">
            <Nav tabs className="main-tabs">
              <NavItem>
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/app/contacts"
                  exact={true}
                >
                  Contacts
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/app/contacts/companies"
                >
                  Companies
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tab}>
              <TabPane tabId={"contacts"}>
                <ContactsListContainer />
              </TabPane>
              <TabPane tabId={"companies"}>
                <CompaniesListContainer />
              </TabPane>
            </TabContent>
          </Card>
        </div>
      </Row>
    </PageContainer>
  );
};

export default Contacts;
