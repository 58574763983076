import React, { Component } from "react";
import ProductPickerContainer from "App/components/Pickers/ProductPicker/ProductPicker.container";
import AddButton from "App/components/Tables/SortableTreeCommon/AddButton";
import SupplyingTable from "./SupplyingTable";
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import ItemPriceSelectorModalContainer from "App/components/Modals/ItemPriceSelectorModal/ItemPriceSelectorModal.container";
import {
  changeNodeAtPath,
  getNodeAtPath,
  getFlatDataFromTree,
  getTreeFromFlatData,
  addNodeUnderParent,
  removeNodeAtPath,
} from "react-sortable-tree";
import SweetAlert from "react-bootstrap-sweetalert";
import io from "socket.io-client";
import currencyFormatter from "App/helpers/currencyFormatter";
import { discountCalculate, marginCalculate } from "App/helpers/calculators";
import styles from "./SupplyingTableContainer.module.scss";
import axiosAPI from "App/services/axios";
import debounce from "App/helpers/debounce";
import defaultSocketOptions from "App/services/socketio";
const socket = io("/lists", defaultSocketOptions);
const classNames = require("classnames");

const initialVersionTotals = {
  loading: true,
  error: false,
  name: null,
  //uses same showDiscountAsPercent/showMarginAsPercent/showTotalAsExVat as totals: {}
  cost: 0,
  list: 0,
  exVat: 0,
  vat: 0,
  incVat: 0,
  nonTaxableItems: 0,
};

const getKey = node => node.id; //when you pass this into getTreeFromFlatData, it gives you each NODE, so you can take id straight from that
const getParentKey = node => node.ParentId; //when you pass this into getTreeFromFlatData, it gives you each NODE, so you can take id straight from that
const getNodeKey = ({ node }) => node.id; //getNodeKey is used by the SortableTree, and is passed an object containing the node and treeindex, so need to destructure it!
const canNodeHaveChildren = node => node.type !== 0; //ie items cannot have children

export default class SupplyingTableContainer extends Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);

    this.debounceUpdate = debounce(() => {
      this.updateTotals();
      this.updateDatabase();
    }, 800);

    socket.on("logout", () => {
      socket.disconnect();
      window.location = "/login";
    });

    //initially, get data from db, then make a tree, then expand
    //we then need to update totals - db doesnt store discount, margin, or totals - it only stores tweakable fields eg qty, name, line price
    //then retrospectively works out the discount% and margin% etc
    this.state = {
      shiftPressed: false,
      cmdPressed: false,
      ctrlPressed: false,
      totals: {
        showDiscountAsPercent: true,
        showMarginAsPercent: true,
        showTotalAsExVat: true,
        cost: 0,
        list: 0,
        exVat: 0,
        vat: 0,
        incVat: 0,
        nonTaxableItems: 0,
      },
      selectedTotals: {
        cost: 0,
        list: 0,
        exVat: 0,
        vat: 0,
        incVat: 0,
        nonTaxableItems: 0,
      },
      versionTotals: initialVersionTotals,
      list: this.props.list || {},
      undoHistory: [],
      redoHistory: [],
      databaseData: [], //this is our local representation of the current db state
      treeData: getTreeFromFlatData({
        //compared to databaseData, and then we tell server what to tweak ennit
        flatData: [],
        getKey: getKey, // resolve a node's key
        getParentKey: getParentKey, // resolve a node's parent's key
        rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
      }),
      showDiscountAsPercent: true,
      showMarginAsPercent: true,
      errorMessage: null,
      errorMore: null,
      itemPriceSelectorModal: {
        path: null,
        ProductId: null,
        PriceListItemId: null,
        StockGroupId: null,
        costPrice: null,
        listPrice: null,
        visible: false,
      },
    };
  }

  componentDidCatch(error, info) {
    /*    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    logComponentStackToMyService(info.componentStack);*/
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      errorMessage: "Oops, we got our wires crossed...",
      errorMore:
        "Sorry, something went badly wrong. Please try refreshing the page",
    };
  }

  //undo stack: just a stack of databaseDatas
  // [ [state], [state], [state], [state], [state] ]
  //   ^ 0 is newest (now)                  ^ 5 is oldest
  //so an undo would jump to [1], and remove [0,1]
  undo = () => {
    if (this.state.undoHistory.length > 1) {
      //[0] is always the current state, so [1] would be the last state
      const changeStateTo = this.state.undoHistory[1];
      this.setState(
        {
          undoHistory: [...this.state.undoHistory].slice(2),
          redoHistory: [
            ...[this.state.undoHistory[0]],
            ...this.state.redoHistory,
          ].slice(0, 5),
        },
        () => this.updateFromFlatData(changeStateTo),
      );
    }
  };

  redo = () => {
    if (this.state.redoHistory.length > 0) {
      //[0] is always the current state, so [1] would be the last state
      const changeStateTo = this.state.redoHistory[0];
      this.setState(
        {
          redoHistory: [...this.state.redoHistory].slice(1),
          undoHistory: [
            ...[this.state.redoHistory[0]],
            ...this.state.undoHistory,
          ].slice(0, 5),
        },
        () => this.updateFromFlatData(changeStateTo),
      );
    }
  };

  handleOnTreeChange = treeData => {
    console.log("ONCHANGE TO TREE", treeData);
    this.setState({ treeData }, async () => {
      await this.updateTotals();
      this.updateDatabase();
    });
  };

  updateTrees = items => {
    console.log("UPDATE TREES ", items);
    this.setState(
      {
        ...(JSON.stringify(items) !== JSON.stringify(this.state.undoHistory[0])
          ? { undoHistory: [...[items], ...this.state.undoHistory].slice(0, 5) }
          : {}),
        databaseData: items,
        treeData: getTreeFromFlatData({
          flatData: items,
          getKey: getKey, // resolve a node's key
          getParentKey: getParentKey, // resolve a node's parent's key
          rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
        }),
      },
      () => {
        this.updateTotals();
      },
    );
  };

  updateVersionTotals = () => {
    this.setState(
      { versionTotals: { ...this.state.versionTotals, loading: true } },
      () => {
        axiosAPI
          .get(`/versions/${this.state.list?.VersionId}/totals`)
          .then(result => {
            this.setState({
              versionTotals: {
                loading: false,
                error: false,
                name: result.data.name,
                ...result.data.totals,
              },
            });
          })
          .catch(error => {
            this.setState({
              versionTotals: {
                ...initialVersionTotals,
                loading: false,
                error: true,
              },
              errorMessage: error?.errorMessage || "An unknown error occurred",
              errorMore:
                error?.errorMore || "Something went wrong, please retry",
            });
            console.error(error);
          });
      },
    );
  };

  async updateDatabase() {
    const DEBUG = true;
    //only some keys we need to check compared to database to see if need to update etc
    const keysToCheck = [
      "name",
      "description",
      "memo",
      "quantity",
      "listPrice",
      "costPrice",
      "linePrice",
      "type",
      "PriceListItemId",
      "StockGroupId",
      "ProductId",
      "ParentId",
      "ListId",
      "sort",
      "expanded",
      "tempId",
      "tempParentId",
      "TaxRateId",
      "selected",
    ];

    //get data from tree, and only include properties we're interested in for comparison
    let flatData = getFlatDataFromTree({
      treeData: JSON.parse(JSON.stringify(this.state.treeData)),
      getNodeKey, // This ensures your "id" properties are exported in the path
      ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
    }).map(({ node, path }, sort) => {
      let newNode = {};
      keysToCheck.forEach((key, i) => {
        //this adds in only the keys we care about
        newNode[key] = node[key];
      });
      //there are some attributes we "calculate", eg treeParentIndex and ListId
      //so do these here:
      newNode = {
        ...newNode,
        //force them to have only 2dp so they pass validation
        ...(node.listPrice ? { listPrice: +node.listPrice.toFixed(2) } : {}),
        ...(node.linePrice ? { linePrice: +node.linePrice.toFixed(2) } : {}),
        ...(node.costPrice ? { costPrice: +node.costPrice.toFixed(2) } : {}),
        ListId: this.state.list?.id,
        id: path[path.length - 1],
        ParentId: path.length > 1 ? path[path.length - 2] : null,
        sort,
        tempId: node.tempId || null,
        expanded: node.expanded,
      };
      //console.log(newNode);
      return newNode;
    });

    //this will be submitted to API
    let nodeOperations = {
      insertItems: [], //array of new items eg { name: 'ads', ... }
      updateItems: [], //array of { id: 12, attributes: {}}
      deleteItems: [], //array of ids to delete eg [12, 15, 18]
    };
    //go through each flatdata and see if it exists and is same in our current database
    flatData.forEach((node, index) => {
      //Go through tree and see what has changed or is new
      let focussedDatabaseNode = {};

      let foundDatabaseNode = this.state.databaseData.filter(
        x => x.id === node.id,
      );
      if (foundDatabaseNode.length > 0) {
        //if (DEBUG) console.log("found node in db", foundDatabaseNode);
        //take it out of the array (as it returns it as [{obj}]   )
        foundDatabaseNode = foundDatabaseNode[0];
        //copy into the focussedDatabaseNode only the attributes we care about
        keysToCheck.forEach((key, i) => {
          //this adds in only the keys we care about
          //to check if theyve changed etc
          focussedDatabaseNode[key] = foundDatabaseNode[key];
        });
      }

      if (Object.keys(focussedDatabaseNode).length === 0) {
        //not found on database side, so insert
        if (DEBUG) console.log("not found node in db", node);
        nodeOperations.insertItems.push(node);
      } else {
        //found on database side, check if it's equal
        if (JSON.stringify(node) === JSON.stringify(focussedDatabaseNode)) {
          //equal so do nothing
          if (DEBUG) console.log("found node in db, and is equal", node);
        } else {
          //console.log(node, focussedDatabaseNode);
          let newNode = JSON.parse(JSON.stringify(node)); //deep copy
          //different so update
          let needToUpdate = false;
          Object.keys(focussedDatabaseNode).forEach(key => {
            if (newNode[key] === focussedDatabaseNode[key]) {
              delete newNode[key];
            } else if (key !== "id") {
              needToUpdate = true;
            }
          });
          if (needToUpdate) {
            //there are some attributes left to update!
            let updateNode = {
              id: node.id,
              attributes: { ...newNode },
            };
            if (DEBUG)
              console.log(
                "found node in db, but different, ",
                node,
                updateNode,
              );

            nodeOperations.updateItems.push(updateNode);
          }
        }
      }
    });

    //Go through database and check anything to delete (ie not present in tree)
    let databaseData = JSON.parse(JSON.stringify(this.state.databaseData));
    databaseData.forEach((node, i) => {
      let treeFoundNode = flatData.filter(x => x.id === node.id); //is it in the tree?
      if (treeFoundNode.length === 0) {
        // not found in tree so need to delete
        if (DEBUG)
          console.log("orphan left on db side, not in tree, delete", node);
        nodeOperations.deleteItems.push(node.id);
      }
    });

    if (
      nodeOperations.insertItems.length > 0 ||
      nodeOperations.updateItems.length > 0 ||
      nodeOperations.deleteItems.length > 0
    ) {
      this.updateList(nodeOperations);
      this.updateVersionTotals();
    }
  }

  updateTotals = () => {
    //these are running totals of all items within a list, as some items dont have vat applied
    let totalCostPrice = 0;
    let totalListPrice = 0; //includes *quantity
    let totalExVat = 0;
    let totalVat = 0;
    let totalIncVat = 0;
    let nonTaxableItems = [];

    let selCostPrice = 0;
    let selListPrice = 0; //includes *quantity
    let selExVat = 0;
    let selVat = 0;
    let selIncVat = 0;

    const calculateChildrenTotal = node => {
      let total = 0; //total price = linePrice * quantity
      let listPrice = 0; //used for the discount and margin calculations, not actually shown in folders
      let linePrice = 0; //used for the discount and margin calculations, not actually shown in folders
      let costPrice = 0; //sum of all cost price
      let discountTotal = 0; //sum of all discount
      let marginTotal = 0; //sum of all margin

      let selectedTotal = 0; //total price = linePrice * quantity
      let selectedListPrice = 0; //used for the discount and margin calculations, not actually shown in folders
      let selectedLinePrice = 0; //used for the discount and margin calculations, not actually shown in folders
      let selectedCostPrice = 0; //sum of all cost price
      let selectedDiscountTotal = 0; //sum of all discount
      let selectedMarginTotal = 0; //sum of all margin

      //let averageCount = 0; //works out averages

      console.log("updateTotals node", node);
      if (node.type === 1) {
        //if its a folder
        if (node.children && node.children?.length > 0) {
          //it has some lovely children
          node.children.forEach((child, i) => {
            //recursively call this function
            let childrenTotals = calculateChildrenTotal(child);
            total += childrenTotals.total;
            listPrice +=
              childrenTotals.listPrice * parseFloat(childrenTotals.quantity);
            linePrice +=
              childrenTotals.linePrice * parseFloat(childrenTotals.quantity);
            costPrice +=
              childrenTotals.costPrice * parseFloat(childrenTotals.quantity);

            selectedTotal += childrenTotals.selTotal;
            selectedListPrice += childrenTotals.selListPrice;
            selectedLinePrice += childrenTotals.selLinePrice;
            selectedCostPrice += childrenTotals.selCostPrice;

            //averageCount++;
          });
          discountTotal = discountCalculate(listPrice, linePrice).amount;
          marginTotal = marginCalculate(costPrice, linePrice).amount;

          selectedDiscountTotal = discountCalculate(
            selectedListPrice,
            selectedLinePrice,
          ).amount;
          selectedMarginTotal = marginCalculate(
            selectedCostPrice,
            selectedLinePrice,
          ).amount;
        } else {
          //its a poor lonely folder with no children boohoo
          total = 0;
          listPrice = 0;
          linePrice = 0;
          costPrice = 0;
          discountTotal = 0;
          marginTotal = 0;
          //averageCount = 1; //cant divide by 0!
        }
      } else {
        //its just a plain old boring rubbish horrible nasty item
        total =
          +(parseFloat(node.quantity) * parseFloat(node.linePrice)).toFixed(
            2,
          ) || 0; //work out total, qty*linePrice. || 0 in case null
        costPrice = +parseFloat(node.costPrice).toFixed(2) || 0; //get its cost price
        listPrice = +parseFloat(node.listPrice).toFixed(2) || 0; //get its list price for discount and margin calculators
        linePrice = +parseFloat(node.linePrice).toFixed(2) || 0; //get its line price for discount and margin calculators
        discountTotal = discountCalculate(listPrice, linePrice).amount;
        marginTotal = marginCalculate(costPrice, linePrice).amount;
        //averageCount = 1; //only one item

        //add to grand totals
        let vatAmount =
          node?.TaxRate?.rate > 0 ? total * (0.01 * node?.TaxRate?.rate) : 0;
        if (node?.TaxRate?.rate === 0) nonTaxableItems.push(node);
        totalCostPrice = +totalCostPrice + +costPrice * +node.quantity;
        totalListPrice = +totalListPrice + +listPrice * +node.quantity;
        totalExVat = +totalExVat + total;
        totalVat = +totalVat + vatAmount;
        totalIncVat = +totalIncVat + total + vatAmount;

        if (node.selected) {
          selCostPrice = +selCostPrice + +costPrice * +node.quantity;
          selListPrice = +selListPrice + +listPrice * +node.quantity;
          selExVat = +selExVat + total;
          selVat = +selVat + vatAmount;
          selIncVat = +selIncVat + total + vatAmount;
        }
      }
      node.total = total; //update this node's total, and return it for parent recursion
      node.costPrice = costPrice;
      node.discountTotal = discountTotal;
      node.marginTotal = marginTotal;
      node.listPrice = listPrice;
      node.linePrice = linePrice;
      node.discountPercent = discountCalculate(listPrice, linePrice).percent;
      node.marginPercent = marginCalculate(costPrice, linePrice).percent;
      return {
        total,
        listPrice,
        linePrice,
        costPrice,
        discountTotal,
        marginTotal,
        discountPercent: node.discountPercent,
        marginPercent: node.marginPercent,
        quantity: node.quantity || 1,

        selCostPrice,
        selListPrice,
        selExVat,
        selVat,
        selIncVat,
      };
    };

    const newTreeData = JSON.parse(JSON.stringify(this.state.treeData)); //deep copy array
    const pseudoRootNode = { type: 1, children: newTreeData }; //fake root node to get all children
    //let { treeTotal: total } = calculateChildrenTotal(pseudoRootNode);
    calculateChildrenTotal(pseudoRootNode);

    this.setState({
      treeData: newTreeData,
      totals: {
        ...this.state.totals,
        cost: totalCostPrice,
        list: totalListPrice,
        exVat: totalExVat,
        vat: totalVat,
        incVat: totalIncVat,
        nonTaxableItems: nonTaxableItems.length,
      },
      selectedTotals: {
        cost: selCostPrice,
        list: selListPrice,
        exVat: selExVat,
        vat: selVat,
        incVat: selIncVat,
      },
    });
  };

  handleDeleteNode = path => {
    this.setState(
      state => ({
        treeData: removeNodeAtPath({
          treeData: state.treeData,
          path,
          getNodeKey,
        }),
      }),
      async () => {
        await this.updateTotals();
        this.updateDatabase();
      },
    );
  };

  handleInsertNode = node => {
    this.setState(
      state => ({
        treeData: addNodeUnderParent({
          treeData: state.treeData,
          parentKey: null,
          expandParent: true,
          getNodeKey,
          newNode: node,
          addAsFirstChild: false,
        }).treeData,
      }),
      async () => {
        //console.log(this.state.treeData);
        //this.pickerSearchInputRef.current.focus();
        await this.updateTotals();
        this.updateDatabase();
      },
    );
  };

  handleRestoreName = (node, path) => {
    this.setState(
      {
        treeData: changeNodeAtPath({
          treeData: this.state.treeData,
          path,
          getNodeKey,
          newNode: { ...node, name: node.Product.name },
        }),
      },
      () => this.updateDatabase(),
    );
  };

  handleDoneSelectPricing = async values => {
    const currentNode = await getNodeAtPath({
      treeData: this.state.treeData,
      path: this.state.itemPriceSelectorModal.path,
      getNodeKey,
      ignoreCollapsed: false,
    });
    this.setState(
      {
        treeData: changeNodeAtPath({
          treeData: this.state.treeData,
          path: this.state.itemPriceSelectorModal.path,
          getNodeKey,
          newNode: { ...currentNode.node, ...values },
        }),
      },
      () => this.updateDatabase(),
    );
  };

  handleSelectPricing = (node, path) => {
    this.setState({
      itemPriceSelectorModal: {
        ...this.state.itemPriceSelectorModal,
        path,
        visible: true,
        ProductId: node?.ProductId,
        PriceListItemId: node?.PriceListItemId,
        StockGroupId: node?.StockGroupId,
        costPrice: node?.costPrice,
        listPrice: node?.listPrice,
      },
    });
  };

  ignoreKeyPress = target => {
    //was it on an input that isnt disabled (ie you can type in it!)
    if (target.tagName === "INPUT" && !target.disabled) return true;
    if (target.tagName === "SELECT" && !target.disabled) return true;
    //not a button (options dropdown)
    if (target.tagName === "BUTTON") return true;
    //otherwise its ok to use as a modifier
    return false;
  };

  async handleKeyDown(event) {
    const { key, target } = event;
    if (this.ignoreKeyPress(target)) return;

    key === "Shift" && this.setState({ shiftPressed: true });
    if (key === "Backspace") {
      //delete anything that is selected
      this.updateFromFlatData(
        this.state.databaseData.filter(row => !row.selected),
      );
    }
    key === "Meta" && this.setState({ cmdPressed: true });
    key === "Control" && this.setState({ ctrlPressed: true });
    //select / deselect all
    if (
      (this.state.cmdPressed || this.state.ctrlPressed) &&
      key.toLowerCase() === "a"
    ) {
      event.preventDefault();
      const selectOrDeselect = !this.state.databaseData[0]?.selected;
      this.updateFromFlatData(
        this.state.databaseData.map(row => ({
          ...row,
          selected: selectOrDeselect,
        })),
      );
    }
    //invert selection
    if (
      (this.state.cmdPressed || this.state.ctrlPressed) &&
      key.toLowerCase() === "i"
    ) {
      event.preventDefault();
      this.updateFromFlatData(
        this.state.databaseData.map(row => ({
          ...row,
          selected: !row.selected,
        })),
      );
    }
    //undo
    if (
      (this.state.cmdPressed || this.state.ctrlPressed) &&
      key.toLowerCase() === "z"
    ) {
      // event.preventDefault();
      // this.undo();
    }

    //redo
    if (
      (this.state.cmdPressed || this.state.ctrlPressed) &&
      this.state.shiftPressed &&
      key.toLowerCase() === "z"
    ) {
      // event.preventDefault();
      // this.redo();
    }
  }

  async handleKeyUp(event) {
    const { key, target } = event;
    if (this.ignoreKeyPress(target)) return;

    key === "Shift" && this.setState({ shiftPressed: false });
    key === "Meta" && this.setState({ cmdPressed: false });
    key === "Control" && this.setState({ ctrlPressed: false });
  }

  handleMoveFocusToNextRow = (node, field, path) => {
    const row = getNodeAtPath({
      treeData: this.state.treeData,
      path,
      getNodeKey,
      ignoreCollapsed: true,
    });
    console.log(row);
  };

  handleSelectRow = (node, path, e) => {
    if (this.ignoreKeyPress(e.target)) return;

    //get which modifier keys pressed with the click
    let { ctrlKey, metaKey, shiftKey } = e;
    let newData = [...this.state.databaseData];

    if (!ctrlKey && !metaKey && !shiftKey) {
      //only select one at a time
      newData = newData.map(row => {
        return row.id === node.id
          ? { ...row, selected: !row.selected }
          : { ...row, selected: false };
      });
    }

    if ((ctrlKey || metaKey) && !shiftKey) {
      //ADD to the selection or REMOVE from selection if already selected
      newData = newData.map(row => {
        return row.id === node.id ? { ...row, selected: !node.selected } : row;
      });
    }

    if (shiftKey && (!ctrlKey || !metaKey)) {
      //shift block selection
      let firstSelectedNode = newData.find(node => node.selected)?.sort;
      let lastSelectedNode = [...newData]
        .reverse()
        .find(node => node.selected)?.sort;

      if (firstSelectedNode === undefined) {
        //could be 0, so !firstSelectedNode wouldnt work
        //just do a normal selection
        newData = newData.map(row => {
          return row.id === node.id
            ? { ...row, selected: !row.selected }
            : { ...row, selected: false };
        });
      } else {
        //already have something else selected so can complete the multi selection
        let thisSelectedNode = node.sort;
        //work out which one is 'earlier' in the list
        let selectFromTo =
          firstSelectedNode < thisSelectedNode
            ? [firstSelectedNode, thisSelectedNode]
            : [thisSelectedNode, lastSelectedNode];
        newData = newData.map(row => {
          return row.sort >= selectFromTo[0] && row.sort <= selectFromTo[1]
            ? { ...row, selected: true }
            : { ...row, selected: false };
        });
      }
    }

    this.updateFromFlatData(newData);
  };

  handleUpdateNode = (node, fieldEdited, newValue, path) => {
    /*
    ones we care about:
    * = editable by user
      - quantity* (affects other fields)
      - name*
      - costPrice (only changed when u change vendor or remove vendor)
      - listPrice
      - customerPrice*
      - discount*
      - linePrice (=customerPrice*discount)
      - total (=qty*linePrice)
      - margin*

    */

    let newNode = { ...node };

    if (fieldEdited !== "discount" && fieldEdited !== "margin") {
      //storable value in the node
      newNode[fieldEdited] = newValue;
    } else {
      //calculated values, so we need to adjust line price to match these
      if (fieldEdited === "margin") {
        if (this.state.showMarginAsPercent) {
          //work out line price based on %
          newNode.marginPercent = newValue;
          newNode.linePrice = newNode.costPrice * (1 + newValue / 100);
        } else {
          //work out line price based on £
          newNode.marginTotal = newValue;
          newNode.linePrice = newNode.costPrice + parseFloat(newValue);
        }
      } else if (fieldEdited === "discount") {
        if (this.state.showDiscountAsPercent) {
          //work out line price based on %
          newNode.discountPercent = newValue;
          newNode.linePrice = newNode.listPrice * (1 - newValue / 100);
        } else {
          newNode.discountTotal = newValue;
          newNode.linePrice = newNode.listPrice - parseFloat(newValue);
        }
      }
    }

    this.setState(
      {
        treeData: changeNodeAtPath({
          treeData: this.state.treeData,
          path,
          getNodeKey,
          newNode: newNode,
        }),
      },
      this.debounceUpdate,
    );
  };

  updateList = operations => {
    socket.emit("updateList", this.state.list?.id, operations);
  };

  handleSocketListUpdates = async (listId, listUpdates) => {
    if (listId !== this.state.list?.id) return;
    console.log("SOCKET LISTUPDATES", listUpdates, this.state);

    let newDatabaseData = JSON.parse(JSON.stringify(this.state.databaseData));

    //remove any deleted ids
    listUpdates.deletedItems.forEach((id, i) => {
      newDatabaseData = newDatabaseData.filter(function (obj) {
        return obj.id !== id;
      });
    });

    //update any items
    listUpdates.updatedItems.forEach((item, i) => {
      const targetIndex = newDatabaseData.findIndex(f => f.id === item.id);
      newDatabaseData[targetIndex] = item;
    });

    //add in inserted items
    listUpdates.insertedItems.forEach((item, i) => {
      newDatabaseData.push(item);
    });

    await newDatabaseData.sort((a, b) => {
      //sort using comparison between a and b
      if (a.sort === b.sort) {
        return 0;
      } else if (a.sort > b.sort) {
        return 1;
      } else if (a.sort < b.sort) {
        return -1;
      }
      return 0;
    });

    //console.log(listUpdates, newDatabaseData);
    this.updateTrees(newDatabaseData);
  };

  joinList(id) {
    socket.emit("joinList", id);
    socket.on("getListItems", ({ listId, listItems }) => {
      if (listId === id) this.updateTrees(listItems);
    });
  }

  leaveList(id) {
    id && socket.emit("leaveList", id);
  }

  componentDidUpdate(prevProps, prevState) {
    //see if list Id has changed
    if (this.props.list?.id !== prevProps.list?.id) {
      this.setState({
        list: this.props.list,
      });
      this.updateVersionTotals();
      this.joinList(this.props.list?.id);
      this.leaveList(prevProps.list?.id);
    }
    //see if isActive has changed
    if (this.props.isActive !== prevProps.isActive) {
      //has changed
      if (this.props.isActive) {
        this.updateVersionTotals();
        this.joinList(this.props.list?.id);
      } else {
        this.leaveList(this.props.list?.id);
      }
    }
    //see if versionId has changed
    if (this.props.list?.VersionId !== prevProps.list?.VersionId) {
      this.updateVersionTotals();
    }
    //see if list isActive has changed
    if (this.props.list?.isActive !== prevProps.list?.isActive) {
      this.updateVersionTotals();
    }
  }

  //just a way of updating the tree from flat data, and totals, and database
  //used by select/delete operations
  updateFromFlatData(flatData) {
    this.setState(
      {
        treeData: getTreeFromFlatData({
          flatData,
          getKey, // resolve a node's key
          getParentKey, // resolve a node's parent's key
          rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
        }),
      },
      async () => {
        await this.updateTotals();
        this.updateDatabase();
      },
    );
  }

  componentDidMount() {
    this.joinList(this.props.list?.id);
    socket.on("socketError", ({ errorMessage, errorMore }) => {
      this.setState({
        errorMessage: errorMessage,
        errorMore: errorMore || "",
      });
    });

    //get version totals
    this.updateVersionTotals();

    //this is called when someone else updates the list - ie another client -> server -> us
    socket.on("listUpdates", this.handleSocketListUpdates);

    //monitor keys we care about
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);
  }

  /* Removing the listener before unmounting the component in order to avoid addition of multiple listener at the time revisit*/
  componentWillUnmount() {
    socket.off("getListItems");
    this.leaveList(this.props.list?.id);

    // Remove event listeners on cleanup
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  }

  render() {
    return (
      <>
        <SweetAlert
          danger
          onConfirm={() => {
            this.setState({ errorMessage: null, errorMore: null });
          }}
          title="Error"
          show={!!this.state.errorMessage}>
          {this.state.errorMessage}
          {this.state.errorMore && (
            <>
              <br />
              <small>{this.state.errorMore}</small>
            </>
          )}
        </SweetAlert>

        <Row className="pt-2">
          <Col xs={12} lg={6} style={{ display: "flex" }}>
            <h4
              className="float-left"
              style={{ paddingTop: "0.3rem", width: "120px" }}>
              Items
              {/*" "}
              {this.state.databaseData &&
                this.state.databaseData?.length > 0 && (
                  <span className="text-muted small">
                    &times; {this.state.databaseData?.length}
                  </span>
                )}*/}
            </h4>
            <ProductPickerContainer
              onSelect={this.handleInsertNode}
              depotId={this.props?.depotId}
            />
          </Col>
          <Col xs={12} lg={6}>
            <AddButton
              node={{
                id: Date.now(),
                name: "Folder",
                type: 1,
                quantity: 1,
                expanded: true,
              }}
              icon={"fa-folder"}
              onClick={this.handleInsertNode}
            />
            {/*<div className="d-inline-block ml-1">
              <ButtonGroup>
                <Button
                  style={{ height: "35px", paddingTop: "6px" }}
                  color="secondary"
                  onClick={() => this.undo()}
                  disabled={this.state.undoHistory.length <= 1}>
                  <i className={"fas fa-undo"} />
                </Button>
                <Button
                  style={{ height: "35px", paddingTop: "6px" }}
                  color="secondary"
                  onClick={() => this.redo()}
                  disabled={this.state.redoHistory.length === 0}>
                  <i className={"fas fa-redo"} />
                </Button>
              </ButtonGroup>
            </div>*/}
          </Col>
        </Row>

        <Row>
          <Col style={{ overflowX: "scroll" }}>
            <SupplyingTable
              treeData={this.state.treeData}
              onTreeChange={this.handleOnTreeChange}
              getNodeKey={getNodeKey}
              canNodeHaveChildren={canNodeHaveChildren}
              showMarginAsPercent={this.state.showMarginAsPercent}
              setShowMarginAsPercent={() =>
                this.setState({
                  showMarginAsPercent: !this.state.showMarginAsPercent,
                })
              }
              showDiscountAsPercent={this.state.showDiscountAsPercent}
              setShowDiscountAsPercent={() =>
                this.setState({
                  showDiscountAsPercent: !this.state.showDiscountAsPercent,
                })
              }
              updateNode={this.handleUpdateNode}
              deleteNode={this.handleDeleteNode}
              restoreName={this.handleRestoreName}
              selectPricing={this.handleSelectPricing}
              selectRow={this.handleSelectRow}
              moveFocusToNextRow={this.handleMoveFocusToNextRow}
            />
            <ItemPriceSelectorModalContainer
              done={values => this.handleDoneSelectPricing(values)}
              setVisible={visible =>
                this.setState({
                  ...this.state,
                  itemPriceSelectorModal: {
                    ...this.state.itemPriceSelectorModal,
                    visible,
                  },
                })
              }
              {...this.state.itemPriceSelectorModal}
            />
          </Col>
        </Row>

        <Row>
          {/* *********** LIST TOTALS *********** */}
          <Col xs={12} xl={2}>
            <h4 className="text-xl-right pt-1">
              {this.state.list?.name} List Totals
              <i className="fa fa-question-circle ml-2" id="listTotalsHelp" />
              <UncontrolledTooltip placement="top" target="listTotalsHelp">
                These are the totals for this list,
                {this.state.list?.isActive
                  ? " which will count towards the version totals below."
                  : " which will not count towards the version totals below, as the list is marked as inactive."}
              </UncontrolledTooltip>
            </h4>
          </Col>
          <Col xs={12} xl={10} className={styles.listTotalsRow}>
            <span className={styles.listTotal}>
              <strong>Cost:</strong> {currencyFormatter(this.state.totals.cost)}
            </span>
            <span
              className={`${styles.listTotal} ${styles.clickableListTotal} ${styles.interactiveListTotal}`}
              onClick={() =>
                this.setState({
                  totals: {
                    ...this.state.totals,
                    showMarginAsPercent: !this.state.totals.showMarginAsPercent,
                  },
                })
              }>
              <strong>Margin:</strong>{" "}
              {this.state.totals.showMarginAsPercent
                ? marginCalculate(
                    this.state.totals.cost,
                    this.state.totals.exVat,
                  ).percent + "%"
                : currencyFormatter(
                    marginCalculate(
                      this.state.totals.cost,
                      this.state.totals.exVat,
                    ).amount,
                  )}
            </span>
            <span className={styles.listTotal}>
              <strong>List:</strong> {currencyFormatter(this.state.totals.list)}
            </span>
            <span
              className={`${styles.listTotal} ${styles.clickableListTotal} ${styles.interactiveListTotal}`}
              onClick={() =>
                this.setState({
                  totals: {
                    ...this.state.totals,
                    showDiscountAsPercent:
                      !this.state.totals.showDiscountAsPercent,
                  },
                })
              }>
              <strong>Discount:</strong>{" "}
              {this.state.totals.showDiscountAsPercent
                ? discountCalculate(
                    this.state.totals.list,
                    this.state.totals.exVat,
                  ).percent + "%"
                : currencyFormatter(
                    discountCalculate(
                      this.state.totals.list,
                      this.state.totals.exVat,
                    ).amount,
                  )}
            </span>
            <span
              className={`${styles.listTotal} ${styles.clickableListTotal} ${styles.interactiveListTotal}`}
              onClick={() =>
                this.setState({
                  totals: {
                    ...this.state.totals,
                    showTotalAsExVat: !this.state.totals.showTotalAsExVat,
                  },
                })
              }>
              <strong>
                Total ({this.state.totals.showTotalAsExVat ? "ex" : "inc"} VAT):
              </strong>{" "}
              {currencyFormatter(
                this.state.totals.showTotalAsExVat
                  ? this.state.totals.exVat
                  : this.state.totals.incVat,
              )}
            </span>
            <span
              className={classNames({
                [styles.listTotal]: true,
                [styles.interactiveListTotal]:
                  this.state.totals.nonTaxableItems > 0,
              })}
              id="vatTotalLine">
              <strong>VAT:</strong> {currencyFormatter(this.state.totals.vat)}
            </span>
            {this.state.totals.nonTaxableItems > 0 && (
              <UncontrolledTooltip placement="top" target="vatTotalLine">
                VAT not charged for {this.state.totals.nonTaxableItems} line
                {this.state.totals.nonTaxableItems > 1 && "s"}
              </UncontrolledTooltip>
            )}
          </Col>
        </Row>

        <Row>
          {/* *********** VERSION TOTALS *********** */}
          <Col xs={12} xl={2}>
            <h4 className="text-xl-right pt-1">
              {this.state.versionTotals?.name} Version Totals
              <i
                className="fa fa-question-circle ml-2"
                id="versionTotalsHelp"
              />
              <UncontrolledTooltip placement="top" target="versionTotalsHelp">
                These totals ignore lists marked as inactive
              </UncontrolledTooltip>
            </h4>
          </Col>
          <Col xs={12} xl={10} className={styles.listVersionTotalsRow}>
            {this.state.versionTotals.error ? (
              "Error loading version totals"
            ) : (
              /*this.state.versionTotals.loading ? (
              "Loading version totals..."
            ) : */ <>
                <span className={styles.listTotal}>
                  <strong>Cost:</strong>{" "}
                  {currencyFormatter(this.state.versionTotals.cost || 0)}
                </span>
                <span
                  className={`${styles.listTotal} ${styles.clickableListTotal} ${styles.interactiveListTotal}`}
                  onClick={() =>
                    this.setState({
                      totals: {
                        ...this.state.totals,
                        showMarginAsPercent:
                          !this.state.totals.showMarginAsPercent,
                      },
                    })
                  }>
                  <strong>Margin:</strong>{" "}
                  {this.state.totals.showMarginAsPercent
                    ? marginCalculate(
                        this.state.versionTotals.cost,
                        this.state.versionTotals.exVat,
                      ).percent + "%"
                    : currencyFormatter(
                        marginCalculate(
                          this.state.versionTotals.cost,
                          this.state.versionTotals.exVat,
                        ).amount,
                      )}
                </span>
                <span className={styles.listTotal}>
                  <strong>List:</strong>{" "}
                  {currencyFormatter(this.state.versionTotals.list)}
                </span>
                <span
                  className={`${styles.listTotal} ${styles.clickableListTotal} ${styles.interactiveListTotal}`}
                  onClick={() =>
                    this.setState({
                      totals: {
                        ...this.state.totals,
                        showDiscountAsPercent:
                          !this.state.totals.showDiscountAsPercent,
                      },
                    })
                  }>
                  <strong>Discount:</strong>{" "}
                  {this.state.totals.showDiscountAsPercent
                    ? discountCalculate(
                        this.state.versionTotals.list,
                        this.state.versionTotals.exVat,
                      ).percent + "%"
                    : currencyFormatter(
                        discountCalculate(
                          this.state.versionTotals.list,
                          this.state.versionTotals.exVat,
                        ).amount,
                      )}
                </span>
                <span
                  className={`${styles.listTotal} ${styles.clickableListTotal} ${styles.interactiveListTotal}`}
                  onClick={() =>
                    this.setState({
                      totals: {
                        ...this.state.totals,
                        showTotalAsExVat: !this.state.totals.showTotalAsExVat,
                      },
                    })
                  }>
                  <strong>
                    Total ({this.state.totals.showTotalAsExVat ? "ex" : "inc"}{" "}
                    VAT):
                  </strong>{" "}
                  {currencyFormatter(
                    this.state.totals.showTotalAsExVat
                      ? this.state.versionTotals.exVat
                      : this.state.versionTotals.incVat,
                  )}
                </span>
                <span
                  className={classNames({
                    [styles.listTotal]: true,
                    [styles.interactiveListTotal]:
                      this.state.versionTotals.nonTaxableItems > 0,
                  })}
                  id="vatVersionTotalLine">
                  <strong>VAT:</strong>{" "}
                  {currencyFormatter(this.state.versionTotals.vat)}
                </span>
                {this.state.versionTotals.nonTaxableItems > 0 && (
                  <UncontrolledTooltip
                    placement="top"
                    target="vatVersionTotalLine">
                    VAT not charged for{" "}
                    {this.state.versionTotals.nonTaxableItems} line
                    {this.state.versionTotals.nonTaxableItems > 1 && "s"}
                  </UncontrolledTooltip>
                )}
              </>
            )}
          </Col>
        </Row>

        <Row>
          {/* *********** SELECTED TOTALS *********** */}
          <Col xs={12} xl={2}>
            <h4 className="text-xl-right pt-1">
              Selected Totals
              <i
                className="fa fa-question-circle ml-2"
                id="versionTotalsHelp"
              />
              <UncontrolledTooltip placement="top" target="versionTotalsHelp">
                The totals for the selected items in your currently selected
                list
              </UncontrolledTooltip>
            </h4>
          </Col>
          <Col xs={12} xl={10} className={styles.listVersionTotalsRow}>
            {this.state.selectedTotals.error ? (
              "Error loading version totals"
            ) : (
              /*this.state.versionTotals.loading ? (
              "Loading version totals..."
            ) : */ <>
                <span className={styles.listTotal}>
                  <strong>Cost:</strong>{" "}
                  {currencyFormatter(this.state.selectedTotals.cost || 0)}
                </span>
                <span
                  className={`${styles.listTotal} ${styles.clickableListTotal} ${styles.interactiveListTotal}`}
                  onClick={() =>
                    this.setState({
                      totals: {
                        ...this.state.totals,
                        showMarginAsPercent:
                          !this.state.totals.showMarginAsPercent,
                      },
                    })
                  }>
                  <strong>Margin:</strong>{" "}
                  {this.state.totals.showMarginAsPercent
                    ? marginCalculate(
                        this.state.selectedTotals.cost,
                        this.state.selectedTotals.exVat,
                      ).percent + "%"
                    : currencyFormatter(
                        marginCalculate(
                          this.state.selectedTotals.cost,
                          this.state.selectedTotals.exVat,
                        ).amount,
                      )}
                </span>
                <span className={styles.listTotal}>
                  <strong>List:</strong>{" "}
                  {currencyFormatter(this.state.selectedTotals.list)}
                </span>
                <span
                  className={`${styles.listTotal} ${styles.clickableListTotal} ${styles.interactiveListTotal}`}
                  onClick={() =>
                    this.setState({
                      totals: {
                        ...this.state.totals,
                        showDiscountAsPercent:
                          !this.state.totals.showDiscountAsPercent,
                      },
                    })
                  }>
                  <strong>Discount:</strong>{" "}
                  {this.state.totals.showDiscountAsPercent
                    ? discountCalculate(
                        this.state.selectedTotals.list,
                        this.state.selectedTotals.exVat,
                      ).percent + "%"
                    : currencyFormatter(
                        discountCalculate(
                          this.state.selectedTotals.list,
                          this.state.selectedTotals.exVat,
                        ).amount,
                      )}
                </span>
                <span
                  className={`${styles.listTotal} ${styles.clickableListTotal} ${styles.interactiveListTotal}`}
                  onClick={() =>
                    this.setState({
                      totals: {
                        ...this.state.totals,
                        showTotalAsExVat: !this.state.totals.showTotalAsExVat,
                      },
                    })
                  }>
                  <strong>
                    Total ({this.state.totals.showTotalAsExVat ? "ex" : "inc"}{" "}
                    VAT):
                  </strong>{" "}
                  {currencyFormatter(
                    this.state.totals.showTotalAsExVat
                      ? this.state.selectedTotals.exVat
                      : this.state.selectedTotals.incVat,
                  )}
                </span>
                <span
                  className={classNames({
                    [styles.listTotal]: true,
                    [styles.interactiveListTotal]:
                      this.state.selectedTotals.nonTaxableItems > 0,
                  })}
                  id="vatVersionTotalLine">
                  <strong>VAT:</strong>{" "}
                  {currencyFormatter(this.state.selectedTotals.vat)}
                </span>
                {this.state.selectedTotals.nonTaxableItems > 0 && (
                  <UncontrolledTooltip
                    placement="top"
                    target="vatVersionTotalLine">
                    VAT not charged for{" "}
                    {this.state.selectedTotals.nonTaxableItems} line
                    {this.state.selectedTotals.nonTaxableItems > 1 && "s"}
                  </UncontrolledTooltip>
                )}
              </>
            )}
          </Col>
        </Row>
      </>
    );
  }
}
