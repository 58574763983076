import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import axiosAPI from "App/services/axios";
import TableIcons from "App/components/Tables/TableIcons";
import MaterialTable, { MTableEditField } from "material-table";
//import { Paper } from "@material-ui/core";
const _ = require("lodash");
const qs = require("qs");

const RemoteTable = forwardRef(
  (
    {
      apiEndpoint,
      apiEditEndpoint = "",
      appendApiEditEndpoint = true,
      title,
      defaultSortModels = null,
      defaultSortField = "updatedAt",
      defaultSortOrder = "DESC",
      editable = false,
      columns = [],
      actions = {},
      tableActions = [],
      ...props
    },
    ref,
  ) => {
    let id = useRef(_.uniqueId("MUITable_"));
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorMore, setErrorMore] = useState(null);

    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [sortModel, setSortModel] = useState(defaultSortModels);
    const [sort, setSort] = useState([defaultSortField, defaultSortOrder]);

    useImperativeHandle(ref, () => ({
      refreshData: () => getData(),
    }));

    useEffect(() => {
      //reset back to first page on search, which triggers a lookup
      setPage(0);
      if (page === 0) getData();
    }, [search]); // eslint-disable-line

    useEffect(() => {
      getData();
    }, [page, pageSize, sort]); // eslint-disable-line

    const focusSearch = () => {
      setTimeout(() => {
        let input = document.querySelector(
          `.${id.current} input[type='text'].MuiInput-input`,
        );
        if (input) input.focus();
      }, 500);
    };

    useEffect(() => {
      focusSearch();
    }, [data, search]); // eslint-disable-line

    const getData = () => {
      setLoading(true);
      axiosAPI
        .get(
          apiEndpoint +
            "?" +
            qs.stringify({
              search: search || "",
              offset: +page * +pageSize,
              limit: +pageSize,
              sortModel,
              sort,
            }),
        )
        .then(result => {
          setData(result.data?.rows || []);
          setTotalCount(result.data?.count);
        })
        .catch(error => {
          setErrorMessage(error?.errorMessage || "An unknown error occurred");
          setErrorMore(
            error?.errorMore || `Something went wrong, please retry`,
          );
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const editRow = row => {
      return new Promise(async (resolve, reject) => {
        //check all required fields have been filled
        let passedValidation = true;
        await columns.forEach(async column => {
          if (
            column.required &&
            row[column.field].toString().trim().length === 0
          ) {
            passedValidation = false;
          }
        });
        if (!passedValidation) {
          return reject();
        }

        return await axiosAPI
          .patch(
            `${appendApiEditEndpoint ? apiEndpoint : ""}${apiEditEndpoint}/${
              row.id
            }`,
            row,
          )
          .then(result => {
            getData();
            return resolve();
          })
          .catch(error => {
            setErrorMessage(
              error?.errorMessage || "An error occurred while editing row",
            );
            setErrorMore(
              error?.errorMore || "An unknown server error occurred",
            );
            return reject();
          });
      });
    };

    return (
      <>
        {!!errorMessage && (
          <SweetAlert
            danger
            onConfirm={() => {
              setErrorMessage(null);
              setErrorMore(null);
            }}
            title="Error"
          >
            {errorMessage}
            {errorMore && (
              <>
                <br />
                <small>{errorMore}</small>
              </>
            )}
          </SweetAlert>
        )}
        <div className={id.current}>
          <MaterialTable
            {...props}
            icons={TableIcons}
            title={title}
            columns={columns.map(column => {
              //add in defaultSort to field, and other useful things
              return {
                ...column,
                customFilterAndSearch: () => true,
                //if this column is the same as defaultSortModels prop, then this is by default the column we want to sort
                ...(_.isEqual(column.sortModel, defaultSortModels) &&
                column.sortField === defaultSortField
                  ? { defaultSort: defaultSortOrder.toLowerCase() } //material-table needs defaultSort: asc/desc
                  : {}),
              };
            })}
            data={data}
            page={page}
            editable={
              editable
                ? {
                    isEditable: rowData => true, // custom callback to show which rows are editable
                    isDeletable: rowData => false,
                    onRowAdd: undefined, //newData => false,
                    onRowUpdate: (newData, oldData) => editRow(newData),
                    onRowDelete: oldData => false,
                  }
                : null
            }
            components={{
              //Container: props => <Paper {...props} elevation={0} />, //causes complete re-render
              EditField: props => {
                if (
                  props.columnDef.required &&
                  props.value.toString().trim().length === 0
                ) {
                  return <MTableEditField {...props} error label="Required" />;
                }
                return <MTableEditField {...props} />;
              },
            }}
            totalCount={totalCount}
            onSearchChange={value => setSearch(value)}
            onChangeRowsPerPage={value => setPageSize(value)}
            onChangePage={value => setPage(value)}
            onOrderChange={(column, order) => {
              if (column === -1) {
                setSort(["updatedAt", "DESC"]);
                setSortModel(null);
              } else {
                setSort([columns[column].sortField, order]);
                setSortModel(columns[column].sortModel);
              }
            }}
            isLoading={loading}
            actions={[
              {
                icon: TableIcons.Refresh,
                tooltip: "Refresh",
                isFreeAction: true,
                onClick: () => getData(),
              },
              ...tableActions,
            ]}
            options={{
              thirdSortClick: false,
              search: true,
              searchAutoFocus: true,
              searchText: search,
              actionsColumnIndex: -1,
              showTitle: true,
              pageSize,
              pageSizeOptions: [5, 10, 25, 50, 100],
              debounceInterval: 300,
              sorting: true,
              filtering: false,
              grouping: false,
              selection: false,
              emptyRowsWhenPaging: false,
              exportButton: false,
              exportFileName: title,
              exportDelimiter: ",",
              loadingType: "linear",
              showEmptyDataSourceMessage: true,
              tableLayout: "fixed",
            }}
            localization={{
              body: {
                emptyDataSourceMessage: loading ? "" : "No results",
              },
            }}
          />
        </div>
      </>
    );
  },
);

RemoteTable.propTypes = {
  apiEndpoint: PropTypes.string,
  apiEditEndpoint: PropTypes.string,
  appendApiEditEndpoint: PropTypes.bool,
  title: PropTypes.string,
  defaultSortModel: PropTypes.string,
  defaultSortField: PropTypes.string,
  defaultSortOrder: PropTypes.string,
  editable: PropTypes.bool,
  columns: PropTypes.array,
  actions: PropTypes.object,
  tableActions: PropTypes.array,
};

export default RemoteTable;
