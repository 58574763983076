import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ItemPriceSelectorModal from "./ItemPriceSelectorModal";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";

const ItemPriceSelectorModalContainer = ({
  ProductId,
  visible = false,
  setVisible,
  ...rest
}) => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  useEffect(() => {
    if (visible && ProductId) {
      getProduct(ProductId);
    }
  }, [ProductId, visible]); // eslint-disable-line

  const getProduct = id => {
    setLoading(true);
    axiosAPI
      .get(`/products/${id}`)
      .then(result => {
        setProduct(result.data || null);
      })
      .catch(error => {
        setVisible(false);
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting the product, please retry",
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {!!errorMessage && (
        <SweetAlert
          danger
          onConfirm={() => {
            setErrorMessage(null);
            setErrorMore(null);
          }}
          title="Error"
        >
          {errorMessage}
          {errorMore && (
            <>
              <br />
              <small>{errorMore}</small>
            </>
          )}
        </SweetAlert>
      )}
      <ItemPriceSelectorModal
        visible={visible}
        product={product}
        setVisible={setVisible}
        loading={loading}
        {...rest}
      />
    </>
  );
};

ItemPriceSelectorModalContainer.propTypes = {
  productId: PropTypes.number,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  done: PropTypes.func,
};

export default ItemPriceSelectorModalContainer;
