import * as React from "react";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  FormFeedback,
  Input,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";
import axiosAPI from "App/services/axios";
import { connect } from "react-redux";
import * as userActions from "App/redux/user/actions";
import { push } from "connected-react-router";
const queryString = require("query-string");

function LoginPage(props) {
  const { success, loggedInWith, name, errorMessage } = queryString.parse(
    window.location.search,
  );

  if (
    !props.user.loading &&
    !props.user.loggedIn &&
    !props.user.error &&
    props.user.loggedOut
  ) {
    setTimeout(() => props.userLoginClear(), 1000);
  }

  if (success && loggedInWith && name) {
    setTimeout(() => {
      window.location = "/app/dashboard";
    }, 1000);
  }

  const validate = values => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (!values.password || values.password.length < 5) {
      errors.password = "Password should be more than 5 characters.";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: values => {
      //alert(JSON.stringify(values, null, 2));
      props.userLoginLoading();
      axiosAPI
        .post("/auth/login", {
          email: values.email,
          password: values.password,
        })
        .then(function (response) {
          if (response.data.success) {
            props.userLoginSuccess(response.data.data);
            setTimeout(() => {
              window.location = "/app/dashboard";
            }, 1000);
          }
        })
        .catch(function (error) {
          props.userLoginFailure(
            error?.errorMessage || "Your credentials are invalid",
          );
        });
    },
  });
  return (
    <>
      <main>
        <section className="section section-shaped section-lg">
          <Container className="pt-lg-7 pb-2">
            <Row className="justify-content-center">
              <Col lg="5">
                <Card className="shadow border-0">
                  <CardHeader className="bg-white pb-3">
                    <img
                      alt="Helix logo"
                      src={require("App/assets/images/logo.svg")}
                      className="m-auto px-5 pt-4 w-100"
                    />
                    <div className="text-muted text-center mb-3">
                      <strong> DEV SITE STANDARD ENVIRONMENT </strong>
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 pb-lg-3 pt-lg-3">
                    {props.user.error && (
                      <Alert color="danger">
                        <strong>Authentication failure</strong>
                        <br />
                        {props.user.error || "Your credentials are invalid :("}
                      </Alert>
                    )}
                    {success === "false" && errorMessage && (
                      <Alert color="danger">
                        <strong>Authentication failure</strong>
                        <br />
                        {errorMessage}
                      </Alert>
                    )}
                    {(props.user.loggedIn ||
                      (success === "true" && loggedInWith && name)) && (
                      <Alert color="success">
                        <strong>Hey {props.user.info.firstName || name}</strong>
                        <br />
                        {loggedInWith
                          ? `Thanks for signing in with ${loggedInWith}, here we go...`
                          : "Welcome aboard, logging in..."}
                      </Alert>
                    )}
                    {!props.user.loading &&
                      !props.user.loggedIn &&
                      !props.user.error &&
                      props.user.loggedOut && (
                        <Alert color="success">
                          <strong>Logged out</strong>
                          <br />
                          See you next time!
                        </Alert>
                      )}
                    {!props.user.loading &&
                      !props.user.loggedIn &&
                      !props.user.error &&
                      props.user.needsLogin && (
                        <Alert color="warning">
                          <strong>Please login</strong>
                          <br />
                          We just need to check who you are
                        </Alert>
                      )}
                    {!props.user.loggedIn && (success === "false" || !success) && (
                      <>
                        <div className="text-muted text-center mb-1">
                          <small>Sign in with</small>
                        </div>
                        <div className="btn-wrapper text-center">
                          <Button
                            className="btn-neutral btn-icon"
                            color="default"
                            href={
                              process.env.REACT_APP_API_BASE_URL +
                              "/auth/microsoft/login"
                            }
                          >
                            <span className="btn-inner--icon mr-1">
                              <img
                                alt="..."
                                src={require("App/assets/images/microsoft.svg")}
                              />
                            </span>
                            <span className="btn-inner--text">Microsoft</span>
                          </Button>
                          {/*<Button
                        className="btn-neutral btn-icon ml-1"
                        color="default"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <span className="btn-inner--icon mr-1">
                          <img
                            alt="..."
                            src={require("App/assets/images/google.svg")}
                          />
                        </span>
                        <span className="btn-inner--text">Google</span>
                      </Button>*/}
                        </div>
                        <div className="text-center text-muted mt-4 mb-2">
                          <small>Sign in with credentials</small>
                        </div>
                        <Form role="form" onSubmit={formik.handleSubmit}>
                          {!props.user.loading && !props.user.loggedIn && (
                            <>
                              <FormGroup className="mb-3">
                                <Input
                                  name="email"
                                  placeholder="Email"
                                  type="email"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.email}
                                  invalid={
                                    formik.touched.email && formik.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {formik.touched.email && (
                                  <FormFeedback>
                                    {formik.errors.email}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                              <FormGroup>
                                <Input
                                  name="password"
                                  placeholder="Password"
                                  type="password"
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.password}
                                  invalid={
                                    formik.touched.password &&
                                    formik.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                {formik.touched.password && (
                                  <FormFeedback>
                                    {formik.errors.password}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                              {/*
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                          >
                            <span>Remember me</span>
                          </label>
                        </div>*/}
                              <div className="text-center">
                                <Button
                                  disabled={props.user.loading}
                                  className="my-4"
                                  color="primary"
                                  type="submit"
                                >
                                  Sign in
                                </Button>
                              </div>
                            </>
                          )}
                        </Form>
                      </>
                    )}
                  </CardBody>
                </Card>
                {/*<Row className="mt-3">
                    <Col xs="6">
                      <a
                        className="text-light"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <small>Forgot password?</small>
                      </a>
                    </Col>
                    <Col className="text-right" xs="6">
                      <a
                        className="text-light"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <small>Create new account</small>
                      </a>
                    </Col>
                  </Row>*/}
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
}

const mapStateToProps = state => {
  return { user: state.user };
};

const mapDispatchToProps = dispatch => {
  return {
    userLoginSuccess: info => {
      dispatch(userActions.userLoginSuccess(info));
    },
    userLoginFailure: error => {
      dispatch(userActions.userLoginFailure(error));
    },
    userLoginLoading: error => {
      dispatch(userActions.userLoginLoading());
    },
    userLoginClear: () => {
      dispatch(userActions.userLoginClear());
    },
    redirectToDashboard: () => {
      dispatch(push("/app/dashboard"));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
