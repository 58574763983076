import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import user from "App/redux/user/reducer";
import { users } from "App/redux/users/reducer";
import { depots } from "App/redux/depots/reducer";
import { app } from "App/redux/app/reducer";

//Combine reducers. Is a function because history is sent into it from the store.js
let createRootReducer = history =>
  combineReducers({
    app,
    user,
    users,
    depots,
    router: connectRouter(history),
  });

export default createRootReducer;
