import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import NumberFormat from "react-number-format";
//import styles from "./ItemPriceSelectorModal.module.scss";

const ItemPriceSelectorModal = ({
  visible = false,
  setVisible,
  product = {},
  loading,
  PriceListItemId,
  StockGroupId,
  costPrice,
  listPrice,
  done,
}) => {
  const [selectedType, setSelectedType] = useState("custom");
  const [selectedId, setSelectedId] = useState(null);
  const [customListPrice, setCustomListPrice] = useState("0");
  const [customListPriceError, setCustomListPriceError] = useState(null);
  const [customCostPrice, setCustomCostPrice] = useState("0");
  const [customCostPriceError, setCustomCostPriceError] = useState(null);

  useEffect(() => {
    setSelectedType(
      !!PriceListItemId
        ? "priceList"
        : !!StockGroupId
        ? "stockGroup"
        : "custom",
    );
    setSelectedId(PriceListItemId || StockGroupId || null);
    setCustomListPrice(listPrice || "0".toString());
    setCustomCostPrice(costPrice || "0".toString());
  }, [costPrice, listPrice, PriceListItemId, StockGroupId]); // eslint-disable-line

  const selectOption = (type, id) => {
    setSelectedType(type);
    setSelectedId(id);
  };

  const submit = () => {
    let finalCostPrice;
    let finalListPrice;

    if (selectedType === "custom") {
      //need to validate its ok
      let listPriceError = null;
      let costPriceError = null;

      if (!customListPrice) listPriceError = "You must enter a value";
      if (isNaN(+customListPrice)) listPriceError = "Not a valid number";
      if (+customListPrice < 0) listPriceError = "Must be a positive value";

      if (!customCostPrice) costPriceError = "You must enter a value";
      if (isNaN(+customCostPrice)) costPriceError = "Not a valid number";
      if (+customCostPrice < 0) costPriceError = "Must be a positive value";

      if (listPriceError || costPriceError) {
        setCustomListPriceError(listPriceError);
        setCustomCostPriceError(costPriceError);
        setTimeout(() => {
          setCustomListPriceError(null);
          setCustomListPriceError(null);
        }, 3000);
        return;
      }

      finalListPrice = +customListPrice;
      finalCostPrice = +customCostPrice;
    } else {
      //theyve selected a stock group or pricelistitem
      if (selectedType === "priceList") {
        const foundPriceList = product?.PriceListItems?.find(
          item => item.id === selectedId,
        );
        finalListPrice = foundPriceList.listPrice;
        finalCostPrice = foundPriceList.costPrice;
      } else if (selectedType === "stockGroup") {
        const foundStockGroup = product?.StockGroups?.find(
          item => item.id === selectedId,
        );
        finalListPrice = foundStockGroup.listPrice;
        finalCostPrice = foundStockGroup.costPrice;
      }
    }

    done({
      listPrice: finalListPrice,
      costPrice: finalCostPrice,
      PriceListItemId: selectedType === "priceList" ? selectedId : null,
      StockGroupId: selectedType === "stockGroup" ? selectedId : null,
    });
    setVisible(false);
  };

  const PriceListItemRenderer = ({ priceListItem }) => (
    <tr key={priceListItem.id}>
      <td
        onClick={() => selectOption("priceList", priceListItem.id)}
        style={{ cursor: "pointer" }}
      >
        <Input
          type="radio"
          name="priceOption"
          checked={
            selectedType === "priceList" && selectedId === priceListItem.id
          }
          onChange={() => selectOption("priceList", priceListItem.id)}
        />
        {priceListItem?.PriceList?.Company?.name}
        <br />
        <small>{priceListItem?.PriceList?.name}</small>
      </td>
      <td>
        <NumberFormat
          value={priceListItem?.costPrice}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"£"}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </td>
      <td>
        <NumberFormat
          value={priceListItem?.listPrice}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"£"}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </td>
    </tr>
  );

  return (
    <Modal
      className="modal-dialog-centered formModal"
      isOpen={visible}
      data-testid="modal"
      backdrop="static"
    >
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border" product="status">
            <span className="sr-only">loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Select Pricing</h2>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setVisible(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Table>
              <tr>
                <th>Vendor</th>
                <th>Cost Price</th>
                <th>List Price</th>
              </tr>
              {product?.PriceListItems.map(priceListItem => (
                <PriceListItemRenderer priceListItem={priceListItem} />
              ))}
              <tr>
                <td
                  onClick={() => selectOption("custom", null)}
                  style={{ cursor: "pointer" }}
                >
                  <Input
                    type="radio"
                    name="priceOption"
                    checked={selectedType === "custom"}
                    onChange={() => selectOption("custom", null)}
                  />
                  Custom Pricing
                </td>
                <td>
                  <FormGroup>
                    £
                    <Input
                      value={customCostPrice}
                      onChange={e => setCustomCostPrice(e.target.value)}
                      disabled={selectedType !== "custom"}
                      bsSize="sm"
                      type="number"
                      className="d-inline-block ml-1"
                      step="0.01"
                      invalid={customCostPriceError}
                    />
                    {customCostPriceError && (
                      <FormFeedback>{customCostPriceError}</FormFeedback>
                    )}
                  </FormGroup>
                </td>
                <td>
                  <FormGroup>
                    £
                    <Input
                      value={customListPrice}
                      onChange={e => setCustomListPrice(e.target.value)}
                      disabled={selectedType !== "custom"}
                      bsSize="sm"
                      type="number"
                      step="0.01"
                      className="d-inline-block ml-1"
                      invalid={customListPriceError}
                    />
                    {customListPriceError && (
                      <FormFeedback>{customListPriceError}</FormFeedback>
                    )}
                  </FormGroup>
                </td>
              </tr>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => submit()}
              color="primary"
              type="submit"
              data-testid="submitbutton"
            >
              Done
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

ItemPriceSelectorModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  product: PropTypes.object,
  loading: PropTypes.bool,
};

export default ItemPriceSelectorModal;
