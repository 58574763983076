import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axiosAPI from "App/services/axios";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Button,
  Alert,
  Table,
  FormGroup,
  Input,
} from "reactstrap";
import DatePicker from "react-datepicker";
import styles from "./AssignPermissionsModal.module.scss";
const moment = require("moment");
const { uniqueId } = require("lodash");

const AssignPermissionsModalContainer = ({
  assignedPermissions = [], //pass in {expiresOn:2020-02...} to timelimit, expects: [ {key:"COMP_EDIT",expiresOn:null},{...} ]
  showExpiresOn = false,
  assignToTitle,
  assignToDescription,
  visible = false,
  setVisible,
  done, //callback
}) => {
  const [groups, setGroups] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  const getPermissions = () => {
    setLoading(true);
    axiosAPI
      .get("/permissions")
      .then(result => {
        setPermissions(result.data || []);
        let newGroups = [];
        result.data.forEach(permission => {
          if (!newGroups.find(group => group === permission.group)) {
            newGroups.push(permission.group);
          }
        });
        setGroups(newGroups);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting permissions, please retry",
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const assignPermission = (key, selected) => {
    let tempSelectedPermissions = [...selectedPermissions];
    if (!selected) {
      //remove from selected array
      tempSelectedPermissions = tempSelectedPermissions.filter(
        permission => permission.key !== key,
      );
    } else {
      //add to selected array
      if (!tempSelectedPermissions.find(permission => permission.key === key)) {
        //not yet in array so add in
        tempSelectedPermissions.push({ key, expiresOn: null });
      }
    }
    setSelectedPermissions(tempSelectedPermissions);
  };

  const setPermissionExpires = (key, expiresDate) => {
    let tempSelectedPermissions = [...selectedPermissions].map(permission => ({
      ...permission,
      expiresOn:
        permission.key === key
          ? expiresDate
            ? moment(expiresDate).toISOString()
            : null
          : permission.expiresOn,
    }));

    setSelectedPermissions(tempSelectedPermissions);
  };

  const closeButton = (
    <button
      aria-label="Close"
      className={`close ${styles.close}`}
      data-dismiss="modal"
      type="button"
      onClick={() => setVisible(false)}
    >
      <span aria-hidden={true}>×</span>
    </button>
  );

  useEffect(() => {
    getPermissions();
  }, []); // eslint-disable-line

  useEffect(() => {
    setSelectedPermissions(assignedPermissions);
  }, [assignedPermissions]); //eslint-disable-line

  return (
    <Modal
      className="modal-dialog-centered modal"
      isOpen={visible}
      data-testid="assignpermissionsmodal"
    >
      {!loading && errorMessage ? (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Error</h2>
            {closeButton}
          </div>
          <div className="modal-body">
            <Alert color="danger">
              <strong>Error</strong> Could not load permissions - sorry about
              that! {errorMore}
            </Alert>
          </div>
        </>
      ) : loading ? (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Loading...</h2>
            {closeButton}
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center my-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">loading...</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <ModalHeader>
            <Row className="mx-0">
              <h3 className="w-100">Assign Permissions to {assignToTitle}</h3>
              {assignToDescription && (
                <p className="d-block mb-0">{assignToDescription}</p>
              )}
            </Row>
            {closeButton}
          </ModalHeader>
          <ModalBody className={`pt-0 ${styles.modalbody}`}>
            <FormGroup className={`mb-0 ${styles.form}`}>
              <Input
                className={styles.search}
                placeholder="Search permissions..."
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <Button
                outline
                size="sm"
                className={styles.selectAllNoneButton}
                color="secondary"
                onClick={() => {
                  if (selectedPermissions.length) {
                    //users are already selected, deselect all
                    setSelectedPermissions([]);
                  } else {
                    //no users selected, select all
                    setSelectedPermissions([
                      ...[...permissions].map(permission => ({
                        key: permission.key,
                        expiresOn: null,
                      })),
                    ]);
                  }
                }}
              >
                Select all/none
              </Button>
            </FormGroup>
            <Table size="sm" className={styles.table} responsive>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Assigned</th>
                  {showExpiresOn && <th>Expiration</th>}
                </tr>
                {groups.map(group => {
                  let groupPermissions = permissions.filter(
                    permission => permission.group === group,
                  );
                  return (
                    <>
                      <tr>
                        <td colspan={showExpiresOn ? 3 : 2}>
                          <h4>{group}</h4>
                        </td>
                      </tr>
                      {groupPermissions.map(permission => {
                        let selected = selectedPermissions.find(
                          selectedPermission =>
                            selectedPermission.key === permission.key,
                        );
                        let checkboxId = uniqueId("PermissionAssigned_");
                        if (
                          search.trim().length === 0 ||
                          (search.trim().length > 0 &&
                            permission.name
                              .toLowerCase()
                              .indexOf(search.trim().toLowerCase()) > -1)
                        ) {
                          return (
                            <tr>
                              <td>
                                {permission.name}
                                {!!permission.description &&
                                  permission.description !==
                                    permission.name && (
                                    <span className={styles.description}>
                                      {permission.description}
                                    </span>
                                  )}
                              </td>
                              <td>
                                <div className="custom-control custom-checkbox">
                                  <input
                                    className="custom-control-input"
                                    id={checkboxId}
                                    checked={!!selected}
                                    type="checkbox"
                                    onChange={e => {
                                      assignPermission(
                                        permission.key,
                                        e.target.checked,
                                      );
                                    }}
                                  />
                                  <label
                                    className="custom-control-label d-inline"
                                    htmlFor={checkboxId}
                                  ></label>
                                </div>
                              </td>
                              {showExpiresOn && (
                                <td>
                                  {selected && (
                                    <>
                                      {selected.expiresOn ? (
                                        moment().isAfter(
                                          moment(selected.expiresOn),
                                        ) ? (
                                          <span className={`small text-danger`}>
                                            Expired{" "}
                                            {moment
                                              .duration(
                                                moment().diff(
                                                  moment(selected.expiresOn),
                                                ),
                                              )
                                              .humanize()}{" "}
                                            ago
                                          </span>
                                        ) : (
                                          <span
                                            className={`small text-success`}
                                          >
                                            Expires in{" "}
                                            {moment
                                              .duration(
                                                moment().diff(
                                                  moment(selected.expiresOn),
                                                ),
                                              )
                                              .humanize()}
                                          </span>
                                        )
                                      ) : (
                                        <span className={`small text-success`}>
                                          Always active
                                        </span>
                                      )}
                                      <DatePicker
                                        selected={
                                          selected?.expiresOn
                                            ? moment(
                                                selected.expiresOn,
                                              ).toDate()
                                            : null
                                        }
                                        onChange={date =>
                                          setPermissionExpires(
                                            permission.key,
                                            date,
                                          )
                                        }
                                        showTimeSelect
                                        isClearable
                                        withPortal
                                        className={styles.expiresPicker}
                                        placeholderText="Never expires"
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                      />
                                    </>
                                  )}
                                </td>
                              )}
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </>
                  );
                })}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button
              color="success"
              onClick={() => {
                done(selectedPermissions);
                setVisible(false);
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

AssignPermissionsModalContainer.propTypes = {
  assignedPermissions: PropTypes.array,
  showExpiresOn: PropTypes.bool,
  assignToTitle: PropTypes.string,
  assignToDescription: PropTypes.string,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  done: PropTypes.func, //callback
};

export default AssignPermissionsModalContainer;
