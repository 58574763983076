import React, { useState, useRef } from "react";
//import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import RemoteTable from "App/components/Tables/RemoteTable/RemoteTable";
import TableIcons from "App/components/Tables/TableIcons";
import hasPermission from "App/helpers/hasPermission";
import DropdownWithPermissions from "App/components/Dropdowns/DropdownWithPermissions";
import PriceListStatus from "App/components/Badges/PriceListStatus";
import SweetAlert from "react-bootstrap-sweetalert";
import PriceListItemsListModalContainer from "App/components/Modals/PriceListItemsListModal/PriceListItemsListModal.container";
import PriceListImportModalContainer from "App/components/Modals/PriceListImportModal/PriceListImportModal.container";
const moment = require("moment");

const PriceListContainer = () => {
  const remoteTable = useRef();
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  const [priceListItemsVisible, setPriceListItemsVisible] = useState(false);
  const [priceListId, setPriceListId] = useState(null);
  const [priceListImportVisible, setPriceListImportVisible] = useState(false);

  const actions = {
    viewPriceListItems: id => {
      setPriceListId(id);
      setPriceListItemsVisible(true);
    },
    addPriceList: () => {
      setPriceListImportVisible(true);
    },
  };

  const columns = [
    {
      title: "Vendor",
      field: "Company.name",
      render: (row, type) =>
        type === "row" ? (
          <>
            <div className={`d-inline-block`}>
              <h5 className={`mb-0 text-default`}>{row.Company.name}</h5>
              {/*<span className={`mb-0 small text-secondary`}>
                {row.PriceListItemsCount} products
              </span>*/}
            </div>
          </>
        ) : (
          row
        ),
    },
    {
      title: "Status",
      field: "status",
      lookup: {
        Inactive: "Inactive",
        Active: "Active",
        Archive: "Archive",
        Scheduled: "Scheduled",
      },
      render: (row, type) =>
        type === "row" ? <PriceListStatus priceList={row} /> : row,
    },
    {
      title: "Start Date",
      field: "startDate",
      filtering: false,
      grouping: false,
      defaultSort: "desc",
      render: row => (
        <span className={`small text-default`}>
          {row.startDate
            ? moment().isAfter(moment(row.startDate))
              ? `Started on ${moment(row.startDate).format("DD MMM YYYY")}`
              : `Starts on ${moment(row.startDate).format("DD MMM YYYY")}`
            : `No start date set (inactive)`}
        </span>
      ),
    },
    {
      title: "End Date",
      field: "endDate",
      filtering: false,
      grouping: false,
      render: row => (
        <span className={`small text-default`}>
          {row.endDate
            ? moment().isAfter(moment(row.endDate))
              ? `Ended on ${moment(row.endDate).format("DD MMM YYYY")}`
              : `Ends on ${moment(row.endDate).format("DD MMM YYYY")}`
            : `No end date set (never expires)`}
        </span>
      ),
    },
    {
      title: "Options",
      field: "id",
      filtering: false,
      grouping: false,
      sorting: false,
      render: row => (
        <UncontrolledButtonDropdown
          size="sm"
          color="primary"
          data-testid="optionsdropdown"
        >
          <DropdownToggle color="primary" caret>
            Options
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>{row.Company.name}</DropdownItem>
            <DropdownWithPermissions
              permission="PL_VIEW_ITEM"
              callback={() => actions.viewPriceListItems(row.id)}
              text="View Price List items"
              testid="viewpricelistitems"
              tooltip="You do not have permission to view price list items"
            />
            <DropdownWithPermissions
              permission="PL_EDIT"
              callback={() => console.log(row)}
              text="Edit"
              testid="editpricelist"
              tooltip="You do not have permission to edit price lists"
            />
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      ),
    },
  ];

  return (
    <>
      {!!errorMessage && (
        <SweetAlert
          danger
          onConfirm={() => {
            setErrorMessage(null);
            setErrorMore(null);
          }}
          title="Error"
        >
          {errorMessage}
          {errorMore && (
            <>
              <br />
              <small>{errorMore}</small>
            </>
          )}
        </SweetAlert>
      )}
      <PriceListItemsListModalContainer //shows a Price List's items in a modal
        visible={priceListItemsVisible}
        setVisible={setPriceListItemsVisible}
        priceListId={priceListId}
      />
      <PriceListImportModalContainer //add a price list - import
        visible={priceListImportVisible}
        setVisible={setPriceListImportVisible}
      />
      <RemoteTable
        apiEndpoint="/priceLists"
        title="Price Lists"
        defaultSortModels={null}
        defaultSortField="updatedAt"
        defaultSortOrder="DESC"
        //detailPanel={detailPanel} //this breaks safari due to width calcs on css <th>
        columns={columns}
        actions={actions}
        ref={remoteTable}
        tableActions={(() => {
          let tableActions = [];
          if (hasPermission("PL_IMPORT")) {
            tableActions.push({
              tooltip: "Import Price List",
              isFreeAction: true,
              icon: TableIcons.Add,
              onClick: (evt, data) => actions.addPriceList(),
            });
          }
          return tableActions;
        })()}
      />
      {/*<PriceList //a table list of pricelists
        loading={loading}
        priceLists={priceLists}
        viewPriceListItems={id => {
          setPriceListId(id);
          setPriceListItemsVisible(true);
        }}
        addPriceList={() => {
          setPriceListImportVisible(true);
        }}
      />*/}
    </>
  );
};

PriceListContainer.propTypes = {};

export default PriceListContainer;
