import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";

const PriceListStatus = ({ priceList, className = "" }) => (
  <Badge
    color={
      priceList.status === "Archive"
        ? "light"
        : priceList.status === "Inactive"
        ? "danger"
        : priceList.status === "Scheduled"
        ? "warning"
        : "success"
    }
    className={className}
  >
    {priceList.status}
  </Badge>
);

PriceListStatus.propTypes = {
  priceList: PropTypes.object,
  className: PropTypes.string,
};

export default PriceListStatus;
