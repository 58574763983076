import React from "react";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet";
import UserMenu from "App/components/Headers/UserMenu";

const PageContainer = ({
  title,
  secondaryTitle = null,
  subtitle,
  children,
}) => {
  return (
    <>
      <div
        className={`header bg-gradient-dark py-4 px-3`}
        style={{ borderBottom: "4px solid #f9c41c" }}
      >
        <UserMenu
          style={{ top: 20, right: 40, position: "absolute" }}
          className="d-none d-md-flex"
        />
        <Helmet>
          <title>{title || "Loading..."}</title>
        </Helmet>
        <h3 className="display-4 w-100 mt--2 pb-3 mb-0 pl-4 text-white">
          {title || "Loading..."}
          {secondaryTitle && (
            <span
              className="font-weight-300 d-inline ml-2"
              style={{ fontSize: "75%" }}
            >
              {secondaryTitle || ""}
            </span>
          )}
        </h3>
        {subtitle && <h2 className="pb-2 pl-4 text-white">{subtitle}</h2>}
      </div>
      <Container className="" fluid>
        {children}
      </Container>
    </>
  );
};

export default PageContainer;
