import React, { useState, useRef } from "react";
//import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import TableIcons from "App/components/Tables/TableIcons";
import RemoteTable from "App/components/Tables/RemoteTable/RemoteTable";
import hasPermission from "App/helpers/hasPermission";
import ContactModal from "App/components/Modals/ContactModal";
import DropdownWithPermissions from "App/components/Dropdowns/DropdownWithPermissions";

const ContactsListContainer = () => {
  const remoteTable = useRef();
  const [contactModal, setContactModal] = useState({
    id: null,
    visible: false,
    edit: false,
    initialValues: null,
  });

  const actions = {
    newContact: () =>
      setContactModal({
        id: null,
        visible: true,
        edit: false,
        initialValues: null,
      }),
    editContact: row =>
      setContactModal({
        ...contactModal,
        visible: true,
        edit: true,
        id: row.id,
        initialValues: row,
      }),
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      sortModel: null,
      sortField: "firstName",
      sorting: true,
      render: row => row.fullName,
    },
    {
      title: "Job Title",
      field: "jobTitle",
      sortModel: null,
      sortField: "jobTitle",
      sorting: true,
    },
    {
      title: "Company",
      field: "Company.name",
      sortModel: ["Company"],
      sortField: "name",
      sorting: true,
    },
    {
      title: "Email",
      field: "email",
      sortModel: null,
      sortField: "email",
      sorting: false,
    },
    {
      title: "Work",
      field: "work",
      sortModel: null,
      sortField: "work",
      sorting: false,
    },
    {
      title: "Mobile",
      field: "mobile",
      sortModel: null,
      sortField: "mobile",
      sorting: false,
    },
    {
      title: "Status",
      field: "status",
      sortModel: null,
      sortField: "status",
      sorting: true,
      render: row => row.statusText,
    },
    {
      title: "Accounts",
      field: "accounts",
      sortModel: null,
      sortField: "accounts",
      sorting: true,
      render: row => (row.accounts ? "Yes" : "No"),
    },
    {
      title: "Options",
      field: "id",
      sorting: false,
      disableClick: true,
      render: row => (
        <UncontrolledButtonDropdown size="sm" color="primary">
          <DropdownToggle color="primary" caret>
            Options
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Contact</DropdownItem>
            <DropdownWithPermissions
              permission="CONT_EDIT"
              callback={() => actions.editContact(row)}
              text="Edit"
              testid="editcontact"
              tooltip="You do not have permission to edit contacts"
            />
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      ),
    },
  ];
  return (
    <>
      <RemoteTable
        apiEndpoint="/contacts"
        title="Contacts"
        defaultSortModels={null}
        defaultSortField="name"
        defaultSortOrder="ASC"
        columns={columns}
        actions={actions}
        ref={remoteTable}
        onRowClick={(event, row) => {
          setContactModal({
            ...contactModal,
            visible: true,
            edit: true,
            id: row.id,
            initialValues: row,
          });
        }}
        tableActions={(() => {
          let tableActions = [];
          //"isFreeAction" means it appears not when selected, its by search bar
          //has permission to delete/restore users
          if (hasPermission("CONT_CREATE")) {
            tableActions.push({
              tooltip: "New Contact",
              isFreeAction: true,
              icon: TableIcons.Add,
              onClick: (evt, data) => actions.newContact(),
            });
          }
          return tableActions;
        })()}
      />
      <ContactModal
        isOpen={contactModal.visible}
        toggleModal={visible => setContactModal({ ...contactModal, visible })}
        initialValues={contactModal.initialValues}
        id={contactModal.id}
        setReturnValue={contact => remoteTable.current.refreshData()}
      />
    </>
  );
};

export default ContactsListContainer;
