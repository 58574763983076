import {
  take,
  takeLeading,
  takeLatest,
  takeEvery,
  delay,
  put,
  all,
  call,
} from "redux-saga/effects";
import * as appActions from "App/redux/app/actions";
import * as userActions from "App/redux/user/actions";
import * as usersActions from "App/redux/users/actions";
import * as depotsActions from "App/redux/depots/actions";
import { push } from "connected-react-router";
import googleErrorReporting from "App/services/googleErrorReporting";

import axiosAPI from "App/services/axios";
const qs = require("qs");

function* api(method, url, queryStringObject = null, data = null) {
  try {
    if (queryStringObject) {
      url = url + "?" + qs.stringify(queryStringObject);
    }
    yield console.log(`API ${method.toUpperCase()} request to ${url}`);
    const response = yield call(axiosAPI[method], url, data);
    return response || {};
  } catch (error) {
    throw new Error(
      `${error?.errorMessage || "An unknown error occurred"}. More info: ${
        error?.errorMore || "[500] - Unavailable"
      }`,
    );
    //return {};
  }
}

function* fetchUsers(action) {
  try {
    const { data } = yield call(
      api,
      "get",
      `/users?showDisabled=true&showDeleted=true`,
    );
    yield put(usersActions.usersGetSuccess(data));
  } catch (err) {
    console.log(err);
    yield put(
      usersActions.usersGetFailure(err || "An unknown error occurred."),
    );
  }
}

function* fetchDepots(action) {
  try {
    const { data } = yield call(api, "get", `/depots?showDeleted=true`);
    yield put(depotsActions.depotsGetSuccess(data));
  } catch (err) {
    console.log(err);
    yield put(
      depotsActions.depotsGetFailure(err || "An unknown error occurred."),
    );
  }
}

function* watchLogout() {
  try {
    while (true) {
      yield take(userActions.USER_LOGOUT);
      const { data } = yield call(api, "get", `/auth/logout`);
      if (data.success) {
        yield put(push(`/login`));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

function* watchNeedsLogin() {
  try {
    yield put(push(`/login`));
  } catch (err) {
    console.log(err);
  }
}

function* watchLoginError() {
  while (true) {
    yield take(userActions.USER_LOGIN_FAILURE);
    yield delay(3000);
    yield put(userActions.userLoginClear());
  }
}

function* updateErrorReportingUser(action) {
  const { email } = action.payload;
  yield googleErrorReporting.setUser(email || "Anonymous");
}

// Root saga
export default function* rootSaga() {
  yield all([
    watchLoginError(),
    watchLogout(),
    takeLeading(appActions.APP_STARTED, fetchDepots),
    takeEvery(userActions.USER_NEEDS_LOGIN, watchNeedsLogin),
    takeLatest(usersActions.USERS_GET, fetchUsers),
    takeLatest(depotsActions.DEPOTS_GET, fetchDepots),
    takeLatest(userActions.USER_LOGIN_SUCCESS, updateErrorReportingUser),
  ]);
}
