import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Button,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import axiosAPI from "App/services/axios";
import CompanyModal from "App/components/Modals/CompanyModal";
import DropdownWithPermissions from "App/components/Dropdowns/DropdownWithPermissions";
import styles from "./CompanyButton.module.scss";
import CompanyProfileModalContainer from "App/components/Modals/CompanyProfileModal/CompanyProfileModal.container";

const CompanyButtonContainer = ({ id, className = "" }) => {
  const [company, setCompany] = useState(null);
  const [companyProfileModalVisible, setCompanyProfileModalVisible] =
    useState(false);
  const [companyModalVisible, setCompanyModalVisible] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const getCompany = useCallback(() => {
    setLoading(true);
    axiosAPI
      .get(`/companies/${id}`)
      .then(result => {
        setCompany(result.data || null);
      })
      .catch(error => {
        setError(true);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]); // eslint-disable-line

  useEffect(() => {
    getCompany();
  }, [id]); // eslint-disable-line

  if (!id) return null;

  if (loading) {
    return (
      <Button color="secondary" size="sm" className={className} disabled={true}>
        Loading...
      </Button>
    );
  }

  if (error || !company) {
    return (
      <Button color="danger" size="sm" className={className} disabled={true}>
        Could not load company
      </Button>
    );
  }

  return (
    <UncontrolledButtonDropdown className={className} size="sm">
      <Button
        color="white"
        className={styles.button}
        onClick={() => setCompanyProfileModalVisible(true)}
      >
        <div className={styles.text}>{company?.name}</div>
      </Button>
      <DropdownToggle caret color="secondary" />
      <DropdownMenu>
        <DropdownItem onClick={() => setCompanyProfileModalVisible(true)}>
          See profile
        </DropdownItem>
        <DropdownWithPermissions
          permission="CONT_EDIT"
          callback={() => setCompanyModalVisible(true)}
          text="Edit company"
          testid="editcompany"
          tooltip="You do not have permission to edit companies"
          visible={true}
        />
        {(company.email || company.work || company.mobile) && (
          <DropdownItem divider />
        )}
        {company.email && (
          <DropdownItem
            onClick={() => (window.location = `mailto:${company.email}`)}
          >
            Email
          </DropdownItem>
        )}
        {company.work && (
          <DropdownItem
            onClick={() => (window.location = `tel:${company.work}`)}
          >
            Call Work
          </DropdownItem>
        )}
        {company.mobile && (
          <DropdownItem
            onClick={() => (window.location = `tel:${company.mobile}`)}
          >
            Call Mobile
          </DropdownItem>
        )}
      </DropdownMenu>
      <CompanyModal
        isOpen={companyModalVisible}
        id={id}
        initialValues={company}
        edit={true}
        toggleModal={visible => setCompanyModalVisible(visible)}
        setReturnValue={company => getCompany()}
      />
      <CompanyProfileModalContainer
        visible={companyProfileModalVisible}
        id={id}
        setVisible={setCompanyProfileModalVisible}
      />
    </UncontrolledButtonDropdown>
  );
};

CompanyButtonContainer.propTypes = {
  id: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default CompanyButtonContainer;
