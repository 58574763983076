import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
const qs = require("qs");

const ManufacturerSearchProductsDropdown = ({
  preload = false,
  className = "",
  placeholder = "Lookup manufacturer products",
  value,
  setValue,
  numberOfOptions = 5,
  manufacturerKey,
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  const manufacturerProductSearch = async query => {
    setLoading(true);
    await axiosAPI
      .get(
        `/manufacturers/${manufacturerKey}/products/search?` +
          qs.stringify({ search: query }),
      )
      .then(result => {
        setOptions(result.data.slice(0, numberOfOptions));
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting products, please retry",
        );
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    //preload options
    if (preload) manufacturerProductSearch();
  }, []); // eslint-disable-line

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <Select
        className={className}
        placeholder={placeholder}
        isClearable
        blurInputOnSelect={true}
        filterOption={() => true}
        onInputChange={(query, actionMeta) => {
          //console.log("INPUT", query, actionMeta);
          manufacturerProductSearch(query);
        }}
        onChange={(option, actionMeta) => {
          //console.log("CHANGE", value, actionMeta);
          setValue(option);
        }}
        options={options}
        getOptionValue={option => {
          return option.id;
        }}
        noOptionsMessage={() => (preload ? "No products" : null)}
        getOptionLabel={option => {
          return (
            <>
              {option.name}
              <small style={{ marginLeft: "5px" }}>
                ({option.manufacturerProductCode})
              </small>
            </>
          );
        }}
        value={value}
        isLoading={loading}
      />
    </>
  );
};

ManufacturerSearchProductsDropdown.propTypes = {
  preload: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  setValue: PropTypes.func,
  numberOfOptions: PropTypes.number,
  manufacturerKey: PropTypes.string,
};

export default ManufacturerSearchProductsDropdown;
