import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import VersionModal from "./VersionModal";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";

const VersionModalContainer = ({
  id,
  jobId,
  visible = false,
  setVisible,
  edit = false,
  clone = false,
  done,
}) => {
  const [version, setVersion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  useEffect(() => {
    if (visible && id) {
      getVersion(id);
    }
    if (visible && (!edit || !id || !clone)) {
      setVersion(null);
    }
  }, [id, edit, clone, visible]); // eslint-disable-line

  const getVersion = id => {
    setLoading(true);
    axiosAPI
      .get(`/versions/${id}`)
      .then(result => {
        setVersion(result.data || null);
      })
      .catch(error => {
        setVisible(false);
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addVersion = async version => {
    setLoading(true);
    await axiosAPI
      .post(`/jobs/${jobId}/versions`, version)
      .then(result => {
        setVisible(false);
        done(result.data);
      })
      .catch(error => {
        setErrorMessage(
          error?.errorMessage || "An error occurred while adding new version",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      })
      .finally(() => setLoading(false));
  };

  const cloneVersion = async versionOverride => {
    setLoading(true);
    await axiosAPI
      .post(`/versions/${versionOverride.id}/clone`, versionOverride)
      .then(result => {
        setVisible(false);
        done(result.data);
      })
      .catch(error => {
        setErrorMessage(
          error?.errorMessage || "An error occurred while cloning version",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      })
      .finally(() => setLoading(false));
  };

  const editVersion = async version => {
    setLoading(true);
    await axiosAPI
      .patch(`/versions/${version.id}`, version)
      .then(result => {
        setVisible(false);
        done(result.data);
      })
      .catch(error => {
        setErrorMessage(
          error?.errorMessage || "An error occurred while editing version",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <VersionModal
        visible={visible}
        version={version}
        edit={edit}
        clone={clone}
        setVisible={setVisible}
        editVersion={editVersion}
        cloneVersion={cloneVersion}
        addVersion={addVersion}
        loading={loading}
      />
    </>
  );
};

VersionModalContainer.propTypes = {
  id: PropTypes.number,
  jobId: PropTypes.number,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  edit: PropTypes.bool,
  clone: PropTypes.bool,
  done: PropTypes.func,
};

export default VersionModalContainer;
