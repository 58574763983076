const _ = require("lodash");
//removes anything except numbers and decimals, and then attempts to convert to a number
//returns null if original was a null (rather than converting to 0.00)
const stringToNumber = (number, roundDp = 2) => {
  if (number === null || number === undefined || number === "") return null;
  if (_.isNumber(number)) {
    //already a number, just decide if we need to round or not
    return roundDp ? _.round(number, roundDp) : number;
  } else {
    //not yet a number, strip out the crap
    let newNumber = _.toString(number)
      .trim()
      .replace(/[^0-9.]/g, "");
    let convertedToNumber = _.toNumber(newNumber);
    if (isNaN(convertedToNumber)) {
      return newNumber; //return string equivalent as its not a valid number
    }
    return roundDp ? _.round(convertedToNumber, roundDp) : convertedToNumber;
  }
};
export default stringToNumber;
