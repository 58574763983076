import React from "react";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import {
  Badge,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import TableIcons from "App/components/Tables/TableIcons";
import hasPermission from "App/helpers/hasPermission";
import Avatar from "react-avatar";
import DropdownWithPermissions from "App/components/Dropdowns/DropdownWithPermissions";
const _ = require("lodash");
const moment = require("moment");

const UsersList = ({
  loading = false,
  users = [],
  editUser,
  deleteUser,
  restoreUser,
  addUser,
  disableUser,
  enableUser,
  assignUserPermissions,
}) => {
  return (
    <MaterialTable
      icons={TableIcons}
      title="Users"
      columns={[
        {
          title: "Avatar",
          field: "avatar",
          sorting: false,
          filtering: false,
          grouping: false,
          //width: 75,
          render: row => (
            <Avatar
              src={
                row?.AvatarFile?.id
                  ? process.env.REACT_APP_API_BASE_URL +
                    `/files/${row?.AvatarFile?.id}`
                  : null
              }
              name={row.fullName}
              size="50"
              className={`mr-2 float-left bg-default`}
              round
            />
          ),
        },
        { title: "Name", field: "fullName" },
        { title: "Job Title", field: "jobTitle" },
        {
          title: "Email",
          field: "email",
          sorting: false,
          filtering: false,
          grouping: false,
        },
        {
          title: "Phone",
          field: "phone",
          sorting: false,
          filtering: false,
          grouping: false,
        },
        {
          title: "Status",
          field: "status",
          lookup: {
            Inactive: "Inactive",
            Active: "Active",
            Deleted: "Deleted",
          },
          render: (row, type) =>
            type === "row" ? (
              <Badge
                color={
                  row.status === "Deleted"
                    ? "light"
                    : row.status === "Inactive"
                    ? "danger"
                    : "success"
                }
              >
                {row.status}
              </Badge>
            ) : (
              row
            ),
        },
        {
          title: "Updated",
          field: "updatedAt",
          filtering: false,
          grouping: false,
          render: row => (
            <>
              {moment.duration(moment().diff(moment(row.updatedAt))).humanize()}{" "}
              ago
              <br />
              <small>on {moment(row.updatedAt).format("DD MMM YYYY")}</small>
            </>
          ),
        },
        {
          title: "Options",
          field: "id",
          filtering: false,
          grouping: false,
          sorting: false,
          render: row => (
            <UncontrolledButtonDropdown
              size="sm"
              color="primary"
              data-testid="optionsdropdown"
            >
              <DropdownToggle color="primary" caret>
                Options
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>{row.fullName}</DropdownItem>
                <DropdownWithPermissions
                  permission="USER_EDIT"
                  callback={() => editUser(row)}
                  text="Edit"
                  testid="edituser"
                  tooltip="You do not have permission to edit users"
                  visible={!row.deletedAt}
                />
                <DropdownWithPermissions
                  permission="USER_EDIT"
                  callback={() => disableUser(row.id)}
                  text="Disable"
                  testid="disableuser"
                  tooltip="You do not have permission to disable (edit) users"
                  visible={!row.deletedAt && !row.disabled}
                />
                <DropdownWithPermissions
                  permission="USER_EDIT"
                  callback={() => enableUser(row.id)}
                  text="Enable"
                  testid="enableuser"
                  tooltip="You do not have permission to enable (edit) users"
                  visible={!row.deletedAt && row.disabled}
                />
                <DropdownItem divider />
                <DropdownWithPermissions
                  permission="USER_PERM_ASSIGN"
                  callback={() => assignUserPermissions(row)}
                  text="Assign Permissions"
                  testid="assignpermissions"
                  tooltip="You do not have permission to assign user permissions"
                />
                <DropdownItem divider />
                <DropdownWithPermissions
                  permission="USER_DEL"
                  callback={() => deleteUser(row.id)}
                  text="Delete"
                  testid="deleteuser"
                  tooltip="You do not have permission to delete users"
                  visible={!row.deletedAt}
                />
                <DropdownWithPermissions
                  permission="USER_DEL"
                  callback={() => restoreUser(row.id)}
                  text="Restore"
                  testid="restoreuser"
                  tooltip="You do not have permission to restore users"
                  visible={!!row.deletedAt}
                />
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          ),
        },
      ]}
      data={_.cloneDeep(users).map((user, index) => {
        return {
          ...user,
          status: user.deletedAt
            ? "Deleted"
            : user.disabled
            ? "Inactive"
            : "Active",
        };
      })}
      isLoading={loading}
      options={{
        sorting: true,
        filtering: false,
        grouping: false,
        selection: true,
        exportButton: true,
        exportFileName: "Users",
        exportDelimiter: ",",
        loadingType: "overlay",
        showEmptyDataSourceMessage: true,
      }}
      localization={{
        body: {
          emptyDataSourceMessage: !loading ? "No users" : "",
        },
      }}
      actions={(() => {
        let actions = [];
        //"isFreeAction" means it appears not when selected, its by search bar
        //has permission to delete/restore users
        if (hasPermission("USER_DEL")) {
          actions.push({
            tooltip: "Delete Selected Users",
            icon: TableIcons.Delete,
            onClick: (evt, data) => {
              data.forEach((user, i) => {
                if (!user.deletedAt) deleteUser(user.id);
              });
            },
          });
          actions.push({
            tooltip: "Restore Selected Users",
            icon: TableIcons.Restore,
            onClick: (evt, data) => {
              data.forEach((user, i) => {
                if (user.deletedAt) restoreUser(user.id);
              });
            },
          });
        }
        //has permission to edit users (therefore make active/inactive)
        if (hasPermission("USER_EDIT")) {
          actions.push({
            tooltip: "Make Selected Users Active",
            icon: TableIcons.Active,
            onClick: (evt, data) => {
              data.forEach((user, i) => {
                if (user.disabled) enableUser(user.id);
              });
            },
          });
          actions.push({
            tooltip: "Make Selected Users Inactive",
            icon: TableIcons.Inactive,
            onClick: (evt, data) => {
              data.forEach((user, i) => {
                if (!user.disabled) disableUser(user.id);
              });
            },
          });
        }
        if (hasPermission("USER_CREATE")) {
          actions.push({
            tooltip: "Add User",
            isFreeAction: true,
            icon: TableIcons.Add,
            onClick: (evt, data) => addUser(),
          });
        }
        return actions;
      })()}
    />
  );
};

UsersList.propTypes = {
  loading: PropTypes.bool,
  users: PropTypes.array,
  editUser: PropTypes.func,
  deleteUser: PropTypes.func,
  addUser: PropTypes.func,
  disableUser: PropTypes.func,
  enableUser: PropTypes.func,
};

export default UsersList;
