import React, { useState, useEffect } from "react";
//import PropTypes from "prop-types";
import UsersList from "./UsersList";
import SweetAlert from "react-bootstrap-sweetalert";
import UserModalContainer from "App/components/Modals/UserModal/UserModal.container";
import axiosAPI from "App/services/axios";
import AssignPermissionsModalContainer from "App/components/Modals/AssignPermissionsModal/AssignPermissionsModal.container";
const qs = require("qs");

const UsersListContainer = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [userModalUser, setUserModalUser] = useState({});
  const [userModalEdit, setUserModalEdit] = useState(false);
  const [assignPermissionsModal, setAssignPermissionsModal] = useState({
    assignedPermissions: [],
    title: "",
    description: "",
    userId: null,
    visible: false,
  });

  const getUsers = query => {
    setLoading(true);
    axiosAPI
      .get(
        "/users?showDeleted=true&showDisabled=true&" +
          qs.stringify({ search: query }),
      )
      .then(result => setUsers(result.data || []))
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting users, please retry",
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveUserPermissions = ({ permissions, userId }) => {
    setLoading(true);
    axiosAPI
      .post(`/users/${userId}/permissions`, { permissions })
      .then(result => getUsers())
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong updating user permissions, please retry",
        );
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const disableUser = async id => {
    setLoading(true);
    await axiosAPI
      .patch(`/users/${id}/disable`)
      .then(result => {
        setLoading(false);
        getUsers();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage || "An error occurred while making user inactive",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  const enableUser = async id => {
    setLoading(true);
    await axiosAPI
      .patch(`/users/${id}/enable`)
      .then(result => {
        setLoading(false);
        getUsers();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage || "An error occurred while making user active",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  const deleteUser = async id => {
    setLoading(true);
    await axiosAPI
      .delete(`/users/${id}`)
      .then(result => {
        setLoading(false);
        getUsers();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage || "An error occurred while deleting user",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  const restoreUser = async id => {
    setLoading(true);
    await axiosAPI
      .patch(`/users/${id}/restore`)
      .then(result => {
        setLoading(false);
        getUsers();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage || "An error occurred while restoring user",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  useEffect(() => {
    getUsers();
  }, []); // eslint-disable-line

  return (
    <>
      {!!errorMessage && (
        <SweetAlert
          danger
          onConfirm={() => {
            setErrorMessage(null);
            setErrorMore(null);
          }}
          title="Error"
        >
          {errorMessage}
          {errorMore && (
            <>
              <br />
              <small>{errorMore}</small>
            </>
          )}
        </SweetAlert>
      )}
      <UsersList
        loading={loading}
        users={users}
        addUser={() => {
          setUserModalUser({});
          setUserModalEdit(false);
          setUserModalVisible(true);
        }}
        deleteUser={deleteUser}
        restoreUser={restoreUser}
        editUser={user => {
          setUserModalUser(user.id);
          setUserModalEdit(true);
          setUserModalVisible(true);
        }}
        disableUser={disableUser}
        enableUser={enableUser}
        assignUserPermissions={row => {
          let selectedPermissions = [];
          row.Permissions.forEach((permission, i) => {
            selectedPermissions.push({
              key: permission.key,
              expiresOn: permission?.UserPermission?.expiresOn || null,
            });
          });
          setAssignPermissionsModal({
            assignedPermissions: selectedPermissions,
            title: `${row.firstName} ${row.lastName}`,
            description: null,
            userId: row.id,
            visible: true,
          });
        }}
      />
      <UserModalContainer
        visible={userModalVisible}
        id={userModalUser}
        edit={userModalEdit}
        setVisible={setUserModalVisible}
        done={() => getUsers()}
      />
      <AssignPermissionsModalContainer
        assignedPermissions={assignPermissionsModal.assignedPermissions} //pass in {expiresOn:2020-02...} to timelimit, expects: [ {id:5,expiresOn:null},{...} ]
        showExpiresOn={true}
        assignToTitle={assignPermissionsModal.title}
        assignToDescription={assignPermissionsModal.description}
        visible={assignPermissionsModal.visible}
        setVisible={visible =>
          setAssignPermissionsModal({ ...assignPermissionsModal, visible })
        }
        done={assignedPermissions => {
          saveUserPermissions({
            permissions: assignedPermissions,
            userId: assignPermissionsModal.userId,
          });
        }}
      />
    </>
  );
};

UsersListContainer.propTypes = {};

export default UsersListContainer;
