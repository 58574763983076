import React from "react";
import PropTypes from "prop-types";
import { UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";

const MemoBadge = ({ id, memo }) => {
  if (!memo) return null;

  return (
    <>
      <FontAwesomeIcon
        className="ml-2 cursor-pointer"
        id={id}
        icon={faCommentDots}
      />
      <UncontrolledPopover placement="bottom" target={id}>
        <PopoverHeader>Memo</PopoverHeader>
        <PopoverBody>{memo}</PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

MemoBadge.propTypes = {
  id: PropTypes.string,
  memo: PropTypes.string,
};

export default MemoBadge;
