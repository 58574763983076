import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Row, Col, Table, ListGroup, ListGroupItem } from "reactstrap";
import MemoBadge from "App/components/Badges/Memo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonWithPermissions from "App/components/Buttons/ButtonWithPermissions";
import {
  faTruckLoading,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import PurchaseOrderTableContainer from "App/components/Tables/PurchaseOrderTable/PurchaseOrderTable.container";
import UserButtonContainer from "App/components/Buttons/UserButton/UserButton.container";
import ContactButtonContainer from "App/components/Buttons/ContactButton/ContactButton.container";
import CompanyButtonContainer from "App/components/Buttons/CompanyButton/CompanyButton.container";
import PurchaseOrderStatusDropdown from "App/components/Dropdowns/PurchaseOrderStatusDropdown";
import SweetAlert from "react-bootstrap-sweetalert";
import DeliveryStatusBadge from "App/components/Badges/DeliveryStatusBadge";
import ShipmentRecordsTab from "App/components/Tabs/ShipmentRecordsTab";
import axiosAPI from "App/services/axios";
const moment = require("moment");

const PurchaseOrderTab = ({
  purchaseOrder,
  isFocused,
  setPurchaseOrderModal,
  setPurchaseOrder, //used to update list of POs
  isActive,
  refresh,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  const [authoriseWarning, setAuthoriseWarning] = useState(false);
  const [tab, setTab] = useState("ordering");

  const handleStatusChange = status => {
    if (status.id === purchaseOrder?.PurchaseOrderStatusId) return; //ignore same status
    if (status.requiresAuthorisation && !purchaseOrder.authorised) return; //ignore if needs auth but not authorised, should not happen because those options are disabled

    setStatusChangeLoading(true);
    axiosAPI
      .patch(`/purchaseOrders/${purchaseOrder.id}/status/${status.id}`)
      .then(({ data }) => {
        setPurchaseOrder(data);
      })
      .catch(error => {
        setErrorMessage(
          error?.errorMessage || "An error occurred while updating status",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      })
      .finally(() => setStatusChangeLoading(false));
  };

  const authorisePurchaseOrder = () => {
    const authorise = !purchaseOrder?.authorised;
    setAuthoriseWarning(false);
    axiosAPI
      .patch(
        `/purchaseOrders/${purchaseOrder.id}/${
          authorise ? "authorise" : "unauthorise"
        }`,
      )
      .catch(error => {
        setStatusChangeLoading(false);
        setErrorMessage(
          error?.errorMessage ||
            "An error occurred while authorising the purchase order",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      })
      .finally(() => refresh());
  };

  if (!purchaseOrder) return null;

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        title="Are you sure?"
        confirmBtnText="Yes"
        onConfirm={() => authorisePurchaseOrder()}
        onCancel={() => setAuthoriseWarning(false)}
        focusCancelBtn
        show={authoriseWarning}
      >
        {purchaseOrder?.authorised
          ? "By unauthorising the purchase order, it will no longer affect stock availability"
          : "By authorising the purchase order, it will affect stock availability"}
      </SweetAlert>
      <Row>
        <Col lg={7}>
          <h2 className="text-muted font-weight-300 mb-0">Purchase Order</h2>
          <h1 className="mb-0 mt--1">
            {purchaseOrder.name}
            <h4 className="d-inline-block ml-4 mb-0">
              <DeliveryStatusBadge {...purchaseOrder} />
            </h4>
          </h1>
          <h3 className="mb-1">
            {purchaseOrder?.AccountEnvironment.purchaseOrderPrefix}
            {purchaseOrder?.number}{" "}
            <small className="ml-1">
              to {purchaseOrder?.Contact?.firstName}{" "}
              {purchaseOrder?.Contact?.lastName},{" "}
              {purchaseOrder?.Contact?.Company?.name}
            </small>
            <MemoBadge id="purchaseOrderMemo" memo={purchaseOrder?.memo} />
          </h3>
        </Col>
        <Col xl={5} className="text-right">
          <div>
            <Button
              color="secondary"
              style={{
                height: "38px",
                paddingTop: 0,
                paddingBottom: 0,
              }}
              onClick={() => refresh()}
            >
              Refresh
            </Button>
            <ButtonWithPermissions
              color={purchaseOrder?.authorised ? "success" : "danger"}
              style={{
                height: "38px",
                paddingTop: 0,
                paddingBottom: 0,
                marginRight: "8px",
                width: "135px",
              }}
              onClick={() => setAuthoriseWarning(true)}
              hoverChildren={
                purchaseOrder?.authorised ? "Unauthorise" : "Authorise"
              }
              hoverColor={purchaseOrder?.authorised ? "danger" : "success"}
              outlineWhenNotHovered={true}
              permission="PO_AUTHORISE"
              tooltip="You do not have permission to authorise/unauthorise a purchase order"
            >
              {purchaseOrder?.authorised ? "Authorised" : "Unauthorised"}
            </ButtonWithPermissions>
            <Button
              color="warning"
              style={{
                height: "38px",
                paddingTop: 0,
                paddingBottom: 0,
                color: "#000",
              }}
              onClick={() =>
                setPurchaseOrderModal({
                  id: purchaseOrder.id,
                  visible: true,
                  edit: true,
                })
              }
            >
              Edit
            </Button>
          </div>
          <PurchaseOrderStatusDropdown
            label="Status"
            name="PurchaseOrderStatus"
            className="text-left mt-2"
            authorised={purchaseOrder?.authorised}
            value={purchaseOrder?.PurchaseOrderStatus}
            setValue={value => handleStatusChange(value)}
            loading={statusChangeLoading}
          />
        </Col>
      </Row>
      <div className="my-3">
        <ListGroup horizontal className="text-center">
          <ListGroupItem
            tag="button"
            action
            active={tab === "ordering"}
            onClick={() => setTab("ordering")}
            className="cursor-pointer smallListGroupItemHeight"
          >
            Ordering
          </ListGroupItem>
          {/*<ListGroupItem
            tag="button"
            action
            active={tab === "shipments"}
            onClick={() => setTab("shipments")}
            className="cursor-pointer smallListGroupItemHeight"
          >
            Shipments
          </ListGroupItem>*/}
          <ListGroupItem
            tag="button"
            action
            active={tab === "information"}
            onClick={() => setTab("information")}
            className="cursor-pointer smallListGroupItemHeight"
          >
            Information
          </ListGroupItem>
        </ListGroup>
      </div>
      {isFocused && (
        <>
          {tab === "ordering" && (
            <div className="mt-2">
              <DndProvider backend={HTML5Backend}>
                <PurchaseOrderTableContainer
                  purchaseOrder={purchaseOrder}
                  isActive={isActive}
                />
              </DndProvider>
            </div>
          )}
          {tab === "shipments" && (
            <ShipmentRecordsTab
              preSelectDepotId={purchaseOrder?.DepotId}
              purchaseOrderId={purchaseOrder?.id}
              hideSending={true}
            />
          )}
          {tab === "information" && (
            <Row>
              <Col xl={6}>
                <h4>
                  <FontAwesomeIcon className="mr-2" icon={faFileInvoice} />
                  Accounting
                </h4>
                <Table bordered responsive size="sm" className="headersInRows">
                  <tbody>
                    <tr>
                      <th scope="row">PO Number</th>
                      <td>
                        {purchaseOrder?.number
                          ? purchaseOrder.number
                          : "Not yet assigned (PO needs to be confirmed)"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Account Environment</th>
                      <td>{purchaseOrder?.AccountEnvironment?.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Contact</th>
                      <td>
                        <ContactButtonContainer id={purchaseOrder?.ContactId} />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Company</th>
                      <td>
                        <CompanyButtonContainer id={purchaseOrder?.CompanyId} />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Created</th>
                      <td>
                        {moment(purchaseOrder?.createdAt).format(
                          "ddd Do MMM YYYY, HH:mm",
                        )}{" "}
                        by
                        <br />
                        <UserButtonContainer
                          id={purchaseOrder?.CreateUserId}
                          className="mt-2"
                        />
                      </td>
                    </tr>
                    {purchaseOrder?.AuthorisedUserId && (
                      <tr>
                        <th scope="row">Authorised</th>
                        <td>
                          {moment(purchaseOrder?.authorisedOn).format(
                            "ddd Do MMM YYYY, HH:mm",
                          )}{" "}
                          by
                          <br />
                          <UserButtonContainer
                            id={purchaseOrder?.AuthorisedUserId}
                            className="mt-2"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
              <Col xl={6}>
                <h4>
                  <FontAwesomeIcon className="mr-2" icon={faTruckLoading} />
                  Delivery
                </h4>
                <Table bordered responsive size="sm" className="headersInRows">
                  <tbody>
                    {purchaseOrder?.supplierReference && (
                      <tr>
                        <th scope="row">Supplier Reference</th>
                        <td>{purchaseOrder?.supplierReference}</td>
                      </tr>
                    )}
                    <tr>
                      <th scope="row">Transport Required</th>
                      <td
                        className={
                          purchaseOrder?.transportRequired
                            ? "text-sucess"
                            : "text-danger"
                        }
                      >
                        {purchaseOrder?.transportRequired ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Depot</th>
                      <td>
                        {purchaseOrder?.Depot?.name},{" "}
                        <small>{purchaseOrder?.Depot?.Address?.county}</small>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Expected Delivery</th>
                      <td>
                        {moment(purchaseOrder?.expectedDelivery).format(
                          "Do MMM YYYY",
                        )}
                      </td>
                    </tr>
                    {purchaseOrder?.receivedOn && (
                      <tr>
                        <th scope="row">Received</th>
                        <td>
                          {moment(purchaseOrder?.receivedOn).format(
                            "HH:mm, Do MMM YYYY",
                          )}{" "}
                          by <br />
                          <UserButtonContainer
                            id={purchaseOrder?.ReceiveUserId}
                            className="mt-2"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

PurchaseOrderTab.propTypes = {
  purchaseOrder: PropTypes.object,
  isFocused: PropTypes.bool,
  setPurchaseOrderModal: PropTypes.func,
  setPurchaseOrder: PropTypes.func,
  refresh: PropTypes.func,
};

export default PurchaseOrderTab;
