import React, { useState, useEffect } from "react";
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import ProductPickerResult from "./ProductPickerResult";
import styles from "./ProductPicker.module.scss";
const _ = require("lodash");

const ProductPicker = ({
  innerRef,
  value,
  onFocus,
  onBlur,
  onChange,
  onSelect,
  showResults,
  results,
  depotId,
  specificVendorId,
  specificDepotId,
}) => {
  //goes through each price list item and stock and works how many available from various places
  //used on supplying table
  const determineProductStock = ({ product }) => {
    const stockAvailable = {};
    let stockInThisDepot = +_.get(
      product,
      `availability.available.${depotId}`,
      0,
    );
    let stockInOtherDepots = Object.keys(
      product?.availability?.available || [],
    ).reduce((sum, depot) => {
      if (+depot === depotId) return sum;
      return sum + +_.get(product, `availability.available.${depot}`, 0);
    }, 0);
    const vendors = [];
    let priceListItem = null;

    product.PriceListItems.forEach(priceListItem => {
      const vendorName = priceListItem?.PriceList?.Company?.name;
      if (!vendors.includes(vendorName)) {
        vendors.push(vendorName);
      }
    });

    if (product.PriceListItems?.length === 1) {
      priceListItem = product.PriceListItems[0];
    }

    return nodeManipulateWithShortcuts({
      product,
      priceListItem,
      stockInfo: {
        stockAvailable,
        stockInThisDepot,
        stockInOtherDepots,
        vendors,
      },
    });
  };

  //takes a node, ie a result, and if user has entered a shortcut eg /q3, will change node to have quantity 3
  //same for discount, margin etc
  const nodeManipulateWithShortcuts = ({
    product,
    priceListItem = null,
    stockInfo,
  }) => {
    const returnNode = {
      id: Date.now(),
      name: product.name,
      description: product.description,
      memo: "",
      quantity: 1,
      costPrice: product.costPrice,
      listPrice: product.listPrice,
      linePrice: product.linePrice || product.listPrice,
      type: 0,
      Product: product,
      ProductId: product.id,
      PriceListItem: priceListItem,
      PriceListItemId: priceListItem?.id,
      TaxRate: product.TaxRate,
      TaxRateId: product.TaxRateId,
      selected: false,
      ...stockInfo,
    };
    const valueSplit = value.split("/").slice(1);
    const quantityString = valueSplit.find(v => v.substring(0, 1) === "q");
    const discountString = valueSplit.find(v => v.substring(0, 1) === "d");
    const marginString = valueSplit.find(v => v.substring(0, 1) === "m");

    if (quantityString && quantityString.length > 1) {
      returnNode.quantity = +quantityString.substring(1);
    }

    if (discountString && discountString.length > 1) {
      returnNode.linePrice =
        +returnNode.listPrice * (1 - +discountString.substring(1) * 0.01);
    }

    if (marginString && marginString.length > 1) {
      returnNode.linePrice =
        +returnNode.costPrice * (+marginString.substring(1) * 0.01 + 1);
    }

    return returnNode;
  };

  //SPLIT RESULTS UP
  let data = [];
  if (results?.length > 0) {
    results.forEach((product, index) => {
      //ITS A NORMAL SEARCH ON QUOTING TAB
      if (!specificVendorId) {
        data.push(
          determineProductStock({
            product,
          }),
        );
      } else {
        //----------------------------------------------
        //JUST LOOKING FOR A DEPOT OR VENDOR'S PRODUCTS
        if (specificVendorId) {
          //we're looking for just one vendor's products, which they could have multiple of
          product.PriceListItems.filter(
            x => x.PriceList.CompanyId === specificVendorId,
          ).forEach(priceListItem => {
            //for each price list item they offer, lob it in the results list
            data.push(
              nodeManipulateWithShortcuts({
                costPrice: priceListItem.costPrice,
                listPrice: priceListItem.listPrice,
                linePrice: priceListItem.linePrice,
                product,
                priceListItem,
              }),
            );
          });
        }
      }
    });
    data = data.slice(0, 5);
  }

  const [highlightedResult, setHighlightedResult] = useState(0);

  const checkFunctionKeys = e => {
    const { key } = e;

    ["ArrowUp", "ArrowDown", "Enter", "Escape"].includes(key) &&
      e.preventDefault();

    key === "ArrowUp" &&
      setHighlightedResult(
        highlightedResult > 0 ? highlightedResult - 1 : data.length - 1,
      );

    key === "ArrowDown" &&
      setHighlightedResult(
        highlightedResult < data.length - 1 ? highlightedResult + 1 : 0,
      );

    if (key === "Enter" && !!data[highlightedResult]) {
      onSelect(data[highlightedResult]);
      onChange("");
    }

    key === "Escape" && onChange("");
  };

  useEffect(() => {
    setHighlightedResult(0);
  }, [value]); // eslint-disable-line

  return (
    <div style={{ flexGrow: 1, marginRight: "15px" }}>
      <FormGroup
        style={{ flexGrow: 1, position: "relative", whiteSpace: "nowrap" }}
      >
        <InputGroup className="d-inline-flex">
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{ height: "35px" }}>
              <i className="fa fa-search" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Search"
            type="text"
            innerRef={innerRef}
            value={value}
            style={{ height: "35px" }}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={e => onChange(e.target.value)}
            onKeyDown={checkFunctionKeys}
          />
        </InputGroup>
        <i className="fa fa-question-circle ml-2" id="searchPickerHelp" />
        <UncontrolledTooltip placement="top" target="searchPickerHelp">
          <div className="text-left">
            <u>Shortcuts:</u>
            <br />
            Escape: clear
            <br />
            Arrow Up/Down: highlight item
            <br />
            Enter: insert item
            <br />
            /q3: quantity of 3
            <br />
            /m10: margin at 10%
            <br />
            /d20: discount at 20%
          </div>
        </UncontrolledTooltip>
        {showResults && results && results?.length > 0 && (
          <div className={styles.pickerSearchResults}>
            {data?.map((node, index) => {
              return (
                <ProductPickerResult
                  key={index}
                  highlighted={index === highlightedResult}
                  node={node}
                  onSelect={onSelect}
                  showStockAndVendorCount={
                    !specificVendorId && !specificDepotId
                  }
                  showAvailableCountForDepotId={specificDepotId}
                />
              );
            })}
          </div>
        )}
      </FormGroup>
    </div>
  );
};

export default ProductPicker;
