import React from "react";
import { Row, Card, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import PageContainer from "App/components/PageContainer";
import ProductsListContainer from "App/components/Tables/ProductsList/ProductsList.container";
import ManufacturersListContainer from "App/components/Tables/ManufacturersList/ManufacturersList.container";
import PriceListContainer from "App/components/Tables/PriceList/PriceList.container";
import hasPermission from "App/helpers/hasPermission";
import { useParams, NavLink } from "react-router-dom";

const Products = () => {
  const { tab = "products" } = useParams(); //id = null

  return (
    <PageContainer title="Products">
      <Row>
        <div className="col">
          <Card className="shadow">
            <Nav tabs className="main-tabs">
              <NavItem>
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/app/products"
                  exact={true}
                >
                  Products
                </NavLink>
              </NavItem>
              {hasPermission("MANF_VIEW") && (
                <NavItem>
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/app/products/manufacturers"
                  >
                    Manufacturers
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/app/products/pricelists"
                >
                  Price Lists
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tab}>
              <TabPane tabId={"products"}>
                <ProductsListContainer />
              </TabPane>
              <TabPane tabId={"product"}>product</TabPane>
              <TabPane tabId={"manufacturers"}>
                {hasPermission("MANF_VIEW") ? (
                  <ManufacturersListContainer />
                ) : (
                  "You do not have permission to view manufacturers"
                )}
              </TabPane>
              <TabPane tabId={"pricelists"}>
                <PriceListContainer />
              </TabPane>
            </TabContent>
          </Card>
        </div>
      </Row>
    </PageContainer>
  );
};

export default Products;
