import React from "react";
import FileExplorerTheme from "App/components/Tables/SortableTreeCommon/Theme";
import SupplyingTableRow from "./SupplyingTableRow";
import styles from "./SupplyingTable.module.scss";
import { SortableTreeWithoutDndContext as SortableTree } from "react-sortable-tree";
const externalNodeType = "item";

const SupplyingTable = ({
  treeData,
  onTreeChange,
  getNodeKey,
  canNodeHaveChildren,
  showMarginAsPercent,
  setShowMarginAsPercent,
  showDiscountAsPercent,
  setShowDiscountAsPercent,
  updateNode,
  deleteNode,
  restoreName,
  selectRow,
  selectPricing,
  moveFocusToNextRow,
}) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.header}>
        <div className={`${styles.headerCell} ${styles.headerRowPad}`}>
          Name
        </div>
        {/*<div className={`${styles.headerCell} ${styles.headerProduct}`}>
          Product
        </div>*/}
        <div className={styles.headerCell}>Cost</div>
        <div
          className={styles.headerCell}
          style={{ cursor: "pointer" }}
          onClick={setShowMarginAsPercent}
        >
          <span className={styles.interactiveHeader}>Margin</span>
        </div>
        <div className={styles.headerCell}>List</div>
        <div
          className={styles.headerCell}
          style={{ cursor: "pointer" }}
          onClick={setShowDiscountAsPercent}
        >
          <span className={styles.interactiveHeader}>Discount</span>
        </div>
        <div className={styles.headerCell}>Line</div>
        <div className={styles.headerCell}>Total</div>
      </div>
      <SortableTree
        theme={FileExplorerTheme}
        dndType={externalNodeType}
        treeData={treeData}
        onChange={onTreeChange}
        getNodeKey={getNodeKey}
        canDrop={true}
        canNodeHaveChildren={canNodeHaveChildren}
        generateNodeProps={({ node, path }) => {
          console.log(node);
          return SupplyingTableRow({
            node,
            path,
            showMarginAsPercent,
            showDiscountAsPercent,
            updateNode,
            deleteNode,
            restoreName,
            selectRow,
            selectPricing,
            moveFocusToNextRow,
          });
        }}
      />
    </div>
  );
};

export default SupplyingTable;
