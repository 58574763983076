import React from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import { Table } from "reactstrap";
const moment = require("moment");
const _ = require("lodash");

const LogModal = ({
  edit = false,
  visible = true,
  setVisible,
  log = {},
  addLog,
  editLog,
  loading,
}) => {
  const { name, description, ip, updatedAt } = log;
  const fullName = _.get(log, "PermissionUser.fullName", "Unknown user");

  return (
    <Modal
      className="modal-dialog-centered formModal modal-big-ass"
      isOpen={visible}
      toggle={() => setVisible(false)}
      data-testid="modal"
    >
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Log Detail</h2>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setVisible(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Table>
              <tr>
                <td>Type: </td>
                <td>{name}</td>
              </tr>
              <tr>
                <td>User: </td>
                <td>{fullName}</td>
              </tr>
              <tr>
                <td>Description: </td>
                <td>{description}</td>
              </tr>
              <tr>
                <td>IP Address: </td>
                <td>{ip}</td>
              </tr>
              <tr>
                <td>Time/Date: </td>
                <td>
                  {moment.duration(moment().diff(moment(updatedAt))).humanize()}{" "}
                  ago, {moment(updatedAt).format("llll")}
                </td>
              </tr>
            </Table>
          </div>
        </>
      )}
    </Modal>
  );
};

LogModal.propTypes = {
  edit: PropTypes.bool,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  log: PropTypes.object,
  addLog: PropTypes.func,
  editLog: PropTypes.func,
  loading: PropTypes.bool,
};

export default LogModal;
