import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ManufacturerModal from "./ManufacturerModal";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";

const ManufacturerModalContainer = ({
  id,
  visible = false,
  setVisible,
  edit = false,
  done,
}) => {
  const [manufacturer, setManufacturer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  useEffect(() => {
    if (visible && id) {
      getManufacturer(id);
    }
  }, [id, visible]); // eslint-disable-line

  const getManufacturer = id => {
    setLoading(true);
    axiosAPI
      .get(`/manufacturers/${id}`)
      .then(result => {
        setManufacturer(result.data || null);
      })
      .catch(error => {
        setVisible(false);
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting Manufacturer, please retry",
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addManufacturer = async manufacturer => {
    setLoading(true);
    await axiosAPI
      .post("/manufacturers", manufacturer)
      .then(result => {
        setVisible(false);
        done();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage ||
            "An error occurred while adding new Manufacturer",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  const editManufacturer = async manufacturer => {
    setLoading(true);
    await axiosAPI
      .patch(`/manufacturers/${manufacturer.id}`, manufacturer)
      .then(result => {
        setVisible(false);
        done();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage || "An error occurred while editing Manufacturer",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  return (
    <>
      {!!errorMessage && (
        <SweetAlert
          danger
          onConfirm={() => {
            setErrorMessage(null);
            setErrorMore(null);
          }}
          title="Error"
        >
          {errorMessage}
          {errorMore && (
            <>
              <br />
              <small>{errorMore}</small>
            </>
          )}
        </SweetAlert>
      )}
      <ManufacturerModal
        visible={visible}
        manufacturer={manufacturer}
        edit={edit}
        setVisible={setVisible}
        editManufacturer={editManufacturer}
        addManufacturer={addManufacturer}
        loading={loading}
      />
    </>
  );
};

ManufacturerModalContainer.propTypes = {
  id: PropTypes.number,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  edit: PropTypes.bool,
  done: PropTypes.func,
};

export default ManufacturerModalContainer;
