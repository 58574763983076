import React from "react";
import {
  Card,
  CardBody,
  Badge,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faEnvelope,
  faPhone,
  faMobileAlt,
  faReceipt,
  faInfo,
  faCompass,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
const moment = require("moment");

const ContactCard = ({ contact, badge = null }) => (
  <Card>
    <CardBody className="pt-3">
      <CardTitle className="mb-1" style={{ fontSize: "1.4rem" }}>
        {contact?.title} {contact?.fullName}
      </CardTitle>
      {badge && <Badge color="primary">{badge}</Badge>}
      <ListGroup className="mt-3">
        {!!contact?.jobTitle && (
          <ListGroupItem>
            <FontAwesomeIcon
              icon={faAddressCard}
              className="ListGroupItemIcon"
            />
            {contact?.jobTitle}
          </ListGroupItem>
        )}
        {!!contact?.email && (
          <ListGroupItem tag="a" href={`mailto:${contact?.email}`} action>
            <FontAwesomeIcon icon={faEnvelope} className="ListGroupItemIcon" />
            {contact?.email}
          </ListGroupItem>
        )}
        {!!contact?.work && (
          <ListGroupItem tag="a" href={`tel:${contact?.work}`} action>
            <FontAwesomeIcon icon={faPhone} className="ListGroupItemIcon" />
            {contact?.work}
          </ListGroupItem>
        )}
        {!!contact?.mobile && (
          <ListGroupItem tag="a" href={`tel:${contact?.mobile}`} action>
            <FontAwesomeIcon icon={faMobileAlt} className="ListGroupItemIcon" />
            {contact?.mobile}
          </ListGroupItem>
        )}
        {!!contact?.accounts && (
          <ListGroupItem>
            <FontAwesomeIcon icon={faReceipt} className="ListGroupItemIcon" />
            Accounts contact
          </ListGroupItem>
        )}
        <ListGroupItem>
          <FontAwesomeIcon icon={faCompass} className="ListGroupItemIcon" />
          <span
            style={{
              color: contact?.status ? "green" : "red",
            }}
          >
            {contact?.status ? "Active" : "Inactive"}
          </span>
        </ListGroupItem>
        {!!contact?.memo && (
          <ListGroupItem>
            <FontAwesomeIcon icon={faInfo} className="ListGroupItemIcon" />
            {contact?.memo}
          </ListGroupItem>
        )}
        <ListGroupItem>
          <FontAwesomeIcon icon={faCalendar} className="ListGroupItemIcon" />
          Contact since {moment(contact?.createdAt).format("MMMM Do YYYY")}
        </ListGroupItem>
      </ListGroup>
    </CardBody>
  </Card>
);

export default ContactCard;
