import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, FormGroup, Col, Row } from "reactstrap";
import Select from "react-select";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import commonStyles from "./PriceListImportModal.module.scss";
import PriceListStatus from "App/components/Badges/PriceListStatus";
import DatePicker from "react-datepicker";
const moment = require("moment");
const _ = require("lodash");

const OptionsConfigurator = ({
  vendor,
  currentStep,
  priceList,
  setPriceList,
  data,
  multipleManufacturer,
  manufacturerList,
  expireOtherPriceLists,
  setExpireOtherPriceLists,
  setOptionsConfigurationHasIssues,
}) => {
  const [priceListOptions, setPriceListOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadPriceLists = async query => {
    setLoading(true);
    await axiosAPI
      .get(`/vendors/${vendor?.id}/priceLists`)
      .then(result => {
        console.log(result.data);
        setPriceListOptions(
          result.data.filter(priceList => priceList.status === "Active"),
        );
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting price lists, please retry",
        );
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    //preload options
    if (currentStep === 5) {
      loadPriceLists();
    }
  }, [currentStep]); // eslint-disable-line

  useEffect(() => {
    let issues = false;
    if (priceList.enabled && !priceList.startDate) issues = true;

    setOptionsConfigurationHasIssues(issues);
  }, [priceList]); // eslint-disable-line

  if (loading) return "Loading...";

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <Row>
        <h2 className={`col-12 text-default`}>
          <span className={commonStyles.sequenceNumber}>1</span>
          Name and Memo
        </h2>
      </Row>
      <p className={commonStyles.numberAlign}>
        Names and memos can help to find your price lists in future.
      </p>
      <div className={commonStyles.numberAlign}>
        <Row>
          <Col md="5">
            <FormGroup>
              <Input
                placeholder="Name..."
                type="text"
                style={{
                  fontSize: "1.1rem",
                  fontWeight: 500,
                  color: "#222",
                  minHeight: "46px",
                }}
                value={priceList.name || ""}
                onChange={e =>
                  setPriceList({
                    ...priceList,
                    name: e.target.value ? e.target.value : null,
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col md="5">
            <FormGroup>
              <Input
                placeholder="Memo... (eg useful information about the Price List)"
                type="textarea"
                rows="1"
                style={{ color: "#444", minHeight: "46px" }}
                value={priceList.memo || ""}
                onChange={e =>
                  setPriceList({
                    ...priceList,
                    memo: e.target.value ? e.target.value : null,
                  })
                }
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <Row className={commonStyles.rowDivider}>
        <h2 className={`col-12 text-default`}>
          <span className={commonStyles.sequenceNumber}>2</span>
          Status
        </h2>
      </Row>
      <p className={commonStyles.numberAlign}>
        You can make a price list immediately available, or schedule it to
        become live in the future. You can also set an end time for the price
        list, up until which it will be valid. A price list can also be set as
        enabled/disabled, temporarily making it unavailable.
      </p>
      <div className={commonStyles.numberAlign}>
        <div className={`custom-control custom-checkbox mb-3`}>
          <input
            className={"form-control custom-control-input"}
            id={`makePriceListInactive`}
            checked={priceList.enabled}
            type="checkbox"
            onChange={() =>
              setPriceList({ ...priceList, enabled: !priceList.enabled })
            }
          />
          <label
            className="custom-control-label"
            htmlFor={`makePriceListInactive`}
          >
            <span style={{ fontSize: "1rem", fontWeight: 500 }}>
              Price List is{" "}
              {priceList.enabled ? (
                <span className="text-success" style={{ top: 0 }}>
                  Enabled
                </span>
              ) : (
                <span className="text-secondary" style={{ top: 0 }}>
                  Disabled
                </span>
              )}
            </span>
          </label>
        </div>
        {priceList.enabled && (
          <div className={"ml-4 pl-1 my-2"}>
            <Row>
              <Col md="4">
                <span>
                  Start date <small className="text-danger">required</small>
                </span>
                <DatePicker
                  selected={
                    priceList.startDate
                      ? moment(priceList.startDate).toDate()
                      : null
                  }
                  onChange={date =>
                    setPriceList({ ...priceList, startDate: date })
                  }
                  showTimeSelect
                  isClearable
                  withPortal
                  className={commonStyles.datePicker}
                  placeholderText="Select start date..."
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Col>
              <Col md="4">
                <span>
                  End date{" "}
                  <small className="text-muted">
                    optional, leave empty for never expires
                  </small>
                </span>
                <DatePicker
                  selected={
                    priceList.endDate
                      ? moment(priceList.endDate).toDate()
                      : null
                  }
                  onChange={date =>
                    setPriceList({ ...priceList, endDate: date })
                  }
                  showTimeSelect
                  isClearable
                  withPortal
                  className={commonStyles.datePicker}
                  placeholderText="Never expires"
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
      <Row className={commonStyles.rowDivider}>
        <h2 className={`col-12 text-default`}>
          <span className={commonStyles.sequenceNumber}>3</span>
          Expire other Price Lists
        </h2>
      </Row>
      <p className={commonStyles.numberAlign}>
        You can set other prices lists to expire at the start time of this price
        list by selecting them from the list below. This will set their end date
        to be the same as this price list's start date. Useful if you want
        several price lists to swap to this price list.
      </p>
      {priceListOptions.length > 0 ? (
        <Select
          className={`w-80 ${commonStyles.numberAlign} pl-0 mb-3`}
          placeholder="Select Price Lists"
          isClearable
          isMulti
          filterOption={() => true}
          onInputChange={(query, actionMeta) => {}}
          onChange={value => {
            setExpireOtherPriceLists(value);
          }}
          value={expireOtherPriceLists}
          options={priceListOptions}
          getOptionValue={option => {
            return option.id;
          }}
          getOptionLabel={option => {
            return (
              <>
                <PriceListStatus
                  priceList={option}
                  className="mr-2 float-right"
                />
                <span
                  style={{
                    paddingTop: "2px",
                    fontSize: "1rem",
                    display: "inline-block",
                    fontWeight: 500,
                  }}
                >
                  {option.name || "Price List"}
                </span>
                <br />
                <small>
                  {moment(option.startDate).format("DD MMM YYYY")} -{" "}
                  {option.endDate
                    ? moment(option.endDate).format("DD MMM YYYY")
                    : "Never expires"}
                  , {option.PriceListItemsCount} products,{" "}
                  {option.minimumOrderQuantity || "No"} minimum order quantity
                </small>
              </>
            );
          }}
          isLoading={loading}
        />
      ) : (
        <span className={`py-3 px-2 ${commonStyles.numberAlign}`}>
          No active price lists for {vendor?.name}
        </span>
      )}
      <Row className={commonStyles.rowDivider}>
        <h2 className={`col-12 text-default`}>
          <span className={commonStyles.sequenceNumber}>4</span>
          Review
        </h2>
      </Row>
      <p className={commonStyles.numberAlign}>
        Final overview of the import to check everything is in order before
        proceeding.
      </p>
      <ul className={commonStyles.numberAlign}>
        <li>
          {priceList.name ? priceList.name : "Price List"} from{" "}
          <strong>{vendor?.name}</strong>
        </li>
        <li>
          {_.toString(data.filter(row => row?.import).length)} rows to import
        </li>
        <li>
          from{" "}
          {multipleManufacturer
            ? `${manufacturerList?.length} manufacturers`
            : "1 manufacturer"}
        </li>
        <li>
          Price List is set to be{" "}
          <strong
            className={priceList.enabled ? "text-success" : "text-secondary"}
          >
            {priceList.enabled ? "Enabled" : "Disabled"}
          </strong>
        </li>
        {priceList.enabled && priceList.startDate && (
          <li>
            Starts on {moment(priceList.startDate).format("HH:mm, Do MMM YYYY")}
            , and
            {priceList.endDate
              ? ` expires on ${moment(priceList.endDate).format(
                  "HH:mm, Do MMM YYYY",
                )}`
              : " never expires"}
          </li>
        )}
        <li>
          {expireOtherPriceLists?.length} other Price Lists set to end{" "}
          {priceList.startDate && (
            <>on {moment(priceList.startDate).format("HH:mm, Do MMM YYYY")}</>
          )}
        </li>
      </ul>
    </>
  );
};

OptionsConfigurator.propTypes = {
  vendor: PropTypes.object,
  currentStep: PropTypes.number,
  priceList: PropTypes.object,
  setPriceList: PropTypes.func,
  data: PropTypes.array,
  multipleManufacturer: PropTypes.bool,
  manufacturerList: PropTypes.array,
  expireOtherPriceLists: PropTypes.array,
  setExpireOtherPriceLists: PropTypes.func,
  setOptionsConfigurationHasIssues: PropTypes.func,
};

export default OptionsConfigurator;
