import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row } from "reactstrap";
import commonStyles from "./PriceListImportModal.module.scss";
import UploadPriceList from "./UploadPriceList";
import Select from "react-select";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import ManufacturerCreatableDropdown from "App/components/Dropdowns/ManufacturerCreateableDropdown";
const qs = require("qs");

const InitialConfigurator = ({
  vendor,
  setVendor,
  priceList,
  setMinimumOrderQuantity,
  setPriceListFileId,
  setUpload,
  setManufacturer,
  manufacturer,
  setMultipleManufacturer,
  multipleManufacturer,
}) => {
  const [vendorLoading, setVendorLoading] = useState(false);
  //const [vendorDisabled, setVendorDisabled] = useState(false);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  const vendorsSearch = async query => {
    setVendorLoading(true);
    await axiosAPI
      .get("/vendors?" + qs.stringify({ search: query }))
      .then(result => {
        setVendorOptions(result.data);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting vendors, please retry",
        );
        console.error(error);
      })
      .finally(() => setVendorLoading(false));
  };

  useEffect(() => {
    //preload options
    vendorsSearch();
  }, []); // eslint-disable-line

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <Row>
        <h2 className={`col-12 text-default`}>
          <span className={commonStyles.sequenceNumber}>1</span>
          Select Vendor
        </h2>
      </Row>
      <p className={commonStyles.numberAlign}>
        Please select which vendor supplied the Price List. If you need to
        create a new vendor, please add a new company and tick 'Vendor'.
      </p>
      <Select
        className={`col-lg-5 col-md-8 col-11 ${commonStyles.numberAlign} pl-0`}
        placeholder="Lookup Vendor"
        isClearable
        blurInputOnSelect={true}
        filterOption={() => true}
        onInputChange={(query, actionMeta) => {
          vendorsSearch(query);
        }}
        onChange={(option, actionMeta) => {
          setVendor(option);
        }}
        options={vendorOptions}
        getOptionValue={option => {
          return option.id;
        }}
        getOptionLabel={option => {
          return <>{option.name}</>;
        }}
        value={vendor}
        //isDisabled={vendorDisabled}
        isLoading={vendorLoading}
      />
      <Row className={commonStyles.rowDivider}>
        <h2 className={`col-12 text-default`}>
          <span className={commonStyles.sequenceNumber}>2</span>
          Multiple Product Manufacturers
        </h2>
      </Row>
      <p className={commonStyles.numberAlign}>
        Are the products in this price list from multiple manufacturers? If you
        select 'No', you will be able to specify which manufacturer all products
        are from.
      </p>
      <div
        className={`custom-control custom-radio mb-3 ${commonStyles.numberAlign}`}
      >
        <input
          className="custom-control-input"
          id="radiomultiple"
          name="numberVendors"
          type="radio"
          checked={multipleManufacturer}
          onChange={() => setMultipleManufacturer(true)}
        />
        <label className="custom-control-label" htmlFor="radiomultiple">
          Yes, from multiple manufacturers
        </label>
      </div>
      <div
        className={`custom-control custom-radio mb-3 ${commonStyles.numberAlign}`}
      >
        <input
          className="custom-control-input"
          id="radiosingle"
          name="numberVendors"
          type="radio"
          checked={!multipleManufacturer}
          onChange={() => setMultipleManufacturer(false)}
        />
        <label className="custom-control-label" htmlFor="radiosingle">
          No, from a single manufacturer
        </label>
      </div>
      {!multipleManufacturer && (
        <ManufacturerCreatableDropdown
          preload={true}
          className={`col-lg-5 col-md-8 col-11 ${commonStyles.numberAlign} pl-4`}
          value={manufacturer}
          setValue={value => setManufacturer(value)}
        />
      )}
      <Row className={commonStyles.rowDivider} style={{ marginTop: "10px" }}>
        <h2 className={`col-12 text-default`}>
          <span className={commonStyles.sequenceNumber}>3</span>
          Minimum Order Quantity
        </h2>
      </Row>
      <p className={commonStyles.numberAlign}>
        Use to set whether the prices in this list are subject to a minimum
        order quantity. Useful if a vendor gives price breaks/discounts for
        different quantities ordered.
      </p>
      <div
        className={`custom-control custom-radio mb-3 ${commonStyles.numberAlign}`}
      >
        <input
          className="custom-control-input"
          id="noMinimumOrderQuantity"
          name="minimumOrderQuantity"
          type="radio"
          checked={!priceList.minimumOrderQuantity}
          onChange={() => setMinimumOrderQuantity(null)}
        />
        <label
          className="custom-control-label"
          htmlFor="noMinimumOrderQuantity"
        >
          No minimum
        </label>
      </div>
      <div
        className={`custom-control custom-radio mb-3 ${commonStyles.numberAlign}`}
      >
        <input
          className="custom-control-input"
          id="yesMinimumOrderQuantity"
          name="minimumOrderQuantity"
          type="radio"
          checked={!!priceList.minimumOrderQuantity}
          onChange={() => setMinimumOrderQuantity(10)}
        />
        <label
          className="custom-control-label"
          htmlFor="yesMinimumOrderQuantity"
        >
          <span className="d-inline">
            Minimum order quantity
            {!!priceList.minimumOrderQuantity && (
              <>
                {" "}
                of{" "}
                <input
                  className="d-inline py-0 px-2 ml-2"
                  style={{
                    borderRadius: 5,
                    width: "90px",
                    border: "1px solid #ccc",
                  }}
                  type="number"
                  name="minimumOrderQuantityValue"
                  id="minimumOrderQuantityValue"
                  value={priceList.minimumOrderQuantity}
                  onChange={e =>
                    setMinimumOrderQuantity(parseFloat(e.target.value))
                  }
                />
              </>
            )}
          </span>
        </label>
      </div>
      <Row className={commonStyles.rowDivider} style={{ marginTop: "10px" }}>
        <h2 className={`col-12 text-default`}>
          <span className={commonStyles.sequenceNumber}>4</span>
          Upload Price List
        </h2>
      </Row>
      <p className={commonStyles.numberAlign}>
        This should be a .csv, .xls or .xlsx file format. The file will be
        stored for future download if needed.
      </p>
      <UploadPriceList
        className={`col-md-8 col-lg-5 ${commonStyles.numberAlign}`}
        setPriceListFileId={setPriceListFileId}
        setUpload={setUpload}
      />
    </>
  );
};

InitialConfigurator.propTypes = {
  vendor: PropTypes.object,
  setVendor: PropTypes.func,
  priceList: PropTypes.object,
  setMinimumOrderQuantity: PropTypes.func,
  setPriceListFileId: PropTypes.func,
  setUploadInfo: PropTypes.func,
  manufacturer: PropTypes.object,
  setManufacturer: PropTypes.func,
  multipleManufacturer: PropTypes.bool,
  setMultipleManufacturer: PropTypes.func,
};

export default InitialConfigurator;
