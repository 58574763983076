import React, { useState } from "react";
import { Row, Button, Modal } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  TextInput,
  CheckboxInput,
} from "App/components/Inputs/BasicFormInputs.js";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";

function CompanyModal(props) {
  const { initialValues = {} } = props;
  const [loading, isLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  const addCompany = async newCompany => {
    isLoading(true);
    await axiosAPI
      .post("/companies", newCompany)
      .then(result => {
        props.setReturnValue(result.data);
        props.toggleModal(false);
        //return callback(result.data.rows);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Please try again");
      })
      .finally(() => isLoading(false));
  };

  const updateCompany = async company => {
    isLoading(true);
    await axiosAPI
      .put(`/companies/${props.id}`, company)
      .then(result => {
        props.setReturnValue(company);
        props.toggleModal(false);
        //        console.log(company);
        //return callback(result.data.rows);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Please try again");
      })
      .finally(() => isLoading(false));
  };

  return (
    <Modal
      className="modal-dialog-centered formModal"
      isOpen={props.isOpen}
      toggle={() => props.toggleModal(false)}
      backdrop="static"
    >
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <div className="modal-header">
        <h2 className="modal-title" id="contactModal">
          {props.id ? "Edit Company" : "Add Company"}
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => props.toggleModal(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: initialValues?.name || "",
          phone: initialValues?.phone || "",
          website: initialValues?.website || "",
          address1: initialValues?.address1 || "",
          address2: initialValues?.address2 || "",
          city: initialValues?.city || "",
          county: initialValues?.county || "",
          postcode: initialValues?.postcode || "",
          country: initialValues?.country || "",
          memo: initialValues?.memo || "",
          suspended: initialValues?.suspended || false,
          suspendedMemo: initialValues?.suspendedMemo || "",
          client: initialValues?.client || true,
          vendor: initialValues?.vendor || false,
          defaultDiscount: initialValues?.defaultDiscount || 0,
          creditLimit: initialValues?.creditLimit || 0,
          creditPeriod: initialValues?.creditPeriod || 0,
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .required("Required")
            .max(200, "Cannot be longer than 200 characters"),
          defaultDiscount: Yup.number().nullable().moreThan(-1).lessThan(101),
          creditLimit: Yup.number().nullable().moreThan(-1).integer(),
          creditPeriod: Yup.number().nullable().moreThan(-1).integer(),
          memo: Yup.string()
            .notRequired()
            .max(500, "Cannot be longer than 500 characters"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (!props.id) {
            addCompany(values);
          } else {
            updateCompany(values);
          }
          /*
          props.contacts.modal.id
            ? props.contactUpdate(newValues)
            : props.contactCreate(newValues);*/
        }}
      >
        {formikProps => (
          <>
            <div className="modal-body">
              {loading ? (
                <p>Loading...</p>
              ) : (
                <Form>
                  <Row>
                    <TextInput label="Name" name="name" type="text" col={12} />
                  </Row>
                  <Row>
                    <TextInput label="Phone" name="phone" type="text" col={6} />
                    <TextInput
                      label="Website"
                      name="website"
                      type="text"
                      col={6}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      label="Address 1"
                      name="address1"
                      type="text"
                      col={6}
                    />
                    <TextInput
                      label="Address 2"
                      name="address2"
                      type="text"
                      col={6}
                    />
                    <TextInput label="City" name="city" type="text" col={6} />
                    <TextInput
                      label="County"
                      name="county"
                      type="text"
                      col={6}
                    />
                    <TextInput
                      label="Postcode"
                      name="postcode"
                      type="text"
                      col={6}
                    />
                    <TextInput
                      label="Country"
                      name="country"
                      type="text"
                      col={6}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      label="Memo (Internal)"
                      name="memo"
                      type="text"
                      col={12}
                    />
                  </Row>
                  <Row>
                    <CheckboxInput
                      col={6}
                      enablevalidation={false}
                      name="suspended"
                    >
                      Suspended
                    </CheckboxInput>
                    <TextInput
                      label="Suspended Reason"
                      name="suspendedMemo"
                      type="text"
                      col={6}
                    />
                  </Row>
                  <Row>
                    <CheckboxInput
                      col={6}
                      enablevalidation={false}
                      name="client"
                    >
                      Client
                    </CheckboxInput>
                    <CheckboxInput
                      col={6}
                      enablevalidation={false}
                      name="vendor"
                    >
                      Vendor
                    </CheckboxInput>
                  </Row>
                  <Row>
                    <TextInput
                      label="Default Discount %"
                      name="defaultDiscount"
                      type="text"
                      col={4}
                    />
                    <TextInput
                      label="Credit Limit £"
                      name="creditLimit"
                      type="text"
                      col={4}
                    />
                    <TextInput
                      label="Credit Period days"
                      name="creditPeriod"
                      type="text"
                      col={4}
                    />
                  </Row>
                </Form>
              )}
            </div>
            <div className="modal-footer">
              <Button
                onClick={formikProps.submitForm}
                color="primary"
                type="button"
                disabled={loading}
              >
                {props.id ? "Edit Company" : "Add Company"}
              </Button>
            </div>
          </>
        )}
      </Formik>
    </Modal>
  );
}

export default CompanyModal;
