export const USERS_GET = "USERS_GET";
export const USERS_GET_SUCCESS = "USERS_GET_SUCCESS";
export const USERS_GET_FAILURE = "USERS_GET_FAILURE";

export const usersGet = () => ({
  type: USERS_GET,
});

export const usersGetSuccess = users => ({
  type: USERS_GET_SUCCESS,
  payload: users,
});

export const usersGetFailure = error => ({
  type: USERS_GET_FAILURE,
  payload: error,
});
