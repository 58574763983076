import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
import commonStyles from "./PriceListImportModal.module.scss";
import ManufacturerCreatableDropdown from "App/components/Dropdowns/ManufacturerCreateableDropdown";
import axiosAPI from "App/services/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  faCheck,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
const manufacturerKeyGenerator =
  require("App/helpers/manufacturerKeyGenerator").default;

const ManufacturerConfigurator = ({
  data,
  currentStep, //used to know if screen comes into focus, therefore lookup manufacturers
  manufacturerList,
  setManufacturerList,
}) => {
  const [loaded] = useState(false); //when mounted, will be false, when focussed, will become true after loaded
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  useEffect(() => {
    if (currentStep === 3 && !loaded) {
      //currentStep just changed and moved onto this screen, and never been loaded before (as dont want to come BACK to this screen and lose all data)
      findManufacturersFromData();
    }
  }, [currentStep]); // eslint-disable-line

  //Gets all manufacturers from data given to it
  //And looks them up by key with database, adds database data to each one
  //Then updates state
  const findManufacturersFromData = async () => {
    setLoading(true);
    let newManufacturerList = [];
    let keyList = [];
    await data.forEach((row, i) => {
      let key = manufacturerKeyGenerator(row.manufacturerName);
      if (!newManufacturerList.find(manufacturer => manufacturer.key === key)) {
        keyList.push(key);
        newManufacturerList.push({
          key,
          manufacturerName: row.manufacturerName,
          foundAutomaticallyInDatabase: false,
          existsInDatabase: false,
          autoCreateManufacturer: row.manufacturerName?.toString().trim()
            ? true
            : false,
        });
      }
    });
    await axiosAPI
      .get(`/manufacturers/lookup/key?keys=${keyList.join(",")}`)
      .then(result => {
        result.data.forEach((lookedUpManufacturer, i) => {
          newManufacturerList = newManufacturerList.map(manufacturer => {
            if (manufacturer.key !== lookedUpManufacturer.key)
              return manufacturer;
            return {
              ...manufacturer,
              foundAutomaticallyInDatabase: true,
              existsInDatabase: true,
              autoCreateManufacturer: false,
              database: { ...lookedUpManufacturer },
            };
          });
        });
        console.log(newManufacturerList);
        setManufacturerList(newManufacturerList);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting manufacturers, please retry",
        );
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  //Alters whether to autoCreate or not
  const manualOption = (manufacturerToUpdate, autoCreate) => {
    let newManufacturerList = manufacturerList.map(manufacturer => {
      if (manufacturer !== manufacturerToUpdate) return manufacturer;
      return {
        ...manufacturer,
        database: autoCreate ? null : manufacturer.database,
        existsInDatabase: false,
        autoCreateManufacturer: autoCreate,
      };
    });
    setManufacturerList(newManufacturerList);
  };

  const manualOptionSetManufacturer = (
    manufacturerToUpdate,
    manualManufacturer, //the option that was chosen in the select box
  ) => {
    let newManufacturerList = manufacturerList.map(manufacturer => {
      if (manufacturer.key !== manufacturerToUpdate.key) return manufacturer;
      return {
        ...manufacturer,
        existsInDatabase: !!manualManufacturer,
        database: manualManufacturer,
      };
    });
    setManufacturerList(newManufacturerList);
  };

  if (loading) return "Loading...";

  return (
    <>
      {" "}
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage("");
          setErrorMore("");
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <h2 className={`text-default`}>
        <span className={commonStyles.sequenceNumber}>1</span>
        Check Manufacturers
      </h2>
      <p className={commonStyles.numberAlign}>
        Please ensure all manufacturers have been mapped correctly.
      </p>
      <div className={commonStyles.numberAlign}>
        <Table className={commonStyles.paperTable}>
          <tbody>
            <tr>
              <th>Imported Manufacturer</th>
              <th>Found Manufacturer</th>
            </tr>
            {manufacturerList.map(manufacturer => (
              <tr key={manufacturer.manufacturerName}>
                <td>
                  {manufacturer.manufacturerName ? (
                    manufacturer.manufacturerName
                  ) : (
                    <i>(empty)</i>
                  )}
                  {manufacturer.foundAutomaticallyInDatabase ? (
                    <div className={`ml-1 text-muted`}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: "10px" }}
                        color={"green"}
                      />
                      Found in database
                    </div>
                  ) : (
                    <div className={`ml-1 text-muted`}>
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        style={{ marginRight: "10px" }}
                        color={"orange"}
                      />
                      Not found in database
                    </div>
                  )}
                </td>
                <td>
                  {manufacturer.foundAutomaticallyInDatabase ? (
                    <div>
                      {manufacturer.database.name}{" "}
                      <small>({manufacturer.database.key})</small>
                    </div>
                  ) : (
                    <>
                      <div className={`custom-control custom-radio`}>
                        <input
                          className="custom-control-input"
                          id={`${manufacturer.manufacturerName}_auto`}
                          name={`${manufacturer.manufacturerName}_autoOrManual`}
                          type="radio"
                          disabled={!manufacturer.manufacturerName}
                          checked={manufacturer.autoCreateManufacturer}
                          onChange={() => manualOption(manufacturer, true)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`${manufacturer.manufacturerName}_auto`}
                        >
                          Automatically create new manufacturer
                        </label>
                      </div>
                      <div className={`custom-control custom-radio`}>
                        <input
                          className="custom-control-input"
                          id={`${manufacturer.manufacturerName}_manual`}
                          name={`${manufacturer.manufacturerName}_autoOrManual`}
                          type="radio"
                          checked={!manufacturer.autoCreateManufacturer}
                          onChange={() => manualOption(manufacturer, false)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`${manufacturer.manufacturerName}_manual`}
                        >
                          Manually specify or create new manufacturer
                        </label>
                      </div>
                      {!manufacturer.autoCreateManufacturer && (
                        <ManufacturerCreatableDropdown
                          preload={true}
                          className={`mt-1 ml-4`}
                          value={manufacturer.database}
                          setValue={value =>
                            manualOptionSetManufacturer(manufacturer, value)
                          }
                        />
                      )}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

ManufacturerConfigurator.propTypes = {
  data: PropTypes.array,
  currentStep: PropTypes.number,
  manufacturerList: PropTypes.array,
  setManufacturerList: PropTypes.func,
};

export default ManufacturerConfigurator;
