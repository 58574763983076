import React from "react";
import contrastColour from "App/helpers/contrastColour";

const JobKind = ({ colour = "#ccc", name = "Unknown" }) => (
  <span
    className="jobPill"
    style={{
      backgroundColor: colour,
      color: contrastColour(colour),
    }}
  >
    {name}
  </span>
);

export default JobKind;
