import React, { useState } from "react";
import { Row, Button, Modal } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CompanyInput from "App/components/Inputs/CompanyInput";
import {
  TextInput,
  SelectInput,
  CheckboxInput,
} from "App/components/Inputs/BasicFormInputs.js";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";

function ContactModal(props) {
  const { initialValues = {} } = props;
  const [loading, isLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  const addContact = async newContact => {
    isLoading(true);
    await axiosAPI
      .post("/contacts", newContact)
      .then(result => {
        props.setReturnValue(result.data);
        props.toggleModal(false);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Please try again");
      })
      .finally(() => isLoading(false));
  };

  const updateContact = async contact => {
    isLoading(true);
    await axiosAPI
      .put(`/contacts/${props.id}`, contact)
      .then(result => {
        props.setReturnValue(contact);
        props.toggleModal(false);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Please try again");
      })
      .finally(() => isLoading(false));
  };

  return (
    <Modal
      className="modal-dialog-centered formModal"
      isOpen={props.isOpen}
      toggle={() => props.toggleModal(false)}
      backdrop="static"
    >
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <div className="modal-header">
        <h2 className="modal-title" id="contactModal">
          {props.id ? "Edit Contact" : "Add Contact"}
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => props.toggleModal(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          Company: initialValues?.Company || {},
          title: initialValues?.title || "Mr",
          firstName: initialValues?.firstName || "",
          lastName: initialValues?.lastName || "",
          jobTitle: initialValues?.jobTitle || "",
          email: initialValues?.email || "",
          mobile: initialValues?.mobile || "",
          work: initialValues?.work || "",
          status: initialValues?.status || 1,
          accounts: initialValues?.accounts || false,
          memo: initialValues?.memo || "",
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .required("Required")
            .max(200, "Cannot be longer than 200 characters"),
          lastName: Yup.string()
            .required("Required")
            .max(200, "Cannot be longer than 200 characters"),
          jobTitle: Yup.string()
            .notRequired()
            .max(200, "Cannot be longer than 200 characters"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          status: Yup.number().integer().positive(),
          memo: Yup.string()
            .notRequired()
            .max(200, "Cannot be longer than 200 characters"),
          Company: Yup.object()
            .nullable(false)
            .typeError("Company is required")
            .shape({
              id: Yup.number("Error with company ID").required(
                "Company is required",
              ),
            }),
        })}
        onSubmit={(values, { setSubmitting }) => {
          let newValues = values;
          newValues["CompanyId"] = values.Company.id;
          console.log(newValues);
          if (!props.id) {
            addContact(newValues);
          } else {
            updateContact(newValues);
          }
        }}
      >
        {formikProps => (
          <>
            <div className="modal-body">
              {loading ? (
                <p>Loading...</p>
              ) : (
                <Form>
                  <Row>
                    <CompanyInput
                      label="Company"
                      col={12}
                      name="Company"
                      type="text"
                      placeholder=""
                      {...formikProps}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      label="Job Title"
                      name="jobTitle"
                      type="text"
                      col={6}
                    />
                    <SelectInput label="Title" name="title" col={6}>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                      <option value="Ms">Ms</option>
                      <option value="Master">Master</option>
                      <option value="Rev">Rev</option>
                      <option value="Dr">Dr</option>
                      <option value="Sir">Sir</option>
                      <option value="Madam">Madam</option>
                      <option value="Lady">Lady</option>
                      <option value="Lord">Lord</option>
                      <option value="Prof">Prof</option>
                      <option value="Dame">Dame</option>
                      <option value="Duke">Duke</option>
                      <option value="HRH">HRH</option>
                    </SelectInput>
                  </Row>
                  <Row>
                    <TextInput
                      label="First Name"
                      name="firstName"
                      type="text"
                      col={6}
                    />
                    <TextInput
                      label="Last Name"
                      name="lastName"
                      type="text"
                      col={6}
                    />
                  </Row>
                  <TextInput label="Email Address" name="email" type="email" />
                  <Row>
                    <TextInput
                      label="Work Phone"
                      name="work"
                      type="text"
                      col={6}
                    />
                    <TextInput
                      label="Mobile Phone"
                      name="mobile"
                      type="text"
                      col={6}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      label="Memo (Internal)"
                      name="memo"
                      type="text"
                      col={6}
                    />
                    <SelectInput label="Status" name="status" col={6}>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                      <option value="3">Caution</option>
                      <option value="4">Disabled</option>
                    </SelectInput>
                  </Row>
                  <CheckboxInput
                    col={6}
                    enablevalidation={false}
                    name="accounts"
                  >
                    Accounts contact
                  </CheckboxInput>
                </Form>
              )}
            </div>
            <div className="modal-footer">
              <Button
                onClick={formikProps.submitForm}
                color="primary"
                type="button"
                disabled={loading}
              >
                {props.id ? "Edit Contact" : "Add Contact"}
              </Button>
            </div>
          </>
        )}
      </Formik>
    </Modal>
  );
}

export default ContactModal;
