var routes = [
  {
    path: "/app/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-default",
  },
  {
    path: "/app/jobs",
    name: "Jobs",
    icon: "ni ni-books text-default",
  },
  {
    path: "/app/access",
    name: "Access",
    icon: "ni ni-single-02 text-default",
  },
  {
    path: "/app/products",
    name: "Products",
    icon: "ni ni-box-2 text-default",
  },
  {
    path: "/app/contacts",
    name: "Contacts",
    icon: "fa fa-address-book text-default",
  },
];
export default routes;
