import React from "react";
import styles from "./PurchaseOrderTableRow.module.scss";
import {
  Label,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import currencyFormatter from "App/helpers/currencyFormatter";
import { Link } from "react-router-dom";
import truncateText from "App/helpers/truncateText";
const classNames = require("classnames");

const PurchaseOrderTableRow = ({
  node,
  path,
  updateNode,
  deleteNode,
  restoreName,
  selectRow,
  moveFocusToNextRow,
  costNominals = [],
  taxRates = [],
  enabled,
}) => {
  return {
    //the qty section of the row
    selectRow,
    dragHandle: (
      <i
        className={classNames({
          fa: true,
          "mx-1": true,
          "fa-box": node.type === 0,
          "opacity-4": node.type === 0,
          "fa-folder": node.type === 1 && !node.expanded,
          "fa-folder-open": node.type === 1 && node.expanded,
        })}
      ></i>
    ),
    qtyTitle: (
      <>
        {node.type === 0 && (
          <>
            <input
              className={`${styles.inputQuantity} ${styles.editableInput}`}
              value={node.quantity}
              name="quantity"
              type="number"
              min="0"
              step="1"
              disabled={!enabled}
              onFocus={e => e.target.select()}
              onKeyDown={e => {
                e.key === "Enter" &&
                  moveFocusToNextRow(node, "quantityInput", path);
              }}
              autoComplete="none"
              onChange={event => {
                updateNode(node, "quantity", event.target.value, path);
              }}
            />
            &times;
          </>
        )}
        <input
          className={`${
            node.type === 1 ? styles.folderName : styles.inputName
          } ${styles.editableInput}`}
          value={node.name}
          name="name"
          disabled={!enabled}
          onFocus={e => e.target.select()}
          onChange={event => {
            updateNode(node, "name", event.target.value, path);
          }}
        />
        {node.type === 0 && (
          <Link
            to={`/app/product/${node?.ProductId}`}
            className={styles.productCodeLink}
            target="_blank"
          >
            {truncateText(
              node?.PriceListItem?.Product?.manufacturerProductCode,
              15,
            )}
          </Link>
        )}
      </>
    ),
    //the main row
    row: (
      <>
        {/*<div className={`${styles.tableProduct} ${styles.tableCell}`}>
          {node.type === 0 && (
            <>
              {node?.PriceListItem?.PriceList?.isNew ? (
                <Badge color="success" className={styles.statusBadge}>
                  New
                </Badge>
              ) : (
                <Badge color="warning" className={styles.statusBadge}>
                  Used
                </Badge>
              )}
              <strong>{node?.PriceListItem?.PriceList?.name}</strong>
              <br />
              Vendor Product Code: {node?.PriceListItem?.vendorProductCode}
            </>
          )}
        </div>*/}
        <div className={`${styles.tableCell}`}>
          {node.type === 0 && (
            <select
              className={styles.selectDropdown}
              value={node.TaxRateId}
              disabled={!enabled}
              onChange={event => {
                updateNode(node, "TaxRateId", +event.target.value, path);
              }}
            >
              {taxRates.map(taxRate => (
                <option value={taxRate.id}>
                  {taxRate.name} ({taxRate.rate}%)
                </option>
              ))}
            </select>
          )}
        </div>
        <div className={`${styles.tableCell}`}>
          {node.type === 0 && (
            <select
              className={styles.selectDropdown}
              value={node.NominalId}
              disabled={!enabled}
              onChange={event => {
                updateNode(node, "NominalId", +event.target.value, path);
              }}
            >
              {costNominals.map(nominal => (
                <option value={nominal.id}>
                  {nominal.number}: {nominal.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className={`${styles.tableCell}`}>
          <Label className={styles.inputNumberLabel}>£</Label>
          <input
            className={styles.inputNumber}
            style={{ fontWeight: node.type === 1 ? 700 : "" }}
            value={node.costPrice}
            type="number"
            step="0.01"
            name="costPrice"
            min="0"
            disabled={node.type !== 0 || !enabled}
            onChange={event => {
              updateNode(node, "costPrice", +event.target.value, path);
            }}
          />
        </div>
        <div className={`${styles.tableCell} ${styles.totalCell}`}>
          <Label
            className={classNames({
              [styles.inputNumberLabel]: true,
            })}
          >
            £
          </Label>
          <input
            className={`${styles.inputNumber}`}
            style={{ fontWeight: node.type === 1 ? 700 : "" }}
            value={currencyFormatter(node.total, false)}
            type="text"
            name="total"
            min="0"
            disabled
          />
        </div>
      </>
    ),
    //right hand side ... button
    menuOptions: (
      <UncontrolledButtonDropdown size="sm" color="link">
        <DropdownToggle color="link" caret>
          Options
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem
            disabled={!enabled}
            onClick={() => {
              deleteNode(path);
            }}
          >
            Delete
          </DropdownItem>
          {node.type === 0 && (
            <DropdownItem
              disabled={!enabled}
              onClick={() => {
                restoreName(node, path);
              }}
            >
              Restore name
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    ),
  };
};

export default PurchaseOrderTableRow;
