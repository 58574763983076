import React from "react";
import { Button, Col, Row } from "reactstrap";
import PageContainer from "App/components/PageContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import {
  faSuitcase,
  faUser,
  faKey,
  faListUl,
  faIndustry,
  faTag,
  faBoxes,
  faAddressBook,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";

const Admin = () => {
  const history = useHistory();

  return (
    <PageContainer title="Dashboard">
      <div style={{ padding: 50 }}>
        <Row>
          <Col lg={6} className="mb-2">
            <Button
              color="primary"
              size="lg"
              block
              onClick={() => history.push("/app/jobs")}
            >
              <FontAwesomeIcon className="mr-1" icon={faSuitcase} />
              Jobs
            </Button>
            <Button
              color="primary"
              size="lg"
              block
              onClick={() => history.push("/app/products")}
            >
              <FontAwesomeIcon className="mr-1" icon={faBoxes} />
              Products
            </Button>
            <Button
              color="warning"
              size="lg"
              block
              onClick={() => history.push("/app/products/manufacturers")}
            >
              <FontAwesomeIcon className="mr-1" icon={faIndustry} />
              Manufacturers
            </Button>
            <Button
              color="warning"
              size="lg"
              block
              onClick={() => history.push("/app/products/pricelists")}
            >
              <FontAwesomeIcon className="mr-1" icon={faTag} />
              Price Lists
            </Button>
          </Col>
          <Col lg={6} className="mb-2">
            <Button
              color="secondary"
              size="lg"
              block
              onClick={() => history.push("/app/access")}
            >
              <FontAwesomeIcon className="mr-1" icon={faUser} />
              Users
            </Button>
            <Button
              color="secondary"
              size="lg"
              block
              onClick={() => history.push("/app/access/roles")}
            >
              <FontAwesomeIcon className="mr-1" icon={faKey} />
              Roles
            </Button>
            <Button
              color="secondary"
              size="lg"
              block
              onClick={() => history.push("/app/access/logs")}
            >
              <FontAwesomeIcon className="mr-1" icon={faListUl} />
              Logs
            </Button>
            <Button
              color="success"
              size="lg"
              block
              onClick={() => history.push("/app/contacts")}
            >
              <FontAwesomeIcon className="mr-1" icon={faAddressBook} />
              Contacts
            </Button>
            <Button
              color="success"
              size="lg"
              block
              onClick={() => history.push("/app/contacts/companies")}
            >
              <FontAwesomeIcon className="mr-1" icon={faBuilding} />
              Companies
            </Button>
          </Col>
        </Row>
      </div>
    </PageContainer>
  );
};

export default Admin;
