import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PurchaseOrderModal from "./PurchaseOrderModal";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";

const PurchaseOrderModalContainer = ({
  id,
  jobId,
  visible = false,
  setVisible,
  edit = false,
  done,
}) => {
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  useEffect(() => {
    if (visible && id) {
      getPurchaseOrder(id);
    }
  }, [id, visible]); // eslint-disable-line

  const getPurchaseOrder = id => {
    setLoading(true);
    axiosAPI
      .get(`/purchaseOrders/${id}`)
      .then(result => {
        setPurchaseOrder(result.data || null);
      })
      .catch(error => {
        setVisible(false);
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addPurchaseOrder = async purchaseOrder => {
    setLoading(true);
    await axiosAPI
      .post("/purchaseOrders", purchaseOrder)
      .then(result => {
        setVisible(false);
        done(result.data.id);
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage ||
            "An error occurred while adding new purchase order",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  const editPurchaseOrder = async purchaseOrder => {
    setLoading(true);
    await axiosAPI
      .patch(`/purchaseOrders/${purchaseOrder.id}`, purchaseOrder)
      .then(result => {
        setVisible(false);
        done();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage ||
            "An error occurred while editing purchase order",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <PurchaseOrderModal
        visible={visible}
        purchaseOrder={edit ? purchaseOrder : {}}
        edit={edit}
        jobId={jobId}
        setVisible={setVisible}
        editPurchaseOrder={editPurchaseOrder}
        addPurchaseOrder={addPurchaseOrder}
        loading={loading}
      />
    </>
  );
};

PurchaseOrderModalContainer.propTypes = {
  id: PropTypes.number,
  jobId: PropTypes.number,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  edit: PropTypes.bool,
  done: PropTypes.func,
};

export default PurchaseOrderModalContainer;
