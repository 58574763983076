import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as usersActions from "App/redux/users/actions";
import { Modal, Row, Alert, Table, Badge } from "reactstrap";
import Avatar from "react-avatar";
import styles from "./ProfileModal.module.scss";
const moment = require("moment");

const ProfileModalContainer = ({
  id,
  users,
  usersGet,
  visible = false,
  setVisible,
}) => {
  const [user, setUser] = useState(null);

  const getUserInfo = id => {
    if (
      !users.error &&
      (users.results.length === 0 ||
        !users.loadedOn ||
        Date.now() - users.loadedOn > 1000 * 60 * 60) //1hr - How long till users expire
    ) {
      usersGet();
    }
    let foundUser = users.results.find(u => u.id === id);
    if (foundUser) setUser(foundUser);
  };

  const closeButton = (
    <button
      aria-label="Close"
      className={`close ${styles.close}`}
      data-dismiss="modal"
      type="button"
      onClick={() => setVisible(false)}
    >
      <span aria-hidden={true}>×</span>
    </button>
  );

  useEffect(() => {
    getUserInfo(id);
  }, [id, users.results, users.loadedOn]); // eslint-disable-line

  if (!id) return null;

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={visible}
      toggle={() => setVisible(!visible)}
      data-testid="profilemodal"
    >
      {!users.loading &&
      (users.error || (users.loadedOn && !users.error && !user)) ? (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Error</h2>
            {closeButton}
          </div>
          <div className="modal-body">
            <Alert color="danger">
              <strong>Error</strong> Could not load user - sorry about that!
            </Alert>
          </div>
        </>
      ) : users.loading || !user ? (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Loading...</h2>
            {closeButton}
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center my-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">loading...</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="modal-header">
            <Row className="mx-0 w-100">
              <div className={`${styles.avatar} col-md-12 mt-md--6 col-4`}>
                <Avatar
                  src={
                    user?.AvatarFile?.id
                      ? process.env.REACT_APP_API_BASE_URL +
                        `/files/${user?.AvatarFile?.id}`
                      : null
                  }
                  name={user.fullName}
                  size="100"
                  className={`bg-default`}
                  round
                />
              </div>
              <div
                className={`${styles.text} col-md-12 col-8 text-md-center mt-md-3`}
              >
                <h2 className={`display-4 ${styles.nameHeader}`}>
                  {user.fullName}
                </h2>
                <h4 style={{ fontWeight: 400 }}>{user.jobTitle}</h4>
              </div>
            </Row>
            {closeButton}
          </div>
          <div className="modal-body">
            <Table responsive>
              <tbody>
                {user.email && (
                  <tr>
                    <td>Email</td>
                    <td>
                      <a href={`mailto:${user.email}`}>{user.email}</a>
                      {user.emailVerified ? (
                        <Badge color="success" className="ml-2">
                          Verified
                        </Badge>
                      ) : (
                        <Badge color="warning" className="ml-2">
                          Unverified
                        </Badge>
                      )}
                    </td>
                  </tr>
                )}
                {user.phone && (
                  <tr>
                    <td>Phone</td>
                    <td>
                      <a href={`tel:${user.phone}`}>{user.phone}</a>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>User since</td>
                  <td>{moment(user.createdAt).format("DD MMM YYYY")}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </>
      )}
    </Modal>
  );
};

ProfileModalContainer.propTypes = {
  id: PropTypes.number.isRequired,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
};

const mapStateToProps = state => {
  return { users: state.users };
};

const mapDispatchToProps = dispatch => {
  return {
    usersGet: () => {
      dispatch(usersActions.usersGet());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileModalContainer);
