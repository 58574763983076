import StackdriverErrorReporter from "stackdriver-errors-js";

const googleErrorReporting = new StackdriverErrorReporter();

googleErrorReporting.start({
  key: process.env.REACT_APP_GCP_API_KEY,
  projectId: process.env.REACT_APP_GCP_PROJECT_ID,

  service: "client_web", // Name of the service reporting the error, defaults to 'web'.

  version: process.env.GAE_VERSION || "development", // Version identifier of the service reporting the error.

  reportUncaughtExceptions: true, // Set to false to prevent reporting unhandled exceptions.

  reportUnhandledPromiseRejections: true, // Set to false to prevent reporting unhandled promise rejections.

  disabled: process.env.NODE_ENV === "development", // Set to true to not send error reports, this can be used when developing locally.

  context: { user: "Anonymous" }, //gets updated via redux/sagas
});

export default googleErrorReporting;
