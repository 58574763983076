import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import PriceListItemsListContainer from "App/components/Tables/PriceListItemsList/PriceListItemsList.container";

const PriceListItemsListModalContainer = ({
  visible = false,
  setVisible,
  priceListId,
}) => {
  const [loading, setLoading] = useState(true);
  const [priceList, setPriceList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  const getPriceList = () => {
    setLoading(true);
    axiosAPI
      .get(`/priceLists/${priceListId}`)
      .then(result => setPriceList(result.data || []))
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting price list, please retry",
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (priceListId) {
      getPriceList();
    }
  }, [priceListId]); // eslint-disable-line

  return (
    <Modal
      className="modal-dialog-centered formModal modal-big-ass"
      isOpen={visible}
      data-testid="modal"
    >
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">loading...</span>
          </div>
        </div>
      ) : (
        <>
          <ModalHeader
            toggle={() => {
              setVisible(!visible);
            }}
          >
            <h2 className={`mb-0 text-default`}>{priceList?.Company?.name}</h2>
            <h4 className={`mb-0 text-default`}>
              Price List{" "}
              <Badge
                color={
                  priceList?.status === "Archive"
                    ? "light"
                    : priceList?.status === "Inactive"
                    ? "danger"
                    : priceList?.status === "Scheduled"
                    ? "warning"
                    : "success"
                }
              >
                {priceList.status}
              </Badge>
            </h4>
          </ModalHeader>
          <ModalBody>
            <PriceListItemsListContainer priceListId={priceListId} />
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                setVisible(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

PriceListItemsListModalContainer.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  priceListId: PropTypes.number,
};

export default PriceListItemsListModalContainer;
