import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import { Link } from "react-router-dom";
import truncateText from "App/helpers/truncateText";
import styles from "./ShipmentStockAssignModal.module.scss";
const { get } = require("lodash");

const ShipmentStockAssignModalContainer = ({
  visible = false,
  setVisible,
  shipmentId = null,
  purchaseOrderId = null,
  depotTransferId = null,
  depotId = null,
  mode = "RECEIVE_NEW", //receiving new stock (so will create new stock), send existing stock (to another depot, or return items from a po), receive sent stock (ie from another depot's depot transfer)
  done,
}) => {
  console.log(shipmentId);
  const apiEndpoint = purchaseOrderId
    ? `/purchaseOrders/${purchaseOrderId}`
    : `/depotTransfers/${depotTransferId}`;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  const [itemStockMovements, setItemStockMovements] = useState({}); //an object that holds all the items, and their assigned (new or existing) stock

  useEffect(() => {
    if (visible) {
      getItems();
    }
  }, [visible]); // eslint-disable-line

  const getItems = id => {
    setLoading(true);
    axiosAPI
      .get(`${apiEndpoint}/items/stockMovements`)
      .then(result => {
        setItems(result.data || []);
      })
      .catch(error => {
        setVisible(false);
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const processStock = () => {
    setLoading(true);
    axiosAPI
      .post(`/shipments/items`, itemStockMovements)
      .then(result => {
        console.log(result.data);
      })
      .catch(error => {
        setVisible(false);
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeQuantity = (item, quantity) => {
    if (quantity < 0) return;

    let stockAllocationArray =
      itemStockMovements[item.id]?.stockAllocation || [];
    if (stockAllocationArray.length < quantity) {
      //need to add to it
      for (let i = stockAllocationArray.length; i < quantity; i++) {
        stockAllocationArray.push({
          //these are needed here for the new stock
          purchasePrice: item.costPrice,
          barcode: null,
          barcodeAvailable: null,
          serialNumber: null,
          stockIn: Date.now(),
          DepotId: depotId,
          PurchaseOrderItemId: purchaseOrderId ? item.id : null,
          DepotTransferItemId: depotTransferId ? item.id : null, //<--- hmm
          ProductId: item.Product.id,
        });
      }
    } else {
      stockAllocationArray = stockAllocationArray.slice(0, quantity);
    }

    setItemStockMovements({
      ...itemStockMovements,
      [item.id]: {
        ...itemStockMovements[item.id],
        //these are needed here for the stock movement
        type: mode,
        ProductId: item.Product.id,
        DepotId: depotId,
        ShipmentId: shipmentId,
        PurchaseOrderItemId: purchaseOrderId ? item.id : null,
        DepotTransferItemId: depotTransferId ? item.id : null, //<--- hmm
        quantity,
        stockAllocation: stockAllocationArray,
      },
    });
  };

  const changeStockAllocationValue = (key, item, index, value) => {
    setItemStockMovements({
      ...itemStockMovements,
      [item.id]: {
        ...itemStockMovements[item.id],
        stockAllocation: itemStockMovements[item.id].stockAllocation.map(
          (stockAllocation, sAIndex) => {
            if (sAIndex !== index) return stockAllocation;
            return {
              ...stockAllocation,
              [key]: value,
            };
          },
        ),
      },
    });
  };

  const checkBarcodeAvailable = async barcode => {
    return await axiosAPI
      .get(`/stock/barcodeAvailable/${barcode}`)
      .then(result => result.data ?? true)
      .catch(error => {
        setVisible(false);
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <Modal
        className="modal-dialog-centered formModal modal-big-ass"
        isOpen={visible}
        data-testid="modal"
        backdrop="static"
      >
        {loading ? (
          <div className="d-flex justify-content-center my-5">
            <div className="spinner-border">
              <span className="sr-only">loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="modal-header">
              <h2 className="modal-title">
                {mode === "RECEIVE_NEW"
                  ? "Receive New Stock"
                  : "Something else"}
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setVisible(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              {items.map(item => {
                const received = item.StockMovements.reduce((sum, movement) => {
                  return sum + movement.quantity;
                }, 0);
                const remaining =
                  item.quantity -
                  received -
                  (itemStockMovements[item.id]?.stockAllocation.length || 0);
                return (
                  <div className={styles.lineItem}>
                    <div className={styles.newQuantity}>
                      <Button
                        color="primary"
                        className={styles.quantityAdjustButton}
                        disabled={
                          itemStockMovements[item.id]?.quantity === 0 ||
                          !itemStockMovements[item.id]?.quantity
                        }
                        onClick={() =>
                          changeQuantity(
                            item,
                            (itemStockMovements[item.id]?.quantity || 0) - 1,
                          )
                        }
                      >
                        -
                      </Button>
                      <input
                        type="number"
                        min="0"
                        step="1"
                        value={
                          +get(itemStockMovements, `${item.id}.quantity`, 0)
                        }
                        onChange={e =>
                          changeQuantity(item, parseInt(e.target.value) || 0)
                        }
                        className={styles.quantityInput}
                      />
                      <Button
                        color="primary"
                        className={styles.quantityAdjustButton}
                        onClick={() =>
                          changeQuantity(
                            item,
                            (itemStockMovements[item.id]?.quantity || 0) + 1,
                          )
                        }
                      >
                        +
                      </Button>
                    </div>
                    <div className={styles.details}>
                      <span
                        className={
                          remaining < 0
                            ? "text-danger"
                            : remaining === 0
                            ? "text-success"
                            : ""
                        }
                      >
                        {remaining} of {item.quantity} remaining
                      </span>
                      <br />
                      <Link
                        to={`/app/product/${item.Product.id}`}
                        target="_blank"
                      >
                        {item.Product.Manufacturer.name} {item.Product.name} (
                        {truncateText(
                          item?.Product?.manufacturerProductCode,
                          15,
                        )}
                        )
                      </Link>
                    </div>
                    {itemStockMovements[item.id]?.stockAllocation?.length >
                      0 && (
                      <ul className={styles.stockAllocationContainer}>
                        {itemStockMovements[item.id]?.stockAllocation.map(
                          (stock, index) => {
                            return (
                              <li
                                className={styles.stockAllocationLine}
                                key={index}
                              >
                                <Form inline>
                                  <div className={styles.formBlock}>
                                    <Label>Barcode</Label>
                                    <Input
                                      type="text"
                                      value={stock.barcode}
                                      onBlur={() => {
                                        checkBarcodeAvailable(
                                          stock.barcode,
                                        ).then(available =>
                                          changeStockAllocationValue(
                                            "barcodeAvailable",
                                            item,
                                            index,
                                            available,
                                          ),
                                        );
                                      }}
                                      className={
                                        stock.barcode === null || !stock.barcode
                                          ? ""
                                          : stock.barcodeAvailable
                                          ? "success-border"
                                          : stock.barcodeAvailable === false
                                          ? "error-border"
                                          : ""
                                      }
                                      disabled={stock.barcode === null}
                                      onChange={e =>
                                        changeStockAllocationValue(
                                          "barcode",
                                          item,
                                          index,
                                          e.target.value,
                                        )
                                      }
                                    />
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          checked={stock.barcode === null}
                                          onChange={() =>
                                            changeStockAllocationValue(
                                              "barcode",
                                              item,
                                              index,
                                              stock.barcode === null
                                                ? ""
                                                : null,
                                            )
                                          }
                                        />{" "}
                                        Generate
                                      </Label>
                                    </FormGroup>
                                  </div>
                                  <div className={styles.formBlock}>
                                    <Label>Serial Number</Label>
                                    <Input
                                      type="text"
                                      placeholder="Serial number"
                                      value={stock.serialNumber}
                                      onChange={e =>
                                        changeStockAllocationValue(
                                          "serialNumber",
                                          item,
                                          index,
                                          e.target.value,
                                        )
                                      }
                                    />
                                  </div>
                                </Form>
                              </li>
                            );
                          },
                        )}
                      </ul>
                    )}
                  </div>
                );
              })}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={processStock}>
                Done
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};

ShipmentStockAssignModalContainer.propTypes = {
  purchaseOrderId: PropTypes.number,
  depotTransferId: PropTypes.number,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  done: PropTypes.func,
};

export default ShipmentStockAssignModalContainer;
