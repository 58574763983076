import React, { useState } from "react";
import { useField } from "formik";
import DatePicker from "react-datepicker";
import { Row, FormGroup, Col, Input, FormFeedback } from "reactstrap";
import ReactPasswordStrength from "react-password-strength";
var classNames = require("classnames");
const millify = require("millify").default;

export const PasswordStrength = ({
  label,
  showRecommendations = true,
  ...props
}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta, helpers] = useField(props);
  const [passwordInfo, setPasswordInfo] = useState({});

  return (
    <FormGroup
      className={classNames({
        [`col-lg-${props.col}`]: !!props.col,
      })}
    >
      <label htmlFor={props.id || props.name}>{label}</label>
      <ReactPasswordStrength
        className=""
        style={{
          borderRadius: "0.375rem",
          borderColor:
            meta.touched && meta.error
              ? "#dc3545"
              : meta.touched && !meta.error
              ? "#28a745"
              : "#c6c6c6",
        }}
        minLength={5}
        minScore={2}
        scoreWords={["terrible", "feeble", "alright", "great", "super duper"]}
        changeCallback={(state, result) => {
          helpers.setValue(state.password);
          if (props.passwordInfoChange) {
            //returns more detailed info
            props.passwordInfoChange({ ...state, ...result });
            setPasswordInfo({ ...state, ...result });
          }
        }}
        inputProps={{
          col: props.col,
          name: field.name,
          "data-testid": field.name,
          invalid: meta.touched && meta.error ? true : false,
          "aria-invalid": meta.error ? true : false,
          valid: field.value && meta.touched && !meta.error ? true : false,
          className: classNames({
            "form-control": true,
          }),
          autoComplete: "new-password",
          onBlur: () => helpers.setTouched(true),
        }}
      />
      {meta.touched && meta.error && (
        <FormFeedback className="d-block">{meta.error}</FormFeedback>
      )}
      {showRecommendations && (
        <Row>
          <Col>
            {(passwordInfo?.feedback?.suggestions?.length > 0 ||
              passwordInfo?.guesses) && (
              <ul className="mb-1 small mt-2 pl-4 text-secondary">
                {passwordInfo.feedback.suggestions.map(
                  (recommendation, index) => (
                    <li key={index}>{recommendation}</li>
                  ),
                )}
                {passwordInfo?.guesses && (
                  <li>
                    It would take a computer running 10 attempts per second{" "}
                    {
                      passwordInfo.crack_times_display
                        .online_no_throttling_10_per_second
                    }{" "}
                    to go through all{" "}
                    {millify(
                      passwordInfo.guesses < Number.MAX_SAFE_INTEGER
                        ? passwordInfo.guesses
                        : Number.MAX_SAFE_INTEGER,
                      {
                        space: true,
                        precision: 1,
                        units: [
                          "",
                          "thousand ",
                          "million ",
                          "billion ",
                          "trillion ",
                          "quadrillion ",
                          "quintillion ",
                        ],
                      },
                    )}{" "}
                    guesses to guess your password!
                  </li>
                )}
              </ul>
            )}
          </Col>
        </Row>
      )}
    </FormGroup>
  );
};

export const DateInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <FormGroup
      className={classNames({
        [`col-lg-${props.col}`]: !!props.col,
      })}
    >
      <label htmlFor={props.id || props.name}>{label}</label>
      <Input
        autoComplete="off"
        invalid={meta.touched && meta.error ? true : false}
        aria-invalid={meta.error ? true : false}
        valid={field.value && meta.touched && !meta.error ? true : false}
        type="date"
        data-testid={field.name}
        {...field}
        {...props}
      />
      {meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
    </FormGroup>
  );
};

export const DateTimeInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta, helpers] = useField(props);

  return (
    <FormGroup
      className={classNames({
        [`col-lg-${props.col}`]: !!props.col,
      })}
    >
      <label htmlFor={props.id || props.name}>{label}</label>
      <DatePicker
        selected={field.value}
        onChange={date => helpers.setValue(date)}
        showTimeSelect
        isClearable
        withPortal
        placeholderText="Select a date and time..."
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="time"
        dateFormat="MMMM d, yyyy h:mm aa"
        onBlur={() => helpers.setTouched(true)}
        className={classNames({
          "form-control": true,
          "error-border": meta.touched && meta.error,
        })}
        todayButton="Today"
      />
      {meta.touched && meta.error && (
        <span className="small text-danger">{meta.error}</span>
      )}
    </FormGroup>
  );
};

export const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <FormGroup
      className={classNames({
        [`col-lg-${props.col}`]: !!props.col,
      })}
    >
      <label htmlFor={props.id || props.name}>{label}</label>
      <Input
        autoComplete="off"
        invalid={meta.touched && meta.error ? true : false}
        aria-invalid={meta.error ? true : false}
        valid={field.value && meta.touched && !meta.error ? true : false}
        type="text"
        data-testid={field.name}
        {...field}
        {...props}
      />
      {meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
    </FormGroup>
  );
};

export const HiddenInput = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <input
      type="hidden"
      data-testid={field.name}
      name={field.name}
      value={field.value}
    />
  );
};

export const SelectInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <FormGroup
      className={classNames({
        [`col-lg-${props.col}`]: !!props.col,
      })}
    >
      <label htmlFor={props.id || props.name}>{label}</label>
      <select
        aria-invalid={meta.error ? true : false}
        className={classNames({
          "form-control": true,
          "custom-select": true,
          "is-invalid": meta.touched && meta.error,
          "is-valid": meta.touched && !meta.error,
        })}
        required
        data-testid={field.name}
        {...field}
        {...props}
      />
      {meta.touched && (
        <div
          className={classNames({
            "invalid-feedback": meta.error,
            "valid-feedback": !meta.error,
          })}
        >
          {meta.error}
        </div>
      )}
    </FormGroup>
  );
};

export const CheckboxInput = ({ children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <FormGroup
      className={classNames({
        [`col-lg-${props.col}`]: !!props.col,
      })}
    >
      <div className="custom-control custom-checkbox">
        <input
          className={classNames({
            "form-control": true,
            "custom-control-input": true,
            "is-invalid": props.enablevalidation && meta.touched && meta.error,
            "is-valid": props.enablevalidation && meta.touched && !meta.error,
          })}
          aria-invalid={meta.error ? true : false}
          disabled={props.disabled || false}
          id={props.id || props.name}
          data-testid={field.name}
          type="checkbox"
          {...field}
          {...props}
        />
        <label
          className="custom-control-label"
          htmlFor={props.id || props.name}
        >
          {children}
        </label>
      </div>
      {props.enablevalidation && meta.touched && (
        <div
          className={classNames({
            "invalid-feedback": props.enablevalidation && meta.error,
            "valid-feedback": props.enablevalidation && !meta.error,
          })}
        >
          {meta.error}
        </div>
      )}
    </FormGroup>
  );
};
