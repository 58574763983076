import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as usersActions from "App/redux/users/actions";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Button,
  Alert,
  Table,
  FormGroup,
  Input,
} from "reactstrap";
import Avatar from "react-avatar";
import DatePicker from "react-datepicker";
import styles from "./AssignUsersModal.module.scss";
const moment = require("moment");
const { uniqueId } = require("lodash");

const AssignUsersModalContainer = ({
  assignedUsers = [], //pass in {expiresOn:2020-02...} to timelimit, expects: [ {id:5,expiresOn:null},{...} ]
  showExpiresOn = false,
  assignToTitle,
  assignToDescription,
  visible = false,
  setVisible,
  done, //callback
  users, //from redux
  usersGet, //from redux
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState("");

  const getUsers = () => {
    if (
      !users.error &&
      (users.results.length === 0 ||
        !users.loadedOn ||
        Date.now() - users.loadedOn > 1000 * 60 * 60) //1hr - How long till users expire
    ) {
      usersGet();
    }
  };

  const assignUser = (id, selected) => {
    let tempSelectedUsers = [...selectedUsers];
    if (!selected) {
      //remove from selected array
      tempSelectedUsers = tempSelectedUsers.filter(user => user.id !== id);
    } else {
      //add to selected array
      if (!tempSelectedUsers.find(user => user.id === id)) {
        //not yet in array so add in
        tempSelectedUsers.push({ id, expiresOn: null });
      }
    }
    setSelectedUsers(tempSelectedUsers);
  };

  const setUserExpires = (id, expiresDate) => {
    let tempSelectedUsers = [...selectedUsers].map(user => ({
      ...user,
      expiresOn:
        user.id === id
          ? expiresDate
            ? moment(expiresDate).toISOString()
            : null
          : user.expiresOn,
    }));

    setSelectedUsers(tempSelectedUsers);
  };

  const closeButton = (
    <button
      aria-label="Close"
      className={`close ${styles.close}`}
      data-dismiss="modal"
      type="button"
      onClick={() => setVisible(false)}
    >
      <span aria-hidden={true}>×</span>
    </button>
  );

  useEffect(() => {
    getUsers();
  }, [users.results, users.loadedOn]); // eslint-disable-line

  useEffect(() => {
    setSelectedUsers(assignedUsers);
  }, [assignedUsers]); //eslint-disable-line

  return (
    <Modal
      className="modal-dialog-centered modal"
      isOpen={visible}
      data-testid="assignusersmodal"
    >
      {!users.loading && users.error ? (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Error</h2>
            {closeButton}
          </div>
          <div className="modal-body">
            <Alert color="danger">
              <strong>Error</strong> Could not load users - sorry about that!
            </Alert>
          </div>
        </>
      ) : users.loading ? (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Loading...</h2>
            {closeButton}
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center my-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">loading...</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <ModalHeader>
            <Row className="mx-0">
              <h3 className="w-100">Assign Users to {assignToTitle}</h3>
              {assignToDescription && (
                <p className="d-block mb-0">{assignToDescription}</p>
              )}
            </Row>
            {closeButton}
          </ModalHeader>
          <ModalBody className={`pt-0 ${styles.modalbody}`}>
            <FormGroup className={`mb-0 ${styles.form}`}>
              <Input
                className={styles.userSearch}
                placeholder="Search users..."
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <Button
                outline
                size="sm"
                className={styles.selectAllNoneButton}
                color="secondary"
                onClick={() => {
                  if (selectedUsers.length) {
                    //users are already selected, deselect all
                    setSelectedUsers([]);
                  } else {
                    //no users selected, select all
                    setSelectedUsers([
                      ...[...users.results].map(user => ({
                        id: user.id,
                        expiresOn: null,
                      })),
                    ]);
                  }
                }}
              >
                Select all/none
              </Button>
            </FormGroup>
            <Table size="sm" className={styles.table} responsive>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Assigned</th>
                  {showExpiresOn && <th>Expiration</th>}
                </tr>
                {users.results.map(user => {
                  let selected = selectedUsers.find(
                    selectedUser => selectedUser.id === user.id,
                  );
                  let checkboxId = uniqueId("UserAssigned_");
                  if (
                    search.trim().length === 0 ||
                    (search.trim().length > 0 &&
                      user.fullName
                        .toLowerCase()
                        .indexOf(search.trim().toLowerCase()) > -1)
                  ) {
                    return (
                      <tr>
                        <td>
                          <Avatar
                            src={
                              user?.AvatarFile?.id
                                ? process.env.REACT_APP_API_BASE_URL +
                                  `/files/${user?.AvatarFile?.id}`
                                : null
                            }
                            name={user.fullName}
                            size="20"
                            className={`mr-2 float-left bg-default`}
                            round
                          />
                          {user.fullName}
                        </td>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id={checkboxId}
                              checked={!!selected}
                              type="checkbox"
                              onChange={e => {
                                assignUser(user.id, e.target.checked);
                              }}
                            />
                            <label
                              className="custom-control-label d-inline"
                              htmlFor={checkboxId}
                            ></label>
                          </div>
                        </td>
                        {showExpiresOn && (
                          <td>
                            {selected && (
                              <>
                                {selected.expiresOn ? (
                                  moment().isAfter(
                                    moment(selected.expiresOn),
                                  ) ? (
                                    <span className={`small text-danger`}>
                                      Expired{" "}
                                      {moment
                                        .duration(
                                          moment().diff(
                                            moment(selected.expiresOn),
                                          ),
                                        )
                                        .humanize()}{" "}
                                      ago
                                    </span>
                                  ) : (
                                    <span className={`small text-success`}>
                                      Expires in{" "}
                                      {moment
                                        .duration(
                                          moment().diff(
                                            moment(selected.expiresOn),
                                          ),
                                        )
                                        .humanize()}
                                    </span>
                                  )
                                ) : (
                                  <span className={`small text-success`}>
                                    Always active
                                  </span>
                                )}
                                <DatePicker
                                  selected={
                                    selected?.expiresOn
                                      ? moment(selected.expiresOn).toDate()
                                      : null
                                  }
                                  onChange={date =>
                                    setUserExpires(user.id, date)
                                  }
                                  showTimeSelect
                                  isClearable
                                  withPortal
                                  className={styles.expiresPicker}
                                  placeholderText="Never expires"
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  timeCaption="time"
                                  dateFormat="MMMM d, yyyy h:mm aa"
                                />
                              </>
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button
              color="success"
              onClick={() => {
                done(selectedUsers);
                setVisible(false);
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

AssignUsersModalContainer.propTypes = {
  users: PropTypes.array,
  showExpiresOn: PropTypes.bool,
  assignToTitle: PropTypes.string,
  assignToDescription: PropTypes.string,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  done: PropTypes.func, //callback
};

const mapStateToProps = state => {
  return { users: state.users };
};

const mapDispatchToProps = dispatch => {
  return {
    usersGet: () => {
      dispatch(usersActions.usersGet());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignUsersModalContainer);
