import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import RoleModal from "App/components/Modals/RoleModal/RoleModal";
import axiosAPI from "App/services/axios";

const RoleModalContainer = ({
  edit = false,
  visible = false,
  setVisible,
  id = null,
  done,
}) => {
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  useEffect(() => {
    if (visible && id) {
      getRole(id);
    }
  }, [id, visible]); // eslint-disable-line

  const getRole = id => {
    setLoading(true);
    axiosAPI
      .get(`/roles/${id}`)
      .then(result => {
        setRole(result.data || null);
      })
      .catch(error => {
        setVisible(false);
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore || "Something went wrong getting role, please retry",
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addRole = async role => {
    setLoading(true);
    await axiosAPI
      .post("/roles", role)
      .then(result => {
        setVisible(false);
        done();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage || "An error occurred while adding new role",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  const editRole = async role => {
    setLoading(true);
    await axiosAPI
      .patch(`/roles/${role.id}`, role)
      .then(result => {
        setVisible(false);
        done();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage || "An error occurred while editing role",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  return (
    <>
      {!!errorMessage && (
        <SweetAlert
          danger
          onConfirm={() => {
            setErrorMessage(null);
            setErrorMore(null);
          }}
          title="Error"
        >
          {errorMessage}
          {errorMore && (
            <>
              <br />
              <small>{errorMore}</small>
            </>
          )}
        </SweetAlert>
      )}
      <RoleModal
        visible={visible}
        role={role}
        edit={edit}
        setVisible={setVisible}
        editRole={editRole}
        addRole={addRole}
        loading={loading}
      />
    </>
  );
};

RoleModalContainer.propTypes = {
  edit: PropTypes.bool,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  role: PropTypes.object,
  done: PropTypes.func,
};

export default RoleModalContainer;
