import React, { useState, useRef } from "react";
//import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import TableIcons from "App/components/Tables/TableIcons";
import RemoteTable from "App/components/Tables/RemoteTable/RemoteTable";
import hasPermission from "App/helpers/hasPermission";
import CompanyModal from "App/components/Modals/CompanyModal";
import currencyFormatter from "App/helpers/currencyFormatter";
import DropdownWithPermissions from "App/components/Dropdowns/DropdownWithPermissions";

const CompaniesListContainer = () => {
  const remoteTable = useRef();
  const [companyModal, setCompanyModal] = useState({
    id: null,
    visible: false,
    edit: false,
    initialValues: null,
  });

  const actions = {
    newCompany: () =>
      setCompanyModal({
        id: null,
        visible: true,
        edit: false,
        initialValues: null,
      }),
    editCompany: row =>
      setCompanyModal({
        ...companyModal,
        visible: true,
        edit: true,
        id: row.id,
        initialValues: row,
      }),
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      sortModel: null,
      sortField: "name",
      sorting: true,
    },
    {
      title: "Phone",
      field: "phone",
      sortModel: null,
      sortField: "phone",
      sorting: false,
    },
    {
      title: "Website",
      field: "website",
      sortModel: null,
      sortField: "website",
      sorting: false,
      render: row => (
        <a href={row.website} target="_blank" rel="noopener noreferrer">
          {row.website}
        </a>
      ),
    },
    {
      title: "Suspended",
      field: "suspended",
      sortModel: null,
      sortField: "suspended",
      sorting: true,
      render: row =>
        row.suspended ? (
          <>
            <Badge color="danger" className="ml-2">
              Suspended
            </Badge>{" "}
            - {row.suspendedMemo}
          </>
        ) : (
          `Active`
        ),
    },
    {
      title: "Client",
      field: "client",
      sortModel: null,
      sortField: "client",
      sorting: true,
      render: row => (row.client ? "Yes" : "No"),
    },
    {
      title: "Vendor",
      field: "vendor",
      sortModel: null,
      sortField: "vendor",
      sorting: true,
      render: row => (row.vendor ? "Yes" : "No"),
    },
    {
      title: "Default Discount",
      field: "defaultDiscount",
      sortModel: null,
      sortField: "defaultDiscount",
      sorting: true,
      render: row => `${row.defaultDiscount}%`,
    },
    {
      title: "Credit Limit",
      field: "creditLimit",
      sortModel: null,
      sortField: "creditLimit",
      sorting: true,
      render: row => currencyFormatter(row.defaultDiscount),
    },
    {
      title: "Credit Period",
      field: "creditPeriod",
      sortModel: null,
      sortField: "creditPeriod",
      sorting: true,
      render: row => `${row.creditPeriod} days`,
    },
    {
      title: "Options",
      field: "id",
      sorting: false,
      disableClick: true,
      render: row => (
        <UncontrolledButtonDropdown size="sm" color="primary">
          <DropdownToggle color="primary" caret>
            Options
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Company</DropdownItem>
            <DropdownWithPermissions
              permission="CONT_EDIT"
              callback={() => actions.editCompany(row)}
              text="Edit"
              testid="editcompany"
              tooltip="You do not have permission to edit companies"
            />
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      ),
    },
  ];
  return (
    <>
      <RemoteTable
        apiEndpoint="/companies"
        title="Companies"
        defaultSortModels={null}
        defaultSortField="name"
        defaultSortOrder="ASC"
        columns={columns}
        actions={actions}
        ref={remoteTable}
        onRowClick={(event, row) => {
          setCompanyModal({
            ...companyModal,
            visible: true,
            edit: true,
            id: row.id,
            initialValues: row,
          });
        }}
        tableActions={(() => {
          let tableActions = [];
          //"isFreeAction" means it appears not when selected, its by search bar
          //has permission to delete/restore users
          if (hasPermission("COMP_CREATE")) {
            tableActions.push({
              tooltip: "New Company",
              isFreeAction: true,
              icon: TableIcons.Add,
              onClick: (evt, data) => actions.newCompany(),
            });
          }
          return tableActions;
        })()}
      />
      <CompanyModal
        isOpen={companyModal.visible}
        toggleModal={visible => setCompanyModal({ ...companyModal, visible })}
        initialValues={companyModal.initialValues}
        id={companyModal.id}
        setReturnValue={company => remoteTable.current.refreshData()}
      />
    </>
  );
};

export default CompaniesListContainer;
