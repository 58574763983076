import React, { useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import { useField } from "formik";
import { Badge, FormGroup, Button } from "reactstrap";
import ContactModal from "App/components/Modals/ContactModal";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
var classNames = require("classnames");
var qs = require("qs");

const ContactInput = ({ label, ...props }) => {
  const [, meta] = useField(props); //field, meta
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [editId, setEditId] = useState(null);
  const input = useRef();
  let [error, setError] = useState("");

  const contactsSearch = async (query, callback) => {
    await axiosAPI
      .get("/contacts?" + qs.stringify({ search: query }))
      .then(result => {
        return callback(result.data.rows);
      })
      .catch(error => {
        let errorMessage = error;
        if (!errorMessage) errorMessage = "An unknown error occurred";
        setError(errorMessage.toString());
      });
  };

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setError("");
        }}
        title="Error"
        show={!!error}
      >
        {error}
      </SweetAlert>
      <FormGroup
        className={classNames({
          suggestInputFormGroup: true,
          [`col-lg-${props.col}`]: !!props.col,
        })}
      >
        <label htmlFor={props.name}>{label}</label>
        <AsyncSelect
          isClearable
          defaultOptions
          cacheOptions
          classNamePrefix="suggestInput"
          className={classNames({
            "is-invalid": meta.touched && meta.error,
            "is-valid": meta.touched && !meta.error,
          })}
          styles={{
            input: (styles, state) => ({
              ...styles,
              height: "3rem",
              paddingTop: "0.7rem",
            }),
          }}
          value={props.values[props.name]}
          name={props.name}
          filterOption={() => true}
          getOptionValue={option => {
            return option;
          }}
          ref={input}
          onBlur={() => props.setFieldTouched(props.name, true)}
          onChange={value => props.setFieldValue(props.name, value || null)}
          getOptionLabel={option => (
            <>
              <strong>
                {option.firstName} {option.lastName}
              </strong>
              <br />
              {option.Company?.name}
              {option.Company?.suspended ? (
                <Badge color="danger" className="ml-2">
                  Suspended
                </Badge>
              ) : null}
            </>
          )}
          loadOptions={contactsSearch}
          noOptionsMessage={query => (
            <Button
              outline
              color="success"
              onClick={() => {
                setInitialValues(null);
                setEditId(null);
                setContactModalOpen(true);
              }}
            >
              Add Contact
            </Button>
          )}
        />
        <Button
          size="sm"
          type="button"
          outline
          className="mt-2 float-right"
          color="success"
          onClick={() => {
            setInitialValues(null);
            setEditId(null);
            setContactModalOpen(true);
          }}
        >
          Add Contact
        </Button>
        {!!props.values[props.name]?.id && ( // eslint-disable-line
          <Button
            size="sm"
            type="button"
            outline
            className="mt-2 float-right"
            color="default"
            onClick={() => {
              setInitialValues(props.values[props.name]);
              setEditId(props.values[props.name].id);
              setContactModalOpen(true);
            }}
          >
            Edit Contact
          </Button>
        )}
        {meta.touched && (
          <div
            className={classNames({
              "invalid-feedback": !!meta.error,
              "valid-feedback": !meta.error,
              feedback: true,
            })}
          >
            {meta.error && Object.values(meta.error).map(error => error)}
          </div>
        )}
      </FormGroup>
      <ContactModal
        isOpen={contactModalOpen}
        toggleModal={open => setContactModalOpen(open)}
        initialValues={initialValues}
        id={editId}
        setReturnValue={contact => {
          input.current.select.select.blur();
          props.setFieldTouched(props.name, true);
          props.setFieldValue(props.name, contact || null);
        }}
      />
    </>
  );
};

export default ContactInput;
