import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, Row, Button, Col } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ContactInput from "App/components/Inputs/ContactInput";
import { TextInput } from "App/components/Inputs/BasicFormInputs.js";
import StatusDropdown from "App/components/Dropdowns/StatusDropdown";
import UserDropdown from "App/components/Dropdowns/UserDropdown";
import DepotDropdown from "App/components/Dropdowns/DepotDropdown";
const _ = require("lodash");

const JobModal = ({
  edit = false,
  visible = false,
  setVisible,
  job = {},
  addJob,
  editJob,
  loading,
}) => {
  return (
    <Modal
      className="modal-dialog-centered formModal"
      isOpen={visible}
      data-testid="modal"
      backdrop="static"
    >
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border">
            <span className="sr-only">loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="modal-header">
            <h2 className="modal-title">{edit ? "Edit Job" : "Add Job"}</h2>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setVisible(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: job?.name || "",
              customerReference: job?.customerReference || "",
              defaultDiscount: job?.defaultDiscount || "",
              memo: job?.memo || "",
              PrimaryContact: job?.PrimaryContact || null,
              Kind: job?.Kind || null,
              JobStatus: job?.JobStatus || null,
              Manager: job?.Manager || null,
              Depot: job?.Depot || null,
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .required("Required")
                .max(200, "Cannot be longer than 200 characters"),
              customerReference: Yup.string(),
              defaultDiscount: Yup.number("Must be a number")
                .nullable()
                .min(0, "Must be greater than or equal to 0"),
              memo: Yup.string(),
              PrimaryContact: Yup.object()
                .nullable(false)
                .typeError("Primary Contact / Company is required")
                .shape({
                  id: Yup.number("Error with primary contact ID").required(
                    "Primary Contact / Company is required",
                  ),
                }),
              Kind: Yup.object()
                .nullable(false)
                .typeError("Kind is required")
                .shape({
                  id: Yup.number("Error with kind ID").required(
                    "Kind is required",
                  ),
                }),
              JobStatus: Yup.object()
                .nullable(false)
                .typeError("Status is required")
                .shape({
                  id: Yup.number("Error with status ID").required(
                    "Status is required",
                  ),
                }),
              Manager: Yup.object()
                .nullable(false)
                .typeError("Manager is required")
                .shape({
                  id: Yup.number("Error with manager ID").required(
                    "Manager is required",
                  ),
                }),
              Depot: Yup.object()
                .nullable(false)
                .typeError("Depot is required")
                .shape({
                  id: Yup.number("Error with depot ID").required(
                    "Depot is required",
                  ),
                }),
            })}
            onSubmit={(values, { setSubmitting }) => {
              let newValues = {
                ...values,
                PrimaryContactId: values.PrimaryContact.id,
                CompanyId: values.PrimaryContact.CompanyId,
                JobStatusId: values.JobStatus.id,
                KindId: values.Kind.id,
                ManagerId: values.Manager.id,
                DepotId: values.Depot.id,
              };
              //Convert all "" to null values
              newValues = _.transform(
                newValues,
                (result, value, key) =>
                  (result[key] = value === "" ? null : value),
              );

              if (edit) {
                editJob({ id: job.id, ...newValues });
              } else addJob(newValues);
            }}
          >
            {formikProps => {
              return (
                <>
                  <ModalBody>
                    <Form>
                      <Row>
                        <TextInput label="Name" name="name" col={12} />
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <UserDropdown
                            label="Manager"
                            name="Manager"
                            {...formikProps}
                          />
                        </Col>
                        <Col xs={6}>
                          <DepotDropdown
                            label="Depot"
                            name="Depot"
                            {...formikProps}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <StatusDropdown
                            label="Kind"
                            name="Kind"
                            apiEndpoint="/kinds"
                            {...formikProps}
                          />
                        </Col>
                        <Col xs={6}>
                          <StatusDropdown
                            label="Status"
                            name="JobStatus"
                            apiEndpoint="/statuses/jobs"
                            {...formikProps}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <TextInput
                          label="Customer Reference"
                          name="customerReference"
                          col={6}
                        />
                        <TextInput
                          label="Default Discount (%)"
                          name="defaultDiscount"
                          type="number"
                          col={6}
                        />
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <ContactInput
                            label="Primary Contact / Company"
                            name="PrimaryContact"
                            type="text"
                            placeholder=""
                            {...formikProps}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <TextInput
                          label="Internal Memo"
                          name="memo"
                          col={12}
                          type="textarea"
                        />
                      </Row>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={formikProps.submitForm}
                      color="primary"
                      type="submit"
                      data-testid="submitbutton"
                    >
                      Done
                    </Button>
                  </ModalFooter>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </Modal>
  );
};

JobModal.propTypes = {
  edit: PropTypes.bool,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  job: PropTypes.object,
  addJob: PropTypes.func,
  editJob: PropTypes.func,
  loading: PropTypes.bool,
};

export default JobModal;
