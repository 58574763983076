export function discountCalculate(from, to) {
  let percent = (
    ((parseFloat(from) - parseFloat(to)) / parseFloat(from)) *
    100
  ).toFixed(1);
  if (isNaN(percent)) percent = 0;
  let amount = parseFloat(parseFloat(from) - parseFloat(to)).toFixed(2);
  if (isNaN(amount)) amount = 0;
  return { percent, amount };
}

export function marginCalculate(from, to) {
  let percent = ((parseFloat(to) / parseFloat(from) - 1) * 100).toFixed(1);
  if (isNaN(percent)) percent = 0;
  let amount = parseFloat(parseFloat(to) - parseFloat(from)).toFixed(2);
  if (isNaN(amount)) amount = 0;
  return { percent, amount };
}
