import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, Row, Button } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextInput } from "App/components/Inputs/BasicFormInputs.js";

const RoleModal = ({
  edit = false,
  visible = false,
  setVisible,
  role = {},
  addRole,
  editRole,
  loading,
}) => {
  return (
    <Modal
      className="modal-dialog-centered formModal"
      isOpen={visible}
      data-testid="modal"
      backdrop="static"
    >
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="modal-header">
            <h2 className="modal-title">{edit ? "Edit Role" : "Add Role"}</h2>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setVisible(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: role?.name || "",
              description: role?.description || "",
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .required("Required")
                .max(200, "Cannot be longer than 200 characters"),
              description: Yup.string()
                .required("Required")
                .max(200, "Cannot be longer than 200 characters"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              if (edit) {
                editRole({ id: role.id, ...values });
              } else addRole(values);
            }}
          >
            {formikProps => {
              return (
                <>
                  <ModalBody>
                    <Form>
                      <Row>
                        <TextInput label="Name" name="name" col={12} />
                      </Row>
                      <Row>
                        <TextInput
                          label="Description"
                          name="description"
                          col={12}
                        />
                      </Row>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={formikProps.submitForm}
                      color="primary"
                      type="submit"
                      data-testid="submitbutton"
                    >
                      {edit ? "Edit Role" : "Add Role"}
                    </Button>
                  </ModalFooter>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </Modal>
  );
};

RoleModal.propTypes = {
  edit: PropTypes.bool,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  role: PropTypes.object,
  addRole: PropTypes.func,
  editRole: PropTypes.func,
  loading: PropTypes.bool,
};

export default RoleModal;
