import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
const moment = require("moment");

const DeliveryStatusBadge = ({
  expectedDispatch,
  dispatchedOn,
  expectedDelivery,
  requiredBy,
  receivedOn,
  className = "",
}) => {
  const todayMoment = moment().startOf("date");

  const requiredByMoment = requiredBy
    ? moment(requiredBy).startOf("date")
    : null;

  if (receivedOn) {
    return (
      <span className="text-success">
        <FontAwesomeIcon className="mr-1" icon={faCircle} />
        Arrived {moment(receivedOn).fromNow()}
      </span>
    );
  }

  //We have an expected delivery date we can use
  if (expectedDelivery) {
    const expectedDeliveryMoment = moment(expectedDelivery).startOf("date");

    //Check if it meets thr requiredby criteria
    if (requiredBy && requiredByMoment.isBefore(expectedDeliveryMoment)) {
      return (
        <span className="text-danger">
          <FontAwesomeIcon className="mr-1" icon={faCircle} />
          Expected delivery {expectedDeliveryMoment.from(requiredByMoment)} past
          required delivery date
        </span>
      );
    }

    //Then use expected delivery to return future/today/past
    return todayMoment.isBefore(expectedDeliveryMoment) ? (
      <span className="text-success">
        <FontAwesomeIcon className="mr-1" icon={faCircle} />
        Expected delivery {expectedDeliveryMoment.from(todayMoment)}
      </span>
    ) : todayMoment.isSame(expectedDeliveryMoment) ? (
      <span className="text-warning">
        <FontAwesomeIcon className="mr-1" icon={faCircle} />
        Expected delivery today
      </span>
    ) : todayMoment.isAfter(expectedDeliveryMoment) ? (
      <span className="text-danger">
        <FontAwesomeIcon className="mr-1" icon={faCircle} />
        Expected delivery {expectedDeliveryMoment.from(todayMoment)}
      </span>
    ) : null;
  }

  //We dont have useful future dates eg expectedDelivery, so use dispatched info
  if (dispatchedOn) {
    const dispatchedOnMoment = moment(dispatchedOn);

    return (
      <span className="text-warning">
        <FontAwesomeIcon className="mr-1" icon={faCircle} />
        Dispatched {dispatchedOnMoment.from(moment())}
      </span>
    );
  }

  //Fall back to expected dispatch
  if (expectedDispatch) {
    const expectedDispatchMoment = moment(expectedDispatch).startOf("date");

    return expectedDispatchMoment.isAfter(todayMoment) ? (
      <span className="text-success">
        <FontAwesomeIcon className="mr-1" icon={faCircle} />
        Expected dispatch {expectedDispatchMoment.from(todayMoment)}
      </span>
    ) : expectedDispatchMoment.isSame(todayMoment) ? (
      <span className="text-warning">
        <FontAwesomeIcon className="mr-1" icon={faCircle} />
        Expected dispatch today
      </span>
    ) : expectedDispatchMoment.isBefore(todayMoment) ? (
      <span className="text-danger">
        <FontAwesomeIcon className="mr-1" icon={faCircle} />
        Expected dispatch {expectedDispatchMoment.from(todayMoment)}
      </span>
    ) : null;
  }

  //No dates from anywhere else, see if we can show the requiredBy date
  if (requiredBy) {
    return (
      <span className="text-warning">
        <FontAwesomeIcon className="mr-1" icon={faCircle} />
        Required {requiredByMoment.from(todayMoment)}
      </span>
    );
  }

  //All else fails, give up, go to the pub, have a nice pint and let this all blow over
  return null;
};

DeliveryStatusBadge.propTypes = {
  priceList: PropTypes.object,
  expectedDispatch: PropTypes.string,
  dispatchedOn: PropTypes.string,
  expectedDelivery: PropTypes.string,
  requiredBy: PropTypes.string,
  receivedOn: PropTypes.string,
};

export default DeliveryStatusBadge;
