import React, { useState, useEffect } from "react";
//import PropTypes from "prop-types";
import { ListGroup, ListGroupItem, Row, Col } from "reactstrap";
import axiosAPI from "App/services/axios";
import ShipmentModalContainer from "App/components/Modals/ShipmentModal/ShipmentModal.container";
import ShipmentStockAssignModalContainer from "App/components/Modals/ShipmentStockAssignModal/ShipmentStockAssignModal.container";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import ButtonWithPermissions from "App/components/Buttons/ButtonWithPermissions";
import { Link } from "react-router-dom";
import truncateText from "App/helpers/truncateText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
const moment = require("moment");

const ShipmentRecordsTab = ({
  preSelectDepotId = null,
  purchaseOrderId = null,
  depotTransferId = null,
  hideSending = false, //whether to hide sending options, eg if its a purchase order
}) => {
  const apiEndpoint = purchaseOrderId
    ? `/purchaseOrders/${purchaseOrderId}`
    : `/depotTransfers/${depotTransferId}`;
  const depots = useSelector(state => state.depots.results);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  //const [loading, setLoading] = useState(true);
  const [shipments, setShipments] = useState([]);
  const [shipmentModal, setShipmentModal] = useState({
    id: null,
    visible: false,
    edit: false,
    preSelectType: null,
  });
  const [shipmentStockAssignModal, setShipmentStockAssignModal] = useState({
    visible: false,
    shipmentId: null,
    purchaseOrderId: 1,
    depotTransferId: null,
    depotId: 1,
  });

  const getShipments = () => {
    //setLoading(true);
    axiosAPI
      .get(`${apiEndpoint}/shipments`)
      .then(result => {
        setShipments(result.data?.rows || []);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        //setLoading(false);
      });
  };

  useEffect(() => {
    if (purchaseOrderId || depotTransferId) getShipments();
  }, [purchaseOrderId, depotTransferId]); // eslint-disable-line

  if (!purchaseOrderId && !depotTransferId) return null;
  /*if (loading)
    return (
      <Spinner type="grow" className="d-block my-8 mx-auto" color="warning" />
    );*/

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <Row className="mb-3">
        <Col>
          <ButtonWithPermissions
            color="success"
            style={{
              height: "38px",
              paddingTop: 0,
              paddingBottom: 0,
              marginRight: "8px",
              width: "135px",
            }}
            onClick={() =>
              setShipmentModal({
                id: null,
                visible: true,
                edit: false,
                preSelectType: "RECEIVE",
              })
            }
            permission="SHIPMENT_RECEIVE"
            tooltip="You do not have permission to receive a new shipment"
          >
            Receive New
          </ButtonWithPermissions>
          {!hideSending && (
            <ButtonWithPermissions
              color="success"
              style={{
                height: "38px",
                paddingTop: 0,
                paddingBottom: 0,
                marginRight: "8px",
                width: "135px",
              }}
              onClick={() =>
                setShipmentModal({
                  id: null,
                  visible: true,
                  edit: false,
                  preSelectType: "SEND",
                })
              }
              permission="SHIPMENT_SEND"
              tooltip="You do not have permission to send a shipment"
            >
              Send New
            </ButtonWithPermissions>
          )}
          <ButtonWithPermissions
            color="warning"
            style={{
              height: "38px",
              paddingTop: 0,
              paddingBottom: 0,
              marginRight: "8px",
              width: "135px",
            }}
            onClick={() =>
              setShipmentModal({
                id: null,
                visible: true,
                edit: false,
                preSelectType: "RETURN",
              })
            }
            permission="SHIPMENT_RETURN"
            tooltip="You do not have permission to return a shipment"
          >
            New Return
          </ButtonWithPermissions>
        </Col>
      </Row>
      {shipments.map(shipment => {
        const verb = shipment.receive ? "received" : "sent";
        return (
          <ListGroup className="ListGroupWithHeader mb-3">
            <ListGroupItem className="ListGroupItemHeader">
              <h4 className="pt-2 mb-0">
                Shipment {shipment.receive ? "to" : "from"}{" "}
                {shipment.Depot.name}, <small>{shipment.Depot.county}</small>
              </h4>
              <h6 className="font-weight-400">
                {verb} by {shipment.User.fullName} on{" "}
                {moment(shipment.date).format("HH:mm, Do MMM YYYY")}
              </h6>
            </ListGroupItem>
            {shipment.StockMovements.map(stockMovement => (
              <ListGroupItem>
                {stockMovement.quantity} &times;{" "}
                <Link
                  to={`/app/product/${stockMovement.Product.id}`}
                  target="_blank"
                >
                  {stockMovement.Product.Manufacturer.name}{" "}
                  {stockMovement.Product.name} (
                  {truncateText(
                    stockMovement?.Product?.manufacturerProductCode,
                    15,
                  )}
                  )
                </Link>{" "}
                {verb} (of{" "}
                {stockMovement?.PurchaseOrderItem?.quantity ||
                  stockMovement?.DepotTransferItem?.quantity}
                )
              </ListGroupItem>
            ))}
            <ListGroupItem className="ListGroupItemFooter">
              <ButtonWithPermissions
                color="none"
                style={{
                  height: "30px",
                  padding: "0 10px",
                  marginRight: "8px",
                }}
                onClick={() =>
                  setShipmentStockAssignModal({
                    visible: true,
                    shipmentId: shipment?.id,
                    purchaseOrderId: shipment?.PurchaseOrderId,
                    depotTransferId: shipment?.DepotTransferId,
                    depotId: shipment?.DepotId,
                  })
                }
                permission={`SHIPMENT_${shipment.type}`}
                tooltip="You do not have permission to add items to this shipment"
              >
                <FontAwesomeIcon className="mr-2" icon={faPlus} />
                Add items
              </ButtonWithPermissions>
            </ListGroupItem>
          </ListGroup>
        );
      })}
      <ShipmentModalContainer
        setVisible={visible => setShipmentModal({ ...shipmentModal, visible })}
        done={() => getShipments()}
        purchaseOrderId={purchaseOrderId}
        depotTransferId={depotTransferId}
        preSelectDepot={depots.find(x => x.id === preSelectDepotId) || null}
        {...shipmentModal}
      />
      <ShipmentStockAssignModalContainer
        setVisible={visible =>
          setShipmentStockAssignModal({ ...shipmentStockAssignModal, visible })
        }
        done={() => getShipments()}
        {...shipmentStockAssignModal}
      />
    </>
  );
};

ShipmentRecordsTab.propTypes = {};

export default ShipmentRecordsTab;
