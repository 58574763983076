import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as userActions from "App/redux/user/actions";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";
import Avatar from "react-avatar";

function UserMenu(props) {
  return (
    <UncontrolledDropdown {...props} nav>
      <DropdownToggle nav>
        <Media className="align-items-center">
          {/*<span className="avatar avatar-sm rounded-circle">
            <img
              alt="..."
              src={"data:image/jpeg;base64," + props.user.info.avatar?.data}
            />
          </span>*/}
          <Avatar
            src={
              props.user.info?.AvatarFile?.id
                ? process.env.REACT_APP_API_BASE_URL +
                  `/files/${props.user.info?.AvatarFile?.id}`
                : null
            }
            name={props.user.info.fullName}
            size="50"
            className={`mr-2 float-left bg-default`}
            round
          />
          <Media className="ml-3 d-none d-lg-block">
            <span className="mb-0 text-sm font-weight-bold text-white">
              {props.user.info.firstName} {props.user.info.lastName}
            </span>
          </Media>
        </Media>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        <DropdownItem className="noti-title" header tag="div">
          <h6 className="text-overflow m-0">
            {props.user.info.firstName} {props.user.info.lastName}
          </h6>
        </DropdownItem>
        <DropdownItem to="/admin/user-profile" tag={Link}>
          <i className="ni ni-single-02" />
          <span>My profile</span>
        </DropdownItem>
        {/*
        <DropdownItem to="/admin/user-profile" tag={Link}>
          <i className="ni ni-settings-gear-65" />
          <span>Settings</span>
        </DropdownItem>
        <DropdownItem to="/admin/user-profile" tag={Link}>
          <i className="ni ni-calendar-grid-58" />
          <span>Activity</span>
        </DropdownItem>
        <DropdownItem to="/admin/user-profile" tag={Link}>
          <i className="ni ni-support-16" />
          <span>Support</span>
        </DropdownItem>*/}
        <DropdownItem divider />
        <DropdownItem
          href="#"
          onClick={e => {
            props.dropDownLogout();
            e.preventDefault();
          }}>
          <i className="ni ni-user-run" />
          <span>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

const mapStateToProps = state => {
  return { user: state.user };
};

const mapDispatchToProps = dispatch => {
  return {
    dropDownLogout: () => {
      dispatch(userActions.userLogout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
