import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./placeholder-renderer.module.scss";

const PlaceholderRenderer = ({ isOver, canDrop }) => (
  <div
    className={classNames(
      styles.rst__placeholder,
      canDrop && styles.rst__placeholderLandingPad,
      canDrop && !isOver && styles.rst__placeholderCancelPad,
    )}
  >
    Drop your items here to begin creating a list.
  </div>
);

PlaceholderRenderer.defaultProps = {
  isOver: false,
  canDrop: false,
};

PlaceholderRenderer.propTypes = {
  isOver: PropTypes.bool,
  canDrop: PropTypes.bool,
};

export default PlaceholderRenderer;
