import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { ConnectedRouter } from "connected-react-router";
import { store, persistor } from "App/redux/store";
import { Provider as StoreProvider, ReactReduxContext } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { history } from "App/redux/store";
import "App/vendor/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "App/vendor/argon/scss/argon-dashboard-react.scss";
import "react-datepicker/dist/react-datepicker.css";
import AppContainer from "./AppContainer";
import Favicon from "react-favicon";
//import routes from "App/routes";
//import { useClearCache } from "react-clear-cache";
require("./App.css");
require("App/services/googleErrorReporting");
require("dotenv").config();

const App = () => {
  //const { isLatestVersion, emptyCacheStorage } = useClearCache();
  //if (!isLatestVersion) emptyCacheStorage();

  return (
    <>
      {/*<React.StrictMode> breaks up/down arrows on contact field AsyncTypeahead */}
      <StoreProvider store={store} context={ReactReduxContext}>
        <PersistGate loading={null} persistor={persistor}>
          <Favicon
            alertCount={0}
            url={require("App/assets/images/favicon.ico")}
          />

          <ConnectedRouter history={history} context={ReactReduxContext}>
            <Switch>
              <Route path="/app">
                <AppContainer />
              </Route>
              <Route path="/login">
                <LoginPage />
              </Route>
              <Redirect from="/loginsuccess" to="/app/dashboard" />
              <Redirect from="/" to="/app/dashboard" />
              {/*
              <Route exact path="/400" component={Error400} />
              <Route exact path="/401" component={Error401} />
              <Route exact path="/403" component={Error403} />
              <Route exact path="/404" component={Error404} />
              <Route exact path="/500" component={Error500} />
              <Route exact path="/503" component={Error503} />
              <Route exact path="/charts" component={ChartsPage} />


              <Route component={Error404} />*/}
            </Switch>
          </ConnectedRouter>
        </PersistGate>
      </StoreProvider>
      {/*</React.StrictMode>*/}
    </>
  );
};

export default App;
