import React, { Component } from "react";
import ProductPickerContainer from "App/components/Pickers/ProductPicker/ProductPicker.container";
import AddButton from "App/components/Tables/SortableTreeCommon/AddButton";
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import {
  changeNodeAtPath,
  getNodeAtPath,
  getFlatDataFromTree,
  getTreeFromFlatData,
  addNodeUnderParent,
  removeNodeAtPath,
} from "react-sortable-tree";
import SweetAlert from "react-bootstrap-sweetalert";
import io from "socket.io-client";
import currencyFormatter from "App/helpers/currencyFormatter";
import styles from "./PurchaseOrderTableContainer.module.scss";
import axiosAPI from "App/services/axios";
import PurchaseOrderTable from "./PurchaseOrderTable";
import debounce from "App/helpers/debounce";
import hasPermission from "App/helpers/hasPermission";
import defaultSocketOptions from "App/services/socketio";
const socket = io("/purchaseOrders", defaultSocketOptions);
const classNames = require("classnames");

const getKey = node => node.id; //when you pass this into getTreeFromFlatData, it gives you each NODE, so you can take id straight from that
const getParentKey = node => node.ParentId; //when you pass this into getTreeFromFlatData, it gives you each NODE, so you can take id straight from that
const getNodeKey = ({ node }) => node.id; //getNodeKey is used by the SortableTree, and is passed an object containing the node and treeindex, so need to destructure it!
const canNodeHaveChildren = node => node.type !== 0; //ie items cannot have children

export default class PurchaseOrderTableContainer extends Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);

    this.debounceUpdate = debounce(() => {
      this.updateTotals();
      this.updateDatabase();
    }, 800);

    socket.on("logout", () => {
      socket.disconnect();
      window.location = "/login";
    });

    //initially, get data from db, then make a tree, then expand
    //we then need to update totals - db doesnt store discount, margin, or totals - it only stores tweakable fields eg qty, name, cost price
    //then retrospectively works out the discount% and margin% etc
    this.state = {
      shiftPressed: false,
      cmdPressed: false,
      ctrlPressed: false,
      totals: {
        exVat: 0,
        vat: 0,
        incVat: 0,
        nonTaxableItems: 0,
      },
      purchaseOrder: this.props.purchaseOrder || {},
      costNominals: [], //all nominals that are COST nominals (expenditure)
      taxRates: [],
      undoHistory: [],
      redoHistory: [],
      databaseData: [], //this is our local representation of the current db state
      treeData: getTreeFromFlatData({
        //compared to databaseData, and then we tell server what to tweak ennit
        flatData: [],
        getKey: getKey, // resolve a node's key
        getParentKey: getParentKey, // resolve a node's parent's key
        rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
      }),
      showDiscountAsPercent: true,
      showMarginAsPercent: true,
      errorMessage: null,
      errorMore: null,
      enabled: true,
    };
  }

  componentDidCatch(error, info) {
    /*    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    logComponentStackToMyService(info.componentStack);*/
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      errorMessage: "Oops, we got our wires crossed...",
      errorMore:
        "Sorry, something went badly wrong. Please try refreshing the page",
    };
  }

  loadCostNominals = () => {
    axiosAPI
      .get(`/nominals/cost`)
      .then(result => {
        this.setState({
          costNominals: result?.data || [],
        });
      })
      .catch(error => {
        this.setState({
          errorMessage: error?.errorMessage || "An unknown error occurred",
          errorMore: error?.errorMore || "Something went wrong, please retry",
        });
        console.error(error);
      });
  };

  loadTaxRates = () => {
    axiosAPI
      .get(`/taxRates`)
      .then(result => {
        this.setState({
          taxRates: result?.data || [],
        });
      })
      .catch(error => {
        this.setState({
          errorMessage: error?.errorMessage || "An unknown error occurred",
          errorMore: error?.errorMore || "Something went wrong, please retry",
        });
        console.error(error);
      });
  };

  //undo stack: just a stack of databaseDatas
  // [ [state], [state], [state], [state], [state] ]
  //   ^ 0 is newest (now)                  ^ 5 is oldest
  //so an undo would jump to [1], and remove [0,1]
  undo = () => {
    if (this.state.undoHistory.length > 1) {
      //[0] is always the current state, so [1] would be the last state
      const changeStateTo = this.state.undoHistory[1];
      this.setState(
        {
          undoHistory: [...this.state.undoHistory].slice(2),
          redoHistory: [
            ...[this.state.undoHistory[0]],
            ...this.state.redoHistory,
          ].slice(0, 5),
        },
        () => this.updateFromFlatData(changeStateTo),
      );
    }
  };

  redo = () => {
    if (this.state.redoHistory.length > 0) {
      //[0] is always the current state, so [1] would be the last state
      const changeStateTo = this.state.redoHistory[0];
      this.setState(
        {
          redoHistory: [...this.state.redoHistory].slice(1),
          undoHistory: [
            ...[this.state.redoHistory[0]],
            ...this.state.undoHistory,
          ].slice(0, 5),
        },
        () => this.updateFromFlatData(changeStateTo),
      );
    }
  };

  handleOnTreeChange = treeData => {
    console.log("ONCHANGE TO TREE", treeData);
    this.setState({ treeData }, async () => {
      await this.updateTotals();
      this.updateDatabase();
    });
  };

  updateTrees = items => {
    console.log("UPDATE TREES ", items);
    this.setState(
      {
        ...(JSON.stringify(items) !== JSON.stringify(this.state.undoHistory[0])
          ? { undoHistory: [...[items], ...this.state.undoHistory].slice(0, 5) }
          : {}),
        databaseData: items,
        treeData: getTreeFromFlatData({
          flatData: items,
          getKey: getKey, // resolve a node's key
          getParentKey: getParentKey, // resolve a node's parent's key
          rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
        }),
      },
      () => {
        this.updateTotals();
      },
    );
  };

  async updateDatabase() {
    const DEBUG = true;
    //only some keys we need to check compared to database to see if need to update etc
    const keysToCheck = [
      "name",
      "description",
      "memo",
      "quantity",
      "costPrice",
      "type",
      "PriceListItemId",
      "ProductId",
      "ParentId",
      "PurchaseOrderId",
      "sort",
      "expanded",
      "tempId",
      "tempParentId",
      "TaxRateId",
      "selected",
    ];

    //get data from tree, and only include properties we're interested in for comparison
    let flatData = getFlatDataFromTree({
      treeData: JSON.parse(JSON.stringify(this.state.treeData)),
      getNodeKey, // This ensures your "id" properties are exported in the path
      ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
    }).map(({ node, path }, sort) => {
      let newNode = {};
      keysToCheck.forEach((key, i) => {
        //this adds in only the keys we care about
        newNode[key] = node[key];
      });
      //there are some attributes we "calculate", eg treeParentIndex and TaxRate and PurchaseOrderId
      //so do these here:
      newNode = {
        ...newNode,
        //force them to have only 2dp so they pass validation
        ...(node.purchaseOrderPrice
          ? { purchaseOrderPrice: +node.purchaseOrderPrice.toFixed(2) }
          : {}),
        ...(node.costPrice ? { costPrice: +node.costPrice.toFixed(2) } : {}),
        PurchaseOrderId: this.state.purchaseOrder?.id,
        id: path[path.length - 1],
        ParentId: path.length > 1 ? path[path.length - 2] : null,
        sort,
        tempId: node.tempId || null,
      };
      //console.log(newNode);
      return newNode;
    });

    let nodeOperations = {
      insertItems: [], //array of new items eg { name: 'ads', ... }
      updateItems: [], //array of { id: 12, attributes: {}}
      deleteItems: [], //array of ids to delete eg [12, 15, 18]
    };
    //go through each flatdata and see if it exists and is same in our current database
    flatData.forEach((node, index) => {
      //Go through tree and see what has changed or is new
      let focussedDatabaseNode = {};

      let foundDatabaseNode = this.state.databaseData.filter(
        x => x.id === node.id,
      );
      if (foundDatabaseNode.length > 0) {
        //if (DEBUG) console.log("found node in db", foundDatabaseNode);
        //take it out of the array (as it returns it as [{obj}]   )
        foundDatabaseNode = foundDatabaseNode[0];
        //copy into the focussedDatabaseNode only the attributes we care about
        keysToCheck.forEach((key, i) => {
          //this adds in only the keys we care about
          //to check if theyve changed etc
          focussedDatabaseNode[key] = foundDatabaseNode[key];
        });
      }

      if (Object.keys(focussedDatabaseNode).length === 0) {
        //not found on database side, so insert
        if (DEBUG) console.log("not found node in db", node);
        nodeOperations.insertItems.push(node);
      } else {
        //found on database side, check if it's equal
        if (JSON.stringify(node) === JSON.stringify(focussedDatabaseNode)) {
          //equal so do nothing
          if (DEBUG) console.log("found node in db, and is equal", node);
        } else {
          //console.log(node, focussedDatabaseNode);
          let newNode = JSON.parse(JSON.stringify(node)); //deep copy
          //different so update
          let needToUpdate = false;
          Object.keys(focussedDatabaseNode).forEach(key => {
            if (newNode[key] === focussedDatabaseNode[key]) {
              delete newNode[key];
            } else if (key !== "id") {
              needToUpdate = true;
            }
          });
          if (needToUpdate) {
            //there are some attributes left to update!
            let updateNode = {
              id: node.id,
              attributes: { ...newNode },
            };
            if (DEBUG)
              console.log(
                "found node in db, but different, ",
                node,
                updateNode,
              );

            nodeOperations.updateItems.push(updateNode);
          }
        }
      }
    });

    //Go through database and check anything to delete (ie not present in tree)
    let databaseData = JSON.parse(JSON.stringify(this.state.databaseData));
    databaseData.forEach((node, i) => {
      let treeFoundNode = flatData.filter(x => x.id === node.id); //is it in the tree?
      if (treeFoundNode.length === 0) {
        // not found in tree so need to delete
        if (DEBUG)
          console.log("orphan left on db side, not in tree, delete", node);
        nodeOperations.deleteItems.push(node.id);
      }
    });

    if (
      nodeOperations.insertItems.length > 0 ||
      nodeOperations.updateItems.length > 0 ||
      nodeOperations.deleteItems.length > 0
    ) {
      this.updatePurchaseOrder(nodeOperations);
    }
  }

  updateTotals = () => {
    let totalExVat = 0;
    let totalVat = 0;
    let totalIncVat = 0;
    let nonTaxableItems = [];

    const calculateChildrenTotal = node => {
      let total = 0; //total price = costPrice * quantity
      let costPrice = 0; //used for the discount and margin calculations, not actually shown in folders

      if (node.type === 1) {
        //if its a folder
        if (node.children && node.children?.length > 0) {
          //it has some lovely children
          node.children.forEach((child, i) => {
            //recursively call this function
            let childrenTotals = calculateChildrenTotal(child);
            total += childrenTotals.total;
            costPrice +=
              childrenTotals.costPrice * parseFloat(childrenTotals.quantity);
          });
        } else {
          //its a poor lonely folder with no children boohoo
          total = 0;
          costPrice = 0;
        }
      } else {
        //its just a plain old boring rubbish horrible nasty item
        total =
          +(parseFloat(node.quantity) * parseFloat(node.costPrice)).toFixed(
            2,
          ) || 0; //work out total, qty*costPrice. || 0 in case null
        costPrice = +parseFloat(node.costPrice).toFixed(2) || 0; //get its cost price for discount and margin calculators

        //add to grand totals
        //add to grand totals
        let vatAmount =
          node?.TaxRate?.rate > 0 ? total * (0.01 * node?.TaxRate?.rate) : 0;
        if (node?.TaxRate?.rate === 0) nonTaxableItems.push(node);
        totalExVat = +totalExVat + total;
        totalVat = +totalVat + vatAmount;
        totalIncVat = +totalIncVat + total + vatAmount;
      }
      node.total = total; //update this node's total, and return it for parent recursion
      node.costPrice = costPrice;

      return {
        total,
        costPrice,
        quantity: node.quantity || 1,
      };
    };

    const newTreeData = JSON.parse(JSON.stringify(this.state.treeData)); //deep copy array
    const pseudoRootNode = { type: 1, children: newTreeData }; //fake root node to get all children
    //let { treeTotal: total } = calculateChildrenTotal(pseudoRootNode);
    calculateChildrenTotal(pseudoRootNode);

    this.setState({
      treeData: newTreeData,
      totals: {
        ...this.state.totals,
        exVat: totalExVat,
        vat: totalVat,
        incVat: totalIncVat,
        nonTaxableItems: nonTaxableItems.length,
      },
    });
  };

  handleDeleteNode = path => {
    this.setState(
      state => ({
        treeData: removeNodeAtPath({
          treeData: state.treeData,
          path,
          getNodeKey,
        }),
      }),
      async () => {
        await this.updateTotals();
        this.updateDatabase();
      },
    );
  };

  handleInsertNode = node => {
    this.setState(
      state => ({
        treeData: addNodeUnderParent({
          treeData: state.treeData,
          parentKey: null,
          expandParent: true,
          getNodeKey,
          newNode: node,
          addAsFirstChild: false,
        }).treeData,
      }),
      async () => {
        //console.log(this.state.treeData);
        //this.pickerSearchInputRef.current.focus();
        await this.updateTotals();
        this.updateDatabase();
      },
    );
  };

  handleRestoreName = (node, path) => {
    this.setState(
      {
        treeData: changeNodeAtPath({
          treeData: this.state.treeData,
          path,
          getNodeKey,
          newNode: { ...node, name: node.PriceListItem.Product.name },
        }),
      },
      () => this.updateDatabase(),
    );
  };

  ignoreKeyPress = target => {
    //was it on an input that isnt disabled (ie you can type in it!)
    if (target.tagName === "INPUT" && !target.disabled) return true;
    if (target.tagName === "SELECT" && !target.disabled) return true;
    //not a button (options dropdown)
    if (target.tagName === "BUTTON") return true;
    //otherwise its ok to use as a modifier
    return false;
  };

  async handleKeyDown(event) {
    const { key, target } = event;
    if (this.ignoreKeyPress(target)) return;
    if (!this.state.enabled) return;

    key === "Shift" && this.setState({ shiftPressed: true });
    if (key === "Backspace") {
      //delete anything that is selected
      this.updateFromFlatData(
        this.state.databaseData.filter(row => !row.selected),
      );
    }
    key === "Meta" && this.setState({ cmdPressed: true });
    key === "Control" && this.setState({ ctrlPressed: true });
    //select / deselect all
    if (
      (this.state.cmdPressed || this.state.ctrlPressed) &&
      key.toLowerCase() === "a"
    ) {
      event.preventDefault();
      //see if an item in purchaseOrder is selected (first one is fine)
      const selectOrDeselect = !this.state.databaseData[0]?.selected;
      this.updateFromFlatData(
        this.state.databaseData.map(row => ({
          ...row,
          selected: selectOrDeselect,
        })),
      );
    }
    //invert selection
    if (
      (this.state.cmdPressed || this.state.ctrlPressed) &&
      key.toLowerCase() === "i"
    ) {
      event.preventDefault();
      this.updateFromFlatData(
        this.state.databaseData.map(row => ({
          ...row,
          selected: !row.selected,
        })),
      );
    }
    //undo
    if (
      (this.state.cmdPressed || this.state.ctrlPressed) &&
      key.toLowerCase() === "z"
    ) {
      // event.preventDefault();
      // this.undo();
    }

    //redo
    if (
      (this.state.cmdPressed || this.state.ctrlPressed) &&
      this.state.shiftPressed &&
      key.toLowerCase() === "z"
    ) {
      // event.preventDefault();
      // this.redo();
    }
  }

  async handleKeyUp(event) {
    const { key, target } = event;
    if (this.ignoreKeyPress(target)) return;

    key === "Shift" && this.setState({ shiftPressed: false });
    key === "Meta" && this.setState({ cmdPressed: false });
    key === "Control" && this.setState({ ctrlPressed: false });
  }

  handleMoveFocusToNextRow = (node, field, path) => {
    const row = getNodeAtPath({
      treeData: this.state.treeData,
      path,
      getNodeKey,
      ignoreCollapsed: true,
    });
    console.log(row);
  };

  handleSelectRow = (node, path, e) => {
    if (this.ignoreKeyPress(e.target)) return;

    //get which modifier keys pressed with the click
    let { ctrlKey, metaKey, shiftKey } = e;
    let newData = [...this.state.databaseData];

    if (!ctrlKey && !metaKey && !shiftKey) {
      //only select one at a time
      newData = newData.map(row => {
        return row.id === node.id
          ? { ...row, selected: !row.selected }
          : { ...row, selected: false };
      });
    }

    if ((ctrlKey || metaKey) && !shiftKey) {
      //ADD to the selection or REMOVE from selection if already selected
      newData = newData.map(row => {
        return row.id === node.id ? { ...row, selected: !node.selected } : row;
      });
    }

    if (shiftKey && (!ctrlKey || !metaKey)) {
      //shift block selection
      let firstSelectedNode = newData.find(node => node.selected)?.sort;
      let lastSelectedNode = [...newData]
        .reverse()
        .find(node => node.selected)?.sort;

      if (firstSelectedNode === undefined) {
        //could be 0, so !firstSelectedNode wouldnt work
        //just do a normal selection
        newData = newData.map(row => {
          return row.id === node.id
            ? { ...row, selected: !row.selected }
            : { ...row, selected: false };
        });
      } else {
        //already have something else selected so can complete the multi selection
        let thisSelectedNode = node.sort;
        //work out which one is 'earlier' in the purchaseOrder
        let selectFromTo =
          firstSelectedNode < thisSelectedNode
            ? [firstSelectedNode, thisSelectedNode]
            : [thisSelectedNode, lastSelectedNode];
        newData = newData.map(row => {
          return row.sort >= selectFromTo[0] && row.sort <= selectFromTo[1]
            ? { ...row, selected: true }
            : { ...row, selected: false };
        });
      }
    }

    this.updateFromFlatData(newData);
  };

  handleUpdateNode = (node, fieldEdited, newValue, path) => {
    let newNode = { ...node };

    //storable value in the node
    newNode[fieldEdited] = newValue;

    this.setState(
      {
        treeData: changeNodeAtPath({
          treeData: this.state.treeData,
          path,
          getNodeKey,
          newNode: newNode,
        }),
      },
      this.debounceUpdate,
    );
  };

  updatePurchaseOrder = operations => {
    socket.emit(
      "updatePurchaseOrder",
      this.state.purchaseOrder?.id,
      operations,
    );
  };

  handleSocketPurchaseOrderUpdates = async (
    purchaseOrderId,
    purchaseOrderUpdates,
  ) => {
    if (purchaseOrderId !== this.state.purchaseOrder?.id) return;
    console.log("SOCKET PO UPDATES", purchaseOrderUpdates, this.state);

    let newDatabaseData = JSON.parse(JSON.stringify(this.state.databaseData));

    //remove any deleted ids
    purchaseOrderUpdates.deletedItems.forEach((id, i) => {
      newDatabaseData = newDatabaseData.filter(function (obj) {
        return obj.id !== id;
      });
    });

    //update any items
    purchaseOrderUpdates.updatedItems.forEach((item, i) => {
      const targetIndex = newDatabaseData.findIndex(f => f.id === item.id);
      newDatabaseData[targetIndex] = item;
    });

    //add in inserted items
    purchaseOrderUpdates.insertedItems.forEach((item, i) => {
      newDatabaseData.push(item);
    });

    await newDatabaseData.sort((a, b) => {
      //sort using comparison between a and b
      if (a.sort === b.sort) {
        return 0;
      } else if (a.sort > b.sort) {
        return 1;
      } else if (a.sort < b.sort) {
        return -1;
      }
      return 0;
    });

    //console.log(purchaseOrderUpdates, newDatabaseData);
    this.updateTrees(newDatabaseData);
  };

  joinPurchaseOrder(id) {
    socket.emit("joinPurchaseOrder", id);
    socket.on(
      "getPurchaseOrderItems",
      ({ purchaseOrderId, purchaseOrderItems }) => {
        if (purchaseOrderId === id) this.updateTrees(purchaseOrderItems);
      },
    );
  }

  leavePurchaseOrder(id) {
    id && socket.emit("leavePurchaseOrder", id);
  }

  componentDidUpdate(prevProps, prevState) {
    //see if purchaseOrder Id has changed
    if (this.props.purchaseOrder?.id !== prevProps.purchaseOrder?.id) {
      this.setState({
        purchaseOrder: this.props.purchaseOrder,
      });
      this.joinPurchaseOrder(this.props.purchaseOrder?.id);
      this.leavePurchaseOrder(prevProps.purchaseOrder?.id);
    }
    //see if isActive has changed
    if (this.props.isActive !== prevProps.isActive) {
      //has changed
      if (this.props.isActive) {
        this.joinPurchaseOrder(this.props.purchaseOrder?.id);
      } else {
        this.leavePurchaseOrder(prevProps.purchaseOrder?.id);
      }
    }
    //see if authorised has changed
    if (
      this.props?.purchaseOrder?.authorised !==
      prevProps?.purchaseOrder?.authorised
    ) {
      this.enableOrDisable();
    }
  }

  enableOrDisable() {
    this.setState({
      enabled: this.props.purchaseOrder.authorised
        ? hasPermission("PO_EDIT_ITEMS_AUTHORISED")
        : hasPermission("PO_EDIT_ITEMS_UNAUTHORISED"),
    });
  }

  //just a way of updating the tree from flat data, and totals, and database
  //used by select/delete operations
  updateFromFlatData(flatData) {
    this.setState(
      {
        treeData: getTreeFromFlatData({
          flatData,
          getKey, // resolve a node's key
          getParentKey, // resolve a node's parent's key
          rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
        }),
      },
      async () => {
        await this.updateTotals();
        this.updateDatabase();
      },
    );
  }

  componentDidMount() {
    this.joinPurchaseOrder(this.props.purchaseOrder?.id);
    socket.on("socketError", ({ errorMessage, errorMore }) => {
      this.setState({
        errorMessage: errorMessage,
        errorMore: errorMore || "",
      });
    });

    //this is called when someone else updates the purchaseOrder - ie another client -> server -> us
    socket.on("purchaseOrderUpdates", this.handleSocketPurchaseOrderUpdates);

    //get all cost nominals + tax rates
    this.loadCostNominals();
    this.loadTaxRates();

    //set whether PO is enabled
    this.enableOrDisable();

    //monitor keys we care about
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);
  }

  /* Removing the purchaseOrderener before unmounting the component in order to avoid addition of multiple purchaseOrderener at the time revisit*/
  componentWillUnmount() {
    socket.off("getPurchaseOrderItems");
    this.leavePurchaseOrder(this.props.purchaseOrder?.id);

    // Remove event purchaseOrdereners on cleanup
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  }

  render() {
    return (
      <>
        <SweetAlert
          danger
          onConfirm={() => {
            this.setState({ errorMessage: null, errorMore: null });
          }}
          title="Error"
          show={!!this.state.errorMessage}
        >
          {this.state.errorMessage}
          {this.state.errorMore && (
            <>
              <br />
              <small>{this.state.errorMore}</small>
            </>
          )}
        </SweetAlert>
        {this.state.enabled && (
          <Row className="pt-2">
            <Col xs={12} lg={6} style={{ display: "flex" }}>
              <ProductPickerContainer
                specificVendorId={this.props?.purchaseOrder?.Contact?.CompanyId}
                onSelect={this.handleInsertNode}
                containerId={this.props?.purchaseOrder?.Contact?.CompanyId}
                showManufacturerNameInProductName={true}
              />
            </Col>
            <Col xs={12} lg={6}>
              <AddButton
                node={{
                  id: Date.now(),
                  name: "Folder",
                  type: 1,
                  quantity: 1,
                  expanded: true,
                }}
                icon={"fa-folder"}
                onClick={this.handleInsertNode}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col style={{ overflowX: "scroll" }}>
            <PurchaseOrderTable
              treeData={this.state.treeData}
              onTreeChange={this.handleOnTreeChange}
              getNodeKey={getNodeKey}
              canNodeHaveChildren={canNodeHaveChildren}
              updateNode={this.handleUpdateNode}
              deleteNode={this.handleDeleteNode}
              restoreName={this.handleRestoreName}
              selectRow={this.handleSelectRow}
              moveFocusToNextRow={this.handleMoveFocusToNextRow}
              costNominals={this.state.costNominals}
              taxRates={this.state.taxRates}
              enabled={this.state.enabled}
            />
          </Col>
        </Row>
        <Row>
          {/* *********** PO TOTALS *********** */}
          <Col xs={12} xl={2}>
            <h4 className="text-xl-right pt-1">
              {this.state.purchaseOrder?.name} PO Totals
              <i className="fa fa-question-circle ml-2" id="listTotalsHelp" />
              <UncontrolledTooltip placement="top" target="listTotalsHelp">
                These are the totals for this purchase order.
              </UncontrolledTooltip>
            </h4>
          </Col>
          <Col xs={12} xl={10} className={styles.listTotalsRow}>
            <span className={`${styles.listTotal}`}>
              <strong>Total (ex VAT):</strong>{" "}
              {currencyFormatter(this.state.totals.exVat)}
            </span>
            <span
              className={classNames({
                [styles.listTotal]: true,
                [styles.interactiveListTotal]:
                  this.state.totals.nonTaxableItems > 0,
              })}
              id="vatTotalLine"
            >
              <strong>VAT:</strong> {currencyFormatter(this.state.totals.vat)}
            </span>
            {this.state.totals.nonTaxableItems > 0 && (
              <UncontrolledTooltip placement="top" target="vatTotalLine">
                VAT not charged for {this.state.totals.nonTaxableItems} line
                {this.state.totals.nonTaxableItems > 1 && "s"}
              </UncontrolledTooltip>
            )}
            <span className={`${styles.listTotal}`}>
              <strong>Total (inc VAT):</strong>{" "}
              {currencyFormatter(this.state.totals.incVat)}
            </span>
          </Col>
        </Row>
      </>
    );
  }
}
