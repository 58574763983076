import React, { useRef } from "react";
import PropTypes from "prop-types";
import RemoteTable from "App/components/Tables/RemoteTable/RemoteTable";

const PriceListItemsListContainer = ({ priceListId }) => {
  const remoteTable = useRef();

  const actions = {};

  const columns = [
    {
      title: "Manufacturer",
      field: "Product.Manufacturer.name",
      sortModel: ["Product", "Manufacturer"],
      sortField: "name",
      editable: "never",
    },
    {
      title: "Product",
      field: "Product.name",
      sortModel: ["Product"],
      sortField: "name",
      editable: "never",
      render: row => {
        return (
          <>
            {row?.Product?.name}
            <br />
            <small>{row?.Product?.manufacturerProductCode}</small>
          </>
        );
      },
    },
    {
      title: "Vendor Product Code",
      field: "vendorProductCode",
      required: true,
      sortModel: null,
      sortField: "vendorProductCode",
    },
    {
      title: "List Price",
      field: "listPrice",
      sortModel: null,
      sortField: "listPrice",
      required: true,
      type: "currency",
      currencySetting: {
        locale: "en-GB",
        currencyCode: "GBP",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      emptyValue: "",
    },
    {
      title: "Line Price",
      field: "linePrice",
      sortModel: null,
      sortField: "linePrice",
      type: "currency",
      currencySetting: {
        locale: "en-GB",
        currencyCode: "GBP",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      emptyValue: "",
    },
    {
      title: "Cost Price",
      field: "costPrice",
      sortModel: null,
      sortField: "costPrice",
      type: "currency",
      required: true,
      currencySetting: {
        locale: "en-GB",
        currencyCode: "GBP",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      emptyValue: "",
    },
    {
      title: "Enabled?",
      field: "enabled",
      sortModel: null,
      sortField: "enabled",
      type: "boolean",
    },
  ];

  return (
    <>
      <RemoteTable
        apiEndpoint={`/priceLists/${priceListId}/items`}
        editable={true}
        title="Price List Items"
        defaultSortModels={["Product"]}
        defaultSortField="name"
        defaultSortOrder="ASC"
        columns={columns}
        actions={actions}
        ref={remoteTable}
      />
    </>
  );
};

PriceListItemsListContainer.propTypes = {
  priceListId: PropTypes.number,
};

export default PriceListItemsListContainer;
