import React from "react";
import PropTypes from "prop-types";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import axiosAPI, { CancelToken } from "App/services/axios";
const qs = require("qs");

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileEncode);

const UploadPriceList = ({ setPriceListFileId, setUpload, className = "" }) => {
  return (
    <FilePond
      className={className}
      name="file"
      allowFileEncode={true}
      beforeAddFile={item => console.log(item)}
      onprocessfile={(error, file) => {
        if (!error) {
          setUpload({
            base64File: file.getFileEncodeBase64String(),
            fileUploaded: true,
          });
        }
      }}
      onremovefile={(error, file) => {
        setUpload({
          fileUploaded: false,
        });
      }}
      server={{
        url: process.env.REACT_APP_API_BASE_URL,
        revert: (uniqueFileId, load, error) => {
          axiosAPI
            .delete(`/files/${uniqueFileId}`)
            .then(result => {
              load();
            })
            .catch(errorCaught => {
              console.log(errorCaught);
              error("An error occurred while deleting the file");
            });
        },
        restore: null,
        patch: null,
        load: (source, load, error, progress, abort, headers) => {
          const cancelToken = CancelToken.source();
          if (source) {
            axiosAPI
              .get(
                `/files/filepath?` +
                  qs.stringify({
                    filepath: `${source}`,
                  }),
                {
                  responseType: "blob",
                  cancelToken: cancelToken.token,
                },
              )
              .then(result => {
                load(result.data);
              })
              .catch(error => {
                abort();
                console.error(error);
              });
          }
          // Should expose an abort method so the request can be cancelled
          return {
            abort: () => {
              // User tapped cancel, abort our ongoing actions here
              cancelToken.cancel("Operation canceled by the user.");

              // Let FilePond know the request has been cancelled
              abort();
            },
          };
        },
        process: (
          fieldName,
          file,
          metadata,
          load,
          error,
          progress,
          abort,
          transfer,
          options,
        ) => {
          // fieldName is the name of the input field
          // file is the actual file object to send

          const formData = new FormData();
          formData.append(fieldName, file, file.name);
          formData.append("directoryPath", "priceLists/");
          formData.append("type", "Price List");
          formData.append("filepond", true);

          const source = CancelToken.source();

          axiosAPI
            .post(`/files`, formData, {
              onUploadProgress: function (e) {
                progress(e.lengthComputable, e.loaded, e.total);
              },
              cancelToken: source.token,
            })
            .then(result => {
              // Should call the load method when done and pass the returned server file id
              // this server file id is then used later on when reverting or restoring a file
              // so your server knows which file to return without exposing that info to the client
              // the load method accepts either a string (id) or an object
              load(result.data);
              setPriceListFileId(result.data.id);
            })
            .catch(error => {
              // Can call the error method if something is wrong, should exit after
              //error(error);
              console.log("ONERROR1", error);
            });

          // Should expose an abort method so the request can be cancelled
          return {
            abort: () => {
              // This function is entered if the user has tapped the cancel button
              source.cancel("Operation canceled by the user.");

              // Let FilePond know the request has been cancelled
              abort();
            },
          };
        },
      }}
      allowMultiple={false}
      acceptedFileTypes={[
        "text/csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ]}
      fileValidateTypeLabelExpectedTypesMap={{
        "text/csv": ".csv",
        "application/vnd.ms-excel": ".xls",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          ".xlsx",
      }}
      labelIdle="Drag & Drop your Price List or <span class='filepond--label-action'>Browse</span>"
      instantUpload={true}
      checkValidity={true}
      maxFiles={1}
    ></FilePond>
  );
};

UploadPriceList.propTypes = {
  setPriceListFileId: PropTypes.func,
  setUpload: PropTypes.func,
  className: PropTypes.string,
};

export default UploadPriceList;
