import React from "react";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import TableIcons from "App/components/Tables/TableIcons";
import hasPermission from "App/helpers/hasPermission";
import DropdownWithPermissions from "App/components/Dropdowns/DropdownWithPermissions";
const _ = require("lodash");
const moment = require("moment");

const RolesList = ({
  loading = false,
  roles = [],
  editRole,
  addRole,
  deleteRole,
  assignRoleUsers,
  assignRolePermissions,
}) => {
  return (
    <MaterialTable
      icons={TableIcons}
      title="Roles"
      columns={[
        { title: "Name", field: "name", grouping: false },
        {
          title: "Description",
          field: "description",
          sorting: false,
          filtering: false,
          grouping: false,
        },
        {
          title: "In active used by",
          sorting: true,
          filtering: false,
          grouping: false,
          render: row => {
            let activeUserCount = row?.Users.filter(
              user =>
                user?.UserRole?.expiresOn === null ||
                moment().isBefore(user?.UserRole?.expiresOn),
            ).length;
            return `${activeUserCount} user${activeUserCount !== 1 ? "s" : ""}`;
          },
        },
        {
          title: "Expired for",
          sorting: true,
          filtering: false,
          grouping: false,
          render: row => {
            let expiredUserCount = row?.Users.filter(
              user =>
                user?.UserRole?.expiresOn !== null &&
                moment().isAfter(user?.UserRole?.expiresOn),
            ).length;
            return `${expiredUserCount} user${
              expiredUserCount !== 1 ? "s" : ""
            }`;
          },
        },
        {
          title: "Associated permissions",
          sorting: true,
          filtering: false,
          grouping: false,
          render: row =>
            `${row?.Permissions.length} permission${
              row?.Permissions.length > 1 ? "s" : ""
            }`,
        },
        {
          title: "Updated",
          field: "updatedAt",
          filtering: false,
          grouping: false,
          render: row => (
            <>
              {moment.duration(moment().diff(moment(row.updatedAt))).humanize()}{" "}
              ago
              <br />
              <small>on {moment(row.updatedAt).format("DD MMM YYYY")}</small>
            </>
          ),
        },
        {
          title: "Options",
          field: "id",
          filtering: false,
          grouping: false,
          sorting: false,
          render: row => (
            <UncontrolledButtonDropdown
              size="sm"
              color="primary"
              data-testid="optionsdropdown"
            >
              <DropdownToggle color="primary" caret>
                Options
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>{row.name}</DropdownItem>
                <DropdownWithPermissions
                  permission="ROLE_EDIT"
                  callback={() => editRole(row)}
                  text="Edit"
                  testid="editrole"
                  tooltip="You do not have permission to edit roles"
                  visible={true}
                />
                <DropdownWithPermissions
                  permission="ROLE_DEL"
                  callback={() => deleteRole(row.id)}
                  text="Delete"
                  testid="deleterole"
                  tooltip="You do not have permission to delete roles"
                  disabled={row?.Users.length > 0}
                  disabledTooltip="You cannot delete a role that has users assigned to it"
                  visible={true}
                />
                <DropdownWithPermissions
                  permission="USER_ROLE_ASSIGN"
                  callback={() => {
                    let selectedUsers = [];
                    row.Users.forEach((user, i) => {
                      selectedUsers.push({
                        id: user.id,
                        expiresOn: user?.UserRole?.expiresOn || null,
                      });
                    });
                    assignRoleUsers({
                      assignedUsers: selectedUsers,
                      title: `${row.name} Role`,
                      description: row.description,
                      roleId: row.id,
                      visible: true,
                    });
                  }}
                  text="Assign Users to Role"
                  testid="assignroleusers"
                  tooltip="You do not have permission to assign roles to users"
                  visible={true}
                />
                <DropdownWithPermissions
                  permission="ROLE_EDIT"
                  callback={() => {
                    let selectedPermissions = [];
                    row.Permissions.forEach((permission, i) => {
                      selectedPermissions.push({
                        key: permission.key,
                      });
                    });
                    assignRolePermissions({
                      assignedPermissions: selectedPermissions,
                      title: `${row.name} Role`,
                      description: row.description,
                      roleId: row.id,
                      visible: true,
                    });
                  }}
                  text="Assign Permissions to Role"
                  testid="assignrolepermissions"
                  tooltip="You do not have permission to assign permissions to roles"
                  visible={true}
                />
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          ),
        },
      ]}
      data={_.cloneDeep(roles)}
      isLoading={loading}
      options={{
        sorting: true,
        filtering: false,
        grouping: false,
        selection: false,
        exportButton: true,
        exportFileName: "Roles",
        exportDelimiter: ",",
        loadingType: "overlay",
        showEmptyDataSourceMessage: true,
      }}
      localization={{
        body: {
          emptyDataSourceMessage: !loading ? "No roles" : "",
        },
      }}
      actions={(() => {
        let actions = [];
        //"isFreeAction" means it appears not when selected, its by search bar
        //has permission to delete/restore users
        if (hasPermission("ROLE_CREATE")) {
          actions.push({
            tooltip: "Add Role",
            isFreeAction: true,
            icon: TableIcons.Add,
            onClick: (evt, data) => addRole(),
          });
        }
        return actions;
      })()}
    />
  );
};

RolesList.propTypes = {
  loading: PropTypes.bool,
  roles: PropTypes.array,
  editRole: PropTypes.func,
  addRole: PropTypes.func,
  deleteRole: PropTypes.func,
  assignRoleUsers: PropTypes.func,
  assignRolePermissions: PropTypes.func,
};

export default RolesList;
