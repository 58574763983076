import React from "react";
import PropTypes from "prop-types";
import { NavItem, NavLink } from "reactstrap";
const classNames = require("classnames");

const StepNavItem = ({ thisStep, activeStep, ...props }) => (
  <NavItem>
    <NavLink
      className={classNames({ active: activeStep === thisStep })}
      style={{ opacity: activeStep >= thisStep ? 1 : 0.3 }}
    >
      {props.children}
    </NavLink>
  </NavItem>
);

StepNavItem.propTypes = {
  thisStep: PropTypes.number,
  activeStep: PropTypes.number,
};

export default StepNavItem;
