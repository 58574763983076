import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

const BackNextButtons = ({
  backVisible = true,
  backColor = "secondary",
  nextColor = "primary",
  backCallback,
  nextCallback,
  backText = "< Back",
  nextText = "Next >",
  disableNext = false,
  disableNextMessage,
  enabledNextMessage,
}) => {
  return (
    <div style={{ width: "100%", paddingBottom: "20px", float: "left" }}>
      {backVisible && (
        <Button
          color={backColor}
          onClick={backCallback}
          style={{ float: "left" }}
        >
          {backText}
        </Button>
      )}
      <div style={{ float: "right", textAlign: "right" }}>
        {disableNext && !!disableNextMessage && (
          <div className="text-warning d-inline-block mr-4">
            <div className={`d-inline-block mr-2`}>
              <FontAwesomeIcon icon={faExclamationCircle} />
            </div>
            <span>{disableNextMessage}</span>
          </div>
        )}
        {!disableNext && !!enabledNextMessage && (
          <div className="text-success d-inline-block mr-4">
            <div className={`d-inline-block mr-2`}>
              <FontAwesomeIcon icon={faCheck} />
            </div>
            <span>{enabledNextMessage}</span>
          </div>
        )}
        <Button
          color={disableNext ? "secondary" : nextColor}
          onClick={nextCallback}
          disabled={disableNext}
          outline={disableNext}
        >
          {nextText}
        </Button>
      </div>
    </div>
  );
};

BackNextButtons.propTypes = {
  backVisible: PropTypes.bool,
  backColor: PropTypes.string,
  nextColor: PropTypes.string,
  backCallback: PropTypes.func,
  nextCallback: PropTypes.func,
  backText: PropTypes.string,
  nextText: PropTypes.string,
  disableNext: PropTypes.bool,
  disableNextMessage: PropTypes.string,
  enabledNextMessage: PropTypes.string,
};

export default BackNextButtons;
