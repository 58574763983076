import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import XLSX from "xlsx";
import { Row, Col, Table, Badge } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styles from "./HeaderConfigurator.module.scss";
import commonStyles from "./PriceListImportModal.module.scss";
import SweetAlert from "react-bootstrap-sweetalert";
const _ = require("lodash");

const HeaderConfigurator = ({
  base64File,
  multipleManufacturer,
  manufacturer,
  setAssignedAllFields,
  assignedAllFields,
  data,
  setData,
  currentStep,
}) => {
  const initialFields = {
    manufacturerProductCode: {
      id: "manufacturerProductCode",
      display: "Manufacturer Product Code",
      required: true,
    },
    vendorProductCode: {
      id: "vendorProductCode",
      display: "Vendor Product Code",
      required: true,
    },
    listPrice: {
      id: "listPrice",
      display: "List Price",
      required: true,
    },
    costPrice: {
      id: "costPrice",
      display: "Cost Price",
      required: true,
    },
    linePrice: {
      id: "linePrice",
      display: "Line Price",
      required: false,
    },
    productName: {
      id: "productName",
      display: "Product Name",
      required: true,
    },
  };

  const initialHeaders = {
    unassigned: {
      id: 999999999,
      droppableId: `unassigned`,
      title: "Unassigned",
      fieldIds: [
        "manufacturerProductCode",
        "vendorProductCode",
        "listPrice",
        "costPrice",
        "linePrice",
        "productName",
        "manufacturerName",
      ],
    },
  };

  const [loading, setLoading] = useState(true);
  const [headers, setHeaders] = useState(initialHeaders);
  const [fields, setFields] = useState(initialFields);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  const [worksheetContent, setWorksheetContent] = useState([]); //without headers

  //UPDATE HEADERS ONCE WE GET A FILE
  //==========================================
  useEffect(() => {
    if (!!base64File) {
      setLoading(true);
      //Get file contents
      let workbook = XLSX.read(base64File, {
        type: "base64",
      });
      let worksheet = workbook.Sheets[workbook.SheetNames[0]];
      let worksheetJson = XLSX.utils
        .sheet_to_json(worksheet, {
          header: 1,
          blankrows: false,
        })
        .slice(0, 10000); //100000 row limit
      setWorksheetContent(worksheetJson.slice(1));

      //Get headers
      let headersFound = worksheetJson[0];
      let newHeaders = { ...initialHeaders };
      headersFound.forEach((header, i) => {
        newHeaders[`header${i}`] = {
          id: i,
          droppableId: `header${i}`,
          title: header,
          fieldIds: [],
        };
      });

      if (multipleManufacturer) {
        //console.log("Multiple manufacturer, adding to headers/fields");
        setFields({
          ...fields,
          manufacturerName: {
            id: "manufacturerName",
            display: "Manufacturer Name",
            required: true,
          },
        });
        setHeaders(newHeaders);
      } else {
        //we need to remove manufacturerName from everywhere
        //console.log("Single manufacturer, removing from headers/fields");
        //remove from fields (easy)
        let newFields = _.cloneDeep(fields);
        delete newFields.manufacturerName;
        setFields(newFields);
        //remove from headers (will have been reset to unassigned)
        newHeaders.unassigned.fieldIds = newHeaders.unassigned.fieldIds.filter(
          field => field !== "manufacturerName",
        );
        setHeaders(newHeaders);
      }

      setLoading(false);
    }
  }, [multipleManufacturer, base64File]); // eslint-disable-line

  //ADJUST IF IT IS MULTIPLE MANUFACTURERS OR NOT
  //==========================================
  useEffect(() => {}, [multipleManufacturer]); // eslint-disable-line

  //CHECK IF ALL HEADERS ALL ASSIGNED
  //==========================================
  useEffect(() => {
    if (currentStep === 2) {
      let assignedAll = true;
      const requiredFields = Object.values(fields).filter(
        field => field.required === true,
      );
      const headersToCheck = Object.values(headers).filter(
        header => header.title !== "Unassigned",
      );
      requiredFields.forEach((field, i) => {
        //check that all fields have been assigned out
        if (
          !headersToCheck.some(header => {
            return header.fieldIds.find(fieldId => fieldId === field.id);
          })
        ) {
          assignedAll = false;
        }
      });
      setAssignedAllFields(assignedAll);
    }
  }, [headers]); // eslint-disable-line

  //UPDATE DATA WITH NEW MAPPED OBJECT
  //essentially creates a big array of objects, where each object is a PriceListItem
  //==========================================
  useEffect(() => {
    if (currentStep === 2) {
      let newData = worksheetContent.map(row => {
        let newRow = {};
        Object.values(fields).forEach((field, i) => {
          newRow[field.id] = getColumnFromWorksheet(row, field.id, false);
        });
        return newRow;
      });
      setData(newData);
    }
  }, [headers]); // eslint-disable-line

  //******************************************
  //DRAGGING STUFF - WHEN YOU DROP, IT UPDATES STATE
  const onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    //get start and finish headers (where did it come from and where did it go)
    const start = headers[source.droppableId];
    const finish = headers[destination.droppableId];
    //console.log(source, destination, draggableId);

    if (start === finish) {
      //dragged around in same header, didnt move headers
      //console.log("Same start and finish header");
      const newFieldIds = Array.from(start.fieldIds);

      newFieldIds.splice(source.index, 1);
      newFieldIds.splice(destination.index, 0, draggableId);

      setHeaders({
        ...headers,
        [start.droppableId]: { ...start, fieldIds: newFieldIds },
      });
      return;
    }

    //moving from one header to another
    //console.log("Moved from one to different header");
    //remove from start column
    const newStartfieldIds = Array.from(start.fieldIds);
    newStartfieldIds.splice(source.index, 1);
    const newStart = {
      ...start,
      fieldIds: newStartfieldIds,
    };

    //add to finish column
    const newFinishfieldIds = Array.from(finish.fieldIds);
    newFinishfieldIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      fieldIds: newFinishfieldIds,
    };
    //console.log(newStart, newFinish);
    setHeaders({
      ...headers,
      [newStart.droppableId]: newStart,
      [newFinish.droppableId]: newFinish,
    });
  };
  //******************************************

  const getColumnFromWorksheet = (row, field, truncate = false) => {
    let foundHeader = Object.values(headers).find(header =>
      header.fieldIds.find(fieldId => fieldId === field),
    );
    if (foundHeader?.title === "Unassigned") return null;
    return truncate
      ? _.truncate(row[foundHeader?.id], { length: 40 })
      : row[foundHeader?.id];
  };

  const DraggableField = ({ fieldId, index }) => {
    const { id, required, display } = fields[fieldId];
    return (
      <Draggable draggableId={id} index={index}>
        {provided => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Badge
              //color={required ? (mappedHeader ? "success" : "danger") : "secondary"}
              color={required ? "primary" : "secondary"}
              pill
              className={styles.draggableFieldBadge}
            >
              {display}
              {required && (
                <span className={styles.draggableFieldBadgeRequired}>
                  Required
                </span>
              )}
            </Badge>
          </div>
        )}
      </Draggable>
    );
  };

  if (loading) return "Loading...";

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <div>
        <h2 className={`text-default`}>
          <span className={commonStyles.sequenceNumber}>1</span>
          Assign headers
        </h2>
        <p className={commonStyles.numberAlign}>
          Assign imported column headers by dragging fields underneath them. You
          must assign all required fields. Any fields not assigned will be
          ignored on the import.
        </p>
        <div className={commonStyles.numberAlign}>
          <Row className={`mx-0`}>
            <Col lg={8} className={`pl-0`}>
              <Table className={commonStyles.paperTable}>
                <tbody>
                  {Object.keys(headers).map(headerId => {
                    if (headerId === "unassigned") return null;
                    const header = headers[headerId];
                    return (
                      <tr key={headerId}>
                        <th>
                          <em>Imported</em>
                          {header.title}
                          <div className={commonStyles.exampleContent}>
                            eg{" "}
                            {_.sampleSize(worksheetContent, 3)
                              .map(
                                example =>
                                  _.truncate(example[header.id], {
                                    length: 20,
                                  }) || `(empty)`,
                              )
                              .join(", ")}
                          </div>
                        </th>
                        <td className={styles.droppable}>
                          <Droppable droppableId={header.droppableId}>
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={styles.droppableZone}
                              >
                                {header.fieldIds.map((fieldId, index) => {
                                  return (
                                    <DraggableField
                                      fieldId={fieldId}
                                      index={index}
                                      key={index}
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </Droppable>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
            <Col lg={4}>
              <div className={styles.optionHolder}>
                <div className={`w-100`}>
                  <Droppable droppableId={`unassigned`}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={styles.droppableZone}
                      >
                        {headers["unassigned"].fieldIds.map(
                          (fieldId, index) => {
                            return (
                              <DraggableField
                                fieldId={fieldId}
                                key={index}
                                index={index}
                              />
                            );
                          },
                        )}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <h2 className={`mb-0 text-default`}>
          <span className={commonStyles.sequenceNumber}>2</span>
          Preview
        </h2>
        <p className={`text-default ${commonStyles.numberAlign}`}>
          Shows a random selection of rows for example purposes to check header
          mapping.
        </p>
        <div className={commonStyles.numberAlign}>
          <Table className={styles.previewTable}>
            <tbody>
              <tr>
                <th>Manufacturer</th>
                <th>Manufacturer Product Code</th>
                <th>Product Name</th>
                <th>Vendor Product Code</th>
                <th>Cost Price</th>
                <th>List Price</th>
                <th>Line Price</th>
              </tr>
              {_.sampleSize(worksheetContent, 5).map((row, index) => (
                <tr key={index}>
                  <td>
                    {multipleManufacturer
                      ? getColumnFromWorksheet(row, "manufacturerName", true)
                      : manufacturer?.name}
                  </td>
                  <td>
                    {getColumnFromWorksheet(
                      row,
                      "manufacturerProductCode",
                      true,
                    )}
                  </td>
                  <td>{getColumnFromWorksheet(row, "productName", true)}</td>
                  <td>
                    {getColumnFromWorksheet(row, "vendorProductCode", true)}
                  </td>
                  <td>{getColumnFromWorksheet(row, "costPrice", true)}</td>
                  <td>{getColumnFromWorksheet(row, "listPrice", true)}</td>
                  <td>{getColumnFromWorksheet(row, "linePrice", true)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </DragDropContext>
  );
};

HeaderConfigurator.propTypes = {
  base64File: PropTypes.string,
  multipleManufacturer: PropTypes.bool,
  manufacturer: PropTypes.object,
  setAssignedAllFields: PropTypes.func,
  assignedAllFields: PropTypes.bool,
  data: PropTypes.array,
  setData: PropTypes.func,
  currentStep: PropTypes.number,
};

export default HeaderConfigurator;
