import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import VersionDropdown from "App/components/Dropdowns/VersionDropdown";
import {
  Button,
  Row,
  Col,
  Container,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import styles from "./JobSupplyingTab.module.scss";
import IdleTimer from "react-idle-timer";
import SupplyingTableContainer from "App/components/Tables/SupplyingTable/SupplyingTable.container";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import VersionModalContainer from "App/components/Modals/VersionModal/VersionModal.container";
import ListModalContainer from "App/components/Modals/ListModal/ListModal.container";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlug } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
const moment = require("moment");

const JobSupplyingTab = ({ job, isFocused = false }) => {
  //*************IS ACTIVE***************
  const idleTimer = useRef();
  const thisTab = useRef();
  const toastInactive = useRef(null);
  const [isActive, setIsActive] = useState(true); //whether user is actively using

  //*************ERRORS***************
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  //*************VERSIONS***************
  const [deleteVersionWarning, setDeleteVersionWarning] = useState(null);
  const [versionModal, setVersionModal] = useState({
    visible: false,
    jobId: job.id,
    id: null,
    edit: false,
    clone: false,
  });
  const [versions, setVersions] = useState([]);
  const [version, setVersion] = useState(
    job?.Versions?.find(v => v.isCurrent) || null,
  );
  const [versionLoading, setVersionLoading] = useState(true);

  //*************LISTS***************
  const [deleteListWarning, setDeleteListWarning] = useState(null);
  const [listModal, setListModal] = useState({
    visible: false,
    versionId: version?.id,
    id: null,
    edit: false,
    clone: false,
  });
  const [, setListLoading] = useState(true);
  const [lists, setLists] = useState([]);
  const [list, setList] = useState(null);

  const loadVersions = () => {
    setVersionLoading(true);
    axiosAPI
      .get(`/jobs/${job.id}/versions`)
      .then(result => {
        setVersions(result.data);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => setVersionLoading(false));
  };

  const loadLists = id => {
    setListLoading(true);
    axiosAPI
      .get(`/versions/${id}/lists`)
      .then(result => {
        result.data?.length > 0 ? setLists(result.data) : setLists(null);
        result.data?.length > 0 ? setList(result.data[0]) : setList(null);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => setListLoading(false));
  };

  const setCurrentVersion = versionId => {
    setVersionLoading(true);
    axiosAPI
      .patch(`/versions/${versionId}/makeCurrent`)
      .then(result => {
        //update versions list
        setVersions(
          versions.map(v =>
            v.id === versionId ? result.data : { ...v, isCurrent: false },
          ),
        );
        //update currently selected version
        setVersion(result.data);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => setVersionLoading(false));
  };

  const setListActive = (listId, active) => {
    setListLoading(true);
    axiosAPI
      .patch(`/lists/${listId}/${active ? "makeActive" : "makeInactive"}`)
      .then(result => {
        //update lists
        setLists(lists.map(l => (l.id === listId ? result.data : l)));
        //update currently selected list
        setList(result.data);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => setListLoading(false));
  };

  const deleteVersion = versionId => {
    setVersionLoading(true);
    axiosAPI
      .delete(`/versions/${versionId}`)
      .then(result => {
        //remove it from array
        setVersions(versions.filter(v => v.id !== versionId));
        //set next highest version number
        setVersion(
          versions.reduce((prev, current) =>
            prev.versionNumber > current.versionNumber ? prev : current,
          ),
        );
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => setVersionLoading(false));
  };

  const deleteList = listId => {
    setListLoading(true);
    axiosAPI
      .delete(`/lists/${listId}`)
      .then(result => {
        //remove it from array
        setLists(lists?.filter(l => l.id !== listId));
        //go to first list
        setList(lists?.length > 1 ? lists[0] : null);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => setListLoading(false));
  };

  useEffect(() => {
    //preload options / when becomes active
    if (isActive && isFocused) {
      loadVersions();
      toast.dismiss(toastInactive.current);
    } else {
      if (isFocused) {
        toastInactive.current = toast.info(
          <div className="toastText">
            <FontAwesomeIcon icon={faPlug} />
            Quoting updates disconnected due to inactivity. Move your mouse
            cursor to reconnect.
          </div>,
          {
            position: "bottom-right",
            autoClose: false,
            closeButton: false,
          },
        );
      } else {
        toast.dismiss(toastInactive.current);
      }
    }
  }, [isActive, isFocused]); // eslint-disable-line

  useEffect(() => {
    if (version?.id) loadLists(version.id);
  }, [version]); // eslint-disable-line

  return (
    <Container fluid className="py-md-4" ref={thisTab}>
      <IdleTimer
        ref={idleTimer.current}
        element={thisTab.current}
        onActive={e => setIsActive(true)}
        onIdle={e => setIsActive(false)}
        onAction={e => {}}
        debounce={250}
        startOnLoad
        timeout={1000 * 30}
      />
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        title="Are you sure?"
        onConfirm={() => {
          setDeleteVersionWarning(null);
          deleteVersion(version?.id);
        }}
        onCancel={() => setDeleteVersionWarning(null)}
        focusCancelBtn
        show={!!deleteVersionWarning}
      >
        Deleting will remove the version, all its lists and items within the
        lists.
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        title="Are you sure?"
        onConfirm={() => {
          setDeleteListWarning(null);
          deleteList(list?.id);
        }}
        onCancel={() => setDeleteListWarning(null)}
        focusCancelBtn
        show={!!deleteListWarning}
      >
        Deleting will remove the list and all items within the list.
      </SweetAlert>
      <VersionModalContainer
        {...versionModal}
        setVisible={value =>
          setVersionModal({ ...versionModal, visible: value })
        }
        done={value => {
          loadVersions();
          setVersion(value);
        }}
      />
      <ListModalContainer
        {...listModal}
        setVisible={value => setListModal({ ...listModal, visible: value })}
        done={value => {
          loadLists(version?.id);
          setList(value);
        }}
      />
      <>
        <Row className="pb-2">
          <Col xs={12} lg={6}>
            <h4
              className="float-left"
              style={{
                paddingTop: "0.5rem",
                paddingRight: "1rem",
                width: "120px",
              }}
            >
              Versions{" "}
              {versions && versions?.length > 0 && (
                <span className="text-muted small">
                  &times; {versions?.length}
                </span>
              )}
            </h4>
            <VersionDropdown
              className="col pl-0"
              versions={versions}
              loading={versionLoading}
              value={version}
              setValue={version => setVersion(version)}
            />
          </Col>
          <Col xs={12} lg={6}>
            <div className="float-left">
              {version?.memo && (
                <span className="text-info small d-block">
                  <FontAwesomeIcon icon={faInfoCircle} /> Internal memo:{" "}
                  {version?.memo}
                </span>
              )}
              <span className="text-muted small d-block">
                Version created{" "}
                {moment
                  .duration(moment().diff(moment(version?.createdAt)))
                  .humanize()}{" "}
                ago
              </span>
            </div>
            <div className={styles.buttonPair}>
              <UncontrolledButtonDropdown className="mr-2">
                <DropdownToggle
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    height: "38px",
                    color: "#000",
                  }}
                  caret
                  color="warning"
                >
                  Edit
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>
                    v{version?.versionNumber}: {version?.name}
                  </DropdownItem>
                  <DropdownItem
                    disabled={version?.isCurrent}
                    onClick={() => setCurrentVersion(version?.id)}
                  >
                    Make Current
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      setVersionModal({
                        edit: true,
                        clone: false,
                        id: version?.id,
                        jobId: job?.id,
                        visible: true,
                      })
                    }
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      setVersionModal({
                        edit: false,
                        clone: true,
                        id: version?.id,
                        jobId: job?.id,
                        visible: true,
                      })
                    }
                  >
                    Clone
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    disabled={versions?.length === 1 || version?.isCurrent}
                    onClick={() => setDeleteVersionWarning(true)}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              <Button
                color="success"
                style={{ paddingTop: 0, paddingBottom: 0, height: "38px" }}
                onClick={() =>
                  setVersionModal({
                    edit: false,
                    clone: false,
                    id: null,
                    jobId: job?.id,
                    visible: true,
                  })
                }
              >
                New
              </Button>
            </div>
          </Col>
        </Row>
        <Row className={styles.listNavPillsContainer}>
          <Col xs={12} lg={10}>
            <h4
              className="float-left"
              style={{ paddingTop: "0.6rem", width: "120px" }}
            >
              Lists{" "}
              {lists && lists?.length > 0 && (
                <span className="text-muted small">
                  &times; {lists?.length}
                </span>
              )}
            </h4>
            <div className={styles.listNavPillsFlex}>
              {lists && lists?.length > 0 ? (
                <Nav pills className={`${styles.listNavPills} col-xs-12`}>
                  {lists?.map(listMap => (
                    <NavItem>
                      <NavLink
                        href="#"
                        onClick={() => setList(listMap)}
                        className={listMap?.id === list?.id ? "active" : null}
                      >
                        {listMap?.name}
                        {!listMap?.isActive && (
                          <small className="ml-2">INACTIVE</small>
                        )}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              ) : (
                <p className="pb-0 mb-0" style={{ paddingTop: "0.6rem" }}>
                  No lists found
                </p>
              )}
            </div>
          </Col>
          <Col xs={12} lg={2}>
            {version && (
              <div className={styles.buttonPair}>
                {lists && lists?.length > 0 && list && (
                  <UncontrolledButtonDropdown className="mr-2">
                    <DropdownToggle
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        height: "38px",
                        color: "#000",
                      }}
                      caret
                      color="warning"
                    >
                      Edit
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>List: {list?.name}</DropdownItem>
                      <DropdownItem
                        onClick={() => setListActive(list?.id, !list?.isActive)}
                      >
                        Make {list?.isActive ? "Inactive" : "Active"}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          setListModal({
                            edit: true,
                            clone: false,
                            id: list?.id,
                            versionId: version?.id,
                            visible: true,
                          })
                        }
                      >
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          setListModal({
                            edit: false,
                            clone: true,
                            id: list?.id,
                            versionId: version?.id,
                            visible: true,
                          })
                        }
                      >
                        Clone
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={() => setDeleteListWarning(true)}>
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                )}
                <Button
                  color="success"
                  style={{ paddingTop: 0, paddingBottom: 0, height: "38px" }}
                  onClick={() =>
                    setListModal({
                      edit: false,
                      clone: false,
                      id: null,
                      versionId: version?.id,
                      visible: true,
                    })
                  }
                >
                  New
                </Button>
              </div>
            )}
          </Col>
        </Row>
        {list && (
          <DndProvider backend={HTML5Backend}>
            <SupplyingTableContainer
              list={list}
              isActive={isActive}
              depotId={job?.DepotId}
            />
          </DndProvider>
        )}
      </>
    </Container>
  );
};

JobSupplyingTab.propTypes = {
  job: PropTypes.object,
};

export default JobSupplyingTab;
