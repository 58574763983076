import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ListModal from "./ListModal";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";

const ListModalContainer = ({
  id,
  versionId,
  visible = false,
  setVisible,
  edit = false,
  clone = false,
  done,
}) => {
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  useEffect(() => {
    if (visible && id) {
      getList(id);
    }
    if (visible && (!edit || !id || !clone)) {
      setList(null);
    }
  }, [id, edit, clone, visible]); // eslint-disable-line

  const getList = id => {
    setLoading(true);
    axiosAPI
      .get(`/lists/${id}`)
      .then(result => {
        setList(result.data || null);
      })
      .catch(error => {
        setVisible(false);
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addList = async list => {
    setLoading(true);
    await axiosAPI
      .post(`/versions/${versionId}/lists`, list)
      .then(result => {
        setVisible(false);
        done(result.data);
      })
      .catch(error => {
        setErrorMessage(
          error?.errorMessage || "An error occurred while adding new list",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      })
      .finally(() => setLoading(false));
  };

  const cloneList = async listOverride => {
    setLoading(true);
    await axiosAPI
      .post(`/lists/${listOverride.id}/clone`, listOverride)
      .then(result => {
        setVisible(false);
        done(result.data);
      })
      .catch(error => {
        setErrorMessage(
          error?.errorMessage || "An error occurred while cloning list",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      })
      .finally(() => setLoading(false));
  };

  const editList = async list => {
    setLoading(true);
    await axiosAPI
      .patch(`/lists/${list.id}`, list)
      .then(result => {
        setVisible(false);
        done(result.data);
      })
      .catch(error => {
        setErrorMessage(
          error?.errorMessage || "An error occurred while editing list",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <ListModal
        visible={visible}
        list={list}
        edit={edit}
        clone={clone}
        setVisible={setVisible}
        editList={editList}
        cloneList={cloneList}
        addList={addList}
        loading={loading}
      />
    </>
  );
};

ListModalContainer.propTypes = {
  id: PropTypes.number,
  versionId: PropTypes.number,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  edit: PropTypes.bool,
  clone: PropTypes.bool,
  done: PropTypes.func,
};

export default ListModalContainer;
