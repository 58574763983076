import React, { Component } from "react";
import ProductPickerContainer from "App/components/Pickers/ProductPicker/ProductPicker.container";
import AddButton from "App/components/Tables/SortableTreeCommon/AddButton";
import { Row, Col } from "reactstrap";
import {
  changeNodeAtPath,
  getNodeAtPath,
  getFlatDataFromTree,
  getTreeFromFlatData,
  addNodeUnderParent,
  removeNodeAtPath,
} from "react-sortable-tree";
import SweetAlert from "react-bootstrap-sweetalert";
import io from "socket.io-client";
import DepotTransferTable from "./DepotTransferTable";
import debounce from "App/helpers/debounce";
import hasPermission from "App/helpers/hasPermission";
import defaultSocketOptions from "App/services/socketio";
const socket = io("/depotTransfers", defaultSocketOptions);

const getKey = node => node.id; //when you pass this into getTreeFromFlatData, it gives you each NODE, so you can take id straight from that
const getParentKey = node => node.ParentId; //when you pass this into getTreeFromFlatData, it gives you each NODE, so you can take id straight from that
const getNodeKey = ({ node }) => node.id; //getNodeKey is used by the SortableTree, and is passed an object containing the node and treeindex, so need to destructure it!
const canNodeHaveChildren = node => node.type !== 0; //ie items cannot have children

export default class DepotTransferTableContainer extends Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);

    this.debounceUpdate = debounce(() => {
      this.updateDatabase();
    }, 800);

    socket.on("logout", () => {
      socket.disconnect();
      window.location = "/login";
    });

    //initially, get data from db, then make a tree, then expand
    //we then need to update totals - db doesnt store discount, margin, or totals - it only stores tweakable fields eg qty, name, cost price
    //then retrospectively works out the discount% and margin% etc
    this.state = {
      shiftPressed: false,
      cmdPressed: false,
      ctrlPressed: false,
      depotTransfer: this.props.depotTransfer || {},
      undoHistory: [],
      redoHistory: [],
      databaseData: [], //this is our local representation of the current db state
      treeData: getTreeFromFlatData({
        //compared to databaseData, and then we tell server what to tweak ennit
        flatData: [],
        getKey: getKey, // resolve a node's key
        getParentKey: getParentKey, // resolve a node's parent's key
        rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
      }),
      showDiscountAsPercent: true,
      showMarginAsPercent: true,
      errorMessage: null,
      errorMore: null,
      enabled: true,
    };
  }

  componentDidCatch(error, info) {
    /*    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    logComponentStackToMyService(info.componentStack);*/
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      errorMessage: "Oops, we got our wires crossed...",
      errorMore:
        "Sorry, something went badly wrong. Please try refreshing the page",
    };
  }

  //undo stack: just a stack of databaseDatas
  // [ [state], [state], [state], [state], [state] ]
  //   ^ 0 is newest (now)                  ^ 5 is oldest
  //so an undo would jump to [1], and remove [0,1]
  undo = () => {
    if (this.state.undoHistory.length > 1) {
      //[0] is always the current state, so [1] would be the last state
      const changeStateTo = this.state.undoHistory[1];
      this.setState(
        {
          undoHistory: [...this.state.undoHistory].slice(2),
          redoHistory: [
            ...[this.state.undoHistory[0]],
            ...this.state.redoHistory,
          ].slice(0, 5),
        },
        () => this.updateFromFlatData(changeStateTo),
      );
    }
  };

  redo = () => {
    if (this.state.redoHistory.length > 0) {
      //[0] is always the current state, so [1] would be the last state
      const changeStateTo = this.state.redoHistory[0];
      this.setState(
        {
          redoHistory: [...this.state.redoHistory].slice(1),
          undoHistory: [
            ...[this.state.redoHistory[0]],
            ...this.state.undoHistory,
          ].slice(0, 5),
        },
        () => this.updateFromFlatData(changeStateTo),
      );
    }
  };

  handleOnTreeChange = treeData => {
    console.log("ONCHANGE TO TREE", treeData);
    this.setState({ treeData }, async () => {
      this.updateDatabase();
    });
  };

  updateTrees = items => {
    console.log("UPDATE TREES ", items);
    this.setState({
      ...(JSON.stringify(items) !== JSON.stringify(this.state.undoHistory[0])
        ? { undoHistory: [...[items], ...this.state.undoHistory].slice(0, 5) }
        : {}),
      databaseData: items,
      treeData: getTreeFromFlatData({
        flatData: items,
        getKey: getKey, // resolve a node's key
        getParentKey: getParentKey, // resolve a node's parent's key
        rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
      }),
    });
  };

  async updateDatabase() {
    const DEBUG = true;
    //only some keys we need to check compared to database to see if need to update etc
    const keysToCheck = [
      "name",
      "memo",
      "quantity",
      "type",
      "ProductId",
      "ParentId",
      "DepotTransferId",
      "sort",
      "expanded",
      "tempId",
      "tempParentId",
      "selected",
    ];

    //get data from tree, and only include properties we're interested in for comparison
    let flatData = getFlatDataFromTree({
      treeData: JSON.parse(JSON.stringify(this.state.treeData)),
      getNodeKey, // This ensures your "id" properties are exported in the path
      ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
    }).map(({ node, path }, sort) => {
      let newNode = {};
      keysToCheck.forEach((key, i) => {
        //this adds in only the keys we care about
        newNode[key] = node[key];
      });
      //there are some attributes we "calculate", eg treeParentIndex and TaxRate and DepotTransferId
      //so do these here:
      newNode = {
        ...newNode,
        DepotTransferId: this.state.depotTransfer?.id,
        id: path[path.length - 1],
        ParentId: path.length > 1 ? path[path.length - 2] : null,
        sort,
        tempId: node.tempId || null,
      };
      //console.log(newNode);
      return newNode;
    });

    let nodeOperations = {
      insertItems: [], //array of new items eg { name: 'ads', ... }
      updateItems: [], //array of { id: 12, attributes: {}}
      deleteItems: [], //array of ids to delete eg [12, 15, 18]
    };
    //go through each flatdata and see if it exists and is same in our current database
    flatData.forEach((node, index) => {
      //Go through tree and see what has changed or is new
      let focussedDatabaseNode = {};

      let foundDatabaseNode = this.state.databaseData.filter(
        x => x.id === node.id,
      );
      if (foundDatabaseNode.length > 0) {
        //if (DEBUG) console.log("found node in db", foundDatabaseNode);
        //take it out of the array (as it returns it as [{obj}]   )
        foundDatabaseNode = foundDatabaseNode[0];
        //copy into the focussedDatabaseNode only the attributes we care about
        keysToCheck.forEach((key, i) => {
          //this adds in only the keys we care about
          //to check if theyve changed etc
          focussedDatabaseNode[key] = foundDatabaseNode[key];
        });
      }

      if (Object.keys(focussedDatabaseNode).length === 0) {
        //not found on database side, so insert
        if (DEBUG) console.log("not found node in db", node);
        nodeOperations.insertItems.push(node);
      } else {
        //found on database side, check if it's equal
        if (JSON.stringify(node) === JSON.stringify(focussedDatabaseNode)) {
          //equal so do nothing
          if (DEBUG) console.log("found node in db, and is equal", node);
        } else {
          //console.log(node, focussedDatabaseNode);
          let newNode = JSON.parse(JSON.stringify(node)); //deep copy
          //different so update
          let needToUpdate = false;
          Object.keys(focussedDatabaseNode).forEach(key => {
            if (newNode[key] === focussedDatabaseNode[key]) {
              delete newNode[key];
            } else if (key !== "id") {
              needToUpdate = true;
            }
          });
          if (needToUpdate) {
            //there are some attributes left to update!
            let updateNode = {
              id: node.id,
              attributes: { ...newNode },
            };
            if (DEBUG)
              console.log(
                "found node in db, but different, ",
                node,
                updateNode,
              );

            nodeOperations.updateItems.push(updateNode);
          }
        }
      }
    });

    //Go through database and check anything to delete (ie not present in tree)
    let databaseData = JSON.parse(JSON.stringify(this.state.databaseData));
    databaseData.forEach((node, i) => {
      let treeFoundNode = flatData.filter(x => x.id === node.id); //is it in the tree?
      if (treeFoundNode.length === 0) {
        // not found in tree so need to delete
        if (DEBUG)
          console.log("orphan left on db side, not in tree, delete", node);
        nodeOperations.deleteItems.push(node.id);
      }
    });

    if (
      nodeOperations.insertItems.length > 0 ||
      nodeOperations.updateItems.length > 0 ||
      nodeOperations.deleteItems.length > 0
    ) {
      this.updateDepotTransfer(nodeOperations);
    }
  }

  handleDeleteNode = path => {
    this.setState(
      state => ({
        treeData: removeNodeAtPath({
          treeData: state.treeData,
          path,
          getNodeKey,
        }),
      }),
      async () => {
        this.updateDatabase();
      },
    );
  };

  handleInsertNode = node => {
    this.setState(
      state => ({
        treeData: addNodeUnderParent({
          treeData: state.treeData,
          parentKey: null,
          expandParent: true,
          getNodeKey,
          newNode: node,
          addAsFirstChild: false,
        }).treeData,
      }),
      async () => {
        this.updateDatabase();
      },
    );
  };

  handleRestoreName = (node, path) => {
    this.setState(
      {
        treeData: changeNodeAtPath({
          treeData: this.state.treeData,
          path,
          getNodeKey,
          newNode: { ...node, name: node.PriceListItem.Product.name },
        }),
      },
      () => this.updateDatabase(),
    );
  };

  ignoreKeyPress = target => {
    //was it on an input that isnt disabled (ie you can type in it!)
    if (target.tagName === "INPUT" && !target.disabled) return true;
    if (target.tagName === "SELECT" && !target.disabled) return true;
    //not a button (options dropdown)
    if (target.tagName === "BUTTON") return true;
    //otherwise its ok to use as a modifier
    return false;
  };

  async handleKeyDown(event) {
    const { key, target } = event;
    if (this.ignoreKeyPress(target)) return;
    if (!this.state.enabled) return;

    key === "Shift" && this.setState({ shiftPressed: true });
    if (key === "Backspace") {
      //delete anything that is selected
      this.updateFromFlatData(
        this.state.databaseData.filter(row => !row.selected),
      );
    }
    key === "Meta" && this.setState({ cmdPressed: true });
    key === "Control" && this.setState({ ctrlPressed: true });
    //select / deselect all
    if (
      (this.state.cmdPressed || this.state.ctrlPressed) &&
      key.toLowerCase() === "a"
    ) {
      event.preventDefault();
      //see if an item in depotTransfer is selected (first one is fine)
      const selectOrDeselect = !this.state.databaseData[0]?.selected;
      this.updateFromFlatData(
        this.state.databaseData.map(row => ({
          ...row,
          selected: selectOrDeselect,
        })),
      );
    }
    //invert selection
    if (
      (this.state.cmdPressed || this.state.ctrlPressed) &&
      key.toLowerCase() === "i"
    ) {
      event.preventDefault();
      this.updateFromFlatData(
        this.state.databaseData.map(row => ({
          ...row,
          selected: !row.selected,
        })),
      );
    }
    //undo
    if (
      (this.state.cmdPressed || this.state.ctrlPressed) &&
      key.toLowerCase() === "z"
    ) {
      //event.preventDefault();
      //this.undo();
    }

    //redo
    if (
      (this.state.cmdPressed || this.state.ctrlPressed) &&
      this.state.shiftPressed &&
      key.toLowerCase() === "z"
    ) {
      //event.preventDefault();
      //this.redo();
    }
  }

  async handleKeyUp(event) {
    const { key, target } = event;
    if (this.ignoreKeyPress(target)) return;

    key === "Shift" && this.setState({ shiftPressed: false });
    key === "Meta" && this.setState({ cmdPressed: false });
    key === "Control" && this.setState({ ctrlPressed: false });
  }

  handleMoveFocusToNextRow = (node, field, path) => {
    const row = getNodeAtPath({
      treeData: this.state.treeData,
      path,
      getNodeKey,
      ignoreCollapsed: true,
    });
    console.log(row);
  };

  handleSelectRow = (node, path, e) => {
    if (this.ignoreKeyPress(e.target)) return;

    //get which modifier keys pressed with the click
    let { ctrlKey, metaKey, shiftKey } = e;
    let newData = [...this.state.databaseData];

    if (!ctrlKey && !metaKey && !shiftKey) {
      //only select one at a time
      newData = newData.map(row => {
        return row.id === node.id
          ? { ...row, selected: !row.selected }
          : { ...row, selected: false };
      });
    }

    if ((ctrlKey || metaKey) && !shiftKey) {
      //ADD to the selection or REMOVE from selection if already selected
      newData = newData.map(row => {
        return row.id === node.id ? { ...row, selected: !node.selected } : row;
      });
    }

    if (shiftKey && (!ctrlKey || !metaKey)) {
      //shift block selection
      let firstSelectedNode = newData.find(node => node.selected)?.sort;
      let lastSelectedNode = [...newData]
        .reverse()
        .find(node => node.selected)?.sort;

      if (firstSelectedNode === undefined) {
        //could be 0, so !firstSelectedNode wouldnt work
        //just do a normal selection
        newData = newData.map(row => {
          return row.id === node.id
            ? { ...row, selected: !row.selected }
            : { ...row, selected: false };
        });
      } else {
        //already have something else selected so can complete the multi selection
        let thisSelectedNode = node.sort;
        //work out which one is 'earlier' in the depotTransfer
        let selectFromTo =
          firstSelectedNode < thisSelectedNode
            ? [firstSelectedNode, thisSelectedNode]
            : [thisSelectedNode, lastSelectedNode];
        newData = newData.map(row => {
          return row.sort >= selectFromTo[0] && row.sort <= selectFromTo[1]
            ? { ...row, selected: true }
            : { ...row, selected: false };
        });
      }
    }

    this.updateFromFlatData(newData);
  };

  handleUpdateNode = (node, fieldEdited, newValue, path) => {
    let newNode = { ...node };

    //storable value in the node
    newNode[fieldEdited] = newValue;

    this.setState(
      {
        treeData: changeNodeAtPath({
          treeData: this.state.treeData,
          path,
          getNodeKey,
          newNode: newNode,
        }),
      },
      this.debounceUpdate,
    );
  };

  updateDepotTransfer = operations => {
    socket.emit(
      "updateDepotTransfer",
      this.state.depotTransfer?.id,
      operations,
    );
  };

  handleSocketDepotTransferUpdates = async (
    depotTransferId,
    depotTransferUpdates,
  ) => {
    if (depotTransferId !== this.state.depotTransfer?.id) return;
    console.log("SOCKET PO UPDATES", depotTransferUpdates, this.state);

    let newDatabaseData = JSON.parse(JSON.stringify(this.state.databaseData));

    //remove any deleted ids
    depotTransferUpdates.deletedItems.forEach((id, i) => {
      newDatabaseData = newDatabaseData.filter(function (obj) {
        return obj.id !== id;
      });
    });

    //update any items
    depotTransferUpdates.updatedItems.forEach((item, i) => {
      const targetIndex = newDatabaseData.findIndex(f => f.id === item.id);
      newDatabaseData[targetIndex] = item;
    });

    //add in inserted items
    depotTransferUpdates.insertedItems.forEach((item, i) => {
      newDatabaseData.push(item);
    });

    await newDatabaseData.sort((a, b) => {
      //sort using comparison between a and b
      if (a.sort === b.sort) {
        return 0;
      } else if (a.sort > b.sort) {
        return 1;
      } else if (a.sort < b.sort) {
        return -1;
      }
      return 0;
    });

    //console.log(depotTransferUpdates, newDatabaseData);
    this.updateTrees(newDatabaseData);
  };

  joinDepotTransfer(id) {
    socket.emit("joinDepotTransfer", id);
    socket.on(
      "getDepotTransferItems",
      ({ depotTransferId, depotTransferItems }) => {
        if (depotTransferId === id) this.updateTrees(depotTransferItems);
      },
    );
  }

  leaveDepotTransfer(id) {
    id && socket.emit("leaveDepotTransfer", id);
  }

  componentDidUpdate(prevProps, prevState) {
    //see if depotTransfer Id has changed
    if (this.props.depotTransfer?.id !== prevProps.depotTransfer?.id) {
      this.setState({
        depotTransfer: this.props.depotTransfer,
      });
      this.joinDepotTransfer(this.props.depotTransfer?.id);
      this.leaveDepotTransfer(prevProps.depotTransfer?.id);
    }
    //see if isActive has changed
    if (this.props.isActive !== prevProps.isActive) {
      //has changed
      if (this.props.isActive) {
        this.joinDepotTransfer(this.props.depotTransfer?.id);
      } else {
        this.leaveDepotTransfer(prevProps.depotTransfer?.id);
      }
    }
    //see if authorised has changed
    if (
      this.props?.depotTransfer?.authorised !==
      prevProps?.depotTransfer?.authorised
    ) {
      this.enableOrDisable();
    }
  }

  enableOrDisable() {
    this.setState({
      enabled: this.props.depotTransfer.authorised
        ? hasPermission("DEPOT_TRANSFER_EDIT_ITEMS_AUTHORISED")
        : hasPermission("DEPOT_TRANSFER_EDIT_ITEMS_UNAUTHORISED"),
    });
  }

  //just a way of updating the tree from flat data, and totals, and database
  //used by select/delete operations
  updateFromFlatData(flatData) {
    this.setState(
      {
        treeData: getTreeFromFlatData({
          flatData,
          getKey, // resolve a node's key
          getParentKey, // resolve a node's parent's key
          rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
        }),
      },
      async () => {
        this.updateDatabase();
      },
    );
  }

  componentDidMount() {
    this.joinDepotTransfer(this.props.depotTransfer?.id);
    socket.on("socketError", ({ errorMessage, errorMore }) => {
      this.setState({
        errorMessage: errorMessage,
        errorMore: errorMore || "",
      });
    });

    //this is called when someone else updates the depotTransfer - ie another client -> server -> us
    socket.on("depotTransferUpdates", this.handleSocketDepotTransferUpdates);

    //set whether DT is enabled
    this.enableOrDisable();

    //monitor keys we care about
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);
  }

  /* Removing the depotTransferener before unmounting the component in order to avoid addition of multiple depotTransferener at the time revisit*/
  componentWillUnmount() {
    socket.off("getDepotTransferItems");
    this.leaveDepotTransfer(this.props.depotTransfer?.id);

    // Remove event depotTransfereners on cleanup
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
  }

  render() {
    return (
      <>
        <SweetAlert
          danger
          onConfirm={() => {
            this.setState({ errorMessage: null, errorMore: null });
          }}
          title="Error"
          show={!!this.state.errorMessage}>
          {this.state.errorMessage}
          {this.state.errorMore && (
            <>
              <br />
              <small>{this.state.errorMore}</small>
            </>
          )}
        </SweetAlert>
        {this.state.enabled && (
          <Row className="pt-2">
            <Col xs={12} lg={6} style={{ display: "flex" }}>
              <ProductPickerContainer
                specificDepotId={this.props?.depotTransfer?.FromDepotId}
                onSelect={this.handleInsertNode}
                containerId={this.props?.depotTransfer?.id}
                showManufacturerNameInProductName={true}
              />
            </Col>
            <Col xs={12} lg={6}>
              <AddButton
                node={{
                  id: Date.now(),
                  name: "Folder",
                  type: 1,
                  quantity: 1,
                  expanded: true,
                }}
                icon={"fa-folder"}
                onClick={this.handleInsertNode}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col style={{ overflowX: "scroll" }}>
            <DepotTransferTable
              treeData={this.state.treeData}
              onTreeChange={this.handleOnTreeChange}
              getNodeKey={getNodeKey}
              canNodeHaveChildren={canNodeHaveChildren}
              updateNode={this.handleUpdateNode}
              deleteNode={this.handleDeleteNode}
              restoreName={this.handleRestoreName}
              selectRow={this.handleSelectRow}
              moveFocusToNextRow={this.handleMoveFocusToNextRow}
              enabled={this.state.enabled}
            />
          </Col>
        </Row>
      </>
    );
  }
}
