import { store } from "App/redux/store";
const _ = require("lodash");

const hasPermission = permissionNeeded => {
  const state = store.getState();
  const user = _.get(state, "user.info", {});

  //1) IMPORTANT RETURNS FIRST eg if user disabled etc
  //if (user.disabled || user.deletedAt || !user.emailVerified) return false;
  //^ NEED TO FIX

  //2) Then check exceptions
  if (permissionNeeded === null) return true; //if permission is null, everyone allowed
  if (user.email === "alex.matthews@dbsolutions.com") return true;
  if (user.email === "george.veys@dbsolutions.com") return true;

  //Get the users Permissions, and Roles (which is an array of roles, each of which has permissions)
  const { Permissions = [], Roles = [] } = user;
  const userPermissions = [];

  Permissions.forEach((permission, i) => {
    if (
      permission.UserPermission.expiresOn === null ||
      permission.UserPermission.expiresOn >= new Date()
    ) {
      userPermissions.push(permission.key);
    }
  });

  Roles.forEach((role, i) => {
    if (
      role.UserRole.expiresOn === null ||
      role.UserRole.expiresOn >= new Date()
    ) {
      role.Permissions.forEach((permission, i) => {
        userPermissions.push(permission.key);
      });
    }
  });

  return userPermissions.find(permission => permission === permissionNeeded);
};

export default hasPermission;
