import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Table } from "reactstrap";
import commonStyles from "./PriceListImportModal.module.scss";
import axiosAPI from "App/services/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationCircle,
  faTimes,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./ProductConfigurator.module.scss";
import currencyFormatter from "App/helpers/currencyFormatter";
import SweetAlert from "react-bootstrap-sweetalert";
import ManufacturerSearchProductsDropdown from "App/components/Dropdowns/ManufacturerSearchProductsDropdown";
const _ = require("lodash");
const stringToNumber = require("App/helpers/stringToNumber").default;
const classNames = require("classnames");

const ProductConfigurator = ({
  data, //all data from csv/xlsx, mapped to headers by header configurator
  setData,
  currentStep,
  multipleManufacturer, //true if multiple manufacturers in the price list
  manufacturer, //if single manufacturer price list, this will be that manufacturer
  manufacturerList, //if multiple manufacturer price list, this will contain the mappings / create new
  setProductConfigurationHasIssues,
}) => {
  const [loaded] = useState(false); //when mounted, will be false, when focussed, will become true after loaded
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  const pageSize = 10;
  const [pagination, setPagination] = useState({
    success: {
      visible: false,
      search: "",
      currentPage: 0, //pages 0 indexed to make array.slice easier
      totalCount: 0,
    },
    warning: {
      visible: true,
      search: "",
      currentPage: 0,
      totalCount: 0,
    },
    error: {
      visible: true,
      search: "",
      currentPage: 0,
      totalCount: 0,
    },
  });

  //WHEN ENTER SCREEN, PROCESS DATA
  useEffect(() => {
    if (currentStep === 4 && !loaded) {
      //currentStep just changed and moved onto this screen, and never been loaded before (as dont want to come BACK to this screen and lose all data)
      findProductsFromData();
    }
  }, [currentStep]); // eslint-disable-line

  //UPDATE FEEDBACK IF OK TO MOVE ON
  useEffect(() => {
    let issues = false;

    //check if any products have selected "manually specify" but not actually been chosen
    const missingManualSpecifiedProduct = !!data.find(row => {
      if (row.import && !row.autoCreateProduct && !row.ProductId) return true;
      return false;
    });

    //check if there are actually products to import!
    const noProductsToImport = data.filter(row => row.import).length === 0;

    if (noProductsToImport || missingManualSpecifiedProduct) issues = true;

    setProductConfigurationHasIssues(issues);
  }, [data]); // eslint-disable-line

  const getManufacturerProducts = (manufacturerKey, productCodeList = []) => {
    return axiosAPI
      .post(`/manufacturers/${manufacturerKey}/products`, {
        productCodeList,
      })
      .then(result => {
        return result.data;
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting products, please retry",
        );
        console.error(error);
        return [];
      });
  };

  const setImport = (product, importRow) => {
    let newData = data.map(row => {
      if (row !== product) return row;
      return {
        ...row,
        import: importRow,
      };
    });
    setData(newData);
  };

  //Gets all products from data given to it
  //Uses manufacturerList to go through and get a manufacturer's products
  //if manufacturer set to auto create, then obviously it doesnt exist, so dont get products
  const findProductsFromData = async () => {
    setLoading(true);
    let newData; //this is the updated data
    let successCount = 0,
      warningCount = 0,
      errorCount = 0;
    /* ****************************************************************************************************************************************************
     * A) FIRST STEP is to give every product a manufacturer (either the mapped one if multiple, or the single one)
     *               from this, we can derive a list of manufacturers and product codes to lookup
     * **************************************************************************************************************************************************** */
    let manufacturerProductLookupList = {}; //this is list of manufacturers to lookup with db. if single manufacturer, obviously this will only be 1 entry

    if (multipleManufacturer) {
      //MULTIPLE MANUFACTURER
      //1) Go through all products, and assign manufacturerKeys to the products
      newData = await data.map(product => {
        let manufacturer = manufacturerList.find(
          manufacturer =>
            manufacturer.manufacturerName === product.manufacturerName,
        );
        if (manufacturer.existsInDatabase) {
          //1) Create key in manufacturerProductLookupList if doesnt exist
          if (!(manufacturer.database.key in manufacturerProductLookupList)) {
            manufacturerProductLookupList[manufacturer.database.key] = [];
          }
          //2) Add to the lookup list
          manufacturerProductLookupList[manufacturer.database.key].push(
            product.manufacturerProductCode,
          );
        }
        return {
          ...product,
          duplicateCount: 0, //exact match in every way
          similarCount: 0, //same manufacturerProductCode, but different products
          foundInDatabase: false,
          autoCreateProduct: true,
          ManufacturerExistsInDatabase: manufacturer.existsInDatabase,
          ManufacturerId: manufacturer.existsInDatabase
            ? manufacturer.database.id
            : null,
          ManufacturerKey: manufacturer.existsInDatabase
            ? manufacturer.database.key
            : manufacturer.key,
          Manufacturer: manufacturer.existsInDatabase
            ? manufacturer.database
            : null,
        };
      });
    } else {
      //SINGLE MANUFACTURER
      //Simply add the manufacturer to every product
      newData = await data.map(product => {
        //1) Create key in manufacturerProductLookupList if doesnt exist
        if (!(manufacturer.key in manufacturerProductLookupList)) {
          manufacturerProductLookupList[manufacturer.key] = [];
        }
        //2) Add to the lookup list
        manufacturerProductLookupList[manufacturer.key].push(
          _.toString(product.manufacturerProductCode).trim(),
        );

        return {
          ...product,
          duplicateCount: 0, //exact match in every way
          similarCount: 0, //same manufacturerProductCode, but different products
          foundInDatabase: false,
          autoCreateProduct: true,
          ManufacturerExistsInDatabase: true,
          ManufacturerId: manufacturer.id,
          ManufacturerKey: manufacturer.key,
          Manufacturer: manufacturer,
        };
      });
    }

    /* ****************************************************************************************************************************************************
     * B) NEXT STEP is to lookup all these manufacturers and their products, and update our manufacturerProductLookupList with that
     *               from this, we can derive our final CSV/XLSX equivalent
     * **************************************************************************************************************************************************** */
    //get all manufacturers as an array
    for await (const manufacturer of Object.keys(
      manufacturerProductLookupList,
    )) {
      //for each manufacturer
      const productsToLookup = manufacturerProductLookupList[manufacturer];
      const productsFromDatabase = await getManufacturerProducts(
        //then get the product info from db, and product groups available
        _.toString(manufacturer).trim(),
        productsToLookup,
      );

      //console.log("BEFORE", manufacturer, newData);
      newData = newData.map(row => {
        //find the product in the data from the database
        let foundProduct = productsFromDatabase.find(product => {
          return (
            row.manufacturerProductCode === product.manufacturerProductCode &&
            row.ManufacturerKey === product.Manufacturer.key
          );
        });

        if (foundProduct) {
          return {
            ...row,
            autoCreateProduct: false,
            foundInDatabase: true,
            ProductId: foundProduct.id,
          };
        } else {
          return row;
        }
      });

      //console.log("AFTER", manufacturer, newData);
    }

    /* ****************************************************************************************************************************************************
     * C) FINALLY is to check for any problems with any products - eg missing product codes, prices etc - therefore cant import
     *
     * **************************************************************************************************************************************************** */
    newData = newData.map((product, index) => {
      let newProduct = { ...product };
      let errors = [];
      let warnings = [];

      //--------------------------------------------------------------------
      //CLEAN UP DATA
      //remove everything except numbers and . from prices
      newProduct.costPrice = stringToNumber(newProduct.costPrice);
      newProduct.listPrice = stringToNumber(newProduct.listPrice);
      newProduct.linePrice = stringToNumber(newProduct.linePrice);

      //--------------------------------------------------------------------
      //CHECK FOR DUPLICATE PRODUCTS (exact match in every way)
      let exactDuplicateCount = newData.filter(
        row =>
          product.manufacturerProductCode === row.manufacturerProductCode &&
          JSON.stringify(row).toLowerCase() ===
            JSON.stringify(product).toLowerCase(),
      ).length;
      //we can import exact duplicates, they will just need filtering out after this .map() function is complete
      newProduct.duplicateCount = exactDuplicateCount;
      if (exactDuplicateCount > 1) {
        //there will always be at least 1 because of itself!
        warnings.push(
          `${exactDuplicateCount} identical duplicate products found for manufacturer product code ${_.toString(
            product.manufacturerProductCode,
          ).trim()}, importing only 1`,
        );
      }

      //--------------------------------------------------------------------
      //CHECK FOR SIMILAR PRODUCTS (same manufacturerProductCode, but different product :( boo!)
      let similarDuplicateCount = newData.filter(
        row =>
          product.manufacturerProductCode === row.manufacturerProductCode &&
          JSON.stringify(row).toLowerCase() !==
            JSON.stringify(product).toLowerCase(),
      ).length;
      //we cannot import similar products, because we dont know which one is the 'correct' one as they all share the same manufacturer product code
      newProduct.similarCount = similarDuplicateCount;
      if (similarDuplicateCount > 0) {
        errors.push(
          `${
            similarDuplicateCount + 1
          } different products found for manufacturer product code ${_.toString(
            product.manufacturerProductCode,
          ).trim()}`,
        );
      }

      //--------------------------------------------------------------------
      //CHECK FOR ERRORS

      //check for missing or invalid values
      if (!_.toString(newProduct.manufacturerProductCode).trim())
        errors.push("missing manufacturer product code");
      if (!_.toString(newProduct.vendorProductCode).trim())
        errors.push("missing vendor product code");
      if (!_.toString(newProduct.productName).trim())
        errors.push("missing product name");
      if (!_.toString(newProduct.costPrice).trim())
        errors.push("missing cost price");
      if (!_.toString(newProduct.listPrice).trim())
        errors.push("missing list price");
      if (!_.toString(newProduct.ManufacturerKey).trim())
        errors.push("missing manufacturer");
      if (newProduct.costPrice && !_.isNumber(newProduct.costPrice))
        errors.push("cost price is not a number");
      if (newProduct.listPrice && !_.isNumber(newProduct.listPrice))
        errors.push("list price is not a number");
      if (newProduct.linePrice && !_.isNumber(newProduct.linePrice))
        errors.push("line price is not a number");

      //update totals count
      if (errors.length > 0) {
        errorCount++;
      } else {
        if (newProduct.foundInDatabase) {
          successCount++;
        } else {
          warningCount++;
        }
      }

      return { ...newProduct, errors, warnings, import: !errors.length };
    });

    //Remove any exact duplicates (ignoring case sensitivity)
    newData = _.uniqBy(newData, row => JSON.stringify(row).toLowerCase());

    console.log(newData);
    //finally, sort by manufacturer
    newData = await _.orderBy(
      newData,
      ["manufacturerName", "manufacturerProductCode"],
      ["ASC", "ASC"],
    );

    setPagination({
      success: { ...pagination.success, totalCount: successCount },
      warning: { ...pagination.warning, totalCount: warningCount },
      error: { ...pagination.error, totalCount: errorCount },
    });
    setData(newData);
    setLoading(false);
  };

  const TablePagination = ({ group }) => {
    if (pagination[group].totalCount === 0) return null;
    const totalNumberPages = Math.ceil(
      pagination[group]?.totalCount / pageSize,
    );
    return (
      <div className={styles.pagination}>
        {/*<input
          type="text"
          onChange={e => {
            setPagination({
              ...pagination,
              [group]: {
                ...pagination[group],
                search: e.target.value
              }
            });
          }}
          placeholder="Search"
          value={pagination[group].search}
          className={styles.search}
        />*/}
        <Button
          size="sm"
          color="secondary"
          disabled={pagination[group].currentPage === 0}
          onClick={() =>
            setPagination({
              ...pagination,
              [group]: {
                ...pagination[group],
                currentPage: parseInt(pagination[group].currentPage) - 1,
              },
            })
          }
        >
          Previous
        </Button>
        <select
          value={pagination[group].currentPage}
          onChange={e =>
            setPagination({
              ...pagination,
              [group]: {
                ...pagination[group],
                currentPage: parseInt(e.target.value),
              },
            })
          }
        >
          {_.range(totalNumberPages).map(page => (
            <option key={page} value={page}>
              Page {page + 1}
            </option>
          ))}
        </select>
        <Button
          size="sm"
          color="secondary"
          disabled={pagination[group].currentPage === totalNumberPages - 1}
          onClick={() => {
            setPagination({
              ...pagination,
              [group]: {
                ...pagination[group],
                currentPage: parseInt(pagination[group].currentPage) + 1,
              },
            });
          }}
        >
          Next
        </Button>
      </div>
    );
  };

  const SelectAllOrNone = ({ group }) => {
    if (pagination[group].totalCount === 0) return null;
    return (
      <Button
        size="sm"
        className={styles.selectAllOrNone}
        color="secondary"
        onClick={() =>
          setData(
            data.map(row => {
              if (!typeFilter(row, group)) return row; //return rows we're not interested in
              return {
                ...row,
                import: !row.import,
              };
            }),
          )
        }
      >
        Invert Selection
      </Button>
    );
  };

  const ShowHideHeader = ({ group, color = "white" }) => {
    //simply makes table visible or not with an arrow thing
    if (pagination[group].totalCount === 0) return null;
    return (
      <div
        className={styles.showHideHeader}
        onClick={() =>
          setPagination({
            ...pagination,
            [group]: {
              ...pagination[group],
              visible: !pagination[group].visible,
            },
          })
        }
      >
        {pagination[group]?.visible ? (
          <FontAwesomeIcon
            icon={faAngleUp}
            style={{ marginRight: "10px" }}
            color={color}
          />
        ) : (
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{ marginRight: "10px" }}
            color={color}
          />
        )}
      </div>
    );
  };

  const typeFilter = (product, type) => {
    if (
      type === "success" &&
      (!product.foundInDatabase || product.errors?.length > 0)
    )
      return false;

    //if asking for only Warning rows, filter out anything else
    if (
      type === "warning" &&
      (product.foundInDatabase || product.errors?.length !== 0)
    )
      return false;

    //if asking for only Error rows, filter out anything else
    if (type === "error" && product.errors?.length === 0) return false;

    return true;
  };

  const setProductAutoCreate = (product, autoCreate) => {
    setData(
      data.map(row => {
        if (row !== product) return row;
        let newRow = { ...row, autoCreateProduct: autoCreate };
        if (!autoCreate) {
          delete newRow.Product;
          delete newRow.ProductId;
        }
        return newRow;
      }),
    );
  };

  const setProductManualSpecify = (productToUpdate, manualProduct) => {
    setData(
      data.map(row => {
        if (row !== productToUpdate) return row;
        return {
          ...row,
          Product: manualProduct,
          ProductId: manualProduct?.id || null,
        };
      }),
    );
  };

  const ProductOptions = ({ product }) => {
    const { ManufacturerKey, manufacturerProductCode } = product;
    if (!product.import) return null;
    return (
      <>
        <div className={`custom-control custom-radio`}>
          <input
            className="custom-control-input"
            id={`${ManufacturerKey}${manufacturerProductCode}_auto`}
            name={`${ManufacturerKey}${manufacturerProductCode}_autoOrManual`}
            type="radio"
            checked={product.autoCreateProduct}
            onChange={() => setProductAutoCreate(product, true)}
          />
          <label
            className="custom-control-label"
            htmlFor={`${ManufacturerKey}${manufacturerProductCode}_auto`}
          >
            Automatically create new product
          </label>
        </div>
        <div className={`custom-control custom-radio`}>
          <input
            className="custom-control-input"
            id={`${ManufacturerKey}${manufacturerProductCode}_manual`}
            name={`${ManufacturerKey}${manufacturerProductCode}_autoOrManual`}
            type="radio"
            checked={!product.autoCreateProduct}
            onChange={() => setProductAutoCreate(product, false)}
          />
          <label
            className="custom-control-label"
            htmlFor={`${ManufacturerKey}${manufacturerProductCode}_manual`}
          >
            Manually specify existing product
          </label>
        </div>
        {!product.autoCreateProduct && (
          <ManufacturerSearchProductsDropdown
            preload={false}
            className={``}
            value={product?.Product}
            setValue={value => setProductManualSpecify(product, value)}
            manufacturerKey={product.ManufacturerKey}
          />
        )}
      </>
    );
  };

  const RenderRowGroup = type => {
    if (pagination[type].totalCount === 0) return null;

    //get our filtered result set of eg just all success, or danger, or error rows
    const filteredData = data.filter(product => typeFilter(product, type));

    return (
      <Table className={commonStyles.paperTable}>
        <tbody>
          <tr>
            <th className={styles.importCheckboxTd}>Import?</th>
            <th>Manufacturer</th>
            <th>Imported Product</th>
            <th>Imported Pricing</th>
            <th className={styles.optionsTd}>Status</th>
          </tr>
          {filteredData
            /*.filter(product => {
              return (
                product.productName
                  .toLowerCase()
                  .indexOf(pagination[type].search?.toLowerCase()) > -1 ||
                product.manufacturerProductCode
                  .toLowerCase()
                  .indexOf(pagination[type].search?.toLowerCase()) > -1
              );
            })*/
            .slice(
              pagination[type].currentPage * pageSize,
              pagination[type].currentPage * pageSize + pageSize,
            )
            .map((product, index) => (
              <tr
                key={`${type}_${index}`}
                className={product.import ? "" : styles.noImport}
              >
                <td className={styles.importCheckboxTd}>
                  <div
                    className={`custom-control custom-checkbox ${styles.importCheckbox}`}
                  >
                    <input
                      className={"form-control custom-control-input"}
                      id={`${type}_${index}`}
                      checked={product.import}
                      disabled={product.errors?.length > 0}
                      type="checkbox"
                      onChange={() => setImport(product, !product.import)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`${type}_${index}`}
                    ></label>
                  </div>
                </td>
                <td>
                  {product.ManufacturerExistsInDatabase ? (
                    product.Manufacturer.name
                  ) : (
                    <>
                      {product.manufacturerName}
                      <br />
                      <em>Auto created</em>
                    </>
                  )}
                </td>
                <td className={styles.productColumn}>
                  <strong>{product.manufacturerProductCode}</strong>
                  <br />
                  <span className={styles.productName}>
                    {product.productName}
                  </span>
                </td>
                <td>
                  <strong>Cost Price:</strong>
                  {currencyFormatter(product.costPrice)}
                  <br />
                  <strong>List Price:</strong>
                  {currencyFormatter(product.listPrice)}
                  {product.linePrice && (
                    <>
                      <br />
                      <strong>Line Price</strong>
                      {currencyFormatter(product.linePrice)}
                    </>
                  )}
                </td>
                <td className={styles.optionsTd}>
                  {product.errors?.length > 0 ? (
                    <div className={`ml-1`}>
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{ marginRight: "10px", verticalAlign: "top" }}
                        color={"red"}
                      />
                      <span className={styles.errorList}>
                        Cannot import: {product.errors.join(", ")}
                      </span>
                    </div>
                  ) : (
                    <>
                      {product.foundInDatabase ? (
                        <div className={`ml-1 text-muted`}>
                          <FontAwesomeIcon
                            icon={faCheck}
                            style={{ marginRight: "10px" }}
                            color={"green"}
                          />
                          Found in database
                        </div>
                      ) : (
                        <div className={`ml-1 text-muted`}>
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            style={{ marginRight: "10px" }}
                            color={"orange"}
                          />
                          Not found in database
                        </div>
                      )}
                      {product.warnings?.length > 0 &&
                        product.warnings.map((warning, warningIndex) => (
                          <div
                            className={`ml-1 text-muted`}
                            key={`${type}_${index}_warning_${warningIndex}`}
                          >
                            <FontAwesomeIcon
                              icon={faExclamationCircle}
                              style={{ marginRight: "10px" }}
                              color={"orange"}
                            />
                            {warning}
                          </div>
                        ))}
                      {product.ManufacturerExistsInDatabase ? (
                        !product.foundInDatabase && (
                          <ProductOptions product={product} />
                        )
                      ) : (
                        <span style={{ marginLeft: "25px" }}>
                          will be auto created with new manufacturer
                        </span>
                      )}
                    </>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  };

  if (loading) return "Loading...";

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <h2 className={`text-default`}>
        <span className={commonStyles.sequenceNumber}>1</span>
        Check Products
      </h2>
      <p className={commonStyles.numberAlign}>
        Please ensure all products have been mapped correctly.
      </p>
      <div className={commonStyles.numberAlign}>
        <h3
          className={classNames({
            [styles.statusHeader]: true,
            [styles.success]: true,
          })}
        >
          <ShowHideHeader group="success" />
          {pagination.success.totalCount} Successful
          <SelectAllOrNone group="success" />
          <TablePagination group="success" />
        </h3>
        {pagination.success.visible && RenderRowGroup("success")}
        <h3
          className={classNames({
            [styles.statusHeader]: true,
            [styles.warning]: true,
          })}
        >
          <ShowHideHeader group="warning" color="#32325d" />
          {pagination.warning.totalCount} Warnings
          <SelectAllOrNone group="warning" />
          <TablePagination group="warning" />
        </h3>
        {pagination.warning.visible && RenderRowGroup("warning")}

        <h3
          className={classNames({
            [styles.statusHeader]: true,
            [styles.error]: true,
          })}
        >
          <ShowHideHeader group="error" />
          {pagination.error.totalCount} Errors
          <TablePagination group="error" />
        </h3>
        {pagination.error.visible && RenderRowGroup("error")}
      </div>
    </>
  );
};

ProductConfigurator.propTypes = {
  data: PropTypes.array,
  setData: PropTypes.func,
  currentStep: PropTypes.number,
  multipleManufacturer: PropTypes.bool,
  manufacturer: PropTypes.object,
  manufacturerList: PropTypes.array,
  setProductConfigurationHasIssues: PropTypes.func,
};

export default ProductConfigurator;
