import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Row, Col, Table, ListGroup, ListGroupItem } from "reactstrap";
import MemoBadge from "App/components/Badges/Memo";
import ButtonWithPermissions from "App/components/Buttons/ButtonWithPermissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlaneDeparture,
  faPlaneArrival,
} from "@fortawesome/free-solid-svg-icons";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import DepotTransferTableContainer from "App/components/Tables/DepotTransferTable/DepotTransferTable.container";
import UserButtonContainer from "App/components/Buttons/UserButton/UserButton.container";
import DepotTransferStatusDropdown from "App/components/Dropdowns/DepotTransferStatusDropdown";
import SweetAlert from "react-bootstrap-sweetalert";
import axiosAPI from "App/services/axios";
import DeliveryStatusBadge from "App/components/Badges/DeliveryStatusBadge";
const moment = require("moment");

const DepotTransferTab = ({
  depotTransfer,
  isFocused,
  setDepotTransferModal,
  setDepotTransfer, //used to update list of POs
  isActive,
  refresh,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  const [authoriseWarning, setAuthoriseWarning] = useState(false);
  const [tab, setTab] = useState("ordering");

  const handleStatusChange = status => {
    if (status.id === depotTransfer?.DepotTransferStatusId) return; //ignore same status
    if (status.requiresAuthorisation && !depotTransfer.authorised) return; //ignore if needs auth but not authorised, should not happen because those options are disabled

    setStatusChangeLoading(true);
    axiosAPI
      .patch(`/depotTransfers/${depotTransfer.id}/status/${status.id}`)
      .then(({ data }) => {
        console.log(data);
        setDepotTransfer(data);
      })
      .catch(error => {
        setErrorMessage(
          error?.errorMessage || "An error occurred while updating status",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      })
      .finally(() => setStatusChangeLoading(false));
  };

  const authoriseDepotTransfer = () => {
    const authorise = !depotTransfer?.authorised;
    setAuthoriseWarning(false);
    axiosAPI
      .patch(
        `/depotTransfers/${depotTransfer.id}/${
          authorise ? "authorise" : "unauthorise"
        }`,
      )
      .catch(error => {
        setStatusChangeLoading(false);
        setErrorMessage(
          error?.errorMessage ||
            "An error occurred while authorising the depot transfer",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      })
      .finally(() => refresh());
  };

  if (!depotTransfer) return null;

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        title="Are you sure?"
        confirmBtnText="Yes"
        onConfirm={() => authoriseDepotTransfer()}
        onCancel={() => setAuthoriseWarning(false)}
        focusCancelBtn
        show={authoriseWarning}
      >
        {depotTransfer?.authorised
          ? "By unauthorising the depot transfer, it will no longer affect stock availability"
          : "By authorising the depot transfer, it will affect stock availability"}
      </SweetAlert>
      <Row>
        <Col lg={7}>
          <h2 className="text-muted font-weight-300 mb-0">Depot Transfer</h2>
          <h1 className="mb-0 mt--1">
            {depotTransfer.name}
            <h4 className="d-inline-block ml-4 mb-0">
              <DeliveryStatusBadge {...depotTransfer} />
            </h4>
          </h1>
          <h3 className="mb-1">
            <span className="text-muted font-weight-400">Depot Transfer</span>{" "}
            from {depotTransfer?.FromDepot?.name}
            {depotTransfer?.depotTransfer && (
              <small className="ml-1">to {depotTransfer?.ToDepot?.name}</small>
            )}
            <MemoBadge id="depotTransferMemo" memo={depotTransfer?.memo} />
          </h3>
        </Col>
        <Col xl={5} className="text-right">
          <div>
            <Button
              color="secondary"
              style={{
                height: "38px",
                paddingTop: 0,
                paddingBottom: 0,
              }}
              onClick={() => refresh()}
            >
              Refresh
            </Button>
            <ButtonWithPermissions
              color={depotTransfer?.authorised ? "success" : "danger"}
              style={{
                height: "38px",
                paddingTop: 0,
                paddingBottom: 0,
                marginRight: "8px",
                width: "135px",
              }}
              onClick={() => setAuthoriseWarning(true)}
              hoverChildren={
                depotTransfer?.authorised ? "Unauthorise" : "Authorise"
              }
              hoverColor={depotTransfer?.authorised ? "danger" : "success"}
              outlineWhenNotHovered={true}
              permission="DEPOT_TRANSFER_AUTHORISE"
              tooltip="You do not have permission to authorise/unauthorise a depot transfer"
            >
              {depotTransfer?.authorised ? "Authorised" : "Unauthorised"}
            </ButtonWithPermissions>
            <Button
              color="warning"
              style={{
                height: "38px",
                paddingTop: 0,
                paddingBottom: 0,
                color: "#000",
              }}
              onClick={() =>
                setDepotTransferModal({
                  id: depotTransfer.id,
                  visible: true,
                  edit: true,
                })
              }
            >
              Edit
            </Button>
          </div>
          <DepotTransferStatusDropdown
            label="Status"
            name="DepotTransferStatus"
            className="text-left mt-2"
            authorised={depotTransfer?.authorised}
            value={depotTransfer?.DepotTransferStatus}
            setValue={value => handleStatusChange(value)}
            loading={statusChangeLoading}
          />
        </Col>
      </Row>
      <div className="my-3">
        <ListGroup horizontal className="text-center">
          <ListGroupItem
            tag="button"
            action
            active={tab === "ordering"}
            onClick={() => setTab("ordering")}
            className="cursor-pointer smallListGroupItemHeight"
          >
            Ordering
          </ListGroupItem>
          <ListGroupItem
            tag="button"
            action
            active={tab === "shipments"}
            onClick={() => setTab("shipments")}
            className="cursor-pointer smallListGroupItemHeight"
          >
            Shipments
          </ListGroupItem>
          <ListGroupItem
            tag="button"
            action
            active={tab === "information"}
            onClick={() => setTab("information")}
            className="cursor-pointer smallListGroupItemHeight"
          >
            Information
          </ListGroupItem>
        </ListGroup>
      </div>
      {isFocused && (
        <>
          {tab === "ordering" && (
            <div className="mt-2">
              <DndProvider backend={HTML5Backend}>
                <DepotTransferTableContainer
                  depotTransfer={depotTransfer}
                  isActive={isActive}
                />
              </DndProvider>
            </div>
          )}
          {tab === "information" && (
            <Row>
              <Col xl={6}>
                <h4>
                  <FontAwesomeIcon className="mr-2" icon={faPlaneDeparture} />
                  Dispatch
                </h4>
                <Table bordered responsive size="sm" className="headersInRows">
                  <tbody>
                    <tr>
                      <th scope="row">Created</th>
                      <td>
                        {moment(depotTransfer?.createdAt).format(
                          "ddd Do MMM YYYY, HH:mm",
                        )}{" "}
                        by
                        <br />
                        <UserButtonContainer
                          id={depotTransfer?.CreateUserId}
                          className="mt-2"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Transport Required</th>
                      <td
                        className={
                          depotTransfer?.transportRequired
                            ? "text-sucess"
                            : "text-danger"
                        }
                      >
                        {depotTransfer?.transportRequired ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">From Depot</th>
                      <td>
                        {depotTransfer?.FromDepot?.name},{" "}
                        <small>
                          {depotTransfer?.FromDepot?.Address?.county}
                        </small>
                      </td>
                    </tr>
                    {depotTransfer?.expectedDispatch && (
                      <tr>
                        <th scope="row">Expected Dispatch</th>
                        <td>
                          {moment(depotTransfer?.expectedDispatch).format(
                            "Do MMM YYYY",
                          )}
                        </td>
                      </tr>
                    )}
                    {depotTransfer?.dispatchedOn && (
                      <tr>
                        <th scope="row">Dispatched</th>
                        <td>
                          {moment(depotTransfer?.dispatchedOn).format(
                            "HH:mm, Do MMM YYYY",
                          )}{" "}
                          by <br />
                          <UserButtonContainer
                            id={depotTransfer?.DispatchUserId}
                            className="mt-2"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
              <Col xl={6}>
                <h4>
                  <FontAwesomeIcon className="mr-2" icon={faPlaneArrival} />
                  Receive
                </h4>
                <Table bordered responsive size="sm" className="headersInRows">
                  <tbody>
                    <tr>
                      <th scope="row">To Depot</th>
                      <td>
                        {depotTransfer?.ToDepot?.name},{" "}
                        <small>{depotTransfer?.ToDepot?.Address?.county}</small>
                      </td>
                    </tr>
                    {depotTransfer?.requiredBy && (
                      <tr>
                        <th scope="row">Required By</th>
                        <td>
                          {moment(depotTransfer?.requiredBy).format(
                            "Do MMM YYYY",
                          )}
                        </td>
                      </tr>
                    )}
                    {depotTransfer?.expectedDelivery && (
                      <tr>
                        <th scope="row">Expected Delivery</th>
                        <td>
                          {moment(depotTransfer?.expectedDelivery).format(
                            "Do MMM YYYY",
                          )}
                        </td>
                      </tr>
                    )}
                    {depotTransfer?.receivedOn && (
                      <tr>
                        <th scope="row">Received</th>
                        <td>
                          {moment(depotTransfer?.receivedOn).format(
                            "HH:mm, Do MMM YYYY",
                          )}{" "}
                          by <br />
                          <UserButtonContainer
                            id={depotTransfer?.ReceiveUserId}
                            className="mt-2"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

DepotTransferTab.propTypes = {
  depotTransfer: PropTypes.object,
  isFocused: PropTypes.bool,
  setDepotTransferModal: PropTypes.func,
  setDepotTransfer: PropTypes.func,
  refresh: PropTypes.func,
};

export default DepotTransferTab;
