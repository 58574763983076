import React from "react";
import {
  Card,
  CardBody,
  Badge,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobeAmericas,
  faPhone,
  faCubes,
  faHandshake,
  faInfo,
  faBan,
  faTag,
  faStopwatch,
  faShoppingCart,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import currencyFormatter from "App/helpers/currencyFormatter";
const moment = require("moment");

const CompanyCard = ({ company, badge = null }) => (
  <Card>
    <CardBody className="pt-3">
      <CardTitle className="mb-1" style={{ fontSize: "1.4rem" }}>
        {company?.name}
      </CardTitle>
      {badge && <Badge color="primary">{badge}</Badge>}
      <ListGroup className="mt-3">
        {!!company?.website && (
          <ListGroupItem tag="a" href={`${company?.website}`} action>
            <FontAwesomeIcon
              icon={faGlobeAmericas}
              className="ListGroupItemIcon"
            />
            {company?.website}
          </ListGroupItem>
        )}
        {!!company?.phone && (
          <ListGroupItem tag="a" href={`tel:${company?.phone}`} action>
            <FontAwesomeIcon icon={faPhone} className="ListGroupItemIcon" />
            {company?.phone}
          </ListGroupItem>
        )}
        {!!company?.vendor && (
          <ListGroupItem>
            <FontAwesomeIcon icon={faCubes} className="ListGroupItemIcon" />
            Vendor
          </ListGroupItem>
        )}
        {!!company?.client && (
          <ListGroupItem>
            <FontAwesomeIcon icon={faHandshake} className="ListGroupItemIcon" />
            Client
          </ListGroupItem>
        )}
        <ListGroupItem>
          <FontAwesomeIcon icon={faBan} className="ListGroupItemIcon" />
          <span
            style={{
              color: company?.suspended ? "red" : "green",
            }}
          >
            {company?.suspended
              ? `Suspended: ${company?.suspendedMemo || "(No reason found)"}`
              : "Not suspended"}
          </span>
        </ListGroupItem>
        {!!company?.defaultDiscount && (
          <ListGroupItem>
            <FontAwesomeIcon icon={faTag} className="ListGroupItemIcon" />
            Default Discount: {company?.defaultDiscount}%
          </ListGroupItem>
        )}
        {!!company?.creditLimit && (
          <ListGroupItem>
            <FontAwesomeIcon
              icon={faShoppingCart}
              className="ListGroupItemIcon"
            />
            Credit Limit: {currencyFormatter(company?.creditLimit)}
          </ListGroupItem>
        )}
        {!!company?.creditPeriod && (
          <ListGroupItem>
            <FontAwesomeIcon icon={faStopwatch} className="ListGroupItemIcon" />
            Credit Period: {company?.creditPeriod} days
          </ListGroupItem>
        )}
        {!!company?.memo && (
          <ListGroupItem>
            <FontAwesomeIcon icon={faInfo} className="ListGroupItemIcon" />
            {company?.memo}
          </ListGroupItem>
        )}
        <ListGroupItem>
          <FontAwesomeIcon icon={faCalendar} className="ListGroupItemIcon" />
          Company since {moment(company?.createdAt).format("MMMM Do YYYY")}
        </ListGroupItem>
      </ListGroup>
    </CardBody>
  </Card>
);

export default CompanyCard;
