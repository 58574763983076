import React, { useState } from "react";
import { DragSource } from "react-dnd";
import { Tooltip } from "reactstrap";
import styles from "./ProductPickerResult.module.scss";
import currencyFormatter from "App/helpers/currencyFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //faWarehouse,
  //faMapMarkedAlt,
  faShippingFast,
} from "@fortawesome/free-solid-svg-icons";
const classNames = require("classnames");
const { get } = require("lodash");

const externalNodeType = "item";
const externalNodeSpec = {
  // This needs to return an object with a property `node` in it.
  // Object rest spread is recommended to avoid side effects of
  // referencing the same object in different trees.
  beginDrag: componentProps => ({ node: { ...componentProps.node } }),
};
const externalNodeCollect = (connect /* , monitor */) => ({
  connectDragSource: connect.dragSource(),
  // Add props via react-dnd APIs to enable more visual
  // customization of your component
  // isDragging: monitor.isDragging(),
  // didDrop: monitor.didDrop(),
});

const ProductPickerResult = ({
  connectDragSource,
  node,
  icon,
  onSelect,
  highlighted = false,
  showStockAndVendorCount = true,
  showAvailableCountForDepotId = null,
}) => {
  const nodeUniqueId = `node_${node?.Product?.id}`;
  const [stockTooltipOpen, setStockTooltipOpen] = useState(false);
  const toggleStockTooltip = () => setStockTooltipOpen(!stockTooltipOpen);
  //const depots = useSelector(state => state.depots.results);
  //const stockDepots = Object.keys(node?.Product?.availability?.available || []);
  const showAvailableCountForDepotIdQuantity = get(
    node,
    `Product.availability.available.${showAvailableCountForDepotId}`,
    0,
  );

  return connectDragSource(
    <div
      className={classNames({
        [styles.result]: true,
        [styles.highlighted]: highlighted,
      })}
      onClick={() => onSelect(node)}
    >
      <h4 className={classNames([styles.title, styles.highlightWhiteText])}>
        {node?.name}
      </h4>
      <h5
        className={`${styles.subtitle} ${styles.highlightWhiteText} text-muted font-weight-400`}
      >
        {node?.Product?.Manufacturer?.name} -{" "}
        {node?.Product?.manufacturerProductCode}
      </h5>
      <div className={styles.bottomRow}>
        <p className={styles.small}>
          <span className={styles.priceInline}>
            Cost: {currencyFormatter(node?.costPrice)}
          </span>
          <span className={styles.priceInline}>
            {node?.linePrice !== node?.listPrice
              ? `Line: ${currencyFormatter(node?.linePrice)}`
              : `List: ${currencyFormatter(node?.listPrice)}`}
          </span>
          {node?.TaxRate?.id !== 1 && (
            <span className={`${styles.priceInline} font-weight-400`}>
              {node?.TaxRate?.rate}% Tax
            </span>
          )}
        </p>
        {!!showAvailableCountForDepotId && (
          <div
            className={classNames({
              [styles.availableCountForDepot]: true,
              [styles.highlightWhiteText]: true,
              "text-success": showAvailableCountForDepotIdQuantity > 0,
              "text-danger": showAvailableCountForDepotIdQuantity < 0,
              "text-muted": showAvailableCountForDepotIdQuantity === 0,
            })}
          >
            {showAvailableCountForDepotIdQuantity} available
          </div>
        )}
        {!!showStockAndVendorCount && (
          <>
            <div className={styles.stockInfo} id={nodeUniqueId + "_stock"}>
              {/*<span
                className={`${styles.stockCount} ${
                  node?.stockInThisDepot > 0
                    ? "text-success"
                    : node?.stockInThisDepot < 0
                    ? "text-danger"
                    : "text-muted"
                }`}
              >
                <FontAwesomeIcon
                  className={styles.stockIcon}
                  icon={faWarehouse}
                />
                {node?.stockInThisDepot || 0}
              </span>
              <span
                className={`${styles.stockCount} ${
                  node?.stockInOtherDepots > 0
                    ? "text-success"
                    : node?.stockInOtherDepots < 0
                    ? "text-danger"
                    : "text-muted"
                }`}
              >
                <FontAwesomeIcon
                  className={styles.stockIcon}
                  icon={faMapMarkedAlt}
                />
                {node?.stockInOtherDepots || 0}
              </span>*/}
              <span className={styles.stockCount}>
                <FontAwesomeIcon
                  className={styles.stockIcon}
                  icon={faShippingFast}
                />
                {node?.vendors?.length || 0}
              </span>
            </div>
            <Tooltip
              placement="right"
              isOpen={stockTooltipOpen}
              autohide={false}
              toggle={toggleStockTooltip}
              target={nodeUniqueId + "_stock"}
            >
              <div className={styles.stockTooltipContent}>
                {/*<span className={styles.stockTooltipHeader}>Stock</span>
                <ul>
                  {stockDepots.length === 0 ? (
                    <small>None available</small>
                  ) : (
                    stockDepots.map(depotId => {
                      return (
                        <li>
                          {depots.find(x => x.id === +depotId)?.name}:{" "}
                          {node?.Product?.availability?.available[depotId]}
                        </li>
                      );
                    })
                  )}
                </ul>*/}
                <span className={styles.stockTooltipHeader}>Vendors</span>
                {node?.vendors?.length === 0 ? (
                  <small>None available</small>
                ) : (
                  <ul>
                    {node?.vendors?.map(vendor => (
                      <li>{vendor}</li>
                    ))}
                  </ul>
                )}
              </div>
            </Tooltip>
          </>
        )}
      </div>
    </div>,
    { dropEffect: "copy" },
  );
};

export default DragSource(
  externalNodeType,
  externalNodeSpec,
  externalNodeCollect,
)(ProductPickerResult);
