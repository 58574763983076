import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ProductModal from "./ProductModal";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";

const ProductModalContainer = ({
  id,
  visible = false,
  setVisible,
  edit = false,
  done,
}) => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  useEffect(() => {
    if (visible && id) {
      getProduct(id);
    }
  }, [id, visible]); // eslint-disable-line

  const getProduct = id => {
    setLoading(true);
    axiosAPI
      .get(`/products/${id}`)
      .then(result => {
        setProduct(result.data || null);
      })
      .catch(error => {
        setVisible(false);
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting Product, please retry",
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addProduct = async product => {
    setLoading(true);
    await axiosAPI
      .post("/products", product)
      .then(result => {
        setVisible(false);
        done();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage || "An error occurred while adding new Product",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  const editProduct = async product => {
    setLoading(true);
    await axiosAPI
      .patch(`/products/${product.id}`, product)
      .then(result => {
        setVisible(false);
        done();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage || "An error occurred while editing Product",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  return (
    <>
      {!!errorMessage && (
        <SweetAlert
          danger
          onConfirm={() => {
            setErrorMessage(null);
            setErrorMore(null);
          }}
          title="Error"
        >
          {errorMessage}
          {errorMore && (
            <>
              <br />
              <small>{errorMore}</small>
            </>
          )}
        </SweetAlert>
      )}
      <ProductModal
        visible={visible}
        product={product}
        edit={edit}
        setVisible={setVisible}
        editProduct={editProduct}
        addProduct={addProduct}
        loading={loading}
      />
    </>
  );
};

ProductModalContainer.propTypes = {
  id: PropTypes.number,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  edit: PropTypes.bool,
  done: PropTypes.func,
};

export default ProductModalContainer;
