import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Input,
  Row,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Spinner,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faPaperPlane,
  faPencilAlt,
  faUpload,
  faTimes,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./EmailsTab.module.scss";
import SweetAlert from "react-bootstrap-sweetalert";
import ButtonWithPermissions from "App/components/Buttons/ButtonWithPermissions";
import io from "socket.io-client";
import AsyncCreatableSelect from "react-select/async-creatable";
import axiosAPI, { CancelToken } from "App/services/axios";
import emailValidator from "App/helpers/emailValidator";
import { debounce } from "lodash";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDropzone } from "react-dropzone";
import DocumentPickerModalContainer from "App/components/Modals/DocumentPickerModal/DocumentPicker.container";
import {
  faPaperclip,
  faExclamation,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { store } from "App/redux/store";
import defaultSocketOptions from "App/services/socketio";
const filesize = require("filesize");
const socket = io("/emails", defaultSocketOptions);
const qs = require("qs");
const classNames = require("classnames");
const moment = require("moment");
const _ = require("lodash");

//https://github.com/react-dropzone/react-dropzone

const RecipientGroup = ({
  field,
  loadRecipients,
  values,
  onRecipientClear,
  onRecipientAdd,
  onRecipientRemove,
  disabled,
}) => (
  <div className={styles.fieldGroup}>
    <span className={styles.fieldLabel}>{field}:</span>
    <AsyncCreatableSelect
      placeholder={`${field}...`}
      className={styles.fieldInput}
      blurInputOnSelect={false}
      loadOptions={loadRecipients} //THIS NEEDS TO BE DEBOUNCED SOMEHOW - may need to move away from AsyncCreatableSelect to createableselect like manufacturer
      //defaultOptions={true}
      getOptionValue={option => {
        return option.email;
      }}
      noOptionsMessage={() =>
        "Type to search, or enter a valid email address to add a custom recipient"
      }
      value={values}
      onChange={(values, actionMeta) => {
        if (actionMeta.action === "clear")
          return onRecipientClear(field.toUpperCase());
        if (
          actionMeta.action === "remove-value" ||
          actionMeta.action === "pop-value"
        )
          return onRecipientRemove(actionMeta?.removedValue?.id);
        if (actionMeta.action === "select-option")
          return onRecipientAdd(field.toUpperCase(), actionMeta.option);
      }}
      onCreateOption={value => {
        return onRecipientAdd(field.toUpperCase(), { email: value });
      }}
      isMulti
      isValidNewOption={(inputValue, selectValue, selectOptions) =>
        emailValidator(inputValue)
      }
      getOptionLabel={option => {
        if (option?.__isNew__) return `${option.value}`;
        const name = option?.name
          ? option.name
          : option.firstName && option.lastName
          ? `${option.firstName} ${option.lastName}`
          : "";
        return (
          <>
            <strong>{name}</strong> &lt;{option.email}&gt;
          </>
        );
      }}
      isDisabled={disabled}
    />
  </div>
);

const EmailsTab = ({ jobId, isFocused = false }) => {
  //*************ERRORS AND LOADING***************
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  const [loading, setLoading] = useState(true);

  //*************CURRENT USER***************
  const state = store.getState();
  const user = _.get(state, "user.info", {});

  //*************EMAILS**************
  const [emails, setEmails] = useState([]);
  const [awaitingCreate, setAwaitingCreate] = useState(false);
  const [activeEmailId, setActiveEmailId] = useState(5);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [deleteAttachmentWarning, setDeleteAttachmentWarning] = useState(null);
  const [renameAttachment, setRenameAttachment] = useState(null);
  const [documentPickerVisible, setDocumentPickerVisible] = useState(false);

  //*************EDITING**************
  const {
    subject,
    isDraft,
    EmailRecipients = [],
    priority,
    message,
    Attachments,
    FromUser,
    createdAt,
    colour,
    status,
    statusMore,
    isSent,
    sentAt,
  } = emails.find(x => x.id === activeEmailId) || {};
  const [pendingAttachments, setPendingAttachments] = useState([]);

  // const filepond = useRef();
  const updateFieldDebounced = useRef(
    debounce(
      (emailId, field, value) => updateField(emailId, field, value),
      500,
    ),
  ).current;

  //updates state
  const setField = useCallback((field, value) => {
    setEmails(
      emails.map(email => {
        if (email.id !== activeEmailId) return email;
        return {
          ...email,
          [field]: value,
        };
      }),
    );
  });

  //updates field over socket
  const updateField = (emailId, field, value) => {
    if (process.env.NODE_ENV === "development")
      console.log("update field", { emailId, field, value });
    socket.emit("setEmailField", +jobId, emailId, field, value);
  };

  useEffect(() => {
    socket.on("logout", () => {
      socket.disconnect();
      window.location = "/login";
    });
    socket.on("socketError", ({ errorMessage, errorMore }) => {
      setErrorMessage(errorMessage);
      setErrorMore(errorMore);
    });
    socket.on(
      "getJobEmails",
      ({ JobId: jobIdFromSocket, emails: emailsFromSocket }) => {
        console.log("get job emails", emailsFromSocket);
        if (jobIdFromSocket === +jobId) {
          setLoading(false);
          setEmails(emailsFromSocket);
        }
      },
    );
    socket.on("emailUpdate", ({ JobId, EmailId, field, value }) => {
      if (+JobId !== +jobId) return;
      if (process.env.NODE_ENV === "development")
        console.log("emailUpdate", { JobId, EmailId, field, value });

      setEmails(existingEmails =>
        existingEmails.map(email => {
          if (email.id !== EmailId) return email;
          return {
            ...email,
            [field]: value,
          };
        }),
      );
    });
    socket.on("addEmailRecipient", ({ JobId, EmailId, type, value }) => {
      if (+JobId !== +jobId) return;
      if (process.env.NODE_ENV === "development")
        console.log("addEmailRecipient", { JobId, EmailId, type, value });

      const newEmails = emails.map(email => {
        if (email.id !== EmailId) return email;
        return {
          ...email,
          EmailRecipients: [...email.EmailRecipients, value],
        };
      });

      setEmails(newEmails);
    });
    socket.on("removeEmailRecipient", ({ JobId, EmailId, id }) => {
      if (+JobId !== +jobId) return;
      if (process.env.NODE_ENV === "development")
        console.log("removeEmailRecipient", { JobId, EmailId, id });

      const newEmails = emails.map(email => {
        if (email.id !== EmailId) return email;
        return {
          ...email,
          EmailRecipients: email.EmailRecipients.filter(x => x.id !== id),
        };
      });

      setEmails(newEmails);
    });
    socket.on("clearEmailRecipients", ({ JobId, EmailId, type }) => {
      if (+JobId !== +jobId) return;
      if (process.env.NODE_ENV === "development")
        console.log("clearEmailRecipients", { JobId, EmailId, type });

      const newEmails = emails.map(email => {
        if (email.id !== EmailId) return email;
        return {
          ...email,
          EmailRecipients: email.EmailRecipients.filter(x => x.type !== type),
        };
      });

      setEmails(newEmails);
    });
    socket.on(
      "addEmailAttachment",
      ({ JobId, EmailId, attachment, PendingFileId }) => {
        if (+JobId !== +jobId) return;
        if (process.env.NODE_ENV === "development")
          console.log("addEmailAttachment", { JobId, EmailId, attachment });

        const newEmails = emails.map(email => {
          if (email.id !== EmailId) return email;
          return {
            ...email,
            Attachments: [...email.Attachments, attachment],
          };
        });

        if (PendingFileId) {
          setPendingAttachments(pA =>
            pA.filter(pending => pending.fileId !== PendingFileId),
          );
        }

        setEmails(newEmails);
      },
    );
    socket.on(
      "renameEmailAttachment",
      ({ JobId, EmailId, fileId, newFileName }) => {
        if (+JobId !== +jobId) return;
        if (process.env.NODE_ENV === "development")
          console.log("renameEmailAttachment", {
            JobId,
            EmailId,
            fileId,
            newFileName,
          });

        const newEmails = emails.map(email => {
          if (email.id !== EmailId) return email;

          return {
            ...email,
            Attachments: email.Attachments.map(x => {
              if (x.id !== fileId) return x;
              return {
                ...x,
                friendlyFileName: newFileName,
              };
            }),
          };
        });

        setEmails(newEmails);
      },
    );
    socket.on("removeEmailAttachment", ({ JobId, EmailId, fileId }) => {
      if (+JobId !== +jobId) return;
      if (process.env.NODE_ENV === "development")
        console.log("removeEmailAttachment", { JobId, EmailId, fileId });

      const newEmails = emails.map(email => {
        if (email.id !== EmailId) return email;

        return {
          ...email,
          Attachments: email.Attachments.filter(x => x.id !== fileId),
        };
      });

      setEmails(newEmails);
    });
    socket.on("emailCreate", ({ JobId, email }) => {
      if (+JobId !== +jobId) return;
      if (process.env.NODE_ENV === "development")
        console.log("emailCreate", { JobId, email });
      setEmails([...emails, email]);
      if (awaitingCreate) setActiveEmailId(email.id);
      setAwaitingCreate(false);
    });
    socket.on("emailDelete", ({ JobId, EmailId }) => {
      if (process.env.NODE_ENV === "development")
        console.log("emailDelete", { JobId, EmailId });
      if (+JobId !== +jobId) return;
      let newEmails = emails.filter(x => x.id !== EmailId);
      setEmails(newEmails);
      if (activeEmailId === EmailId) setActiveEmailId(null);
    });

    return () => {
      socket.off("logout");
      socket.off("socketError");
      socket.off("getJobEmails");
      socket.off("addEmailRecipient");
      socket.off("removeEmailRecipient");
      socket.off("clearEmailRecipients");
      socket.off("addEmailAttachment");
      socket.off("removeEmailAttachment");
      socket.off("renameEmailAttachment");
      socket.off("emailCreate");
      socket.off("emailUpdate");
      socket.off("emailDelete");
    };
  }, [activeEmailId, awaitingCreate, emails, jobId]);

  useEffect(() => {
    socket.emit("joinJobEmails", +jobId);

    return () => {
      socket.emit("leaveJobEmails", +jobId);
    };
  }, [jobId]);

  const processNewPendingFiles = async files => {
    setPendingAttachments(pA => [...pA, ...files]);
    for await (const file of files) {
      // console.log(file);
      if (file.file.size > 50 * 1000 * 1000) {
        setPendingAttachments(pA =>
          pA.map(pending => {
            if (pending.fileId !== file.fileId) return pending;
            return {
              ...pending,
              error: "File must be less than 50MB",
            };
          }),
        );
        setTimeout(() => {
          setPendingAttachments(pA =>
            pA.filter(pending => pending.fileId !== file.fileId),
          );
        }, 10000);
        continue;
      }

      const formData = new FormData();
      formData.append("file", file.file, file.file.name);
      formData.append("directoryPath", "emailAttachments/");
      formData.append("type", "Email attachment");
      formData.append("JobId", +jobId);
      formData.append("EmailId", +activeEmailId);

      const source = CancelToken.source();

      await axiosAPI
        .post(`/files`, formData, {
          onUploadProgress: function (e) {
            // console.log(e.lengthComputable, e.loaded, e.total);
            if (e?.lengthComputable) {
              setPendingAttachments(pA =>
                pA.map(pending => {
                  if (pending.fileId !== file.fileId) return pending;
                  return {
                    ...pending,
                    progress: e?.loaded || 0,
                    total: e?.total || Infinity,
                  };
                }),
              );
            }
          },
          cancelToken: source.token,
          timeout: 60000,
        })
        .then(result => {
          onAttachmentAdd(result.data);
          setPendingAttachments(pA =>
            pA.filter(pending => pending.fileId !== file.fileId),
          );
        })
        .catch(error => {
          console.log("Pending file upload error", error);
          setPendingAttachments(pA =>
            pA.map(pending => {
              if (pending.fileId !== file.fileId) return pending;
              return {
                ...pending,
                error: "An error occurred while uploading the attachment",
              };
            }),
          );
          setTimeout(() => {
            setPendingAttachments(pA =>
              pA.filter(pending => pending.fileId !== file.fileId),
            );
          }, 6000);
        });
    }
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections, event) => {
      // console.log({ acceptedFiles, fileRejections, event });
      const newFiles = acceptedFiles.map(file => ({
        file,
        progress: 0,
        total: Infinity,
        fileId: `pendingFile_${Math.random().toString(36).substr(2, 16)}`,
        emailId: activeEmailId,
      }));
      processNewPendingFiles(newFiles);
      //setField("Attachments", [...Attachments, ...acceptedFiles]);
    },
    [jobId, activeEmailId],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const loadRecipients = value => {
    return new Promise(async (resolve, reject) => {
      try {
        const local = await axiosAPI
          .get("/emails/recipients?" + qs.stringify({ search: value }))
          .then(result => result.data);

        const remote = await axiosAPI
          .get("/emails/recipients/remote?" + qs.stringify({ search: value }))
          .then(result => result.data)
          .catch(error => console.log(error)); //ignore if remote fails

        return resolve([...(local || []), ...(remote || [])]);
      } catch (error) {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting recipients, please retry",
        );
        console.error(error);
        return reject("An error occurred");
      }
    });
  };

  const onNewEmail = () => {
    setAwaitingCreate(true);
    socket.emit("createEmail", +jobId);
  };

  const onDeleteEmail = (JobId, emailId) => {
    setDeleteWarning(false);
    socket.emit("deleteEmail", JobId, emailId);
  };

  const onSendEmail = emailId => {
    socket.emit("sendEmail", emailId);
  };

  //actionMeta: select-option or create-option
  const onRecipientAdd = (type = "TO", value) => {
    const name =
      !!value?.firstName && !!value?.lastName
        ? `${value.firstName} ${value.lastName}`
        : null;
    const newValue = {
      name,
      email: value?.email || null,
      type,
      EmailId: activeEmailId,
      UserId: value?.UserId || null,
      ContactId: value?.ContactId || null,
    };
    socket.emit("addEmailRecipient", +jobId, activeEmailId, type, newValue);
  };

  //actionMeta: remove-value or pop-value
  const onRecipientRemove = id => {
    socket.emit("removeEmailRecipient", +jobId, activeEmailId, id);
  };

  //actionMeta: clear
  const onRecipientClear = (type = "TO") => {
    socket.emit("clearEmailRecipients", +jobId, activeEmailId, type);
  };

  const onDocumentAdd = (DocumentId, StationeryId) => {
    const tempId = `${Math.floor(Math.random() * 1000)}_${Date.now().toString(
      36,
    )}`;
    console.log("add document", DocumentId, StationeryId, tempId);
    setPendingAttachments(pA => [
      ...pA,
      {
        emailId: activeEmailId,
        fileId: tempId,
        newDocumentBeingGenerated: true,
      },
    ]);
    socket.emit(
      "addEmailDocumentAttachment",
      +jobId,
      activeEmailId,
      DocumentId,
      StationeryId,
      tempId,
    );
  };

  const onAttachmentAdd = file => {
    console.log("add attachment", file.id);
    socket.emit("addEmailAttachment", +jobId, activeEmailId, file.id);
  };

  const onAttachmentRename = (file, newFileName) => {
    console.log("rename file", file.id);
    socket.emit(
      "renameEmailAttachment",
      +jobId,
      activeEmailId,
      file.id,
      newFileName,
    );
  };

  const onAttachmentRemove = file => {
    console.log("remove file", file.id);
    socket.emit("removeEmailAttachment", +jobId, activeEmailId, file.id);
  };

  const TabOption = ({ text, smallText, status, color, tabId }) => (
    <ListGroupItem
      tag="a"
      action
      className="cursor-pointer active-all-white"
      active={activeEmailId === tabId}
      onClick={() => {
        activeEmailId === tabId
          ? setActiveEmailId(null)
          : setActiveEmailId(tabId);
      }}
    >
      <span>
        {text}
        {color && status && (
          <small
            className={`float-right pt-1`}
            style={{ color: color || "#ccc" }}
          >
            <FontAwesomeIcon className="mr-1" icon={faCircle} />
            {status}
          </small>
        )}
      </span>
      {smallText && (
        <small className="mt--1 d-block text-muted">{smallText}</small>
      )}
    </ListGroupItem>
  );

  if (loading)
    return (
      <Spinner type="grow" className="d-block my-8 mx-auto" color="warning" />
    );

  return (
    <Container fluid className="py-md-4" style={{ background: "#f5f5f5" }}>
      <DocumentPickerModalContainer
        visible={documentPickerVisible}
        setVisible={setDocumentPickerVisible}
        done={({ selectedDocument, selectedStationery }) => {
          onDocumentAdd(selectedDocument, selectedStationery);
          setDocumentPickerVisible(false);
        }}
      />
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <Row>
        <Col xl={3}>
          <ButtonWithPermissions
            color="success"
            style={{
              height: "38px",
              paddingTop: 0,
              paddingBottom: 0,
              marginRight: "8px",
              width: "135px",
            }}
            onClick={() => onNewEmail()}
            permission="EMAIL_SEND"
            tooltip="You do not have permission to create, edit or send a new email"
          >
            New Email
            {!!awaitingCreate && (
              <Spinner size="sm" className="ml-2" color="white" />
            )}
          </ButtonWithPermissions>
          <hr />
          <ListGroup className={classNames(["mt-1 mb-3", styles.emailList])}>
            {emails &&
              emails?.length > 0 &&
              emails
                .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                .map(email => {
                  const { id, subject = "Email", status, colour } = email;
                  return (
                    <TabOption
                      text={subject}
                      smallText={`Email`}
                      status={status}
                      color={colour}
                      tabId={id}
                      key={id}
                    />
                  );
                })}
          </ListGroup>
        </Col>
        <Col xl={9}>
          {!!activeEmailId ? (
            <div className={styles.email} style={{ borderTopColor: colour }}>
              <div className={styles.statusRow}>
                {status === "Draft" && (
                  <FontAwesomeIcon
                    className={styles.statusIcon}
                    icon={faPencilAlt}
                    color={colour}
                  />
                )}
                {status === "Sending" && (
                  <FontAwesomeIcon
                    className={styles.statusIcon}
                    icon={faUpload}
                    color={colour}
                  />
                )}
                {status === "Sent" && (
                  <FontAwesomeIcon
                    className={styles.statusIcon}
                    icon={faPaperPlane}
                    color={colour}
                  />
                )}
                {status === "Error" && (
                  <FontAwesomeIcon
                    className={styles.statusIcon}
                    icon={faTimes}
                    color={colour}
                  />
                )}
                <h5
                  className={styles.statusTitle}
                  style={{ color: colour || "#111" }}
                >
                  {status}
                </h5>
                <span className={styles.statusMore}>
                  {statusMore}
                  {!!isSent &&
                    !!sentAt &&
                    moment(sentAt).format(" [on] ddd do MMM YYYY [at] HH:mm")}
                </span>
              </div>
              <h2 className={styles.bigSubject}>{subject}</h2>
              <hr />
              <div className={styles.fieldGroup} style={{ marginBottom: 0 }}>
                <span className={styles.fieldLabel}>From:</span>
                <span className={styles.fieldInput}>
                  <strong>
                    {FromUser?.firstName} {FromUser?.lastName}
                  </strong>{" "}
                  <small>&lt;{FromUser?.email}&gt;</small>
                </span>
              </div>
              <div
                className={styles.fieldGroup}
                style={{ marginBottom: "10px" }}
              >
                <span className={styles.fieldLabel}></span>
                <span
                  className={classNames([
                    styles.fieldInput,
                    "text-muted small",
                  ])}
                >
                  Created at{" "}
                  {moment(createdAt).format("ddd do MMM YYYY [at] HH:mm")}
                </span>
              </div>
              {["To", "CC", "BCC"].map(field => (
                <RecipientGroup
                  field={field}
                  key={field}
                  loadRecipients={loadRecipients}
                  values={EmailRecipients.filter(
                    x => x?.type === field.toUpperCase(),
                  )}
                  onRecipientClear={onRecipientClear}
                  onRecipientAdd={onRecipientAdd}
                  onRecipientRemove={onRecipientRemove}
                  disabled={!isDraft}
                />
              ))}
              <div className={styles.fieldGroup}>
                <span className={styles.fieldLabel}>Subject:</span>
                <Input
                  className={classNames([
                    styles.fieldInput,
                    styles.subjectInput,
                  ])}
                  disabled={!isDraft}
                  type="text"
                  key={`${activeEmailId}_subject`}
                  placeholder="Subject..."
                  onChange={e => {
                    setField("subject", e.target.value);
                    updateFieldDebounced(
                      activeEmailId,
                      "subject",
                      e.target.value,
                    );
                  }}
                  value={subject}
                />
              </div>
              <div className={styles.fieldGroup}>
                <span className={styles.fieldLabel}>
                  <FontAwesomeIcon icon={faExclamation} />
                </span>
                <Input
                  type="select"
                  name="priority"
                  className={classNames([
                    styles.fieldInput,
                    styles.subjectInput,
                  ])}
                  key={`${activeEmailId}_priority`}
                  disabled={!isDraft}
                  value={priority}
                  onChange={e => {
                    setField("priority", e.target.value);
                    updateFieldDebounced(
                      activeEmailId,
                      "priority",
                      e.target.value,
                    );
                  }}
                >
                  <option value="Low">Low Priority</option>
                  <option value="Normal">Normal Priority</option>
                  <option value="High">High Priority</option>
                </Input>
              </div>
              <div className={styles.fieldGroup}>
                <span className={styles.fieldLabel}>
                  <FontAwesomeIcon icon={faPaperclip} />
                </span>
                <div className="w-100">
                  {isDraft && (
                    <div {...getRootProps()} className={styles.uploadDropper}>
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>
                          Drop em like they're hot{" "}
                          <span aria-label="fire" role="img">
                            🔥
                          </span>
                        </p>
                      ) : (
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      )}
                    </div>
                  )}
                  {isDraft && (
                    <ButtonWithPermissions
                      color="success"
                      size="sm"
                      className={styles.actionButton}
                      onClick={() => setDocumentPickerVisible(true)}
                      permission="EMAIL_SEND"
                      tooltip="You do not have permission to edit or send emails"
                    >
                      <FontAwesomeIcon
                        icon={faFile}
                        style={{ marginRight: "5px" }}
                      />
                      Add attachment from documents
                    </ButtonWithPermissions>
                  )}
                  {!isDraft &&
                    !Attachments?.length &&
                    !pendingAttachments?.length && (
                      <span className={styles.noAttachments}>
                        No attachments
                      </span>
                    )}
                  <ul className={styles.attachmentList}>
                    {pendingAttachments
                      ?.filter(pending => pending.emailId === activeEmailId)
                      .map((pending, index) => {
                        return (
                          <li
                            className={styles.uploading}
                            key={`pending_${index}`}
                          >
                            {pending?.newDocumentBeingGenerated ? (
                              "Generating document..."
                            ) : (
                              <>
                                Uploading: {pending?.file?.path} (
                                {Math.ceil(
                                  (pending?.progress / pending?.total) * 100,
                                )}
                                %)
                              </>
                            )}
                            {pending?.error && (
                              <span className={styles.error}>
                                Error: {pending?.error}
                              </span>
                            )}
                          </li>
                        );
                      })}
                    {Attachments?.map(file => {
                      return (
                        <li key={file.id}>
                          <span className={styles.fileName}>
                            {file.friendlyFileName || file.path}
                          </span>
                          <small className={styles.fileSize}>
                            {filesize(file.size)}
                          </small>
                          <div className={styles.attachmentOptions}>
                            {isDraft && (
                              <Button
                                color="link"
                                size="sm"
                                onClick={() => setRenameAttachment(file)}
                              >
                                <FontAwesomeIcon icon={faPencilAlt} /> Rename
                              </Button>
                            )}
                            <Button
                              color="link"
                              size="sm"
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_API_BASE_URL}/files/${file.id}`,
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faExternalLinkAlt} /> View
                            </Button>
                            {isDraft && (
                              <Button
                                color="link"
                                size="sm"
                                onClick={() => setDeleteAttachmentWarning(file)}
                                className={styles.deleteAttachmentButton}
                              >
                                <FontAwesomeIcon icon={faTimes} /> Remove
                              </Button>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className={styles.messageEditorContainer}>
                <ReactQuill
                  className={isDraft ? "" : styles.readOnlyQuill}
                  theme="snow"
                  value={message || ""}
                  readOnly={!isDraft}
                  onChange={(content, delta, source, editor) => {
                    if (source !== "user") return; //ie if came from socket, so dont end up reflecting back and in a circular loop
                    setField("message", content);
                    updateFieldDebounced(activeEmailId, "message", content);
                  }}
                />
              </div>
              {!!renameAttachment && (
                <SweetAlert
                  input
                  required={true}
                  validationMsg="Please enter a name with at least 1 character"
                  validationRegex={/^.{1,}$/}
                  showCancel
                  title="Rename attachment"
                  defaultValue={renameAttachment?.friendlyFileName?.substring(
                    0,
                    renameAttachment?.friendlyFileName?.lastIndexOf("."),
                  )}
                  onConfirm={response => {
                    const newFileNameWithExtension = `${response}${renameAttachment?.friendlyFileName?.substring(
                      renameAttachment?.friendlyFileName?.lastIndexOf("."),
                    )}`;
                    console.log(newFileNameWithExtension);
                    onAttachmentRename(
                      renameAttachment,
                      newFileNameWithExtension,
                    );
                    setRenameAttachment(null);
                  }}
                  onCancel={() => setRenameAttachment(null)}
                  closeOnClickOutside={false}
                >
                  Do not include the file extension, this will be added
                  automatically.
                  <br />
                  <br />
                </SweetAlert>
              )}
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Delete"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={() => {
                  onDeleteEmail(jobId, activeEmailId);
                }}
                onCancel={() => setDeleteWarning(false)}
                focusCancelBtn
                show={!!deleteWarning}
              >
                Deleting will permanently remove this email.
              </SweetAlert>
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Remove"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={() => {
                  setDeleteAttachmentWarning(null);
                  onAttachmentRemove(deleteAttachmentWarning);
                }}
                onCancel={() => setDeleteAttachmentWarning(null)}
                focusCancelBtn
                show={!!deleteAttachmentWarning}
              >
                This will remove the attachment from the email.
              </SweetAlert>
              <div className={styles.actionsRow}>
                {!!isDraft && (
                  <ButtonWithPermissions
                    color="danger"
                    disabled={FromUser.id !== user.id}
                    disabledTooltip="You can only delete emails created by yourself"
                    className={styles.actionButton}
                    onClick={() => setDeleteWarning(true)}
                    permission="EMAIL_DELETE"
                    tooltip="You do not have permission to delete emails"
                  >
                    Delete
                  </ButtonWithPermissions>
                )}
                {(isDraft || (!isSent && status !== "Sending")) && (
                  <ButtonWithPermissions
                    color={status === "Error" ? "warning" : "success"}
                    disabled={FromUser?.id !== user.id}
                    disabledTooltip="You can only send emails created by yourself"
                    className={styles.actionButton}
                    onClick={() => onSendEmail(activeEmailId)}
                    permission="EMAIL_SEND"
                    tooltip="You do not have permission to send emails"
                  >
                    {status === "Error" ? "Resend" : "Send"}
                  </ButtonWithPermissions>
                )}
              </div>
            </div>
          ) : (
            <div className={styles.placeholderNoSelectionContainer}>
              <FontAwesomeIcon
                className={styles.placeholderIcon}
                icon={faPaperPlane}
              />
              <h4 className={styles.placeholderTitle}>
                Create or select an email to get started
              </h4>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

EmailsTab.propTypes = {
  job: PropTypes.object,
};

export default EmailsTab;
