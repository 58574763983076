import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import axiosAPI from "App/services/axios";

const DocumentsTab = ({ jobId, isFocused }) => {
  const [loading, isLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [stationery, setStationery] = useState([]);
  const [setErrorMessage] = useState(null);
  const [setErrorMore] = useState(null);

  const getDocumentsAndStationeryAvailable = () => {
    try {
      isLoading(true);
      axiosAPI.get(`/documents`).then(result => {
        setDocuments(result?.data || []);
      });
      axiosAPI.get(`/stationery`).then(result => {
        setStationery(result?.data || []);
      });
    } catch (error) {
      setErrorMessage(error?.errorMessage || "An unknown error occurred");
      setErrorMore(error?.errorMore || "Please try again");
    } finally {
      isLoading(false);
    }
  };

  useEffect(() => {
    getDocumentsAndStationeryAvailable();
  }, []);

  return (
    <div style={{ padding: "30px" }}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h4>Quotes</h4>
          {documents
            .filter(x => x?.for === "JOBQUOTE")
            .map(document => {
              return (
                <div style={{ marginBottom: "10px" }}>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle size="lg" caret color="primary">
                      {document?.name}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>PDF</DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_BASE_URL}/documents/${document.id}/pdf/stationery/depotDefault/job/${jobId}`,
                          )
                        }>
                        Job Depot Branding
                      </DropdownItem>
                      {stationery.map(s => {
                        return (
                          <DropdownItem
                            key={s.id}
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_API_BASE_URL}/documents/${document.id}/pdf/stationery/${s.id}/job/${jobId}`,
                              )
                            }>
                            {s.name}
                          </DropdownItem>
                        );
                      })}
                      <DropdownItem divider />
                      <DropdownItem header>HTML</DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_BASE_URL}/documents/${document.id}/html/stationery/none/job/${jobId}`,
                          )
                        }>
                        Webpage
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </div>
              );
            })}
        </>
      )}
    </div>
  );
};

DocumentsTab.propTypes = {
  jobId: PropTypes.number,
};

export default DocumentsTab;
