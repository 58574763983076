import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, Row, Button, Col } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  TextInput,
  DateTimeInput,
  SelectInput,
} from "App/components/Inputs/BasicFormInputs.js";
import DepotDropdown from "App/components/Dropdowns/DepotDropdown";

const ShipmentModal = ({
  edit = false,
  visible = false,
  setVisible,
  shipment = {},
  addShipment,
  editShipment,
  loading,
  preSelectType,
  preSelectDepot,
}) => {
  return (
    <Modal
      className="modal-dialog-centered formModal"
      isOpen={visible}
      data-testid="modal"
      backdrop="static"
    >
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border">
            <span className="sr-only">loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="modal-header">
            <h2 className="modal-title">{edit ? "Edit" : "Add"} Shipment</h2>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setVisible(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              memo: shipment?.memo || "",
              type: shipment?.type || preSelectType || "RECEIVE",
              date: shipment?.date || new Date(),
              Depot: shipment?.Depot || preSelectDepot || null,
            }}
            validationSchema={Yup.object({
              memo: Yup.string(),
              type: Yup.string().oneOf(
                ["SEND", "RECEIVE", "RETURN"],
                "Shipment type is invalid",
              ),
              Depot: Yup.object()
                .nullable(false)
                .typeError("Depot is required")
                .shape({
                  id: Yup.number("Error with depot ID").required(
                    "Depot is required",
                  ),
                }),
              date: Yup.date()
                .typeError("Shipment date is required")
                .required("Shipment date is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              //console.log(values);
              let newValues = {
                ...values,
                DepotId: values.Depot.id,
              };
              //Convert all "" to null values
              //this seems to make it angry when it gets to transport: false thing
              /*newValues = _.transform(
                newValues,
                (result, value, key) => {
                  return (result[key] = value === "" ? null : value);
                },
                {}
              );*/
              //console.log(newValues);

              if (edit) {
                editShipment({ id: shipment.id, ...newValues });
              } else addShipment(newValues);
            }}
          >
            {formikProps => {
              return (
                <>
                  <ModalBody>
                    <Form>
                      <Row>
                        <SelectInput label="Type" name="type" col={6}>
                          <option value="SEND">Send</option>
                          <option value="RECEIVE">Receive</option>
                          <option value="RETURN">Return</option>
                        </SelectInput>
                        <Col xs={6}>
                          <DepotDropdown
                            label="Depot"
                            name="Depot"
                            {...formikProps}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <DateTimeInput
                          label={`Shipment ${formikProps.values.type.toLowerCase()} date`}
                          name="date"
                          col={6}
                        />
                      </Row>
                      <Row>
                        <TextInput
                          label="Internal Memo"
                          name="memo"
                          col={12}
                          type="textarea"
                        />
                      </Row>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={formikProps.submitForm}
                      color="primary"
                      type="submit"
                      data-testid="submitbutton"
                    >
                      Done
                    </Button>
                  </ModalFooter>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </Modal>
  );
};

ShipmentModal.propTypes = {
  edit: PropTypes.bool,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  shipment: PropTypes.object,
  addShipment: PropTypes.func,
  editShipment: PropTypes.func,
  loading: PropTypes.bool,
};

export default ShipmentModal;
