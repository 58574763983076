import React, { useState, useRef } from "react";
//import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import TableIcons from "App/components/Tables/TableIcons";
import RemoteTable from "App/components/Tables/RemoteTable/RemoteTable";
import hasPermission from "App/helpers/hasPermission";
import UserButtonContainer from "App/components/Buttons/UserButton/UserButton.container";
import JobKind from "App/components/Badges/JobKind";
import JobStatus from "App/components/Badges/JobStatus";
import { useHistory } from "react-router-dom";
import JobModalContainer from "App/components/Modals/JobModal/JobModal.container";
import DropdownWithPermissions from "App/components/Dropdowns/DropdownWithPermissions";

const JobsListContainer = () => {
  let history = useHistory();
  const remoteTable = useRef();
  const [jobModal, setJobModal] = useState({
    id: null,
    visible: false,
    edit: false,
  });

  const actions = {
    newJob: () => setJobModal({ id: null, visible: true, edit: false }),
    editJob: id => setJobModal({ id, visible: true, edit: true }),
  };

  const columns = [
    {
      title: "ID",
      field: "id",
      sortModel: null,
      sortField: "id",
      sorting: true,
      render: row => row?.reference,
    },
    {
      title: "Kind",
      field: "Kind.name",
      sortModel: ["Kind"],
      sortField: "name",
      sorting: true,
      render: row => <JobKind {...row?.Kind} />,
    },
    {
      title: "Name",
      field: "name",
      sortModel: null,
      sortField: "name",
      sorting: true,
    },
    {
      title: "Contact",
      field: "PrimaryContact.fullName",
      sortModel: [["Contact", "PrimaryContact"]],
      sortField: "firstName",
      sorting: true,
    },
    {
      title: "Company",
      field: "Company.name",
      sortModel: ["Company"],
      sortField: "name",
      sorting: true,
    },
    {
      title: "Manager",
      field: "Manager.fullName",
      sortModel: ["Manager"],
      sortField: "firstName",
      sorting: true,
      disableClick: true,
      render: row =>
        row?.Manager?.id ? (
          <UserButtonContainer id={row.Manager.id} />
        ) : (
          "Unknown user"
        ),
    },
    {
      title: "Status",
      field: "JobStatus.name",
      sortModel: ["JobStatus"],
      sortField: "name",
      sorting: true,
      render: row => <JobStatus {...row?.JobStatus} />,
    },
    {
      title: "Options",
      field: "id",
      sorting: false,
      disableClick: true,
      render: row => (
        <UncontrolledButtonDropdown size="sm" color="primary">
          <DropdownToggle color="primary" caret>
            Options
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Job</DropdownItem>
            <DropdownWithPermissions
              permission="JOB_EDIT"
              callback={() => actions.editJob(row.id)}
              text="Edit"
              testid="editjob"
              tooltip="You do not have permission to edit jobs"
            />
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      ),
    },
  ];
  return (
    <>
      <RemoteTable
        apiEndpoint="/jobs"
        title="Jobs"
        defaultSortModels={null}
        defaultSortField="name"
        defaultSortOrder="ASC"
        columns={columns}
        actions={actions}
        ref={remoteTable}
        onRowClick={(event, row) => history.push(`/app/jobs/${row.id}`)}
        tableActions={(() => {
          let tableActions = [];
          //"isFreeAction" means it appears not when selected, its by search bar
          //has permission to delete/restore users
          if (hasPermission("JOB_CREATE")) {
            tableActions.push({
              tooltip: "New Job",
              isFreeAction: true,
              icon: TableIcons.Add,
              onClick: (evt, data) => actions.newJob(),
            });
          }
          return tableActions;
        })()}
      />
      <JobModalContainer
        id={jobModal.id}
        visible={jobModal.visible}
        setVisible={visible => setJobModal({ ...jobModal, visible })}
        edit={jobModal.edit}
        done={newId =>
          newId
            ? history.push(`/app/jobs/${newId}`)
            : remoteTable.current.refreshData()
        }
      />
    </>
  );
};

export default JobsListContainer;
