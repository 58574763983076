import React, { useEffect, useCallback, useState } from "react";
import {
  Card,
  Button,
  Row,
  Spinner,
  Col,
  Container,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import PageContainer from "App/components/PageContainer";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import hasPermission from "App/helpers/hasPermission";
import { useParams, NavLink, useHistory } from "react-router-dom";
import JobKind from "App/components/Badges/JobKind";
import UserButtonContainer from "App/components/Buttons/UserButton/UserButton.container";
import JobStatus from "App/components/Badges/JobStatus";
import ContactCard from "App/components/Cards/ContactCard";
import CompanyCard from "App/components/Cards/CompanyCard";
import JobSupplyingTab from "App/components/Tabs/JobSupplyingTab";
import JobOrderingTab from "App/components/Tabs/JobOrderingTab";
import JobModalContainer from "App/components/Modals/JobModal/JobModal.container";
import EmailsTab from "App/components/Tabs/EmailsTab";
import DocumentsTab from "App/components/Tabs/DocumentsTab";
const moment = require("moment");

const Job = () => {
  const { id: jobId, tab = "overview" } = useParams();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  let history = useHistory();

  useEffect(() => {
    getJob();
  }, [jobId]); // eslint-disable-line

  const getJob = useCallback(() => {
    setLoading(true);
    axiosAPI
      .get(`/jobs/${jobId}?showDeleted=true`)
      .then(result => {
        setJob(result.data || null);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [jobId]); // eslint-disable-line

  return (
    <PageContainer
      title={job?.name}
      secondaryTitle={`for ${job?.Company?.name || ""}`}
    >
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>

      <Row>
        <div className="col">
          <Card className="shadow">
            {loading || !job ? (
              <Spinner
                type="grow"
                className="d-block my-8 mx-auto"
                color="warning"
              />
            ) : (
              <>
                <Nav tabs className="main-tabs">
                  <NavItem>
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={`/app/jobs/${jobId}`}
                      exact={true}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  {hasPermission("JOB_SUP_LIST") && (
                    <NavItem>
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to={`/app/jobs/${jobId}/quoting`}
                      >
                        Quoting
                      </NavLink>
                    </NavItem>
                  )}
                  {hasPermission("JOB_ORDERING_VIEW") && (
                    <NavItem>
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to={`/app/jobs/${jobId}/ordering`}
                      >
                        Ordering
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem>
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={`/app/jobs/${jobId}/billing`}
                    >
                      Billing
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={`/app/jobs/${jobId}/files`}
                    >
                      Files
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={`/app/jobs/${jobId}/documents`}
                    >
                      Documents
                    </NavLink>
                  </NavItem>
                  {hasPermission("EMAIL_VIEW") && (
                    <NavItem>
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to={`/app/jobs/${jobId}/emails`}
                      >
                        Emails
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={tab}>
                  <TabPane tabId="overview">
                    <Container fluid className="py-md-4">
                      <Row className="pb-3">
                        <Col>
                          <Button onClick={() => history.push("/app/jobs")}>
                            Back to Jobs
                          </Button>
                          <Button onClick={() => getJob()}>Refresh</Button>
                          <Button
                            color="warning"
                            style={{ color: "#000" }}
                            onClick={() => setEditModalVisible(true)}
                          >
                            Edit
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h2>Details</h2>
                        </Col>
                      </Row>
                      <hr className="mt-0 mb-3" />
                      <Row>
                        <Col xs={12} lg={6} xl={4}>
                          <Table bordered responsive className="headersInRows">
                            <tbody>
                              <tr>
                                <th scope="row">Job ID</th>
                                <td>{job?.reference}</td>
                              </tr>
                              <tr>
                                <th scope="row">Depot</th>
                                <td>
                                  {job?.Depot?.name}
                                  <small className="ml-1 muted">
                                    {job?.Depot?.Address?.county}
                                  </small>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Manager</th>
                                <td>
                                  <UserButtonContainer id={job?.Manager?.id} />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Kind</th>
                                <td>
                                  <JobKind {...job?.Kind} />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Status</th>
                                <td>
                                  <JobStatus {...job?.JobStatus} />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Customer Reference</th>
                                <td>{job?.customerReference}</td>
                              </tr>
                              <tr>
                                <th scope="row">Default Discount</th>
                                <td>{job?.defaultDiscount}%</td>
                              </tr>
                              <tr>
                                <th scope="row">Memo</th>
                                <td>{job?.memo}</td>
                              </tr>
                              <tr>
                                <th scope="row">Created At</th>
                                <td>
                                  {moment(job?.createdAt).format(
                                    "ddd Do MMM YYYY, HH:mm",
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                        <Col xs={12} lg={6} xl={4}>
                          <ContactCard
                            contact={job?.PrimaryContact}
                            badge="Primary Contact"
                          />
                        </Col>
                        <Col xs={12} lg={6} xl={4}>
                          <CompanyCard company={job?.Company} badge="Client" />
                        </Col>
                      </Row>
                      {/*<Row className="mt-3">
                        <Col>
                          <h2>Statistics</h2>
                        </Col>
                      </Row>
                      <hr className="mt-0 mb-3" />
                      <Row>
                        <Col lg="6" xl="3">
                          <Card className="card-stats mb-4 mb-xl-0">
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                  >
                                    Traffic
                                  </CardTitle>
                                  <span className="h2 font-weight-bold mb-0">
                                    350,897
                                  </span>
                                </div>
                                <Col className="col-auto">
                                  <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                    <i className="fas fa-chart-bar" />
                                  </div>
                                </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-muted text-sm">
                                <span className="text-success mr-2">
                                  <i className="fa fa-arrow-up" /> 3.48%
                                </span>{" "}
                                <span className="text-nowrap">
                                  Since last month
                                </span>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="6" xl="3">
                          <Card className="card-stats mb-4 mb-xl-0">
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                  >
                                    New users
                                  </CardTitle>
                                  <span className="h2 font-weight-bold mb-0">
                                    2,356
                                  </span>
                                </div>
                                <Col className="col-auto">
                                  <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                    <i className="fas fa-chart-pie" />
                                  </div>
                                </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-muted text-sm">
                                <span className="text-danger mr-2">
                                  <i className="fas fa-arrow-down" /> 3.48%
                                </span>{" "}
                                <span className="text-nowrap">
                                  Since last week
                                </span>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="6" xl="3">
                          <Card className="card-stats mb-4 mb-xl-0">
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                  >
                                    Sales
                                  </CardTitle>
                                  <span className="h2 font-weight-bold mb-0">
                                    924
                                  </span>
                                </div>
                                <Col className="col-auto">
                                  <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                                    <i className="fas fa-users" />
                                  </div>
                                </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-muted text-sm">
                                <span className="text-warning mr-2">
                                  <i className="fas fa-arrow-down" /> 1.10%
                                </span>{" "}
                                <span className="text-nowrap">
                                  Since yesterday
                                </span>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="6" xl="3">
                          <Card className="card-stats mb-4 mb-xl-0">
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                  >
                                    Performance
                                  </CardTitle>
                                  <span className="h2 font-weight-bold mb-0">
                                    49,65%
                                  </span>
                                </div>
                                <Col className="col-auto">
                                  <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                    <i className="fas fa-percent" />
                                  </div>
                                </Col>
                              </Row>
                              <p className="mt-3 mb-0 text-muted text-sm">
                                <span className="text-success mr-2">
                                  <i className="fas fa-arrow-up" /> 12%
                                </span>{" "}
                                <span className="text-nowrap">
                                  Since last month
                                </span>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>*/}
                    </Container>
                  </TabPane>
                  <TabPane tabId="quoting">
                    {hasPermission("JOB_SUP_LIST") ? (
                      <JobSupplyingTab
                        job={job}
                        isFocused={tab === "quoting"}
                      />
                    ) : (
                      "You do not have permission to view job supplying lists"
                    )}
                  </TabPane>
                  <TabPane tabId="ordering">
                    {hasPermission("JOB_ORDERING_VIEW") ? (
                      <JobOrderingTab
                        job={job}
                        isFocused={tab === "ordering"}
                      />
                    ) : (
                      "You do not have permission to view job ordering"
                    )}
                  </TabPane>
                  <TabPane tabId="documents">
                    {hasPermission(null) ? (
                      <DocumentsTab
                        jobId={jobId}
                        isFocused={tab === "documents"}
                      />
                    ) : (
                      "You do not have permission to view documents"
                    )}
                  </TabPane>
                  <TabPane tabId="emails">
                    {hasPermission("EMAIL_VIEW") ? (
                      <EmailsTab jobId={jobId} isFocused={tab === "emails"} />
                    ) : (
                      "You do not have permission to view emails"
                    )}
                  </TabPane>
                </TabContent>
              </>
            )}
          </Card>
        </div>
      </Row>
      <JobModalContainer
        id={jobId}
        visible={editModalVisible}
        setVisible={visible => setEditModalVisible(visible)}
        edit={true}
        done={() => getJob()}
      />
    </PageContainer>
  );
};

export default Job;
