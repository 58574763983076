import initialState from "App/redux/initialState";
import * as actions from "./actions";

export const app = (state = initialState.app, action) => {
  switch (action.type) {
    case actions.APP_STARTED:
      return {
        ...state,
        started: true,
      };
    default:
      return state;
  }
};
