import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import hasPermission from "App/helpers/hasPermission";
import ReactTooltip from "react-tooltip";
const { uniqueId } = require("lodash");

const ButtonWithPermissions = ({
  permission,
  testid,
  tooltip,
  children,
  disabled = false, //an extra second conditional to check against
  disabledTooltip, //and its associated error
  visible = true,
  hoverChildren = null,
  hoverColor = null,
  outlineWhenNotHovered = false,
  color,
  ...rest
}) => {
  const [hover, setHover] = useState(false);
  let id = uniqueId("ButtonWithPermissions_");
  let enabled = hasPermission(permission);
  if (!visible) return null;
  return (
    <>
      <span data-tip data-for={id}>
        <Button
          disabled={!enabled || !!disabled}
          data-testid={testid}
          outline={outlineWhenNotHovered && !hover ? true : false}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          color={
            color ? (hover && hoverColor ? hoverColor : color) : "secondary"
          }
          {...rest}
        >
          {(!hoverChildren || !hover) && children}
          {!!hoverChildren && hover && hoverChildren}
        </Button>
      </span>
      {!enabled ? (
        <ReactTooltip id={id} place="top" type="dark" effect="solid">
          {tooltip}
        </ReactTooltip>
      ) : (
        !!disabled && (
          <ReactTooltip id={id} place="top" type="dark" effect="solid">
            {disabledTooltip}
          </ReactTooltip>
        )
      )}
    </>
  );
};

ButtonWithPermissions.propTypes = {
  permission: PropTypes.string,
  testid: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
  visible: PropTypes.bool,
};

export default ButtonWithPermissions;
