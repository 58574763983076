import React, { useState, useRef } from "react";
//import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ProductModalContainer from "App/components/Modals/ProductModal/ProductModal.container";
import RemoteTable from "App/components/Tables/RemoteTable/RemoteTable";
import DropdownWithPermissions from "App/components/Dropdowns/DropdownWithPermissions";

const ProductsListContainer = () => {
  const [productModalVisible, setProductModalVisible] = useState(false);
  const [productModal, setProductModal] = useState({});
  const [productModalEdit, setProductModalEdit] = useState(false);
  const remoteTable = useRef();

  const actions = {
    editProduct: product => {
      setProductModal(product);
      setProductModalEdit(true);
      setProductModalVisible(true);
    },
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      sortModel: null,
      sortField: "name",
      sorting: true,
    },
    {
      title: "Manufacturer",
      field: "Manufacturer.name",
      sortModel: ["Manufacturer"],
      sortField: "name",
      sorting: true,
      render: row => (
        <>
          {row.Manufacturer.name}
          <br />
          <small className="text-muted">({row.Manufacturer.key})</small>
        </>
      ),
    },
    {
      title: "Manufacturer Part Code",
      field: "manufacturerProductCode",
      sortModel: null,
      sortField: "manufacturerProductCode",
      sorting: true,
    },
    {
      title: "Options",
      field: "id",
      sorting: false,
      render: row => (
        <UncontrolledButtonDropdown size="sm" color="primary">
          <DropdownToggle color="primary" caret>
            Options
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Product</DropdownItem>
            <DropdownWithPermissions
              permission="PROD_EDIT"
              callback={() => actions.editProduct(row)}
              text="Edit"
              tooltip="You do not have permission to view Product detail"
              visible={!row.deletedAt}
            />
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      ),
    },
  ];
  return (
    <>
      <RemoteTable
        apiEndpoint="/products"
        title="Products"
        defaultSortModels={["Manufacturer"]}
        defaultSortField="name"
        defaultSortOrder="ASC"
        columns={columns}
        actions={actions}
        ref={remoteTable}
      />
      <ProductModalContainer
        id={productModal.id}
        visible={productModalVisible}
        setVisible={setProductModalVisible}
        edit={productModalEdit}
        done={() => {
          remoteTable.current.refreshData();
        }}
      />
    </>
  );
};

export default ProductsListContainer;
