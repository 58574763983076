import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, Row, Button, Col } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ContactInput from "App/components/Inputs/ContactInput";
import {
  TextInput,
  CheckboxInput,
  DateInput,
} from "App/components/Inputs/BasicFormInputs.js";
import AccountEnvironmentDropdown from "App/components/Dropdowns/AccountEnvironmentDropdown";
import DepotDropdown from "App/components/Dropdowns/DepotDropdown";

const PurchaseOrderModal = ({
  edit = false,
  visible = false,
  setVisible,
  jobId = null,
  purchaseOrder = {},
  addPurchaseOrder,
  editPurchaseOrder,
  loading,
}) => {
  return (
    <Modal
      className="modal-dialog-centered formModal"
      isOpen={visible}
      data-testid="modal"
      backdrop="static"
    >
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border">
            <span className="sr-only">loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="modal-header">
            <h2 className="modal-title">
              {edit ? "Edit" : "Add"} Purchase Order
            </h2>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setVisible(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: purchaseOrder?.name || "",
              transportRequired: purchaseOrder?.transportRequired ?? false,
              supplierReference: purchaseOrder?.supplierReference || "",
              memo: purchaseOrder?.memo || "",
              expectedDelivery: purchaseOrder?.expectedDelivery || null,
              number: purchaseOrder?.number || null,
              Contact: purchaseOrder?.Contact || null,
              PurchaseOrderStatus: purchaseOrder?.PurchaseOrderStatus || null,
              Depot: purchaseOrder?.Depot || null,
              AccountEnvironment: purchaseOrder?.AccountEnvironment || null,
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .required("Required")
                .max(200, "Cannot be longer than 200 characters"),
              transportRequired: Yup.boolean(),
              customerReference: Yup.string(),
              memo: Yup.string(),
              Contact: Yup.object()
                .nullable(false)
                .typeError("Contact / Company is required")
                .shape({
                  id: Yup.number("Error with contact ID").required(
                    "Contact / Company is required",
                  ),
                }),
              Depot: Yup.object()
                .nullable(false)
                .typeError("Depot is required")
                .shape({
                  id: Yup.number("Error with depot ID").required(
                    "Depot is required",
                  ),
                }),
              AccountEnvironment: Yup.object()
                .nullable(false)
                .typeError("Account Environment is required")
                .shape({
                  id: Yup.number("Error with Account Environment ID").required(
                    "Account Environment is required",
                  ),
                }),
              expectedDelivery: Yup.date(
                "Expected delivery date is not a valid date",
              ).nullable(),
            })}
            onSubmit={(values, { setSubmitting }) => {
              //console.log(values);
              let newValues = {
                ...values,
                JobId: jobId,
                ContactId: values.Contact.id,
                CompanyId: values.Contact.CompanyId,
                DepotId: values.Depot.id,
                AccountEnvironmentId: values.AccountEnvironment.id,
                authorised: purchaseOrder?.authorised ?? false,
              };
              //Convert all "" to null values
              //this seems to make it angry when it gets to transport: false thing
              /*newValues = _.transform(
                newValues,
                (result, value, key) => {
                  return (result[key] = value === "" ? null : value);
                },
                {}
              );*/
              //console.log(newValues);

              if (edit) {
                editPurchaseOrder({ id: purchaseOrder.id, ...newValues });
              } else addPurchaseOrder(newValues);
            }}
          >
            {formikProps => {
              return (
                <>
                  <ModalBody>
                    <Form>
                      <Row>
                        <TextInput label="Name" name="name" col={12} />
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <DepotDropdown
                            label="Depot"
                            name="Depot"
                            {...formikProps}
                          />
                        </Col>
                        <Col xs={6}>
                          <AccountEnvironmentDropdown
                            label="Account Enviromment"
                            name="AccountEnvironment"
                            {...formikProps}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <TextInput
                          label="Supplier Reference"
                          name="supplierReference"
                          col={6}
                        />
                        <DateInput
                          label="Expected Delivery Date"
                          name="expectedDelivery"
                          col={6}
                        />
                      </Row>
                      <Row>
                        <CheckboxInput
                          col={6}
                          enablevalidation={false}
                          name="transportRequired"
                        >
                          Transport Required
                        </CheckboxInput>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <ContactInput
                            label="Contact / Company"
                            name="Contact"
                            type="text"
                            placeholder=""
                            {...formikProps}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <TextInput
                          label="Internal Memo"
                          name="memo"
                          col={12}
                          type="textarea"
                        />
                      </Row>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={formikProps.submitForm}
                      color="primary"
                      type="submit"
                      data-testid="submitbutton"
                    >
                      Done
                    </Button>
                  </ModalFooter>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </Modal>
  );
};

PurchaseOrderModal.propTypes = {
  edit: PropTypes.bool,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  purchaseOrder: PropTypes.object,
  addPurchaseOrder: PropTypes.func,
  editPurchaseOrder: PropTypes.func,
  loading: PropTypes.bool,
};

export default PurchaseOrderModal;
