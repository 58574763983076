import React, { useState, useEffect } from "react";
//import PropTypes from "prop-types";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import RolesList from "./RolesList";
import AssignPermissionsModalContainer from "App/components/Modals/AssignPermissionsModal/AssignPermissionsModal.container";
import AssignUsersModalContainer from "App/components/Modals/AssignUsersModal/AssignUsersModal.container";
import RoleModalContainer from "App/components/Modals/RoleModal/RoleModal.container";

const RolesListContainer = () => {
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  const [assignUsersModal, setAssignUsersModal] = useState({
    assignedUsers: [],
    title: "",
    description: "",
    roleId: null,
    visible: false,
  });
  const [assignPermissionsModal, setAssignPermissionsModal] = useState({
    assignedPermissions: [],
    title: "",
    description: "",
    roleId: null,
    visible: false,
  });
  const [roleModalVisible, setRoleModalVisible] = useState(false);
  const [roleModalRole, setRoleModalRole] = useState({});
  const [roleModalEdit, setRoleModalEdit] = useState(false);

  const saveRoleUsers = ({ users, roleId }) => {
    setLoading(true);
    axiosAPI
      .post(`/roles/${roleId}/users`, { users })
      .then(result => getRoles())
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong updating role users, please retry",
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveRolePermissions = ({ permissions, roleId }) => {
    setLoading(true);
    axiosAPI
      .post(`/roles/${roleId}/permissions`, { permissions })
      .then(result => getRoles())
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong updating role permissions, please retry",
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //CHECK ON SERVER SIDE IF USERS ASSIGNED TO IT TOO!
  const deleteRole = async id => {
    setLoading(true);
    await axiosAPI
      .delete(`/roles/${id}`)
      .then(result => {
        getRoles();
      })
      .catch(error => {
        setErrorMessage(
          error?.errorMessage || "An error occurred while deleting user",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      })
      .finally(() => setLoading(false));
  };

  const getRoles = () => {
    setLoading(true);
    axiosAPI
      .get("/roles")
      .then(result => setRoles(result.data || []))
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting roles, please retry",
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getRoles();
  }, []); // eslint-disable-line

  return (
    <>
      {!!errorMessage && (
        <SweetAlert
          danger
          onConfirm={() => {
            setErrorMessage(null);
            setErrorMore(null);
          }}
          title="Error"
        >
          {errorMessage}
          {errorMore && (
            <>
              <br />
              <small>{errorMore}</small>
            </>
          )}
        </SweetAlert>
      )}
      <RolesList
        loading={loading}
        roles={roles}
        assignRoleUsers={options => setAssignUsersModal(options)}
        assignRolePermissions={options => setAssignPermissionsModal(options)}
        addRole={() => {
          setRoleModalRole({});
          setRoleModalEdit(false);
          setRoleModalVisible(true);
        }}
        editRole={role => {
          setRoleModalRole(role.id);
          setRoleModalEdit(true);
          setRoleModalVisible(true);
        }}
        deleteRole={deleteRole}
      />
      <RoleModalContainer
        edit={roleModalEdit}
        visible={roleModalVisible}
        setVisible={setRoleModalVisible}
        id={roleModalRole}
        done={() => getRoles()}
      />
      <AssignPermissionsModalContainer
        assignedPermissions={assignPermissionsModal.assignedPermissions} //pass in {expiresOn:2020-02...} to timelimit, expects: [ {id:5,expiresOn:null},{...} ]
        showExpiresOn={false}
        assignToTitle={assignPermissionsModal.title}
        assignToDescription={assignPermissionsModal.description}
        visible={assignPermissionsModal.visible}
        setVisible={visible =>
          setAssignPermissionsModal({ ...assignPermissionsModal, visible })
        }
        done={assignedPermissions => {
          saveRolePermissions({
            permissions: assignedPermissions,
            roleId: assignPermissionsModal.roleId,
          });
        }}
      />
      <AssignUsersModalContainer
        assignedUsers={assignUsersModal.assignedUsers} //pass in {expiresOn:2020-02...} to timelimit, expects: [ {id:5,expiresOn:null},{...} ]
        showExpiresOn={true}
        assignToTitle={assignUsersModal.title}
        assignToDescription={assignUsersModal.description}
        visible={assignUsersModal.visible}
        setVisible={visible =>
          setAssignUsersModal({ ...assignUsersModal, visible })
        }
        done={assignedUsers => {
          saveRoleUsers({
            users: assignedUsers,
            roleId: assignUsersModal.roleId,
          });
        }}
      />
    </>
  );
};

RolesListContainer.propTypes = {};

export default RolesListContainer;
