import axios from "axios";
import { store } from "App/redux/store";
import { userLogout, userNeedsToLogin } from "App/redux/user/actions";
import googleErrorReporting from "App/services/googleErrorReporting";
const _ = require("lodash");

const axiosAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: process.env.REACT_APP_API_TIMEOUT,
});

axiosAPI.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    console.log(error.response);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.status === 401) {
      //user not logged in
      store.dispatch(userLogout());
      store.dispatch(userNeedsToLogin());
      return Promise.reject({
        errorMessage: `You are not logged in`,
        errorMore: `[401] - You need to be logged in to access this resource`,
      });
    } else if (error.response?.status === 403) {
      return Promise.reject({
        errorMessage: `You do not have enough permissions to access this resource`,
        errorMore: `[403] - ${_.get(
          error,
          "response.data.errorMore",
          "You may need to request more permissions, or your account is disabled or you need to verify your email first.",
        )}`,
      });
    } else {
      const errorMessage = _.get(
        error,
        "response.data.errorMessage",
        "An unknown error occurred",
      );
      const errorMore = _.get(
        error,
        "response.data.errorMore",
        _.get(error, "response.data.error", "Unavailable"),
      );
      googleErrorReporting.report(
        `CLIENT ERROR: Axios HTTP Status [${error.response?.status}]: ${errorMessage} - Requested ${error?.response?.request?.responseURL} - ${errorMore}`,
      );
      return Promise.reject({
        errorMessage,
        errorMore: `[${error.response?.status}] - ${errorMore}`,
        data: _.get(error, "response.data", {}),
      });
    }
  },
);

export default axiosAPI;

export const CancelToken = axios.CancelToken;
