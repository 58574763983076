import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DepotTransferModal from "./DepotTransferModal";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";

const DepotTransferModalContainer = ({
  id,
  jobId,
  depotId,
  visible = false,
  setVisible,
  edit = false,
  done,
}) => {
  const [depotTransfer, setDepotTransfer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  useEffect(() => {
    if (visible && id) {
      getDepotTransfer(id);
    }
  }, [id, visible]); // eslint-disable-line

  const getDepotTransfer = id => {
    setLoading(true);
    axiosAPI
      .get(`/depotTransfers/${id}`)
      .then(result => {
        setDepotTransfer(result.data || null);
      })
      .catch(error => {
        setVisible(false);
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addDepotTransfer = async depotTransfer => {
    setLoading(true);
    await axiosAPI
      .post("/depotTransfers", depotTransfer)
      .then(result => {
        setVisible(false);
        done(result.data.id);
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage ||
            "An error occurred while adding new stock request",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  const editDepotTransfer = async depotTransfer => {
    setLoading(true);
    await axiosAPI
      .patch(`/depotTransfers/${depotTransfer.id}`, depotTransfer)
      .then(result => {
        setVisible(false);
        done();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage ||
            "An error occurred while editing stock request",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <DepotTransferModal
        visible={visible}
        depotTransfer={edit ? depotTransfer : {}}
        edit={edit}
        jobId={jobId}
        depotId={depotId}
        setVisible={setVisible}
        editDepotTransfer={editDepotTransfer}
        addDepotTransfer={addDepotTransfer}
        loading={loading}
      />
    </>
  );
};

DepotTransferModalContainer.propTypes = {
  id: PropTypes.number,
  jobId: PropTypes.number,
  depotId: PropTypes.number,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  edit: PropTypes.bool,
  done: PropTypes.func,
};

export default DepotTransferModalContainer;
