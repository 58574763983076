import React, { useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import { useField } from "formik";
import { Badge, FormGroup, Button } from "reactstrap";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import CompanyModal from "App/components/Modals/CompanyModal";
var classNames = require("classnames");
var qs = require("qs");

const CompanyInput = ({ label, ...props }) => {
  const [, meta] = useField(props); //field, meta
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [editId, setEditId] = useState(null);
  const input = useRef();
  let [error, setError] = useState("");

  const companiesSearch = async (query, callback) => {
    await axiosAPI
      .get("/companies?" + qs.stringify({ search: query }))
      .then(result => {
        return callback(result.data.rows);
      })
      .catch(error => {
        let errorMessage = error;
        if (!errorMessage) errorMessage = "An unknown error occurred";
        setError(errorMessage.toString());
      });
  };

  return (
    <>
      {!!error && (
        <SweetAlert
          danger
          onConfirm={() => {
            setError("");
          }}
          title="Error"
        >
          {error}
        </SweetAlert>
      )}
      <FormGroup
        className={classNames({
          suggestInputFormGroup: true,
          [`col-lg-${props.col}`]: !!props.col,
        })}
      >
        <label htmlFor={props.name}>{label}</label>
        <AsyncSelect
          isClearable
          classNamePrefix="suggestInput"
          className={classNames({
            "is-invalid": meta.touched && meta.error,
            "is-valid": meta.touched && !meta.error,
          })}
          styles={{
            input: (styles, state) => ({
              ...styles,
              height: "2.5rem",
              paddingTop: "0.5rem",
            }),
          }}
          value={props.values[props.name]}
          name={props.name}
          filterOption={() => true}
          getOptionValue={option => {
            return option?.id;
          }}
          ref={input}
          onBlur={() => props.setFieldTouched(props.name, true)}
          onChange={value => props.setFieldValue(props.name, value || null)}
          getOptionLabel={option => (
            <>
              {option.name}
              {!!option.suspended && (
                <Badge color="danger" className="ml-2">
                  Suspended
                </Badge>
              )}
            </>
          )}
          loadOptions={companiesSearch}
          noOptionsMessage={query => (
            <Button
              outline
              color="success"
              onClick={() => {
                setInitialValues(null);
                setEditId(null);
                setCompanyModalOpen(true);
              }}
            >
              Add Company
            </Button>
          )}
        />
        <Button
          size="sm"
          type="button"
          outline
          className="mt-2 float-right"
          color="success"
          onClick={() => {
            setInitialValues(null);
            setEditId(null);
            setCompanyModalOpen(true);
          }}
        >
          Add Company
        </Button>
        {!!props.values[props.name]?.id && ( // eslint-disable-line
          <Button
            size="sm"
            type="button"
            outline
            className="mt-2 float-right"
            color="default"
            onClick={() => {
              setInitialValues(props.values[props.name]);
              setEditId(props.values[props.name].id);
              setCompanyModalOpen(true);
            }}
          >
            Edit Company
          </Button>
        )}
        {meta.touched && (
          <div
            className={classNames({
              "invalid-feedback": !!meta.error,
              "valid-feedback": !meta.error,
              feedback: true,
            })}
          >
            {meta.error && Object.values(meta.error).map(error => error)}
          </div>
        )}
      </FormGroup>
      <CompanyModal
        isOpen={companyModalOpen}
        toggleModal={open => setCompanyModalOpen(open)}
        initialValues={initialValues}
        id={editId}
        setReturnValue={company => {
          input.current.select.select.blur();
          props.setFieldTouched(props.name, true);
          props.setFieldValue(props.name, company || null);
        }}
      />
    </>
  );
};

export default CompanyInput;
