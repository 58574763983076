import React, { useState, useRef } from "react";
//import PropTypes from "prop-types";
import LogsModal from "App/components/Modals/LogsModal/LogsModal";
import RemoteTable from "App/components/Tables/RemoteTable/RemoteTable";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DropdownWithPermissions from "App/components/Dropdowns/DropdownWithPermissions";
import UserButtonContainer from "App/components/Buttons/UserButton/UserButton.container";
const moment = require("moment");

const LogsListContainer = () => {
  const [logModalVisible, setLogModalVisible] = useState(false);
  const [logModalLog, setLogModalLog] = useState({});
  const remoteTable = useRef();

  const actions = {
    viewLog: log => {
      setLogModalLog(log);
      setLogModalVisible(true);
    },
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      sortModel: null,
      sortField: "name",
      sorting: false,
    },
    {
      title: "Description",
      field: "description",
      sortModel: null,
      sortField: "description",
      sorting: false,
    },
    {
      title: "User",
      field: "PermissionUser.fullName",
      sortModel: "PermissionUser",
      sortField: "fullName",
      sorting: false,
      render: row =>
        row?.PermissionUser?.id ? (
          <UserButtonContainer id={row.PermissionUser.id} />
        ) : (
          "Unknown user"
        ),
    },
    {
      title: "Timestamp",
      field: "updatedAt",
      type: "date",
      sortModel: null,
      sortField: "updatedAt",
      sorting: true,
      render: row => (
        <>
          {moment.duration(moment().diff(moment(row.updatedAt))).humanize()} ago
          <br />
          <small>on {moment(row.updatedAt).format("DD MMM YYYY")}</small>
        </>
      ),
    },
    {
      title: "Options",
      field: "id",
      sorting: false,
      render: row => (
        <UncontrolledButtonDropdown size="sm" color="primary">
          <DropdownToggle color="primary" caret>
            Options
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Log Entry</DropdownItem>
            <DropdownWithPermissions
              permission="LOG_VIEW_DETAIL"
              callback={() => actions.viewLog(row)}
              text="View Detail"
              tooltip="You do not have permission to view log detail"
              visible={!row.deletedAt}
            />
            {/*<DropdownItem divider />
            <DropdownWithPermissions
              permission="JOB_VIEW_ALL"
              callback={() => window.open("/app/job/" + row.JobId)}
              text="Open Job"
              tooltip="You do not have permission to view this job"
              visible={row.JobId}
            />
            <DropdownWithPermissions
              permission="USER_EDIT"
              callback={() => window.open("/app/job/" + row.JobId)}
              text="Edit User"
              tooltip="You do not have permission to edit this user"
              visible={row.UserId}
            />*/}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      ),
    },
  ];

  return (
    <>
      <RemoteTable
        apiEndpoint="/logs"
        title="Logs"
        defaultSortModels={null}
        defaultSortField="updatedAt"
        defaultSortOrder="DESC"
        columns={columns}
        actions={actions}
        ref={remoteTable}
      />
      <LogsModal
        visible={logModalVisible}
        log={logModalLog}
        setVisible={setLogModalVisible}
      />
    </>
  );
};

LogsListContainer.propTypes = {};

export default LogsListContainer;
