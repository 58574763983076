import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import axiosAPI from "App/services/axios";
import { Modal, Row, Alert, Table } from "reactstrap";
import styles from "./CompanyProfileModal.module.scss";
import currencyFormatter from "App/helpers/currencyFormatter";
const moment = require("moment");

const CompanyProfileModalContainer = ({ id, visible = false, setVisible }) => {
  const [company, setCompany] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const getCompany = useCallback(() => {
    setLoading(true);
    axiosAPI
      .get(`/companies/${id}`)
      .then(result => {
        setCompany(result.data || null);
      })
      .catch(error => {
        setError(true);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]); // eslint-disable-line

  useEffect(() => {
    getCompany();
  }, [id]); // eslint-disable-line

  if (!id) return null;

  const closeButton = (
    <button
      aria-label="Close"
      className={`close ${styles.close}`}
      data-dismiss="modal"
      type="button"
      onClick={() => setVisible(false)}
    >
      <span aria-hidden={true}>×</span>
    </button>
  );

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={visible}
      toggle={() => setVisible(!visible)}
      data-testid="profilemodal"
    >
      {!loading && (error || !company) ? (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Error</h2>
            {closeButton}
          </div>
          <div className="modal-body">
            <Alert color="danger">
              <strong>Error</strong> Could not load company - sorry about that!
            </Alert>
          </div>
        </>
      ) : loading ? (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Loading...</h2>
            {closeButton}
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center my-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">loading...</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="modal-header">
            <Row className="mx-0 w-100">
              <div className={`${styles.text} col-md-12 col-8 mt-2`}>
                <small className="text-muted">Company</small>
                <h2 className={`display-4 ${styles.nameHeader}`}>
                  {company.name}
                </h2>
              </div>
            </Row>
            {closeButton}
          </div>
          <div className="modal-body">
            <Table responsive>
              <tbody>
                {company?.website && (
                  <tr>
                    <td>Website</td>
                    <td>
                      <a
                        href={
                          company?.website.indexOf("http") === -1
                            ? `http://${company?.website}`
                            : company?.website
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {company.website}
                      </a>
                    </td>
                  </tr>
                )}
                {company?.phone && (
                  <tr>
                    <td>Phone</td>
                    <td>
                      <a href={`tel:${company.phone}`}>{company.phone}</a>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Status</td>
                  <td>
                    <span
                      style={{
                        color: company?.suspended ? "red" : "green",
                      }}
                    >
                      {company?.suspended
                        ? "Suspended: " + company.suspendedMemo
                        : "Active"}
                    </span>
                  </td>
                </tr>
                {company?.client && (
                  <tr>
                    <td>Client</td>
                    <td className="text-success">Yes</td>
                  </tr>
                )}
                {company?.vendor && (
                  <tr>
                    <td>Vendor</td>
                    <td className="text-success">Yes</td>
                  </tr>
                )}
                {!!company?.defaultDiscount && (
                  <tr>
                    <td>Default Discount</td>
                    <td>{company.defaultDiscount}%</td>
                  </tr>
                )}
                {!!company?.creditLimit && (
                  <tr>
                    <td>Credit Limit</td>
                    <td>{currencyFormatter(company.creditLimit)}</td>
                  </tr>
                )}
                {!!company?.creditPeriod && (
                  <tr>
                    <td>Credit Period</td>
                    <td>{company.creditPeriod} days</td>
                  </tr>
                )}
                {company?.memo && (
                  <tr>
                    <td>Memo</td>
                    <td>{company.memo}</td>
                  </tr>
                )}
                <tr>
                  <td>Company since</td>
                  <td>{moment(company.createdAt).format("DD MMM YYYY")}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </>
      )}
    </Modal>
  );
};

CompanyProfileModalContainer.propTypes = {
  id: PropTypes.number.isRequired,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
};

export default CompanyProfileModalContainer;
