import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import axiosAPI from "App/services/axios";
import { Modal, Row, Alert, Table } from "reactstrap";
import styles from "./ContactProfileModal.module.scss";
import CompanyButtonContainer from "App/components/Buttons/CompanyButton/CompanyButton.container";
const moment = require("moment");

const ContactProfileModalContainer = ({ id, visible = false, setVisible }) => {
  const [contact, setContact] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const getContact = useCallback(() => {
    setLoading(true);
    axiosAPI
      .get(`/contacts/${id}`)
      .then(result => {
        setContact(result.data || null);
      })
      .catch(error => {
        setError(true);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]); // eslint-disable-line

  useEffect(() => {
    getContact();
  }, [id]); // eslint-disable-line

  if (!id) return null;

  const closeButton = (
    <button
      aria-label="Close"
      className={`close ${styles.close}`}
      data-dismiss="modal"
      type="button"
      onClick={() => setVisible(false)}
    >
      <span aria-hidden={true}>×</span>
    </button>
  );

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={visible}
      toggle={() => setVisible(!visible)}
      data-testid="profilemodal"
    >
      {!loading && (error || !contact) ? (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Error</h2>
            {closeButton}
          </div>
          <div className="modal-body">
            <Alert color="danger">
              <strong>Error</strong> Could not load contact - sorry about that!
            </Alert>
          </div>
        </>
      ) : loading ? (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Loading...</h2>
            {closeButton}
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center my-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">loading...</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="modal-header">
            <Row className="mx-0 w-100">
              <div className={`${styles.text} col-md-12 col-8 mt-2`}>
                <small className="text-muted">Contact</small>
                <h2 className={`display-4 ${styles.nameHeader}`}>
                  {contact.fullName}
                </h2>
                <h4 style={{ fontWeight: 400 }}>
                  {contact?.jobTitle} at {contact?.Company?.name}
                </h4>
              </div>
            </Row>
            {closeButton}
          </div>
          <div className="modal-body">
            <Table responsive>
              <tbody>
                {contact?.email && (
                  <tr>
                    <td>Email</td>
                    <td>
                      <a href={`mailto:${contact?.email}`}>{contact.email}</a>
                    </td>
                  </tr>
                )}
                {contact?.work && (
                  <tr>
                    <td>Work</td>
                    <td>
                      <a href={`tel:${contact.work}`}>{contact.work}</a>
                    </td>
                  </tr>
                )}
                {contact?.mobile && (
                  <tr>
                    <td>Mobile</td>
                    <td>
                      <a href={`tel:${contact.mobile}`}>{contact.mobile}</a>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Status</td>
                  <td>
                    <span
                      style={{
                        color: contact?.status === 1 ? "green" : "red",
                      }}
                    >
                      {contact?.statusText}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Company</td>
                  <td>
                    <CompanyButtonContainer id={contact?.CompanyId} />
                  </td>
                </tr>
                {contact?.accounts && (
                  <tr>
                    <td>Accounts Contact</td>
                    <td className="text-success">Yes</td>
                  </tr>
                )}
                {contact?.memo && (
                  <tr>
                    <td>Memo</td>
                    <td>{contact.memo}</td>
                  </tr>
                )}
                <tr>
                  <td>Contact since</td>
                  <td>{moment(contact.createdAt).format("DD MMM YYYY")}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </>
      )}
    </Modal>
  );
};

ContactProfileModalContainer.propTypes = {
  id: PropTypes.number.isRequired,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
};

export default ContactProfileModalContainer;
