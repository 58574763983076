import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";
import Select from "react-select";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { useField } from "formik";
const classNames = require("classnames");
const qs = require("qs");

const UserDropdown = ({
  //works with any api endpoint that gives id, name and colour
  className = "",
  placeholder = "Search...",
  label,
  ...props
}) => {
  const [, meta] = useField(props);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  const loadOptions = async query => {
    setLoading(true);
    axiosAPI
      .get(`/users?` + qs.stringify({ search: query }))
      .then(result => {
        setOptions(result.data.slice(0, 5));
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    //preload options
    loadOptions();
  }, []); // eslint-disable-line

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <FormGroup className="suggestInputFormGroup">
        <label htmlFor={props.id || props.name}>{label}</label>
        <Select
          classNamePrefix="suggestInput"
          className={classNames({
            "is-invalid": meta.touched && meta.error,
            "is-valid": meta.touched && !meta.error,
          })}
          value={props.values[props.name]}
          ref={props.setRef}
          name={props.name}
          onBlur={() => props.setFieldTouched(props.name, true)}
          onChange={value => props.setFieldValue(props.name, value || null)}
          noOptionsMessage={query => <>No users found</>}
          label={placeholder}
          placeholder={placeholder}
          options={options}
          isLoading={loading}
          isClearable
          filterOption={() => true}
          onInputChange={query => {
            loadOptions(query);
          }}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.fullName}
        />
        {meta.touched && (
          <div
            className={classNames({
              "invalid-feedback": !!meta.error,
              "valid-feedback": !meta.error,
              feedback: true,
            })}
          >
            {meta.error && Object.values(meta.error).map(error => error)}
          </div>
        )}
      </FormGroup>
    </>
  );
};

UserDropdown.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

export default UserDropdown;
