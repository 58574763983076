import React from "react";
import { Row, Card, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import PageContainer from "App/components/PageContainer";
import UsersListContainer from "App/components/Tables/UsersList/UsersList.container";
import RolesListContainer from "App/components/Tables/RolesList/RolesList.container";
import LogsListContainer from "App/components/Tables/LogsList/LogsList.container";
import hasPermission from "App/helpers/hasPermission";
import { useParams, NavLink } from "react-router-dom";

const Access = () => {
  const { tab = "users" } = useParams();

  return (
    <PageContainer title="Access">
      <Row>
        <div className="col">
          <Card className="shadow">
            <Nav tabs className="main-tabs">
              <NavItem>
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/app/access"
                  exact={true}
                >
                  Users
                </NavLink>
              </NavItem>
              {hasPermission("ROLE_LIST") && (
                <NavItem>
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/app/access/roles"
                  >
                    Roles
                  </NavLink>
                </NavItem>
              )}
              {hasPermission("LOG_VIEW") && (
                <NavItem>
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/app/access/logs"
                  >
                    Logs
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={tab}>
              <TabPane tabId={"users"}>
                {hasPermission("USER_LIST") ? (
                  <UsersListContainer />
                ) : (
                  "You do not have permission to view users"
                )}
              </TabPane>
              <TabPane tabId={"roles"}>
                {hasPermission("ROLE_LIST") ? (
                  <RolesListContainer />
                ) : (
                  "You do not have permission to view roles"
                )}
              </TabPane>
              <TabPane tabId={"logs"}>
                {hasPermission("LOG_VIEW") ? (
                  <LogsListContainer />
                ) : (
                  "You do not have permission to view logs"
                )}
              </TabPane>
            </TabContent>
          </Card>
        </div>
      </Row>
    </PageContainer>
  );
};

export default Access;
