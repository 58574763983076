import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, Row, Button } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Table } from "reactstrap";
import { TextInput } from "App/components/Inputs/BasicFormInputs.js";
import NumberFormat from "react-number-format";
const moment = require("moment");
//const fullName = _.get(log, "PermissionUser.fullName", "Unknown user");

const ProductModal = ({
  edit = false,
  visible = false,
  setVisible,
  product = {},
  addProduct,
  editProduct,
  loading,
}) => {
  return (
    <Modal
      className="modal-dialog-centered formModal"
      isOpen={visible}
      data-testid="modal"
      backdrop="static"
    >
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border" product="status">
            <span className="sr-only">loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="modal-header">
            <h2 className="modal-title">
              {edit ? "Edit Product" : "Add Product"}
            </h2>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setVisible(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: product?.name || "",
              memo: product?.memo || "",
              length: product?.length || "",
              width: product?.width || "",
              height: product?.height || "",
              weight: product?.weight || "",
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .required("Insert a name you pleb")
                .max(200, "Cannot be longer than 200 characters"),
              memo: Yup.string(),
              length: Yup.number()
                .transform((v, o) => (o === "" ? null : v)) // eslint-disable-line
                .positive()
                .nullable(true)
                .default(null),
              width: Yup.number(),
              height: Yup.number(),
              weight: Yup.number(),
            })}
            onSubmit={(values, { setSubmitting }) => {
              if (edit) {
                console.log(values);
                editProduct({ id: product.id, ...values });
              } else addProduct(values);
            }}
          >
            {formikProps => {
              return (
                <>
                  <ModalBody>
                    <Form>
                      <Row>
                        <TextInput label="Name" name="name" col={12} />
                      </Row>
                      <Row>
                        <TextInput label="Memo" name="memo" col={12} />
                      </Row>
                      <Row>
                        <TextInput label="Length" name="length" col={2} />
                        <TextInput label="Width" name="width" col={2} />
                        <TextInput label="Height" name="height" col={2} />
                        <TextInput label="Weight" name="weight" col={2} />
                      </Row>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={formikProps.submitForm}
                      color="primary"
                      type="submit"
                      data-testid="submitbutton"
                    >
                      {edit ? "Edit Product" : "Add Product"}
                    </Button>
                  </ModalFooter>
                </>
              );
            }}
          </Formik>

          {product?.PriceListItems?.length === 0 ? (
            <h5 className="text-center">No vendors for this product found.</h5>
          ) : (
            <Table>
              <tr>
                <th>Vendor</th>
                <th>Date Active</th>
                <th>List Price</th>
                <th>Line Price</th>
                <th>Cost Price</th>
              </tr>
              {product?.PriceListItems?.map(priceListItem => (
                <tr>
                  <td>{priceListItem?.PriceList?.Company?.name}</td>
                  <td>
                    {moment(priceListItem?.PriceList?.startDate).format("lll")}
                  </td>
                  <td>
                    <NumberFormat
                      value={priceListItem?.listPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"£"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={priceListItem?.linePrice}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"£"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={priceListItem?.costPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"£"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
              ))}
            </Table>
          )}
        </>
      )}
    </Modal>
  );
};

ProductModal.propTypes = {
  edit: PropTypes.bool,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  product: PropTypes.object,
  addProduct: PropTypes.func,
  editProduct: PropTypes.func,
  loading: PropTypes.bool,
};

export default ProductModal;
