import React, { useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import AdminFooter from "App/components/Footers/AdminFooter.js";
import Sidebar from "App/components/Sidebar/Sidebar.js";
import Dashboard from "App/pages/Dashboard";
import Jobs from "App/pages/Jobs";
import Job from "App/pages/Job";
import Access from "App/pages/Access";
import routes from "App/routes";
import Products from "App/pages/Products";
import Contacts from "App/pages/Contacts";
import Product from "App/pages/Product";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as userActions from "App/redux/user/actions";
import * as appActions from "App/redux/app/actions";
//import io from "socket.io-client";
import axiosAPI from "App/services/axios";

const AppContainer = ({
  appStarted,
  userLoginSuccess,
  userNeedsToLogin,
  user,
  ...props
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    appStarted();
    axiosAPI
      .get("/users/me")
      .then(result => {
        userLoginSuccess(result.data);
        return true;
      })
      .catch(error => {
        console.error(error);
        userNeedsToLogin();
      });
  }, []); // eslint-disable-line

  return !user.loggedIn ? null : (
    <>
      <ToastContainer />
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/dashboard",
          imgSrc: require("App/assets/images/logo.svg"),
          imgAlt: "Helix",
        }}
      />
      <div className="main-content">
        <Switch>
          <Route path="/app/dashboard">
            <Dashboard />
          </Route>
          <Route path="/app/jobs/:id/:tab?">
            <Job />
          </Route>
          <Route path="/app/jobs">
            <Jobs />
          </Route>
          <Route path="/app/access/:tab?">
            <Access />
          </Route>
          <Route path="/app/products/:tab?">
            <Products />
          </Route>
          <Route path="/app/product/:id">
            <Product />
          </Route>
          <Route path="/app/contacts/:tab?">
            <Contacts />
          </Route>
          <Redirect from="*" to="/app/dashboard" />
        </Switch>

        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return { user: state.user };
};

const mapDispatchToProps = dispatch => {
  return {
    appStarted: () => {
      dispatch(appActions.appStarted());
    },
    userLoginSuccess: user => {
      dispatch(userActions.userLoginSuccess(user));
    },
    userNeedsToLogin: () => {
      dispatch(userActions.userNeedsToLogin());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
