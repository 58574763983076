export default {
  app: {
    started: false,
  },
  user: {
    loading: false, //checking theyre a valid pleb
    loggedIn: false, //theyve just logged in, so say hi: aka "Welcome aboard"
    loggedOut: false, //theyve requested to be logged out so say bye bye: aka "See you next time!"
    needsLogin: false, //access a page they need to login to see: aka "Please login"
    error: null,
    info: {},
  },
  users: {
    loading: true,
    error: null,
    loadedOn: null,
    results: [],
  },
  depots: {
    loading: true,
    error: null,
    loadedOn: null,
    results: [],
  },
};
