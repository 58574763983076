export const DEPOTS_GET = "DEPOTS_GET";
export const DEPOTS_GET_SUCCESS = "DEPOTS_GET_SUCCESS";
export const DEPOTS_GET_FAILURE = "DEPOTS_GET_FAILURE";

export const depotsGet = () => ({
  type: DEPOTS_GET,
});

export const depotsGetSuccess = depots => ({
  type: DEPOTS_GET_SUCCESS,
  payload: depots,
});

export const depotsGetFailure = error => ({
  type: DEPOTS_GET_FAILURE,
  payload: error,
});
