import initialState from "App/redux/initialState";
import * as actions from "./actions";

const user = (state = initialState.user, action) => {
  switch (action.type) {
    case actions.USER_LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actions.USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedOut: false,
        loggedIn: true,
        error: null,
        info: action.payload,
      };
    case actions.USER_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        loggedOut: false,
        loggedIn: false,
        error: action.payload,
      };
    case actions.USER_LOGIN_CLEAR:
      return {
        ...initialState.user,
      };
    case actions.USER_LOGOUT:
      return {
        ...initialState.user,
        loggedOut: true,
      };
    case actions.USER_NEEDS_LOGIN:
      return {
        ...initialState.user,
        needsLogin: true,
      };
    default:
      return state;
  }
};

export default user;
