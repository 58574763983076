import initialState from "App/redux/initialState";
import * as actions from "./actions";

export const depots = (state = initialState.depots, action) => {
  switch (action.type) {
    case actions.DEPOTS_GET:
      return {
        ...state,
        loading: true,
      };
    case actions.DEPOTS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        loadedOn: Date.now(),
        results: action.payload,
      };
    case actions.DEPOTS_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loadedOn: null,
        results: [],
      };
    default:
      return state;
  }
};
