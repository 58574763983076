import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
const qs = require("qs");
const manufacturerKeyGenerator =
  require("App/helpers/manufacturerKeyGenerator").default;

const ManufacturerCreatableDropdown = ({
  preload = false,
  className = "",
  placeholder = "Lookup or create manufacturer",
  value,
  setValue,
  numberOfOptions = 5,
}) => {
  const [manufacturerLoading, setManufacturerLoading] = useState(false);
  const [manufacturerDisabled, setManufacturerDisabled] = useState(false);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  const manufacturersCreate = async name => {
    if (name.toString().trim() === "") {
      setErrorMessage("Empty manufacturer name not allowed");
      setErrorMore(
        "Please ensure the manufacturer name is set before trying to create",
      );
      return;
    }
    setManufacturerLoading(true);
    setManufacturerDisabled(true);
    await axiosAPI
      .post("/manufacturers", {
        name,
        key: manufacturerKeyGenerator(name),
      })
      .then(result => {
        setValue(result.data);
        setManufacturerOptions(
          [...manufacturerOptions, { ...result.data }].slice(
            0,
            numberOfOptions,
          ),
        );
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong creating manufacturer, please retry",
        );
        console.error(error);
      })
      .finally(() => {
        setManufacturerLoading(false);
        setManufacturerDisabled(false);
      });
  };

  const manufacturersSearch = async query => {
    setManufacturerLoading(true);
    await axiosAPI
      .get("/manufacturers?" + qs.stringify({ search: query }))
      .then(({ data: { rows } }) => {
        setManufacturerOptions(rows || [].slice(0, numberOfOptions));
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting manufacturers, please retry",
        );
        console.error(error);
      })
      .finally(() => setManufacturerLoading(false));
  };

  useEffect(() => {
    //preload options
    if (preload) manufacturersSearch();
  }, []); // eslint-disable-line

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <CreatableSelect
        className={className}
        placeholder={placeholder}
        isClearable
        blurInputOnSelect={true}
        filterOption={() => true}
        onInputChange={(query, actionMeta) => {
          //console.log("INPUT", query, actionMeta);
          manufacturersSearch(query);
        }}
        onChange={(option, actionMeta) => {
          //console.log("CHANGE", value, actionMeta);
          if (option?.__isNew__) {
            manufacturersCreate(option.value);
          } else {
            setValue(option);
          }
        }}
        options={manufacturerOptions}
        getOptionValue={option => {
          return option.id;
        }}
        getOptionLabel={option => {
          if (option?.__isNew__) return `Create '${option.value}'`;
          return (
            <>
              {option.name}
              <small style={{ marginLeft: "5px" }}>({option.key})</small>
            </>
          );
        }}
        value={value}
        isDisabled={manufacturerDisabled}
        isLoading={manufacturerLoading}
      />
    </>
  );
};

ManufacturerCreatableDropdown.propTypes = {
  preload: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  setValue: PropTypes.func,
  numberOfOptions: PropTypes.number,
};

export default ManufacturerCreatableDropdown;
