import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";
import Select from "react-select";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import chroma from "chroma-js";

const DepotTransferStatusDropdown = ({
  //works with any api endpoint that gives id, name and colour
  className = "",
  name,
  value,
  setValue,
  authorised = false,
  loading: externalLoading,
}) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  const loadOptions = () => {
    setLoading(true);
    axiosAPI
      .get("/statuses/depotTransfers")
      .then(result => {
        setOptions(result.data || []);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    //preload options
    loadOptions();
  }, []); // eslint-disable-line

  const dot = (color = "#ccc") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.colour);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.colour
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.colour,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? data.colour : color.alpha(0.3).css()),
        },
      };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({
      ...styles,
      ...{ color: data?.colour || null },
      ...dot(data.colour),
    }),
  };

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <FormGroup className="suggestInputFormGroup mb-0">
        <Select
          classNamePrefix="suggestInput"
          className={className}
          value={value}
          name={name}
          onChange={value => setValue(value)}
          noOptionsMessage={query => <>No statuses found</>}
          options={options.map(option => ({
            ...option,
            isDisabled: option.requiresAuthorisation && !authorised,
          }))}
          styles={colourStyles}
          isLoading={loading || externalLoading}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
        />
      </FormGroup>
    </>
  );
};

DepotTransferStatusDropdown.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.object,
  setValue: PropTypes.func,
};

export default DepotTransferStatusDropdown;
