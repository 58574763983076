import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import Select from "react-select";
const _ = require("lodash");

const VersionDropdown = ({
  //works with any api endpoint that gives id, name and colour
  className = "",
  placeholder = "Search...",
  label,
  loading,
  versions = [],
  value,
  setValue,
}) => {
  return (
    <Select
      className={className}
      value={value}
      onChange={value => setValue(value)}
      noOptionsMessage={query => <>No versions found</>}
      placeholder={placeholder}
      options={versions}
      filterOption={(label, value, option) =>
        _.toString(label.data?.versionNumber)
          .trim()
          .toLowerCase()
          .indexOf(value.toLowerCase().trim()) > -1 ||
        _.toString(label.data?.name)
          .trim()
          .toLowerCase()
          .indexOf(value.toLowerCase().trim()) > -1 ||
        _.toString(label.data?.memo)
          .trim()
          .toLowerCase()
          .indexOf(value.toLowerCase().trim()) > -1
      }
      isLoading={loading}
      getOptionValue={option => option.id}
      getOptionLabel={option => (
        <>
          <Badge style={{ marginTop: "-2px" }}>v{option.versionNumber}</Badge>
          <span style={{ margin: "0 5px", display: "inline-block" }}>
            {option.name}
          </span>
          {option.isCurrent && <small className="text-success">CURRENT</small>}
        </>
      )}
    />
  );
};

VersionDropdown.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  versions: PropTypes.array,
  value: PropTypes.object,
  setValue: PropTypes.func,
};

export default VersionDropdown;
