import React from "react";
import FileExplorerTheme from "App/components/Tables/SortableTreeCommon/Theme/";
import PurchaseOrderTableRow from "./PurchaseOrderTableRow";
import styles from "./PurchaseOrderTable.module.scss";
import { SortableTreeWithoutDndContext as SortableTree } from "react-sortable-tree";
const externalNodeType = "item";

const PurchaseOrderTable = ({
  treeData,
  onTreeChange,
  getNodeKey,
  canNodeHaveChildren,
  updateNode,
  deleteNode,
  restoreName,
  selectRow,
  moveFocusToNextRow,
  costNominals,
  taxRates,
  enabled,
}) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.header}>
        <div className={`${styles.headerCell} ${styles.headerRowPad}`}>
          Name
        </div>
        {/*<div className={`${styles.headerCell} ${styles.headerProduct}`}>
          Product
        </div>*/}
        <div className={styles.headerCell}>Tax Rate</div>
        <div className={styles.headerCell}>Nominal</div>
        <div className={styles.headerCell}>Cost</div>
        <div className={styles.headerCell}>Total</div>
      </div>
      <SortableTree
        theme={FileExplorerTheme}
        dndType={externalNodeType}
        treeData={treeData}
        canDrag={enabled}
        onChange={onTreeChange}
        getNodeKey={getNodeKey}
        canNodeHaveChildren={canNodeHaveChildren}
        generateNodeProps={({ node, path }) => {
          return PurchaseOrderTableRow({
            node,
            path,
            updateNode,
            deleteNode,
            restoreName,
            selectRow,
            moveFocusToNextRow,
            costNominals,
            taxRates,
            enabled,
          });
        }}
      />
    </div>
  );
};

export default PurchaseOrderTable;
