import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";
import Select from "react-select";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import chroma from "chroma-js";
import { useField } from "formik";
const classNames = require("classnames");

const StatusDropdown = ({
  //works with any api endpoint that gives id, name and colour
  className = "",
  placeholder = "Search...",
  label,
  apiEndpoint,
  ...props
}) => {
  const [, meta] = useField(props);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  const loadOptions = () => {
    setLoading(true);
    axiosAPI
      .get(apiEndpoint)
      .then(result => {
        setOptions(result.data);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    //preload options
    loadOptions();
  }, []); // eslint-disable-line

  const dot = (color = "#ccc") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.colour);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.colour
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.colour,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? data.colour : color.alpha(0.3).css()),
        },
      };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({
      ...styles,
      ...{ color: data?.colour || null },
      ...dot(data.colour),
    }),
  };

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <FormGroup className="suggestInputFormGroup">
        <label htmlFor={props.id || props.name}>{label}</label>
        <Select
          classNamePrefix="suggestInput"
          className={classNames({
            "is-invalid": meta.touched && meta.error,
            "is-valid": meta.touched && !meta.error,
          })}
          value={props.values[props.name]}
          ref={props.setRef}
          name={props.name}
          onBlur={() => props.setFieldTouched(props.name, true)}
          onChange={value => props.setFieldValue(props.name, value || null)}
          noOptionsMessage={query => <>No statuses found</>}
          label={placeholder}
          placeholder={placeholder}
          options={options}
          styles={colourStyles}
          isLoading={loading}
          isClearable
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
        />
        {meta.touched && (
          <div
            className={classNames({
              "invalid-feedback": !!meta.error,
              "valid-feedback": !meta.error,
              feedback: true,
            })}
          >
            {meta.error && Object.values(meta.error).map(error => error)}
          </div>
        )}
      </FormGroup>
    </>
  );
};

StatusDropdown.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  apiEndpoint: PropTypes.string,
};

export default StatusDropdown;
