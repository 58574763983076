import React from "react";
import { DragSource } from "react-dnd";
import { Button } from "reactstrap";
import styles from "./AddButton.module.scss";
const classNames = require("classnames");

const externalNodeType = "item";
const externalNodeSpec = {
  // This needs to return an object with a property `node` in it.
  // Object rest spread is recommended to avoid side effects of
  // referencing the same object in different trees.
  beginDrag: componentProps => ({ node: { ...componentProps.node } }),
};
const externalNodeCollect = (connect /* , monitor */) => ({
  connectDragSource: connect.dragSource(),
  // Add props via react-dnd APIs to enable more visual
  // customization of your component
  // isDragging: monitor.isDragging(),
  // didDrop: monitor.didDrop(),
});

const AddButton = ({ connectDragSource, node, icon, onClick }) => {
  return connectDragSource(
    <div className="d-inline-block">
      <Button
        style={{ height: "35px", paddingTop: "6px" }}
        color="info"
        onClick={() => onClick(node)}
      >
        <i
          className={classNames({
            fa: true,
            [icon]: true,
            [styles.icon]: true,
          })}
        />
        {node.name}
      </Button>
    </div>,
    { dropEffect: "copy" },
  );
};

export default DragSource(
  externalNodeType,
  externalNodeSpec,
  externalNodeCollect,
)(AddButton);

/*
<div
  className={`${styles.button} col-xs-6 col-sm-4 col-md-2 col-lg-1`}
  onClick={() => onClick(node)}
>
  <i
    className={classNames({
      fa: true,
      [icon]: true,
      [styles.icon]: true
    })}
  />
  {node.name}
</div>
*/
