import React from "react";
import { Card, Row } from "reactstrap";
import PageContainer from "App/components/PageContainer";
import JobsListContainer from "App/components/Tables/JobsList/JobsList.container";

const Jobs = () => {
  return (
    <PageContainer title="Jobs">
      <Row>
        <div className="col">
          <Card className="shadow">
            <JobsListContainer />
          </Card>
        </div>
      </Row>
    </PageContainer>
  );
};

export default Jobs;
