import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DocumentPickerModal from "./DocumentPickerModal";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";

const DocumentPickerModalContainer = ({
  visible = false,
  setVisible,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [stationery, setStationery] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  useEffect(() => {
    if (visible) {
      getDocumentsAndStationery();
    }
  }, [visible]); // eslint-disable-line

  const getDocumentsAndStationery = () => {
    try {
      setLoading(true);
      axiosAPI.get(`/documents`).then(result => {
        setDocuments(result?.data || []);
      });
      axiosAPI.get(`/stationery`).then(result => {
        setStationery(result?.data || []);
      });
    } catch (error) {
      setErrorMessage(error?.errorMessage || "An unknown error occurred");
      setErrorMore(error?.errorMore || "Please try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <DocumentPickerModal
        visible={visible}
        setVisible={setVisible}
        loading={loading}
        stationery={stationery}
        documents={documents}
        {...rest}
      />
    </>
  );
};

DocumentPickerModalContainer.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  done: PropTypes.func,
};

export default DocumentPickerModalContainer;
