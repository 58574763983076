import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import UserModal from "App/components/Modals/UserModal/UserModal";
import axiosAPI from "App/services/axios";

const UserModalContainer = ({
  edit = false,
  visible = false,
  setVisible,
  id = null,
  done,
}) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  useEffect(() => {
    if (visible && id) {
      getUser(id);
    }
  }, [id, visible]); // eslint-disable-line

  const getUser = id => {
    setLoading(true);
    axiosAPI
      .get(`/users/${id}?showDeleted=true`)
      .then(result => {
        setUser(result.data || null);
        setLoading(false);
      })
      .catch(error => {
        setVisible(false);
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong getting users, please retry",
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addUser = async user => {
    setLoading(true);
    await axiosAPI
      .post("/users", user)
      .then(result => {
        setVisible(false);
        done();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage || "An error occurred while adding new user",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  const editUser = async user => {
    setLoading(true);
    await axiosAPI
      .patch(`/users/${user.id}`, user)
      .then(result => {
        setVisible(false);
        done();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage || "An error occurred while editing user",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  return (
    <>
      {!!errorMessage && (
        <SweetAlert
          danger
          onConfirm={() => {
            setErrorMessage(null);
            setErrorMore(null);
          }}
          title="Error"
        >
          {errorMessage}
          {errorMore && (
            <>
              <br />
              <small>{errorMore}</small>
            </>
          )}
        </SweetAlert>
      )}
      <UserModal
        visible={visible}
        user={user}
        edit={edit}
        setVisible={setVisible}
        editUser={editUser}
        addUser={addUser}
        loading={loading}
      />
    </>
  );
};

UserModalContainer.propTypes = {
  edit: PropTypes.bool,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  user: PropTypes.object,
  done: PropTypes.func,
};

export default UserModalContainer;
