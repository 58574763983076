import React from "react";
import styles from "./SupplyingTableRow.module.scss";
import {
  Label,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import currencyFormatter from "App/helpers/currencyFormatter";
import { Link } from "react-router-dom";
import truncateText from "App/helpers/truncateText";
const classNames = require("classnames");

const SupplyingTableRow = ({
  node,
  path,
  showMarginAsPercent,
  showDiscountAsPercent,
  updateNode,
  deleteNode,
  restoreName,
  selectRow,
  selectPricing,
  moveFocusToNextRow,
}) => {
  const isCustomPricing = node.type === 0 && !node?.PriceListItemId;
  const customPricingWarning = `Custom pricing has been used`;

  return {
    //the qty section of the row
    selectRow,
    dragHandle: (
      <i
        className={classNames({
          fa: true,
          "mx-1": true,
          "fa-box": node.type === 0,
          "opacity-4": node.type === 0,
          "fa-folder": node.type === 1 && !node.expanded,
          "fa-folder-open": node.type === 1 && node.expanded,
        })}></i>
    ),
    qtyTitle: (
      <>
        {/*<i
          onClick={e => selectRow(node, path, e)}
          className={`${node.selected ? "fa" : "far"} fa-circle`}
          style={{ fontSize: "0.6rem", marginRight: "5px" }}
        ></i>*/}
        {node.type === 0 && (
          <>
            <input
              className={`${styles.inputQuantity} ${styles.editableInput}`}
              value={node.quantity}
              name="quantity"
              type="number"
              min="0"
              step="1"
              onFocus={e => e.target.select()}
              onKeyDown={e => {
                e.key === "Enter" &&
                  moveFocusToNextRow(node, "quantityInput", path);
              }}
              autoComplete="none"
              onChange={event => {
                updateNode(node, "quantity", event.target.value, path);
              }}
            />
            &times;
          </>
        )}
        <input
          className={`${
            node.type === 1 ? styles.folderName : styles.inputName
          } ${styles.editableInput}`}
          value={node.name}
          name="name"
          onFocus={e => e.target.select()}
          onChange={event => {
            updateNode(node, "name", event.target.value, path);
          }}
        />
        {node.type === 0 && (
          <Link
            to={`/app/product/${node?.ProductId}`}
            className={styles.productCodeLink}
            target="_blank">
            {truncateText(node?.Product?.manufacturerProductCode, 15)}
          </Link>
        )}
      </>
    ),
    //the main row
    row: (
      <>
        {/*<div
          className={`${styles.tableProduct} ${styles.tableCell} ${
            node.type === 0 ? styles.nonEditableCell : null
          } ${styles.productCell}`}
        >
          {node.type === 0 && (
            <>
              {truncateText(node?.Product?.Manufacturer?.name)} -{" "}
              {node?.Product?.manufacturerProductCode}
            </>
          )}
        </div>*/}
        <div
          className={`${styles.tableCell} ${
            node.type === 0 ? styles.nonEditableCell : null
          }`}>
          <Label
            className={classNames({
              [styles.inputNumberLabel]: true,
              [styles.customPricingLabel]: isCustomPricing,
            })}>
            £
            {isCustomPricing && (
              <span className={styles.tooltiptext}>{customPricingWarning}</span>
            )}
          </Label>
          <input
            className={styles.inputNumber}
            style={{ fontWeight: node.type === 1 ? 700 : "" }}
            value={currencyFormatter(node.costPrice, false)}
            name="costPrice"
            type="text"
            disabled
          />
        </div>
        <div
          className={`${styles.tableCell} ${
            node.type === 0 ? styles.editableCell : null
          }`}>
          {!showMarginAsPercent && (
            <Label className={`${styles.inputNumberLabel}`}>£</Label>
          )}
          <input
            className={styles.inputNumber}
            style={{ fontWeight: node.type === 1 ? 700 : "" }}
            value={showMarginAsPercent ? node.marginPercent : node.marginTotal}
            type="number"
            name="margin"
            step="0.1"
            min="0"
            onFocus={e => e.target.select()}
            disabled={node.type !== 0}
            onChange={event => {
              updateNode(node, "margin", event.target.value, path);
            }}
          />
          {showMarginAsPercent && (
            <Label
              className={`${styles.inputNumberLabel} ${styles.inputNumberLabelRight}`}>
              %
            </Label>
          )}
        </div>
        <div
          className={`${styles.tableCell} ${
            node.type === 0 ? styles.nonEditableCell : null
          }`}>
          <>
            <Label
              className={classNames({
                [styles.inputNumberLabel]: true,
                [styles.customPricingLabel]: isCustomPricing,
              })}>
              £
              {isCustomPricing && (
                <span className={styles.tooltiptext}>
                  {customPricingWarning}
                </span>
              )}
            </Label>
            <input
              className={styles.inputNumber}
              style={{ fontWeight: node.type === 1 ? 700 : "" }}
              value={currencyFormatter(node.listPrice, false)} //currencyFormatter(node.listPrice, false)
              type="text"
              name="listPrice"
              min="0"
              disabled
            />
          </>
        </div>
        <div
          className={`${styles.tableCell} ${
            node.type === 0 ? styles.editableCell : null
          }`}>
          {!showDiscountAsPercent && (
            <Label className={`${styles.inputNumberLabel}`}>£</Label>
          )}
          <input
            className={styles.inputNumber}
            style={{ fontWeight: node.type === 1 ? 700 : "" }}
            value={
              showDiscountAsPercent ? node.discountPercent : node.discountTotal
            }
            type="number"
            step="0.1"
            name="discount"
            max="100"
            onFocus={e => e.target.select()}
            disabled={node.type !== 0}
            onChange={event => {
              updateNode(node, "discount", event.target.value, path);
            }}
          />
          {showDiscountAsPercent && (
            <Label
              className={`${styles.inputNumberLabel} ${styles.inputNumberLabelRight}`}>
              %
            </Label>
          )}
        </div>
        <div
          className={`${styles.tableCell} ${
            node.type === 0 ? styles.editableCell : null
          }`}>
          <Label className={styles.inputNumberLabel}>£</Label>
          <input
            className={styles.inputNumber}
            style={{ fontWeight: node.type === 1 ? 700 : "" }}
            value={
              node.type === 0
                ? node.linePrice
                : currencyFormatter(node.linePrice, false)
            }
            type="number"
            step="0.01"
            name="linePrice"
            min="0"
            disabled={node.type !== 0}
            onChange={event => {
              updateNode(node, "linePrice", event.target.value, path);
            }}
          />
        </div>
        <div className={`${styles.tableCell} ${styles.totalCell}`}>
          <Label
            className={classNames({
              [styles.inputNumberLabel]: true,
              [styles.totalLabelNoTax]:
                node?.TaxRate?.id !== 1 && node.type === 0,
            })}>
            £
            {node?.TaxRate?.id !== 1 && node.type === 0 && (
              <span className={styles.tooltiptext}>
                {node?.TaxRate?.rate}% tax rate for this product
              </span>
            )}
          </Label>
          <input
            className={`${styles.inputNumber}`}
            style={{ fontWeight: node.type === 1 ? 700 : "" }}
            value={currencyFormatter(node.total, false)}
            type="text"
            name="total"
            min="0"
            disabled
          />
        </div>
      </>
    ),
    //right hand side ... button
    menuOptions: (
      <UncontrolledButtonDropdown size="sm" color="link">
        <DropdownToggle color="link" caret>
          Options
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem
            onClick={() => {
              deleteNode(path);
            }}>
            Delete
          </DropdownItem>
          {node.type === 0 && (
            <>
              <DropdownItem
                onClick={() => {
                  restoreName(node, path);
                }}>
                Restore name
              </DropdownItem>
              <DropdownItem onClick={() => selectPricing(node, path)}>
                Select Pricing
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    ),
  };
};

export default SupplyingTableRow;
