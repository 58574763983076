import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
// import styles from "./DocumentPickerModal.module.scss";

const DocumentPickerModal = ({
  visible = false,
  setVisible,
  loading,
  documents = [],
  stationery = [],
  done,
}) => {
  const [selectedDocument, setSelectedDocument] = useState(undefined);
  const [selectedStationery, setSelectedStationery] = useState(undefined);

  useEffect(() => {
    if (stationery?.length) {
      setSelectedStationery(stationery?.[1]?.id);
    }
  }, [stationery]);

  useEffect(() => {
    if (documents?.length) {
      setSelectedDocument(documents?.[1]?.id);
    }
  }, [documents]);

  return (
    <Modal
      className="modal-dialog-centered formModal"
      isOpen={visible}
      data-testid="modal"
      backdrop="static"
    >
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border" product="status">
            <span className="sr-only">loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Select Document &amp; Stationery</h2>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setVisible(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <FormGroup row>
              <Label for="documentSelect" sm={2}>
                Document
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="document"
                  id="documentSelect"
                  value={selectedDocument}
                  onChange={e => setSelectedDocument(e.target.value)}
                >
                  {documents?.map(document => {
                    return (
                      <option
                        value={document?.id}
                        key={`document_${document?.id}`}
                      >
                        {document?.name}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="stationerySelect" sm={2}>
                Stationery
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="stationery"
                  id="stationerySelect"
                  value={selectedStationery}
                  onChange={e => setSelectedStationery(e.target.value)}
                >
                  {stationery?.map(s => {
                    return (
                      <option value={s?.id} key={`stationery_${s?.id}`}>
                        {s?.name}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setVisible(false)} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => done({ selectedDocument, selectedStationery })}
              color="primary"
              type="submit"
              data-testid="submitbutton"
            >
              Done
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

DocumentPickerModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  loading: PropTypes.bool,
};

export default DocumentPickerModal;
