import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Spinner,
  Container,
  Col,
  Table,
} from "reactstrap";
import PageContainer from "App/components/PageContainer";
import { useParams, NavLink } from "react-router-dom";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import currencyFormatter from "App/helpers/currencyFormatter";
const _ = require("lodash");

const Product = () => {
  const { tab = "product", id: productId = null } = useParams();
  //let history = useHistory();

  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [depots, setDepots] = useState([]);

  const getProduct = () => {
    setLoading(true);
    axiosAPI
      .get(`/products/${productId}`)
      .then(({ data }) => setProduct(data))
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDepots = () => {
    setLoading(true);
    axiosAPI
      .get(`/depots`)
      .then(({ data }) => setDepots(data))
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getProduct();
    getDepots();
  }, [productId]); // eslint-disable-line

  if (loading)
    return (
      <Spinner type="grow" className="d-block my-8 mx-auto" color="warning" />
    );

  const {
    stock,
    allocated,
    purchased,
    transfer,
    corrections,
    available,
    stockValueCost,
    stockValueList,
  } = product?.availability || {};

  return (
    <PageContainer title={product?.name || "Product"}>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage("");
          setErrorMore("");
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <Row>
        <div className="col">
          <Card className="shadow">
            <Nav tabs className="main-tabs">
              <NavItem>
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to={`/app/product/${productId}`}
                  exact={true}
                >
                  Details
                </NavLink>
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to={`/app/product/${productId}/inventory`}
                  exact={true}
                >
                  Inventory
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tab}>
              <TabPane tabId={"product"}>
                <Container fluid className="py-md-4">
                  <Row>
                    <Col>
                      <h2>Inventory</h2>
                    </Col>
                  </Row>
                  <hr className="mt-0 mb-3" />
                  <Table responsive hover className="grayHeaders">
                    <thead>
                      <tr>
                        <th>Depot</th>
                        <th>Stock to Hand</th>
                        <th>Stock Value Cost</th>
                        <th>Stock Value List</th>
                        <th>Allocated on Jobs</th>
                        <th>On Purchase</th>
                        <th>Awaiting Transfer In/Out</th>
                        <th>Corrections</th>
                        <th>Available</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(product?.availability?.available || {}).map(
                        depotId => {
                          const depot =
                            depots?.find(x => x.id === +depotId) || {};

                          const availableQty = _.get(available, depotId, 0);

                          return (
                            <tr>
                              <td>{depot?.name}</td>
                              <td>{_.get(stock, depotId, 0)}</td>
                              <td>
                                {currencyFormatter(
                                  _.get(stockValueCost, depotId, 0),
                                )}
                              </td>
                              <td>
                                {currencyFormatter(
                                  _.get(stockValueList, depotId, 0),
                                )}
                              </td>
                              <td>{_.get(allocated, depotId, 0)}</td>
                              <td>{_.get(purchased, depotId, 0)}</td>
                              <td>{_.get(transfer, depotId, 0)}</td>
                              <td>{_.get(corrections, depotId, 0)}</td>
                              <td>
                                {availableQty >= 0 ? (
                                  <span className="text-success">
                                    {availableQty}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    {availableQty}
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </Table>
                </Container>
              </TabPane>
            </TabContent>
          </Card>
        </div>
      </Row>
    </PageContainer>
  );
};

export default Product;
