import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
} from "reactstrap";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import hasPermission from "App/helpers/hasPermission";
import StepNavItem from "App/components/Navigation/StepNavItem";
import InitialConfigurator from "./InitialConfigurator";
import HeaderConfigurator from "./HeaderConfigurator";
import ManufacturerConfigurator from "./ManufacturerConfigurator";
import ProductConfigurator from "./ProductConfigurator";
import OptionsConfigurator from "./OptionsConfigurator";
import BackNextButtons from "App/components/Navigation/BackNextButtons";
import commonStyles from "./PriceListImportModal.module.scss";
const _ = require("lodash");
// const example = require("./example").default; //csv/xlsx converted to json - "COMPLICATED IMPORT"
// const example2 = require("./example2").default; //csv/xlsx converted to json - "VDC TRADING"
// const exampleData = require("./exampleData").exampleData; //mapped data to headers - "COMPLICATED IMPORT"
// const exampleData2 = require("./exampleData2").exampleData2; //mapped data to headers - "VDC TRADING"
// const exampleManufacturers = require("./exampleManufacturers")
//   .exampleManufacturers; //mapped manufacturers to manufacturers in db or create new automatically - "COMPLICATED IMPORT" - vdc is single manufacturer so no example for that

const PriceListImportModalContainer = ({ visible = false, setVisible }) => {
  //************LOADING, ERRORS, SAVING************
  const [cancelWarning, setCancelWarning] = useState(null);
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  //************FINAL PRICELIST DB STUFF************
  const initialPriceListData = {
    name: null,
    enabled: true,
    startDate: null,
    endDate: null,
    memo: null,
    CompanyId: null,
    PriceListFileId: null,
    minimumOrderQuantity: null,
  };
  const [priceList, setPriceList] = useState(initialPriceListData); //holds the object that will become the PriceList database entry for this whole import
  const [data, setData] = useState([]); //exampleData2, worksheet XLSX/CSV data, mapped to headers using HeaderConfigurator, used for seqeuelize bulkcreate

  //************STEP 1: INITIAL CONFIGURATOR************
  const [vendor, setVendor] = useState(null); //who price list is from
  /*
  {
    id: 2,
    name: "Amber Sound Ltd"
  }*/
  const [multipleManufacturer, setMultipleManufacturer] = useState(true); //one or multiple manufacturers in initial configurator
  const [manufacturer, setManufacturer] = useState(null); //which manufacturer if single manufacturer
  /* example manufacturers:
    {
      id: 1,
      name: "d&b audiotechnik",
      key: "DBAUDIOTECHNIK",
      createdAt: "2020-04-13T14:09:36.000Z",
      updatedAt: "2020-04-13T14:09:36.000Z",
      deletedAt: null
    }

    {
      id: 26,
      name: "VDC Trading",
      key: "VDCTRADING",
      createdAt: "2020-04-13T14:09:36.000Z",
      updatedAt: "2020-04-13T14:09:36.000Z",
      deletedAt: null
    }
    */
  const initialUpload = {
    //filepond info
    base64File: null, //example or example2
    fileUploaded: false, //true
  };
  const [upload, setUpload] = useState(initialUpload);

  //************STEP 2: HEADER CONFIGURATOR************
  const [assignedAllFields, setAssignedAllFields] = useState(false); //has header configurator assigned all fields out

  //************STEP 3: MANUFACTURER CONFIGURATOR************
  //Nb. this step is skipped if its a single manufacturer set in step 1
  //Its sole job is to make sure the right manufacturers are mapped
  const [manufacturerList, setManufacturerList] = useState([]); //exampleManufacturers

  //************STEP 4: PRODUCT CONFIGURATOR************
  const [productConfigurationHasIssues, setProductConfigurationHasIssues] =
    useState(false);

  //************STEP 5: OPTION CONFIGURATOR************
  //Mostly uses priceList variable, but also can do some minor other features
  const [expireOtherPriceLists, setExpireOtherPriceLists] = useState([]);
  const [optionsConfigurationHasIssues, setOptionsConfigurationHasIssues] =
    useState(true);

  //************STEP 5: OPTION CONFIGURATOR************
  const [importing, setImporting] = useState(false);
  const [importResults, setImportResults] = useState(null);
  const resetImportProcess = () => {
    //Reset so can do another import
    setVisible(false);
    setCancelWarning(null);
    setStep(1);
    setPriceList(initialPriceListData);
    setData([]);
    setVendor(null);
    setMultipleManufacturer(true);
    setManufacturer(null);
    setUpload(initialUpload);
    setAssignedAllFields(false);
    setManufacturerList([]);
    setProductConfigurationHasIssues(false);
    setOptionsConfigurationHasIssues(true);
    setImporting(false);
    setImportResults(null);
  };
  const importPriceList = async () => {
    setImporting(true);
    await axiosAPI
      .post(
        `/priceLists/import`,
        {
          priceList: priceList,
          manufacturers: multipleManufacturer
            ? manufacturerList
            : [
                {
                  ...manufacturer,
                  manufacturerName: manufacturer.name,
                  database: manufacturer,
                  autoCreateManufacturer: false,
                  existsInDatabase: true,
                  foundAutomaticallyInDatabase: true,
                },
              ],
          products: data,
          expirePriceLists: expireOtherPriceLists,
        },
        {
          timeout: 60000,
        },
      )
      .then(result => {
        console.log(result.data);
        setImportResults(result.data);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(
          error?.errorMore ||
            "Something went wrong importing price list, please retry",
        );
        console.error(error);
      })
      .finally(() => setImporting(false));
  };

  if (!hasPermission("PL_IMPORT")) return null;

  return (
    <Modal
      className="modal-dialog-centered formModal modal-big-ass"
      isOpen={visible}
      data-testid="modal"
    >
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>

      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        title="Are you sure?"
        onConfirm={() => {
          resetImportProcess();
          setCancelWarning(null);
          setVisible(false);
        }}
        onCancel={() => setCancelWarning(null)}
        focusCancelBtn
        show={!!cancelWarning}
      >
        Cancelling will lose any changes
      </SweetAlert>
      {importing ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Importing, please wait...</span>
          </div>
        </div>
      ) : importResults ? (
        <>
          <ModalHeader
            toggle={() => {
              setVisible(false);
            }}
          >
            <h2 className="mb-0">Import Results</h2>
          </ModalHeader>
          <ModalBody>
            <h3>Success</h3>
            <ul>
              <li>
                <strong>New Manufacturers auto-created:</strong>{" "}
                {importResults.newManufacturers} manufacturers
              </li>
              <li>
                <strong>New Products auto-created:</strong>{" "}
                {importResults.newProducts} products
              </li>
              <li>
                <strong>Expired other Price Lists:</strong>{" "}
                {importResults.expiredPriceLists} price lists
              </li>
              <li>
                <strong>Imported:</strong> {importResults.priceListItems} price
                list items
              </li>
            </ul>
            <Button
              color="primary"
              className="mt-2 mb-4"
              onClick={() => resetImportProcess()}
            >
              Done
            </Button>
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHeader
            toggle={() => {
              setCancelWarning(true);
            }}
          >
            <h2 className="mb-0">Import Price List</h2>
          </ModalHeader>
          <ModalBody>
            <Nav tabs className={`steps`}>
              <StepNavItem thisStep={1} activeStep={step}>
                Upload
              </StepNavItem>
              <StepNavItem thisStep={2} activeStep={step}>
                Headers
              </StepNavItem>
              {multipleManufacturer && (
                <StepNavItem thisStep={3} activeStep={step}>
                  Manufacturers
                </StepNavItem>
              )}
              <StepNavItem thisStep={4} activeStep={step}>
                Products
              </StepNavItem>
              <StepNavItem thisStep={5} activeStep={step}>
                Options
              </StepNavItem>
            </Nav>
            <TabContent activeTab={step}>
              <TabPane tabId={1} className={commonStyles.tabPane}>
                <InitialConfigurator
                  vendor={vendor}
                  setVendor={vendor => {
                    setVendor(vendor); //for the select dropdown
                    setPriceList({
                      ...priceList,
                      CompanyId: vendor?.id || null,
                    }); //for db saving later
                  }}
                  priceList={priceList}
                  setMinimumOrderQuantity={value =>
                    setPriceList({ ...priceList, minimumOrderQuantity: value })
                  }
                  setPriceListFileId={id =>
                    setPriceList({ ...priceList, PriceListFileId: id })
                  }
                  setUpload={newUpload =>
                    setUpload({ ...upload, ...newUpload })
                  }
                  manufacturer={manufacturer}
                  setManufacturer={manufacturer =>
                    setManufacturer(manufacturer)
                  }
                  multipleManufacturer={multipleManufacturer}
                  setMultipleManufacturer={isMultiple =>
                    setMultipleManufacturer(isMultiple)
                  }
                />
                <BackNextButtons
                  backVisible={false}
                  nextCallback={() => setStep(2)}
                  disableNext={
                    !upload.fileUploaded || //no file uploaded
                    _.isEmpty(vendor) || //empty vendor
                    (!multipleManufacturer && _.isEmpty(manufacturer)) //specified single manufacturer but not chosen
                  }
                  disableNextMessage="You must complete all fields before moving onto the next step"
                  enabledNextMessage="Looks good to us!"
                />
              </TabPane>
              <TabPane tabId={2} className={commonStyles.tabPane}>
                <HeaderConfigurator
                  base64File={upload.base64File}
                  multipleManufacturer={multipleManufacturer}
                  manufacturer={manufacturer}
                  assignedAllFields={assignedAllFields}
                  setAssignedAllFields={value => setAssignedAllFields(value)}
                  data={data}
                  setData={newData => setData(newData)}
                  currentStep={step}
                />
                <BackNextButtons
                  backVisible={true}
                  backCallback={() => setStep(1)}
                  nextCallback={() => {
                    multipleManufacturer ? setStep(3) : setStep(4);
                  }}
                  disableNext={!assignedAllFields}
                  disableNextMessage="You must assign all fields before moving onto the next step"
                  enabledNextMessage="Top notch work"
                />
              </TabPane>
              <TabPane tabId={3} className={commonStyles.tabPane}>
                <ManufacturerConfigurator
                  data={data}
                  currentStep={step}
                  manufacturerList={manufacturerList}
                  setManufacturerList={setManufacturerList}
                />
                <BackNextButtons
                  backVisible={true}
                  backCallback={() => setStep(2)}
                  nextCallback={() => setStep(4)}
                  disableNext={manufacturerList.some(manufacturer => {
                    if (
                      !manufacturer.existsInDatabase &&
                      !manufacturer.autoCreateManufacturer &&
                      _.isEmpty(manufacturer.database)
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  })}
                  disableNextMessage="You must assign all manufacturers before moving onto the next step"
                  enabledNextMessage="Great stuff"
                />
              </TabPane>
              <TabPane tabId={4} className={commonStyles.tabPane}>
                <ProductConfigurator
                  data={data}
                  setData={newData => setData(newData)}
                  currentStep={step}
                  multipleManufacturer={multipleManufacturer}
                  manufacturer={manufacturer}
                  manufacturerList={manufacturerList}
                  setProductConfigurationHasIssues={value =>
                    setProductConfigurationHasIssues(value)
                  }
                />
                <BackNextButtons
                  backVisible={true}
                  backCallback={() =>
                    multipleManufacturer ? setStep(3) : setStep(2)
                  }
                  nextCallback={() => setStep(5)}
                  disableNext={productConfigurationHasIssues}
                  disableNextMessage="You must assign all manual products, and have some products to import, before moving onto the next step"
                  enabledNextMessage="Great stuff"
                />
              </TabPane>
              <TabPane tabId={5} className={commonStyles.tabPane}>
                <OptionsConfigurator
                  vendor={vendor}
                  currentStep={step}
                  priceList={priceList}
                  setPriceList={setPriceList}
                  data={data}
                  multipleManufacturer={multipleManufacturer}
                  manufacturerList={manufacturerList}
                  expireOtherPriceLists={expireOtherPriceLists}
                  setExpireOtherPriceLists={value =>
                    setExpireOtherPriceLists(value)
                  }
                  setOptionsConfigurationHasIssues={value =>
                    setOptionsConfigurationHasIssues(value)
                  }
                />
                <BackNextButtons
                  backVisible={true}
                  backCallback={() => setStep(4)}
                  nextCallback={() => importPriceList()}
                  nextColor="success"
                  nextText="Import"
                  disableNext={optionsConfigurationHasIssues}
                  disableNextMessage="You must complete all fields before importing"
                  enabledNextMessage="Ready for liftoff"
                />
              </TabPane>
            </TabContent>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

PriceListImportModalContainer.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
};

export default PriceListImportModalContainer;
