import React from "react";
import PropTypes from "prop-types";
import { DropdownItem, UncontrolledTooltip } from "reactstrap";
import hasPermission from "App/helpers/hasPermission";
const { uniqueId } = require("lodash");

const DropdownWithPermissions = ({
  permission,
  testid,
  callback,
  text,
  tooltip,
  children,
  disabled = false, //an extra second conditional to check against
  disabledTooltip, //and its associated error
  visible = true,
}) => {
  let id = uniqueId("dropdown_");
  let enabled = hasPermission(permission);
  if (!visible) return null;
  return (
    <>
      <DropdownItem
        href="#"
        disabled={!enabled || !!disabled}
        role="menuitem"
        id={id}
        data-testid={testid}
        onClick={callback}
      >
        {text}
        {children}
      </DropdownItem>
      {!enabled ? (
        <UncontrolledTooltip delay={0} placement="top" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      ) : (
        !!disabled && (
          <UncontrolledTooltip delay={0} placement="top" target={id}>
            {disabledTooltip}
          </UncontrolledTooltip>
        )
      )}
    </>
  );
};

DropdownWithPermissions.propTypes = {
  permission: PropTypes.string,
  testid: PropTypes.string,
  callback: PropTypes.func,
  text: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
  visible: PropTypes.bool,
};

export default DropdownWithPermissions;
