import React from "react";

const JobStatus = ({ colour = "#ccc", name = "Unknown" }) => (
  <span className="statusText" style={{ color: colour }}>
    <span
      className="statusBlob"
      style={{
        backgroundColor: colour,
      }}
    />
    {name}
  </span>
);

export default JobStatus;
