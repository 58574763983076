import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Button,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import axiosAPI from "App/services/axios";
import ContactModal from "App/components/Modals/ContactModal";
import DropdownWithPermissions from "App/components/Dropdowns/DropdownWithPermissions";
import styles from "./ContactButton.module.scss";
import ContactProfileModalContainer from "App/components/Modals/ContactProfileModal/ContactProfileModal.container";

const ContactButtonContainer = ({ id, className = "" }) => {
  const [contact, setContact] = useState(null);
  const [contactProfileModalVisible, setContactProfileModalVisible] =
    useState(false);
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const getContact = useCallback(() => {
    setLoading(true);
    axiosAPI
      .get(`/contacts/${id}`)
      .then(result => {
        setContact(result.data || null);
      })
      .catch(error => {
        setError(true);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]); // eslint-disable-line

  useEffect(() => {
    getContact();
  }, [id]); // eslint-disable-line

  if (!id) return null;

  if (loading) {
    return (
      <Button color="secondary" size="sm" className={className} disabled={true}>
        Loading...
      </Button>
    );
  }

  if (error || !contact) {
    return (
      <Button color="danger" size="sm" className={className} disabled={true}>
        Could not load contact
      </Button>
    );
  }

  return (
    <UncontrolledButtonDropdown className={className} size="sm">
      <Button
        color="white"
        className={styles.button}
        onClick={() => setContactProfileModalVisible(true)}
      >
        <div className={styles.text}>
          {contact?.firstName} {contact?.lastName}
        </div>
      </Button>
      <DropdownToggle caret color="secondary" />
      <DropdownMenu>
        <DropdownItem onClick={() => setContactProfileModalVisible(true)}>
          See profile
        </DropdownItem>
        <DropdownWithPermissions
          permission="CONT_EDIT"
          callback={() => setContactModalVisible(true)}
          text="Edit contact"
          testid="editcontact"
          tooltip="You do not have permission to edit contacts"
          visible={true}
        />
        {(contact.email || contact.work || contact.mobile) && (
          <DropdownItem divider />
        )}
        {contact.email && (
          <DropdownItem
            onClick={() => (window.location = `mailto:${contact.email}`)}
          >
            Email
          </DropdownItem>
        )}
        {contact.work && (
          <DropdownItem
            onClick={() => (window.location = `tel:${contact.work}`)}
          >
            Call Work
          </DropdownItem>
        )}
        {contact.mobile && (
          <DropdownItem
            onClick={() => (window.location = `tel:${contact.mobile}`)}
          >
            Call Mobile
          </DropdownItem>
        )}
      </DropdownMenu>
      <ContactModal
        isOpen={contactModalVisible}
        id={id}
        initialValues={contact}
        edit={true}
        toggleModal={visible => setContactModalVisible(visible)}
        setReturnValue={contact => getContact()}
      />
      <ContactProfileModalContainer
        visible={contactProfileModalVisible}
        id={id}
        setVisible={setContactProfileModalVisible}
      />
    </UncontrolledButtonDropdown>
  );
};

ContactButtonContainer.propTypes = {
  id: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default ContactButtonContainer;
