import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as usersActions from "App/redux/users/actions";
import {
  Button,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import UserModalContainer from "App/components/Modals/UserModal/UserModal.container";
import DropdownWithPermissions from "App/components/Dropdowns/DropdownWithPermissions";
import Avatar from "react-avatar";
import styles from "./UserButton.module.scss";
import ProfileModalContainer from "App/components/Modals/ProfileModal/ProfileModal.container";
// const { uniqueId } = require("lodash");

const UserButtonContainer = ({ id, users, usersGet, className = "" }) => {
  const [user, setUser] = useState(null);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [userModalUser, setUserModalUser] = useState({});
  const [userModalEdit, setUserModalEdit] = useState(false);

  const getUserInfo = id => {
    if (
      !users.error &&
      (users.results.length === 0 ||
        !users.loadedOn ||
        Date.now() - users.loadedOn > 1000 * 60 * 60) //1hr - How long till users expire
    ) {
      usersGet();
    }
    let foundUser = users.results.find(u => u.id === id);
    if (foundUser) setUser(foundUser);
  };

  useEffect(() => {
    getUserInfo(id);
  }, [id, users.results, users.loadedOn]); // eslint-disable-line

  if (!id) return null;

  if (
    !users.loading &&
    (users.error || (users.loadedOn && !users.error && !user))
  ) {
    return (
      <Button color="danger" size="sm" className={className} disabled={true}>
        Could not load user
      </Button>
    );
  }
  if (users.loading || !user) {
    return (
      <Button color="secondary" size="sm" className={className} disabled={true}>
        Loading...
      </Button>
    );
  }

  return (
    <UncontrolledButtonDropdown className={className} size="sm">
      <Button
        color="white"
        className={styles.button}
        onClick={() => setProfileModalVisible(true)}
      >
        <div className={styles.avatar}>
          <Avatar
            src={
              user?.AvatarFile?.id
                ? process.env.REACT_APP_API_BASE_URL +
                  `/files/${user?.AvatarFile?.id}`
                : null
            }
            name={user.fullName}
            size="25"
            className={`bg-default`}
            round
          />
        </div>
        <div className={styles.text}>{user.fullName}</div>
      </Button>
      <DropdownToggle caret color="secondary" />
      <DropdownMenu>
        <DropdownItem onClick={() => setProfileModalVisible(true)}>
          See profile
        </DropdownItem>
        <DropdownWithPermissions
          permission="USER_EDIT"
          callback={() => {
            setUserModalUser(user.id);
            setUserModalEdit(true);
            setUserModalVisible(true);
          }}
          text="Edit user"
          testid="edituser"
          tooltip="You do not have permission to edit users"
          visible={true}
        />
        {(user.email || user.phone) && <DropdownItem divider />}
        {user.email && (
          <DropdownItem
            onClick={() => (window.location = `mailto:${user.email}`)}
          >
            Email
          </DropdownItem>
        )}
        {user.phone && (
          <DropdownItem onClick={() => (window.location = `tel:${user.phone}`)}>
            Call
          </DropdownItem>
        )}
      </DropdownMenu>
      <UserModalContainer
        visible={userModalVisible}
        id={userModalUser}
        edit={userModalEdit}
        setVisible={setUserModalVisible}
        done={() => usersGet()}
      />
      <ProfileModalContainer
        visible={profileModalVisible}
        id={user.id}
        setVisible={setProfileModalVisible}
      />
    </UncontrolledButtonDropdown>
  );
};

UserButtonContainer.propTypes = {
  id: PropTypes.number.isRequired,
  className: PropTypes.string,
};

const mapStateToProps = state => {
  return { users: state.users };
};

const mapDispatchToProps = dispatch => {
  return {
    usersGet: () => {
      dispatch(usersActions.usersGet());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserButtonContainer);
