import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ShipmentModal from "./ShipmentModal";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";

const ShipmentModalContainer = ({
  id,
  purchaseOrderId,
  depotTransferId,
  visible = false,
  setVisible,
  edit = false,
  done,
  preSelectType = null,
  preSelectDepot = null,
}) => {
  const [shipment, setShipment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);

  useEffect(() => {
    if (visible && id) {
      getShipment(id);
    }
  }, [id, visible]); // eslint-disable-line

  const getShipment = id => {
    setLoading(true);
    axiosAPI
      .get(`/shipments/${id}`)
      .then(result => {
        setShipment(result.data || null);
      })
      .catch(error => {
        setVisible(false);
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addShipment = async shipment => {
    setLoading(true);
    await axiosAPI
      .post("/shipments", {
        ...shipment,
        PurchaseOrderId: purchaseOrderId,
        DepotTransferId: depotTransferId,
      })
      .then(result => {
        setVisible(false);
        done(result.data.id);
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage || "An error occurred while adding new shipment",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  const editShipment = async shipment => {
    setLoading(true);
    await axiosAPI
      .patch(`/shipments/${shipment.id}`, shipment)
      .then(result => {
        setVisible(false);
        done();
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(
          error?.errorMessage || "An error occurred while editing shipment",
        );
        setErrorMore(error?.errorMore || "An unknown server error occurred");
      });
  };

  return (
    <>
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <ShipmentModal
        visible={visible}
        shipment={edit ? shipment : {}}
        edit={edit}
        setVisible={setVisible}
        editShipment={editShipment}
        addShipment={addShipment}
        loading={loading}
        preSelectType={preSelectType}
        preSelectDepot={preSelectDepot}
      />
    </>
  );
};

ShipmentModalContainer.propTypes = {
  id: PropTypes.number,
  purchaseOrderId: PropTypes.number,
  depotTransferId: PropTypes.number,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  edit: PropTypes.bool,
  done: PropTypes.func,
  preSelectType: PropTypes.string,
};

export default ShipmentModalContainer;
