import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Row,
  Col,
  Container,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import DropdownWithPermissions from "App/components/Dropdowns/DropdownWithPermissions";
import PurchaseOrderModalContainer from "App/components/Modals/PurchaseOrderModal/PurchaseOrderModal.container";
import DepotTransferModalContainer from "App/components/Modals/DepotTransferModal/DepotTransferModal.container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faBan,
  faDolly,
  //faAngleUp,
  faAngleDown,
  // faSortAmountDownAlt,
  // faSortAmountUp,
  // faClipboardCheck,
  // faTachometerAlt,
  // faWarehouse,
  // faMapMarkedAlt,
  faShippingFast,
  faStopwatch,
  faPlug,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./JobOrderingTab.module.scss";
import axiosAPI from "App/services/axios";
import SweetAlert from "react-bootstrap-sweetalert";
import DepotTransferTab from "App/components/Tabs/DepotTransferTab";
import PurchaseOrderTab from "App/components/Tabs/PurchaseOrderTab";
import { toast } from "react-toastify";
import IdleTimer from "react-idle-timer";

const JobOrderingTab = ({ job, isFocused = false }) => {
  //*************IS ACTIVE***************
  const idleTimer = useRef();
  const thisTab = useRef();
  const toastInactive = useRef(null);
  const [isActive, setIsActive] = useState(true); //whether user is actively using

  //*************IS ACTIVE***************
  const { id: jobId } = job;
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabType, setTabType] = useState("purchaseOrder");
  const [tabId, setTabId] = useState(1);

  //*************PURCHASE ORDERS**************
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [purchaseOrderModal, setPurchaseOrderModal] = useState({
    id: null,
    visible: false,
    edit: false,
  });

  //*************DEPOT TRANSFERS**************
  const [depotTransfers, setDepotTransfers] = useState([]);
  const [depotTransferModal, setDepotTransferModal] = useState({
    id: null,
    visible: false,
    edit: false,
  });

  const getPurchaseOrders = () => {
    setLoading(true);
    axiosAPI
      .get(`/jobs/${jobId}/purchaseOrders`)
      .then(result => {
        setPurchaseOrders(result.data?.rows || []);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDepotTransfers = () => {
    setLoading(true);
    axiosAPI
      .get(`/jobs/${jobId}/depotTransfers`)
      .then(result => {
        setDepotTransfers(result.data?.rows || []);
      })
      .catch(error => {
        setErrorMessage(error?.errorMessage || "An unknown error occurred");
        setErrorMore(error?.errorMore || "Something went wrong, please retry");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    //preload options / when becomes active
    if (isActive && isFocused && jobId) {
      getPurchaseOrders();
      getDepotTransfers();
      toast.dismiss(toastInactive.current);
    } else {
      if (isFocused) {
        toastInactive.current = toast.info(
          <div className="toastText">
            <FontAwesomeIcon icon={faPlug} />
            Ordering updates disconnected due to inactivity. Move your mouse
            cursor to reconnect.
          </div>,
          {
            position: "bottom-right",
            autoClose: false,
            closeButton: false,
          },
        );
      } else {
        toast.dismiss(toastInactive.current);
      }
    }
  }, [isActive, isFocused, jobId]); // eslint-disable-line

  const TabOption = ({
    text,
    smallText,
    status,
    color,
    tabType: thisTabType,
    tabId: thisTabId,
  }) => (
    <ListGroupItem
      tag="button"
      action
      className="cursor-pointer active-all-white"
      active={tabId === thisTabId && tabType === thisTabType}
      onClick={() => {
        setTabId(thisTabId);
        setTabType(thisTabType);
      }}
    >
      <span>
        {text}
        {color && status && (
          <small
            className={`float-right pt-1`}
            style={{ color: color || "#ccc" }}
          >
            <FontAwesomeIcon className="mr-1" icon={faCircle} />
            {status}
          </small>
        )}
      </span>
      {smallText && (
        <small className="mt--1 d-block text-muted">{smallText}</small>
      )}
    </ListGroupItem>
  );

  if (loading)
    return (
      <Spinner type="grow" className="d-block my-8 mx-auto" color="warning" />
    );

  return (
    <Container
      fluid
      className="py-md-4"
      style={{ background: "#f5f5f5" }}
      ref={thisTab}
    >
      <IdleTimer
        ref={idleTimer.current}
        element={thisTab.current}
        onActive={e => setIsActive(true)}
        onIdle={e => setIsActive(false)}
        onAction={e => {}}
        debounce={250}
        startOnLoad
        timeout={1000 * 300}
      />
      <SweetAlert
        danger
        onConfirm={() => {
          setErrorMessage(null);
          setErrorMore(null);
        }}
        title="Error"
        show={!!errorMessage}
      >
        {errorMessage}
        {errorMore && (
          <>
            <br />
            <small>{errorMore}</small>
          </>
        )}
      </SweetAlert>
      <Row>
        <Col xl={3}>
          <div>
            <UncontrolledButtonDropdown className="mr-2">
              <DropdownToggle color="success" caret>
                New
              </DropdownToggle>
              <DropdownMenu>
                {/*<DropdownWithPermissions
                  permission="DEPOT_TRANSFER_CREATE"
                  callback={() =>
                    setDepotTransferModal({
                      id: null,
                      edit: false,
                      visible: true
                    })
                  }
                  tooltip="You do not have permission to create a depot transfer"
                >
                  Depot Transfer
                  <small
                    className="d-block text-muted"
                    style={{ fontSize: "75%", margintop: "-2px" }}
                  >
                    Transfer stock from other depots into this depot
                  </small>
                </DropdownWithPermissions>*/}
                <DropdownWithPermissions
                  permission="PO_CREATE"
                  callback={() => {
                    setPurchaseOrderModal({
                      id: null,
                      edit: false,
                      visible: true,
                    });
                  }}
                  tooltip="You do not have permission to create a new Purchase Order"
                >
                  Purchase Order
                  <small
                    className="d-block text-muted"
                    style={{ fontSize: "75%", margintop: "-2px" }}
                  >
                    Purchase stock from a vendor
                  </small>
                </DropdownWithPermissions>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
          {/*
          <ListGroup className="mb-4 mt-3">
            <ListGroupItem
              tag="button"
              action
              active={tabType === "overview"}
              onClick={() => {
                setTabType("overview");
                setTabId(null);
              }}
              className="border-secondary cursor-pointer"
              style={{ borderWidth: 0, borderLeftWidth: "5px" }}
            >
              <FontAwesomeIcon icon={faTachometerAlt} className="mr-2" />
              Overview
            </ListGroupItem>
            <ListGroupItem
              tag="button"
              action
              active={tabType === "shortfalling"}
              onClick={() => {
                setTabType("shortfalling");
                setTabId(null);
              }}
              className="border-danger cursor-pointer"
              style={{ borderWidth: 0, borderLeftWidth: "5px" }}
            >
              <FontAwesomeIcon icon={faSortAmountDownAlt} className="mr-2" />
              Shortfalling <small>&times; 14</small>
            </ListGroupItem>
            <ListGroupItem
              tag="button"
              action
              active={tabType === "overordered"}
              onClick={() => {
                setTabType("overordered");
                setTabId(null);
              }}
              className="border-warning cursor-pointer"
              style={{ borderWidth: 0, borderLeftWidth: "5px" }}
            >
              <FontAwesomeIcon icon={faSortAmountUp} className="mr-2" />
              Overordered <small>&times; 3</small>
            </ListGroupItem>
            <ListGroupItem
              tag="button"
              action
              active={tabType === "allocated"}
              onClick={() => {
                setTabType("allocated");
                setTabId(null);
              }}
              className="border-success cursor-pointer"
              style={{ borderWidth: 0, borderLeftWidth: "5px" }}
            >
              <FontAwesomeIcon icon={faClipboardCheck} className="mr-2" />
              Allocated <small>&times; 25</small>
            </ListGroupItem>
          </ListGroup>*/}

          {/*<h4 className="text-black ml-1 mb-2 mt-4">
            <FontAwesomeIcon className="mr-2" icon={faWarehouse} />
            Depot Transfers
          </h4>
          <ListGroup className="mt-1 mb-3">
            {depotTransfers &&
              depotTransfers?.length > 0 &&
              depotTransfers.map(depotTransfer => (
                <>
                  <TabOption
                    text={depotTransfer?.name || "Depot Transfer"}
                    smallText={`from ${depotTransfer?.FromDepot?.name}, ${depotTransfer?.FromDepot?.Address?.county} to ${depotTransfer?.ToDepot?.name}, ${depotTransfer?.ToDepot?.Address?.county}`}
                    status={depotTransfer?.DepotTransferStatus?.name}
                    color={depotTransfer?.DepotTransferStatus?.colour}
                    tabType="depotTransfer"
                    tabId={depotTransfer?.id}
                  />
                </>
              ))}
          </ListGroup>*/}

          <h4 className="text-black ml-1 mb-2 mt-4">
            <FontAwesomeIcon className="mr-2" icon={faShippingFast} />
            Purchase Orders
          </h4>
          <ListGroup className="mt-1 mb-3">
            {purchaseOrders &&
              purchaseOrders?.length > 0 &&
              purchaseOrders.map(purchaseOrder => (
                <>
                  <TabOption
                    text={purchaseOrder?.name || "Purchase Order"}
                    smallText={`${purchaseOrder?.Contact?.Company?.name} - ${
                      purchaseOrder?.AccountEnvironment?.purchaseOrderPrefix
                    }${purchaseOrder?.number || ""}`}
                    status={purchaseOrder?.PurchaseOrderStatus?.name}
                    color={purchaseOrder?.PurchaseOrderStatus?.colour}
                    tabType="purchaseOrder"
                    tabId={purchaseOrder?.id}
                  />
                </>
              ))}
          </ListGroup>
        </Col>

        <Col xl={9} className={styles.supplierDetail}>
          <TabContent activeTab={tabType}>
            <TabPane tabId="overview">
              <h1>
                Stock at SFL Group<small className="ml-1">Reading</small>
              </h1>
              <h2 className="text-success mt-5">
                <FontAwesomeIcon className="mr-2" icon={faCircle} />
                In stock
              </h2>
              <div className="pl-4">
                <h5 className="mt-2">
                  <FontAwesomeIcon className="mr-1" icon={faAngleDown} />2
                  &times; Y7P Loudspeaker NL4, New
                </h5>
                <ListGroup flush className="mt-1 mb-3 ml-2 smallListGroup">
                  <ListGroupItem>
                    <small>
                      Serial: Z0418014808140, from d&b audiotechnik, PO13012
                    </small>
                    <Button
                      color="warning"
                      onClick={() => {}}
                      outline
                      size="sm"
                      style={{ float: "right" }}
                    >
                      <FontAwesomeIcon className="mr-2" icon={faBan} />
                      Unallocate
                    </Button>
                  </ListGroupItem>
                  <ListGroupItem>
                    <small>
                      Serial: Z02474207420724, from d&b audiotechnik, PO13012
                    </small>
                    <Button
                      color="warning"
                      onClick={() => {}}
                      outline
                      size="sm"
                      style={{ float: "right" }}
                    >
                      <FontAwesomeIcon className="mr-2" icon={faBan} />
                      Unallocate
                    </Button>
                  </ListGroupItem>
                </ListGroup>
                <h5 className="mt-2">
                  <FontAwesomeIcon className="mr-1" icon={faAngleDown} />1
                  &times; Aviom 16-channel A-Net out card for..., New
                </h5>
                <ListGroup flush className="mt-1 mb-3 ml-2 smallListGroup">
                  <ListGroupItem>
                    <small>Serial: AVR239, from Amber Sound, PO8018</small>
                    <Button
                      color="warning"
                      onClick={() => {}}
                      outline
                      size="sm"
                      style={{ float: "right" }}
                    >
                      <FontAwesomeIcon className="mr-2" icon={faBan} />
                      Unallocate
                    </Button>
                  </ListGroupItem>
                </ListGroup>
              </div>
              <h2 className="text-warning mt-5">
                <FontAwesomeIcon className="mr-2" icon={faDolly} />
                In transit
              </h2>
              <div className="pl-4">
                <h5 className="mt-2">
                  <FontAwesomeIcon className="mr-1" icon={faAngleDown} />1
                  &times; Clear-Com HelixNet 2-channel dual-listen monaural
                  beltpack with..., New
                </h5>
                <ListGroup flush className="mt-1 mb-3 ml-2 smallListGroup">
                  <ListGroupItem>
                    <small>
                      Expected delivery in 1 week from Amber Sound, PO8018
                    </small>
                    <Button
                      color="warning"
                      onClick={() => {}}
                      outline
                      size="sm"
                      style={{ float: "right" }}
                    >
                      <FontAwesomeIcon className="mr-2" icon={faBan} />
                      Unallocate
                    </Button>
                  </ListGroupItem>
                </ListGroup>
              </div>
              <h2 className="text-danger mt-5">
                <FontAwesomeIcon className="mr-2" icon={faStopwatch} />
                Overdue
              </h2>
              <div className="pl-4">
                <h5 className="mt-2">
                  <FontAwesomeIcon className="mr-1" icon={faAngleDown} />1
                  &times; Clear-Com HelixNet base station
                </h5>
                <ListGroup flush className="mt-1 mb-3 ml-2 smallListGroup">
                  <ListGroupItem>
                    <small>
                      Expected delivery 3 weeks ago from K&M Stands, PO10129
                    </small>
                    <Button
                      color="warning"
                      onClick={() => {}}
                      outline
                      size="sm"
                      style={{ float: "right" }}
                    >
                      <FontAwesomeIcon className="mr-2" icon={faBan} />
                      Unallocate
                    </Button>
                  </ListGroupItem>
                </ListGroup>
              </div>
            </TabPane>
            <TabPane tabId="shortfall">shortfall</TabPane>
            <TabPane tabId="depotTransfer">
              <DepotTransferTab
                depotTransfer={depotTransfers.find(a => a.id === tabId) || null}
                isFocused={tabType === "depotTransfer"}
                isActive={isActive}
                setDepotTransferModal={setDepotTransferModal}
                setDepotTransfer={depotTransfer =>
                  setDepotTransfers(
                    depotTransfers.map(dt =>
                      dt.id === depotTransfer.id ? depotTransfer : dt,
                    ),
                  )
                }
                refresh={() => getDepotTransfers()}
              />
            </TabPane>
            <TabPane tabId="purchaseOrder">
              <PurchaseOrderTab
                purchaseOrder={purchaseOrders.find(a => a.id === tabId) || null}
                isFocused={tabType === "purchaseOrder"}
                isActive={isActive}
                setPurchaseOrderModal={setPurchaseOrderModal}
                setPurchaseOrder={purchaseOrder =>
                  setPurchaseOrders(
                    purchaseOrders.map(po =>
                      po.id === purchaseOrder.id ? purchaseOrder : po,
                    ),
                  )
                }
                refresh={() => getPurchaseOrders()}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
      <PurchaseOrderModalContainer
        id={purchaseOrderModal.id}
        jobId={jobId}
        visible={purchaseOrderModal.visible}
        setVisible={visible =>
          setPurchaseOrderModal({ ...purchaseOrderModal, visible })
        }
        edit={purchaseOrderModal.edit}
        done={newId => {
          getPurchaseOrders();
          if (newId) {
            setTabType("purchaseOrder");
            setTabId(newId);
          }
        }}
      />
      <DepotTransferModalContainer
        id={depotTransferModal.id}
        jobId={jobId}
        depotId={job.DepotId}
        visible={depotTransferModal.visible}
        setVisible={visible =>
          setDepotTransferModal({ ...depotTransferModal, visible })
        }
        edit={depotTransferModal.edit}
        done={newId => {
          getDepotTransfers();
          if (newId) {
            setTabType("depotTransfer");
            setTabId(newId);
          }
        }}
      />
    </Container>
  );
};

JobOrderingTab.propTypes = {
  job: PropTypes.object,
};

export default JobOrderingTab;
