import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, Row, Button, Col } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  TextInput,
  CheckboxInput,
  DateInput,
} from "App/components/Inputs/BasicFormInputs.js";
import DepotDropdown from "App/components/Dropdowns/DepotDropdown";

const DepotTransferModal = ({
  edit = false,
  visible = false,
  setVisible,
  jobId = null,
  depotId = null,
  depotTransfer = {},
  addDepotTransfer,
  editDepotTransfer,
  loading,
}) => {
  return (
    <Modal
      className="modal-dialog-centered formModal"
      isOpen={visible}
      data-testid="modal"
      backdrop="static"
    >
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <div className="spinner-border">
            <span className="sr-only">loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="modal-header">
            <h2 className="modal-title">
              {edit ? "Edit" : "Create"} Depot Transfer
            </h2>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setVisible(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: depotTransfer?.name || "",
              transportRequired: depotTransfer?.transportRequired ?? false,
              memo: depotTransfer?.memo || "",
              requiredBy: depotTransfer?.requiredBy || null,
              FromDepot: depotTransfer?.FromDepot,
              ToDepot: depotTransfer?.ToDepot,
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .required("Required")
                .max(200, "Cannot be longer than 200 characters"),
              transportRequired: Yup.boolean(),
              memo: Yup.string().nullable(),
              FromDepot: Yup.object().shape({
                id: Yup.number("Error with from depot ID").required(
                  "From Depot is required",
                ),
              }),
              ToDepot: Yup.object().shape({
                id: Yup.number("Error with to depot ID").required(
                  "To Depot is required",
                ),
              }),
              requiredBy: Yup.date(
                "Required by date is not a valid date",
              ).nullable(),
            })}
            onSubmit={(values, { setSubmitting }) => {
              //console.log(values);
              let newValues = {
                ...values,
                transportRequired: depotTransfer
                  ? values.transportRequired
                  : false,
                requiredBy: values.requiredBy,
                JobId: jobId,
                FromDepotId: values.FromDepot.id,
                ToDepotId: values.ToDepot.id,
              };
              //Convert all "" to null values
              //this seems to make it angry when it gets to transport: false thing
              /*newValues = _.transform(
                newValues,
                (result, value, key) => {
                  return (result[key] = value === "" ? null : value);
                },
                {}
              );*/
              //console.log(newValues);

              if (edit) {
                editDepotTransfer({ id: depotTransfer.id, ...newValues });
              } else addDepotTransfer(newValues);
            }}
          >
            {formikProps => {
              return (
                <>
                  <ModalBody>
                    <Form>
                      <Row>
                        <TextInput label="Name" name="name" col={12} />
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <DepotDropdown
                            label="From Depot"
                            name="FromDepot"
                            excludeDepots={[depotId]}
                            {...formikProps}
                          />
                        </Col>
                        <Col xs={6}>
                          <DepotDropdown
                            label="To Depot"
                            name="ToDepot"
                            {...formikProps}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <div className="pt-4 mt-2 col-lg-6">
                          <CheckboxInput
                            enablevalidation={false}
                            name="transportRequired"
                          >
                            Transport Required
                          </CheckboxInput>
                        </div>
                        <DateInput
                          label="Delivery required by"
                          name="requiredBy"
                          col={6}
                        />
                      </Row>
                      <Row>
                        <TextInput
                          label="Internal Memo"
                          name="memo"
                          col={12}
                          type="textarea"
                        />
                      </Row>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      onClick={formikProps.submitForm}
                      color="primary"
                      type="submit"
                      data-testid="submitbutton"
                    >
                      Done
                    </Button>
                  </ModalFooter>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </Modal>
  );
};

DepotTransferModal.propTypes = {
  edit: PropTypes.bool,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  depotTransfer: PropTypes.object,
  addDepotTransfer: PropTypes.func,
  editDepotTransfer: PropTypes.func,
  loading: PropTypes.bool,
};

export default DepotTransferModal;
