import React, { useState, useRef } from "react";
//import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import RemoteTable from "App/components/Tables/RemoteTable/RemoteTable";
import SweetAlert from "react-bootstrap-sweetalert";
import ManufacturerModalContainer from "App/components/Modals/ManufacturerModal/ManufacturerModal.container";
import DropdownWithPermissions from "App/components/Dropdowns/DropdownWithPermissions";

const ManufacturersListContainer = () => {
  const remoteTable = useRef();
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMore, setErrorMore] = useState(null);
  const [manufacturerModal, setManufacturerModal] = useState({
    id: null,
    visible: false,
    edit: false,
  });

  const actions = {
    editManufacturer: id =>
      setManufacturerModal({ id, visible: true, edit: true }),
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      sortModel: null,
      sortField: "name",
      sorting: true,
    },
    {
      title: "Key",
      field: "key",
      sortModel: null,
      sortField: "key",
      sorting: true,
    },
    {
      title: "Options",
      field: "id",
      sorting: false,
      disableClick: true,
      render: row => (
        <UncontrolledButtonDropdown size="sm" color="primary">
          <DropdownToggle color="primary" caret>
            Options
          </DropdownToggle>
          <DropdownMenu>
            <DropdownWithPermissions
              permission="MANF_EDIT"
              callback={() => actions.editManufacturer(row.id)}
              text="Edit"
              testid="editmanufacturer"
              tooltip="You do not have permission to edit manufacturers"
            />
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      ),
    },
  ];

  return (
    <>
      {!!errorMessage && (
        <SweetAlert
          danger
          onConfirm={() => {
            setErrorMessage(null);
            setErrorMore(null);
          }}
          title="Error"
        >
          {errorMessage}
          {errorMore && (
            <>
              <br />
              <small>{errorMore}</small>
            </>
          )}
        </SweetAlert>
      )}
      <RemoteTable
        apiEndpoint="/manufacturers"
        title="Manufacturers"
        defaultSortModels={null}
        defaultSortField="name"
        defaultSortOrder="ASC"
        columns={columns}
        actions={actions}
        ref={remoteTable}
        tableActions={(() => {
          let tableActions = [];
          return tableActions;
        })()}
      />
      <ManufacturerModalContainer
        {...manufacturerModal}
        setVisible={visible =>
          setManufacturerModal({ ...manufacturerModal, visible })
        }
        done={() => remoteTable.current.refreshData()}
      />
    </>
  );
};

export default ManufacturersListContainer;
